import axios from 'axios';
import { notifyIsLoading, notifyApiErrorMessage, notifyApiErrorDetails } from 'providers/apiStateProvider';
import { RESET_RESPONSE } from 'constants/constants';

const getAccessToken = () => {
    var oktaTokenStorage = JSON.parse(localStorage.getItem("okta-token-storage"));

    return oktaTokenStorage?.accessToken?.accessToken;
}

const _headers = () => {
    return {
        'Authorization': 'Bearer ' + getAccessToken(),
        'Content-Type': 'application/json'
    }
};

const _formDataHeaders = () => {
    return {
        'Authorization': 'Bearer ' + getAccessToken(),
        'Content-Type': 'multipart/form-data'
    }
};

const appendBaseUri = (uri) => {
    return `${process.ringiEnv.REACT_APP_API_ROOT_PATH}/${uri}`;
}

const fetch = async (uri) => {
    return await callAPI(async () => {
        const response = await axios.get(appendBaseUri(uri), {
            headers: {
                ..._headers()
            }
        });

        if (validResponse(response.status)) {
            return response.data;
        }
        else {
            return null;
        }
    });
}

const httpDelete = async (uri) => {
    return await callAPI(async () => {
        const response = await axios.delete(appendBaseUri(uri), {
            headers: {
                ..._headers()
            }
        });

        if (response.status === 205) {
            return RESET_RESPONSE;
        }
        else if (validResponse(response.status)) {
            return response.data;
        }
        else {
            return null;
        }
    });
}

const post = async (uri, data) => {
    return await callAPI(async () => {
        const response = await axios.post(appendBaseUri(uri), data, {
            headers: {
                ..._headers()
            }
        });
        if (validResponse(response.status)) {
            return response.data;
        }
        else {
            return null;
        }
    });
}

const postFormData = async (uri, formData) => {
    return await callAPI(async () => {
        const response = await axios.post(appendBaseUri(uri), formData, {
            headers: {
                ..._formDataHeaders()
            }
        });
        if (validResponse(response.status)) {
            return response.data;
        }
        else {
            return null;
        }
    });
}

const put = async (uri, data) => {
    return await callAPI(async () => {
        const response = await axios.put(appendBaseUri(uri), data, {
            headers: {
                ..._headers()
            }
        });
        if (response.status === 205) {
            return RESET_RESPONSE;
        }
        else if (validResponse(response.status)) {
            return response.data;
        }
        else {
            return null;
        }
    });
}

const downloadFile = async (uri, downloadedFileId) => {
    return await callAPI(async () => {
        const response = await axios.get(appendBaseUri(uri), {
            headers: {
                'Authorization': 'Bearer ' + getAccessToken()
            },
            responseType: 'blob'
        });

        if (validResponse(response.status)) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadedFileId);
            link.click();
            window.URL.revokeObjectURL(url)
        }
    });
}

const patch = async (uri, ...patchOperations) => {
    return await callAPI(async () => {
        const response = await axios.patch(appendBaseUri(uri), patchOperations, {
            headers: {
                ..._headers()
            }
        });
        if (validResponse(response.status)) {
            return response.data;
        }
        else {
            return null;
        }
    });
}

class PatchOperation {
    constructor(type, propertyPath, value) {
        this.op = type;
        this.path = propertyPath;
        this.value = value;
    }
}

const validResponse = (status) => {
    return status >= 200 && status < 300;
}

const callAPI = async (asyncAPICallFunction) => {
    notifyIsLoading(true);
    notifyApiErrorMessage(null);
    notifyApiErrorDetails(null);
    try {
        return await asyncAPICallFunction();
    }
    catch (error) {
        if (error.response.status !== 404) {
            if (error.response?.data?.isDevEnv === false) {
                notifyApiErrorMessage("");
                notifyApiErrorDetails({
                    errorId: error.response?.data?.errorId,
                    supportEmail: error.response?.data?.supportEmail,
                    isDevEnv: error.response?.data?.isDevEnv
                });
            }
            else {
                let errorMessage;
                const responseMessage = error.response?.data?.message;
                if (responseMessage) {
                    errorMessage = responseMessage;
                    const detail = error.response?.data?.detail;
                    if (detail) {
                        errorMessage += "\n\n" + detail;
                    }
                }
                else if (typeof error.response?.data === "string") {
                    errorMessage = error.response?.data
                }
                else {
                    errorMessage = error.toString() ?? "Unexpected error";
                }
                notifyApiErrorMessage(errorMessage);
                notifyApiErrorDetails({
                    isDevEnv: error.response?.data?.isDevEnv
                });
            }
        }
        return null;
    }
    finally {
        notifyIsLoading(false);
    }
};

export {
    fetch,
    post,
    postFormData,
    put,
    downloadFile,
    httpDelete,
    patch,
    PatchOperation
};