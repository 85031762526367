import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocale, formatLocalized } from 'services/localization/localizationContextProvider';
import { useAuthorizationClaims } from 'providers/authorizationClaimsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faShare } from '@fortawesome/free-solid-svg-icons';
import Authorized from 'components/Authorized';
import { Button } from 'react-bootstrap';
import { put, post, httpDelete } from 'services/api';
import ApproverCard from 'pages/Ringis/components/Approvers/ApproverCard';
import ApproverMenu from 'pages/Ringis/components/Approvers/ApproverMenu';
import { useSubmittedRingi } from 'pages/Ringis/providers/submittedRingiContextProvider';
import { useModal, ModalBody, ModalButtons, ModalOkButton, ModalSubmitButton, ModalCancelButton, ModalForm } from 'controls/ActionModal/ActionModal';
import { APPROVER_RESPONSE } from 'constants/enums';
import { RESET_RESPONSE } from 'constants/constants';
import ToggleSwitch from 'controls/ToggleSwitch/ToggleSwitch';
import { useNotificationsClaim } from 'providers/notificationsProvider';
import 'pages/Ringis/components/Approvers/ApproverList.scss';
import SendToJapanEmailSelection from 'pages/Ringis/components/Approvers/SendToJapanEmailSelection';
import classNames from 'classnames';

export default function ApproverList() {
    const [canViewAddApprover, setCanViewAddApprover] = useState(false);
    const [toggleKey, setToggleKey] = useState(0);
    const [currentApprovalLevel, setCurrentApprovalLevel] = useState(-1);
    const [DeleteApproverConfirmationModal, showDeleteApproverConfirmationModal] = useModal();
    const [CompleteConfirmationModal, showCompleteConfirmationModal, hideCompleteConfirmationModal] = useModal();
    const [SendToJapanConfirmationModal, showSendToJapanConfirmationModal, hideSendToJapanConfirmationModal] = useModal();
    const [deleteApproverMessage, setDeleteApproverMessage] = useState("");
    const [isProxyEnabled, setProxyEnabled] = useState(false);
    const [deleteApproverTitle, setDeleteApproverTitle] = useState("");
    const { loginName, isAdmin } = useAuthorizationClaims();
    const [hasPendingApprovers, setHasPendingApprovers] = useState(false);
    const currentEmailAddresses = useRef([]);

    const { ringi, approvers, isLockedDown, refreshRingi, refreshApprovers, refreshComments, refreshHistoryLogs } = useSubmittedRingi();
    
    const {
        pages: {
            view_ringi: {
                approval_chain: approvalChainLocalization
            }
        },
        modals: {
            confirmation: {
                approver: {
                    removingFromChain: removeApproverLocalization
                },
                adminReview: {
                    options: adminOptionsLocalization,
                    complete: completeLocalization,
                    sendToJapan: sendToJapanLocalization
                }
            }
        }
    } = useLocale();

    const { fetchNormalNotificationsCount } = useNotificationsClaim();

    useEffect(() => {
        var pendingApprovers = approvers.filter(approver => approver.approverResponseId === APPROVER_RESPONSE.Pending);
        var currentApprovalLevel = pendingApprovers.length === 0 ? -1 :
            pendingApprovers.reduce((previous, current) => previous.approvalLevel < current.approvalLevel ? previous : current).approvalLevel;
        setCurrentApprovalLevel(currentApprovalLevel);
        setHasPendingApprovers(pendingApprovers.length > 0);
    }, [approvers]);

    useEffect(() => {
        const isApprover = approvers.some(ra => ra.user?.loginName?.trim().toLocaleLowerCase() === loginName);
        setCanViewAddApprover(isAdmin || isApprover);
    }, [isAdmin, approvers, loginName]);

    const setShiftApprovalLevelMessage = useCallback((defaultMessage) => {
        setDeleteApproverMessage(`${removeApproverLocalization.shift_approval_level_message}\n\n${defaultMessage}`);
        setDeleteApproverTitle(removeApproverLocalization.default_title);
    }, [removeApproverLocalization]);

    const setRingiCompleteMessage = useCallback((defaultMessage) => {
        setDeleteApproverMessage(`${removeApproverLocalization.ringi_will_complete_message}\n\n${defaultMessage}`);
        setDeleteApproverTitle(removeApproverLocalization.ringi_will_complete_title);
    }, [removeApproverLocalization]);

    const setRingiAdminReviewMessage = useCallback((defaultMessage) => {
        setDeleteApproverMessage(`${removeApproverLocalization.ringi_change_status_to_pending_admin_review_message}\n\n${defaultMessage}`);
        setDeleteApproverTitle(removeApproverLocalization.ringi_change_status_to_pending_admin_review_title);
    }, [removeApproverLocalization]);

    const setSendToJapanMessage = useCallback((defaultMessage) => {
        setDeleteApproverMessage(`${removeApproverLocalization.ringi_send_to_japan_message}\n\n${defaultMessage}`);
        setDeleteApproverTitle(removeApproverLocalization.ringi_send_to_japan_title);
    }, [removeApproverLocalization]);

    const toggleProxy = (newValue) => {
        setProxyEnabled(newValue);
    }

    const handleDeleteApprover = useCallback((approver) => {
        var approverName = approver.isJapaneseApprovalApprover ? approvalChainLocalization.headquarter_approval_card_name : approver.user.fullName
        const defaultMessage = formatLocalized(removeApproverLocalization.default_message, { approverName: approverName });

        const isLastApprover = approvers.slice(-1).some(ra => ra.id === approver.id);
        const everyOtherApproverApproved = approvers.filter(ra => ra.id !== approver.id).every(ra => ra.approverResponseId === APPROVER_RESPONSE.Approved);

        const numberOfApproversInLevel = approvers.filter(ra => ra.approvalLevel === approver.approvalLevel).length;

        const subsidiaryApprovers = approvers.filter(ra => ra.userId != null);
        const isLastSubsidiaryApprover = subsidiaryApprovers.slice(-1).some(ra => ra.id === approver.id);
        const everyOtherSubsidiaryApproverApproved = subsidiaryApprovers.filter(ra => ra.id !== approver.id).every(ra => ra.approverResponseId === APPROVER_RESPONSE.Approved);

        if (isLastApprover && everyOtherApproverApproved) {
            if (ringi.requiresAdminReview) {
                setRingiAdminReviewMessage(defaultMessage);
            }
            else {
                setRingiCompleteMessage(defaultMessage);
            }
        }
        else if (isLastSubsidiaryApprover && everyOtherSubsidiaryApproverApproved) {
            setSendToJapanMessage(defaultMessage);
        }
        else if (numberOfApproversInLevel === 1) {
            setShiftApprovalLevelMessage(defaultMessage);
        }
        else {
            setDeleteApproverMessage(defaultMessage);
            setDeleteApproverTitle(removeApproverLocalization.default_title);
        }

        showDeleteApproverConfirmationModal(approver);
    }, [setShiftApprovalLevelMessage, ringi.requiresAdminReview, setRingiAdminReviewMessage, setRingiCompleteMessage, setSendToJapanMessage, removeApproverLocalization, approvalChainLocalization, approvers, showDeleteApproverConfirmationModal]);

    const handleDeleteApproverConfirmation = async (approverToDelete) => {
        var response = await httpDelete(`ringis/${approverToDelete.ringiId}/approvers/${approverToDelete.id}`);
        if (response != null) {
            await refreshApprovers();
            if (response === RESET_RESPONSE) {
                await refreshRingi();
            }
        }
    }

    const handleApproverApprove = async (resetContent) => {
        fetchNormalNotificationsCount();
        await refreshApprovers();
        if (resetContent) {
            await refreshRingi();
        }
        setProxyEnabled(false);
        setToggleKey(toggleKey + 1);
    }

    const handleApproverDenied = async (resetContent) => {
        fetchNormalNotificationsCount();
        await refreshApprovers();
        if (resetContent) {
            await refreshRingi();
            await refreshComments();
        }
        setProxyEnabled(false);
        setToggleKey(toggleKey + 1);
    }

    const onCompleteRingiSubmit = async () => {
        hideCompleteConfirmationModal();
        const response = await put(`ringis/${ringi.id}/complete`);

        if (response === RESET_RESPONSE) {
            await refreshRingi();
            await refreshHistoryLogs();
        }
    };

    const sendRingiToJapan = async (formData) => {
        hideSendToJapanConfirmationModal();
        
        if (currentEmailAddresses.current.length === 0) {
            return;
        }

        const response = await put(`ringis/${ringi.id}/send`, JSON.stringify(currentEmailAddresses.current));

        if (response === RESET_RESPONSE) {
            await refreshRingi();
            await refreshApprovers();
        }

    };

    return (
        <>
            {isAdmin && <div className="proxy-toggle__container">
                <div className={classNames("proxy-toggle__label", {"disabled": isLockedDown})}>{approvalChainLocalization.proxy_mode}</div>
                <div>
                    <ToggleSwitch key={toggleKey} onSwitch={toggleProxy} enabled={!isLockedDown}></ToggleSwitch>
                </div>
            </div>}
            <div data-testid="approver-list" className="approver-list__approvers">
                {approvers.map((ringiApprover, i) => (
                    <ApproverCard key={i}
                        ringiApprover={ringiApprover}
                        onDelete={handleDeleteApprover}
                        isCurrentApprovalLevel={currentApprovalLevel === ringiApprover.approvalLevel}
                        refreshComments={refreshComments}
                        onApprove={handleApproverApprove}
                        onDeny={handleApproverDenied}
                        proxyEnabled={isProxyEnabled}
                    />
                ))}
                {ringi.showAdminReview && !hasPendingApprovers && <div data-testid="admin-review" className="admin-review">
                    <div className="admin-review__header">
                        Ringi Admin Review
                    </div>
                    <div className="admin-review__buttons">
                        <div className="admin-review__buttons--cell">
                            <Button
                                data-testid="complete-ringi-button"
                                className="text-nowrap"
                                onClick={showCompleteConfirmationModal}>
                                <FontAwesomeIcon icon={faCheck} /> {adminOptionsLocalization.complete}
                            </Button>
                        </div>
                        <div className="admin-review__buttons--cell">
                            <Button
                                data-testid="send-ringi-button"
                                className="text-nowrap"
                                onClick={showSendToJapanConfirmationModal}>
                                <FontAwesomeIcon icon={faShare} /> {adminOptionsLocalization.sendToJapan}
                            </Button>
                        </div>
                    </div>
                </div>}
            </div>
            <Authorized isAuthorized={canViewAddApprover}>
                <ApproverMenu />
            </Authorized>

            <DeleteApproverConfirmationModal variant='confirmation' title={deleteApproverTitle}>
                <ModalBody>{deleteApproverMessage}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton />
                    <ModalOkButton text={removeApproverLocalization.success} onClick={handleDeleteApproverConfirmation} />
                </ModalButtons>
            </DeleteApproverConfirmationModal>

            <CompleteConfirmationModal variant='confirmation' title={completeLocalization.title}>
                <ModalForm onSubmit={onCompleteRingiSubmit}>
                    <ModalBody>
                        {completeLocalization.message}
                    </ModalBody>
                    <ModalButtons>
                        <ModalCancelButton />
                        <ModalSubmitButton text={completeLocalization.success} />
                    </ModalButtons>
                </ModalForm>
            </CompleteConfirmationModal>

            <SendToJapanConfirmationModal data-testid="send-to-japan-modal" variant='confirmation' title={sendToJapanLocalization.title}>
                <ModalForm onSubmit={sendRingiToJapan}>
                    <ModalBody>
                        <SendToJapanEmailSelection defaultValue={ringi.divisionId} currentEmailAddresses={currentEmailAddresses} />
                    </ModalBody>
                    <ModalButtons>
                        <ModalCancelButton />
                        <ModalSubmitButton text={sendToJapanLocalization.success}>
                            <FontAwesomeIcon icon={faCheck} className="mr-2" />
                        </ModalSubmitButton>
                    </ModalButtons>
                </ModalForm>
            </SendToJapanConfirmationModal>
        </>
    );
}