import React from "react";
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton";
import { useLocale } from 'services/localization/localizationContextProvider';
import _ from "lodash";
import "./ReportYearPicker.scss";

const ReportYearPicker = ({ reportYear, setReportYear }) => {
    const yearRange = _.range(2016, (new Date().getFullYear() + 1)).reverse();
    const localizedReportYear = useLocale().pages.reports.report_year;
    return (
        <DropdownButton title={`${localizedReportYear} ${reportYear}`} className='year-picker'>
            {yearRange.map((year) => (
                <Dropdown.Item onClick={() => {
                    setReportYear(year);
                }}>{year}</Dropdown.Item>
            ))}
        </DropdownButton>
    );
}

export default ReportYearPicker;