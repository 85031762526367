import React from 'react';
import { useScreenDetect } from 'hooks/useScreenDetect';
import classNames from 'classnames';
import 'pages/Notification/NotificationPreferencePanel.scss';

export default function NotificationPreferencePanel(props) {
    const { preferenceId, isChecked, title, description, onChanged, isLast = false } = props;
    const { isDesktop } = useScreenDetect();
    const cbxId = `cbx-${preferenceId}`;

    const onChange = (state) => {
        onChanged(preferenceId, state);
    };

    return (
        <div className={classNames("preference-container", { "preference-container--desktop": isDesktop, "preference-container--last": isLast })}>
            <div className="preference-row">
                <div className="preference-column">
                    <input id={cbxId} data-testid={cbxId} type="checkbox" checked={isChecked} onChange={e => onChange(e.currentTarget.checked)} />
                </div>
                <div>
                    <div>
                        <label htmlFor={cbxId} className="preference-title">{title}</label>
                    </div>
                    <div className="preference-description">
                        {description}
                    </div>
                </div>
            </div>
        </div>
    );
};
