import React, { useContext, useEffect, useState, useCallback } from 'react';
import { fetch } from 'services/api';
import { useAuthorizationClaims } from 'providers/authorizationClaimsProvider';
import { useLocale } from 'services/localization/localizationContextProvider';
import { RINGI_STATUS } from 'constants/enums';

const SubmittedRingiContext = React.createContext();

const useSubmittedRingi = () => useContext(SubmittedRingiContext);

const SubmittedRingiContextProvider = ({ children, ringiId }) => {
    const [ringi, setRingi] = useState({});
    const [approvers, setApprovers] = useState([]);
    const [readers, setReaders] = useState([]);
    const [comments, setComments] = useState([]);
    const [historyLogs, setHistoryLogs] = useState([]);
    const [activeUsers, setActiveUsers] = useState([]);
    const [isLockedDown, setIsLockedDown] = useState(true);
    const [commentModsLockedDown, setCommentModsLockedDown] = useState(true);
    const [commentEntryLockedDown, setCommentEntryLockedDown] = useState(true);
    const [canPauseRingiForReply, setCanPauseRingiForReply] = useState(true);
    const [addReaderLockedDown, setAddReaderLockedDown] = useState(true);
    const { isAdmin } = useAuthorizationClaims();

    const { pages: {
        view_ringi: {
            history_logs: historyLogsLocalization
        }
    }
    } = useLocale();

    const fetchUserCanReadRingi = async (ringiId) => {
        return await fetch(`ringis/${ringiId}/userCanReadRingi`);
    };

    const fetchRingi = useCallback(async () => {
        if (ringiId) {
            if (await fetchUserCanReadRingi(ringiId)) {
                const ringi = await fetch(`ringis/${ringiId}/details`);
                if (ringi) {
                    setRingi(ringi);
                    setIsLockedDown(ringi.statusId === RINGI_STATUS.Approved || ringi.statusId === RINGI_STATUS.ApprovedWithConditions || ringi.statusId === RINGI_STATUS.Denied || (ringi.statusId === RINGI_STATUS.PendingJapan && !isAdmin) || ringi.statusId === RINGI_STATUS.Cancelled || ringi.statusId === RINGI_STATUS.Paused);
                    setCommentModsLockedDown(ringi.statusId === RINGI_STATUS.Approved || ringi.statusId === RINGI_STATUS.ApprovedWithConditions || ringi.statusId === RINGI_STATUS.Denied || ringi.statusId === RINGI_STATUS.Cancelled);
                    setCommentEntryLockedDown(((ringi.statusId === RINGI_STATUS.Approved || ringi.statusId === RINGI_STATUS.ApprovedWithConditions || ringi.statusId === RINGI_STATUS.Denied) && !isAdmin) || ringi.statusId === RINGI_STATUS.Cancelled);
                    setAddReaderLockedDown(!(ringi.statusId === RINGI_STATUS.Approved || ringi.statusId === RINGI_STATUS.ApprovedWithConditions || ringi.statusId === RINGI_STATUS.Denied || ringi.statusId === RINGI_STATUS.Pending || ringi.statusId === RINGI_STATUS.Paused || ringi.statusId === RINGI_STATUS.PendingJapan));
                    setCanPauseRingiForReply(ringi.canPauseRingiForReply);
                }
            }
        }
    }, [ringiId, isAdmin]);

    const fetchApprovalChain = useCallback(async () => {
        if (ringiId) {
            if (await fetchUserCanReadRingi(ringiId)) {
                const approvers = await fetch(`ringis/${ringiId}/approvers`) ?? [];
                setApprovers(approvers ?? []);
            }
        }
    }, [ringiId]);

    const fetchReaders = useCallback(async () => {
        if (ringiId) {
            if (await fetchUserCanReadRingi(ringiId)) {
                const readers = await fetch(`ringis/${ringiId}/readers`) ?? [];
                setReaders(readers);
            }
        }
    }, [ringiId]);

    const fetchComments = useCallback(async () => {
        if (ringiId) {
            if (await fetchUserCanReadRingi(ringiId)) {
                const comments = await fetch(`comments/ringi/${ringiId}`) ?? [];

                const ringiComments = comments?.map((comment) => {
                    return {
                        id: comment.id,
                        user: {
                            fullName: comment.createdByUserFullName
                        },
                        dateText: comment.createdModifiedDateIso8601,
                        text: comment.comment,
                        isCommentCreator: comment.isCommentCreator,
                        isCommentEdited: comment.isModified,
                        level: comment.level,
                        hasReplies: comment.hasReplies,
                        isReasonForDenial: comment.isReasonForDenial,
                        isReasonForCancel: comment.isReasonForCancel,
                        isForConditionalApproval: comment.isForConditionalApproval,
                        pauseForSubmitterResponse: comment.pauseForSubmitterResponse,
                        showPauseCheckbox: comment.showPauseCheckbox,
                        showReplyNeededMessage: comment.showReplyNeededMessage,
                        attachments: [...comment.attachments]
                    }
                });

                setComments([
                    ...ringiComments
                ]);
            }
        }
    }, [ringiId]);

    const fetchHistoryLogs = useCallback(async () => {
        if (ringiId) {
            if (await fetchUserCanReadRingi(ringiId)) {
                const historyLogResponse = await fetch(`historylogs/ringi/${ringiId}`);

                const historyLogs = historyLogResponse?.map((historyLog) => {
                    let logText = '';

                    switch (historyLog.historyLogEventId) {
                        case 1:
                            logText = historyLogsLocalization.event_1_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 2:
                            logText = historyLogsLocalization.event_2_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 3:
                            logText = historyLogsLocalization.event_3_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 4:
                            logText = historyLogsLocalization.event_4_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 5:
                            logText = historyLogsLocalization.event_5_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 6:
                            logText = historyLogsLocalization.event_6_message;
                            break;
                        case 7:
                            logText = historyLogsLocalization.event_7_message;
                            break;
                        case 8:
                            logText = historyLogsLocalization.event_8_message;
                            break;
                        case 9:
                            logText = historyLogsLocalization.event_9_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 10:
                            logText = historyLogsLocalization.event_10_message.replace('param1', historyLog.associatedUser.fullName).replace('param2', historyLog.approverUser.fullName);
                            break;
                        case 11:
                            logText = historyLogsLocalization.event_11_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 12:
                            logText = historyLogsLocalization.event_12_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 13:
                            logText = historyLogsLocalization.event_13_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 14:
                            logText = historyLogsLocalization.event_14_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 15:
                            if (historyLog.fieldUpdatedName === "AFCNumber") {
                                logText = historyLogsLocalization.event_15_message
                                    .replace('param1', historyLogsLocalization.field_update_name_AFCNumber)
                                    .replace('param2', historyLog.fieldUpdatedFromValue)
                                    .replace('param3', historyLog.fieldUpdatedToValue);
                            } else {
                                logText = 'ERROR: The log event for this field update is not configured.'
                            }
                            break;
                        case 16:
                            logText = historyLogsLocalization.event_16_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 17:
                            logText = historyLogsLocalization.event_17_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 18:
                            logText = historyLogsLocalization.event_18_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 19:
                            logText = historyLogsLocalization.event_19_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 20:
                            logText = historyLogsLocalization.event_20_message;
                            break;
                        case 21:
                            logText = historyLogsLocalization.event_21_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        case 22:
                            logText = historyLogsLocalization.event_22_message.replace('param1', historyLog.associatedUser.fullName);
                            break;
                        default:
                            logText = 'ERROR: The log event id is not configured.'
                    }

                    return {
                        id: historyLog.id,
                        dateText: historyLog.createdDateIso8601,
                        logText: logText
                    }
                });

                setHistoryLogs([
                    ...historyLogs
                ]);
            }
        }
    }, [ringiId]);

    useEffect(() => {
        const fetchUsers = async () => {
            const users = await fetch(`users`);
            setActiveUsers(users);
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        fetchRingi();
    }, [fetchRingi]);

    useEffect(() => {
        fetchApprovalChain();
    }, [fetchApprovalChain]);

    useEffect(() => {
        fetchReaders();
    }, [fetchReaders]);

    return (
        <SubmittedRingiContext.Provider value={{
            ringi: ringi,
            approvers: approvers,
            readers: readers,
            comments: comments,
            historyLogs: historyLogs,
            approverAndReaderCandidates: activeUsers,
            isLockedDown: isLockedDown,
            commentModsLockedDown: commentModsLockedDown,
            commentEntryLockedDown: commentEntryLockedDown,
            canPauseRingiForReply: canPauseRingiForReply,
            addReaderLockedDown: addReaderLockedDown,
            refreshRingi: fetchRingi,
            refreshApprovers: fetchApprovalChain,
            refreshReaders: fetchReaders,
            refreshComments: fetchComments,
            refreshHistoryLogs: fetchHistoryLogs,
            fetchUserCanReadRingi: fetchUserCanReadRingi
        }}>
            {children}
        </SubmittedRingiContext.Provider>
    );
}


export {
    SubmittedRingiContextProvider,
    useSubmittedRingi
};