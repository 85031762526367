import React, { useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { useValidationService } from 'providers/validationServiceContextProvider';
import ValidationError, { GetValidationError } from 'components/ValidationError';
import { oversizedFileUploadRule } from 'services/validationRules';
import 'controls/ValidationFileInput/AttachedFile.scss';

export default function AttachedFile({ fieldName, file, validationLevel, onRemove }) {
    const { control, setValue, unregister } = useFormContext();
    const { registerField, unRegisterField } = useValidationService();

    const validationError = GetValidationError(fieldName);

    useEffect(() => {
        if (validationLevel) {
            registerField(fieldName, validationLevel);
        }

        return () => {
            unRegisterField(fieldName, validationLevel);
        }
    }, [registerField, unRegisterField, validationLevel, fieldName]);

    useEffect(() => {
        setValue(fieldName, file.fileToUpload);
        return () => {
            if (setValue) { // make sure the parent FormProvider has not already been cleaned up
                setValue(fieldName, '');
                unregister(fieldName);
            }
        }
    }, [fieldName, unregister, setValue, file.fileToUpload]);

    return (
        <span data-testid="attached-file" className="validation-attachment-wrapper">
            <Controller
                control={control}
                name={fieldName}
                rules={{
                    validate: {
                        attachmentSize: (fileToUpload) => oversizedFileUploadRule(fileToUpload)
                    }
                }}
                render={() => (
                    <span data-testid="attached-file-wrapper" className={classnames("validation-attachment", { "validation-attachment--error": validationError })}>
                        <FontAwesomeIcon icon={faFile} />
                        <span data-testid="attached-file-name" className="validation-attachment__file-name">{file.name}</span>
                        <span className="validation-attachment__remove-file-icon">
                            <FontAwesomeIcon data-testid="attached-file-remove-button" icon={faTimesCircle} onClick={() => { onRemove(file); }} />
                        </span>
                    </span>
                )}
            />
            <ValidationError fieldName={fieldName} className="validation-attachment__icon" />
        </span>
    );
}