import React, { useEffect } from 'react';
import { useLocale } from 'services/localization/localizationContextProvider';
import ViewRingisPage from 'pages/Ringis/ViewRingisPage';
import { RINGI_LIST_FILTER_OPTIONS } from 'constants/enums';
import 'pages/Ringis/ViewRingisPage.scss';
import { usePageHistoryContextProvider } from 'providers/pageHistoryContextProvider';
import { ROUTES } from 'constants/constants';

export default function ViewRingisToReadPage() {
    const localizedViewRingis = useLocale().pages.view_ringis;
    const { setLastPage } = usePageHistoryContextProvider();

    useEffect(() => {
        setLastPage(ROUTES.RINGIS_TO_READ_PAGE);
    }, [setLastPage]);

    return (
        <ViewRingisPage emptyMessage={localizedViewRingis.empty_message_read_ringis}
            fetchRingisUrl={`ringis/my/toRead`}
            fetchColumnsUrl={`ringiColumns/my/current`}
            fetchSelectedColumnsUrl={`ringiColumns/my/currentSelected`}
            defaultSelection={RINGI_LIST_FILTER_OPTIONS.RingisToRead}
        />
    );
}