import React, { useState, useEffect } from 'react';
import RingiField from 'pages/Ringis/components/RingiField';
import RingiFormattedTextField from 'pages/Ringis/components/RingiFormattedTextField';
import RingiHeader from 'controls/RingiHeader/RingiHeader';
import DownloadLink from 'controls/DownloadLink/DownloadLink';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import { Row, Button } from 'react-bootstrap';
import { useLocale } from 'services/localization/localizationContextProvider';
import { ScreenDetectColumn } from 'hooks/useScreenDetect';
import { useSubmittedRingi } from 'pages/Ringis/providers/submittedRingiContextProvider';
import { ATTACHMENT_TYPES, RINGI_STATUS } from 'constants/enums';
import { LocalDate } from 'services/localization/dateTimeService';
import { requiredRule } from 'services/validationRules';
import { useAuthorizationClaims } from 'providers/authorizationClaimsProvider';
import { usePageHistoryContextProvider } from 'providers/pageHistoryContextProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useScreenDetect } from 'hooks/useScreenDetect';
import { faCheck, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { patch, put, PatchOperation} from 'services/api';
import { RESET_RESPONSE } from 'constants/constants';
import { useModal, ModalForm, ModalBody, ModalButtons, ModalCancelButton, ModalSubmitButton } from 'controls/ActionModal/ActionModal';
import 'pages/Ringis/components/ReadOnlyRingiPanel.scss';
import classNames from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';


export default function ReadOnlyRingiPanel() {
    const history = useHistory();
    const localizedRingiField = useLocale().ringi_field;
    const localizedCancelRingi = useLocale().modals.confirmation.ringi.cancel;
    const localizedButtons = useLocale().buttons;
    const methods = useForm({ reValidateMode: 'onSubmit' });
    const { isAdmin, loginName } = useAuthorizationClaims();
    const [statusModifier, setStatusModifier] = useState("");
    const [baseFields, setBaseFields] = useState([]);
    const [categoryFields, setCategoryFields] = useState([]);
    const [typeFields, setTypeFields] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [commentAttachments, setCommentAttachments] = useState([]);
    const [showHeadquarterFields, setShowHeadquarterFields] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showCopy, setShowCopy] = useState(false);
    const { ringi, comments, refreshRingi, refreshComments, refreshHistoryLogs, fetchUserCanReadRingi} = useSubmittedRingi();
    const { getLastPage } = usePageHistoryContextProvider();
    const { isDesktop } = useScreenDetect();
    const [isEditingAFCNumber, setIsEditingAFCNumber] = useState(false);
    const [CancelConfirmationModal, showCancelConfirmationModal, closeCancelConfirmationModal] = useModal();

    useEffect(() => {
        const setShowButtons = async () => {
            if (ringi?.id) {
                var canReadRingi = await fetchUserCanReadRingi(ringi?.id);

                setShowCancel(canReadRingi &&
                    (isAdmin || loginName?.toLowerCase() === createdByUserLoginName?.toLowerCase()) &&
                    ringi?.statusId !== RINGI_STATUS.PendingJapan &&
                    ringi?.statusId !== RINGI_STATUS.Cancelled &&
                    ringi?.statusId !== RINGI_STATUS.Approved &&
                    ringi?.statusId !== RINGI_STATUS.Denied &&
                    ringi?.statusId !== RINGI_STATUS.ApprovedWithConditions);

                setShowCopy(canReadRingi);
            }
        }

        switch (ringi?.statusId) {
            case RINGI_STATUS.Pending:
            case RINGI_STATUS.PendingAdminReview:
            case RINGI_STATUS.PendingJapan:
                setStatusModifier("pending");
                break;
            case RINGI_STATUS.Approved:
            case RINGI_STATUS.ApprovedWithConditions:
                setStatusModifier("approved");
                break;
            case RINGI_STATUS.Denied:
                setStatusModifier("denied");
                break;
            case RINGI_STATUS.Cancelled:
                setStatusModifier("cancelled");
                break;
            case RINGI_STATUS.Paused:
                setStatusModifier("paused");
                break;
            case RINGI_STATUS.DraftError:
                setStatusModifier("draft error");
                break;
            default:
                setStatusModifier("");
                break;
        }

        setBaseFields(ringi?.baseRingiFields ?? []);
        setCommentAttachments(comments ? comments.map(c => c.attachments).flat() : null);
        setCategoryFields(ringi?.categoryFields ?? []);
        setTypeFields(ringi?.typeFields ?? []);
        setAttachments(ringi?.attachments ?? []);
        setShowHeadquarterFields(ringi.migrationDate !== null || (ringi.requiresJapaneseApproval && ringi.statusId >= RINGI_STATUS.PendingJapan));

        const createdByUserLoginName = ringi?.createdByUserLoginName;

        setShowButtons();

    }, [ringi, comments, loginName, isAdmin]);

    const handleCancelClick = () => {
        showCancelConfirmationModal();
    }

    const onAFCUpdateSubmit = async (formValues) => {
        const operation = new PatchOperation('replace', 'AFCNumber', formValues.newAfcNumber);
        await patch(`ringis/${ringi.id}`, operation);
        await refreshRingi();
        await refreshHistoryLogs();
        setIsEditingAFCNumber(false);
    }

    const handleCancelRingi = async (formData) => {
        const response = await put(`ringis/${ringi.id}/cancel`, JSON.stringify(formData.cancelReason));

        closeCancelConfirmationModal();

        if (response === RESET_RESPONSE) {
            await refreshRingi();
            await refreshComments();
            await refreshHistoryLogs();
        }
    };

    const handleCopyRingiClick = async (formData) => {
        history.push(`/ringis/copy/${ringi.id}`);
    };

    return (
        <>
            {isDesktop &&
                <RingiHeader
                    onCancelClick={handleCancelClick}
                    onCopyRingiClick={handleCopyRingiClick}
                    className="read-only-ringi-panel__title"
                    showLegacyRingiIcon={ringi.isLegacyRingi}
                    showCancel={showCancel}
                    showCopyRingi={showCopy}
                    path={getLastPage()}
                    label={ringi.subject}
                    hideBackButton={false}
                   ringi={ringi}
                >
                </RingiHeader>
            }
            {!isDesktop &&
                <RingiHeader
                    onCancelClick={handleCancelClick}
                    onCopyRingiClick={handleCopyRingiClick}
                    className="read-only-ringi-panel__title"
                    showLegacyRingiIcon={ringi.isLegacyRingi}
                    showCancel={showCancel}
                    showCopyRingi={showCopy}
                    label={ringi.subject}
                    hideBackButton={true}
                    isDraftError={true}
                    ringi={ringi}
                >
                </RingiHeader>
            }
            <Row>
                <ScreenDetectColumn desktopColumnWidth={6}>
                    <RingiField ringiField={{ fieldName: localizedRingiField.category_label, value: ringi.categoryName }} />
                </ScreenDetectColumn>
                <ScreenDetectColumn desktopColumnWidth={6}>
                    <RingiField ringiField={{ fieldName: localizedRingiField.type_label, value: ringi.typeName }} />
                </ScreenDetectColumn>
                <ScreenDetectColumn desktopColumnWidth={6}>
                    <RingiField ringiField={{ fieldName: localizedRingiField.ringi_number_label, value: ringi.ringiNumber }} />
                </ScreenDetectColumn>
                {showHeadquarterFields &&
                    <>
                        <ScreenDetectColumn desktopColumnWidth={6}>
                            {!isEditingAFCNumber &&
                                <div>
                                    <RingiField
                                        ringiField={{ fieldName: localizedRingiField.afc_number_label, value: ringi.afcNumber }}
                                        canEdit={isAdmin}
                                        onEditClick={() => setIsEditingAFCNumber(true)}
                                    />
                                </div>
                            }
                            {isEditingAFCNumber &&
                                <div data-testid='editing-afc-panel' className={classNames("edit-afc-container", isDesktop ? 'vw-20' : '')}>
                                    <FormProvider {...methods}>
                                        <form onSubmit={methods.handleSubmit(onAFCUpdateSubmit)} className="approval-list__add-approval-template-section approval-list__section">
                                            <ValidationInput
                                                autoFocus
                                                type="text"
                                                data-testid="new-approval-template-input"
                                                title={localizedRingiField.afc_number_label}
                                                className="approval-list__new-approval-template-input"
                                                maxLength={100}
                                                minLength={1}
                                                defaultValue={ringi.afcNumber}
                                                fieldName="newAfcNumber"
                                                validationRules={{ required: requiredRule }}
                                            />
                                            <div className="approval-list-header__new-approval-template-buttons">
                                                <Button variant="link" onClick={() => setIsEditingAFCNumber(false)}>{localizedButtons.cancel}</Button>
                                                <Button type="submit" data-testid="save-new-approval-template">
                                                    <FontAwesomeIcon icon={faSave} /> &nbsp;
                                                    {localizedButtons.save}
                                                </Button>
                                            </div>
                                        </form>
                                    </FormProvider>
                                </div>
                            }
                        </ScreenDetectColumn>
                        <ScreenDetectColumn desktopColumnWidth={6}>
                            <RingiField ringiField={{ fieldName: localizedRingiField.consent_number_label, value: ringi.consentNumber }} />
                        </ScreenDetectColumn>
                    </>
                }
                <ScreenDetectColumn desktopColumnWidth={6}>
                    <RingiField ringiField={{ fieldName: localizedRingiField.cost_center_label, value: ringi.costCenterName }} />
                </ScreenDetectColumn>
                <ScreenDetectColumn desktopColumnWidth={6}>
                    <RingiField ringiField={{ fieldName: localizedRingiField.status_label, value: ringi.status, statusModifier: statusModifier }} />
                </ScreenDetectColumn>
                <ScreenDetectColumn desktopColumnWidth={6}>
                    <RingiField ringiField={{ fieldName: localizedRingiField.initiator_label, value: ringi.initiatorName }} />
                </ScreenDetectColumn>
                <ScreenDetectColumn desktopColumnWidth={6}>
                    <RingiField ringiField={{ fieldName: localizedRingiField.submitted_date_label, value: LocalDate(ringi.submittedDateIso8601) }} />
                </ScreenDetectColumn>
                <ScreenDetectColumn desktopColumnWidth={6}>
                    <RingiField ringiField={{ fieldName: localizedRingiField.approval_by_label, value: LocalDate(ringi.approvalByDateIso8601) }} />
                </ScreenDetectColumn>
                <ScreenDetectColumn desktopColumnWidth={6}>
                    <RingiField ringiField={{ fieldName: localizedRingiField.completed_date_label, value: LocalDate(ringi.completedDateIso8601) }} />
                </ScreenDetectColumn>
                <ScreenDetectColumn desktopColumnWidth={6}>
                    <RingiField ringiField={{ fieldName: localizedRingiField.dollar_amount_requested, value: ringi.dollarAmountRequested }} />
                </ScreenDetectColumn>

                {baseFields?.map((baseField, i) => (
                    <ScreenDetectColumn key={i} desktopColumnWidth={baseField.extraLong ? 12 : 6}>
                        {!baseField.extraLong ?
                            <RingiField ringiField={baseField} /> :
                            <RingiFormattedTextField ringiField={{ fieldName: baseField.fieldName, value: baseField.value }} />}
                    </ScreenDetectColumn>
                ))}
                <ScreenDetectColumn desktopColumnWidth={12}>
                    <RingiFormattedTextField ringiField={{ fieldName: localizedRingiField.purpose_label, value: ringi.purpose }} />
                </ScreenDetectColumn>
                <ScreenDetectColumn desktopColumnWidth={12}>
                    <RingiFormattedTextField ringiField={{ fieldName: localizedRingiField.opportunity_costs, value: ringi.opportunityCosts ?? '' }} />
                </ScreenDetectColumn>
                <ScreenDetectColumn desktopColumnWidth={12}>
                    <RingiFormattedTextField ringiField={{ fieldName: localizedRingiField.alternatives, value: ringi.alternatives ?? '' }} />
                </ScreenDetectColumn>
            </Row>
            {(categoryFields.length > 0 || typeFields.length > 0) &&
                <Row>
                    {categoryFields?.map((categoryField, i) => (
                        <ScreenDetectColumn key={i} desktopColumnWidth={categoryField.extraLong ? 12 : 6}>
                            {!categoryField.extraLong ? 
                                <RingiField ringiField={categoryField} /> :
                                <RingiFormattedTextField ringiField={{ fieldName: categoryField.fieldName, value: categoryField.value }} />}
                        </ScreenDetectColumn>
                    ))}

                    {typeFields?.map((typeField, i) => (
                        <ScreenDetectColumn key={i} desktopColumnWidth={typeField.extraLong ? 12 : 6}>
                            {!typeField.extraLong ?
                                <RingiField ringiField={typeField} /> :
                                <RingiFormattedTextField ringiField={{ fieldName: typeField.fieldName, value: typeField.value }} />}
                        </ScreenDetectColumn>
                    ))}
                </Row>
            }
            {(attachments.length > 0 || commentAttachments?.length > 0) &&
                <Row>
                    <ScreenDetectColumn>
                        <div className="ringi-field">
                            <ScreenDetectColumn desktopColumnWidth={12} className="read-only-ringi-panel-card__title">
                                <span>{localizedRingiField.multiple_attachments_label}</span>
                            </ScreenDetectColumn>
                            {attachments?.map((attachment, i) => (
                                <ScreenDetectColumn key={i} desktopColumnWidth={12} className="read-only-ringi-panel-card__attachment">
                                    <DownloadLink attachmentType={ATTACHMENT_TYPES.Ringi} fileName={attachment.name}
                                        ringiAttachmentId={attachment.id} className="read-only-ringi-panel-card-attachment__link" />
                                </ScreenDetectColumn>
                            ))}
                        </div>
                    </ScreenDetectColumn>
                    <ScreenDetectColumn>
                        <div className="ringi-field">
                            <ScreenDetectColumn desktopColumnWidth={12} className="read-only-ringi-panel-card__title">
                                <span>{localizedRingiField.multiple_comment_attachments_label}</span>
                            </ScreenDetectColumn>
                            {commentAttachments?.map((attachment, i) => (
                                <ScreenDetectColumn key={i} desktopColumnWidth={12} className="read-only-ringi-panel-card__attachment">
                                    <DownloadLink attachmentType={ATTACHMENT_TYPES.Comment} fileName={attachment.name}
                                        ringiCommentAttachmentId={attachment.id} className="read-only-ringi-panel-card-attachment__link" />
                                </ScreenDetectColumn>
                            ))}
                        </div>
                    </ScreenDetectColumn>
                </Row>
            }

            <CancelConfirmationModal variant='confirmation' title={localizedCancelRingi.title}>
                <div className="deny-modal__body">
                    <ModalForm onSubmit={handleCancelRingi}>
                        <ModalBody >
                            <label className="deny-modal__label" htmlFor={"denialReason-input"}>{localizedCancelRingi.message}</label>
                            <ValidationInput
                                type="text"
                                placeholder={localizedCancelRingi.placeholder}
                                fieldName="cancelReason"
                                validationRules={{
                                    required: requiredRule
                                }}
                            />
                        </ModalBody>
                        <ModalButtons>
                            <ModalCancelButton />
                            <ModalSubmitButton text={localizedCancelRingi.success}>
                                <FontAwesomeIcon className="deny-modal-submit__check" icon={faCheck} size="1x" />
                            </ModalSubmitButton>
                        </ModalButtons>
                    </ModalForm>
                </div>
            </CancelConfirmationModal>
        </>
    );
}