import React from 'react';
import TemplateFieldEditor from 'pages/Configuration/Editors/TemplateFieldEditor';
import { put } from 'services/api';
import { attachTemplateFieldAttachment } from 'services/attacher';
import { FIELD_TYPE } from 'constants/enums';

export default ({ onCancel, templateField, onEdited }) => {
    const
        HEADER_TEXT = "Existing Field Edit",
        handleSave = async (editedTemplateField) => {

            let attachmentNameToSave = editedTemplateField.attachmentName;
            let attachmentStorageId = editedTemplateField.storageId;
            if (parseInt(editedTemplateField.fieldTypeId) === FIELD_TYPE.Attachment && editedTemplateField.attachedTemplate) {
                const attachmentName = await attachTemplateFieldAttachment(editedTemplateField.attachedTemplate);
                if (attachmentName) {
                    attachmentNameToSave = attachmentName
                }
                attachmentStorageId = editedTemplateField.attachedTemplate.storageId;
            }

            await put(`layers/${templateField.layerId}/fields`,
                {
                    fieldName: editedTemplateField.fieldName,
                    fieldTypeId: editedTemplateField.fieldTypeId,
                    isRequired: editedTemplateField.isRequired,
                    options: editedTemplateField.options,
                    attachmentName: attachmentNameToSave,
                    id: editedTemplateField.id,
                    storageId: attachmentStorageId,
                    optionA: editedTemplateField.optionA,
                    optionB: editedTemplateField.optionB,
                    optionAIsDefault: editedTemplateField.optionAIsDefault,
                    defaultOptionIndex: editedTemplateField.defaultOptionIndex,
                    isFilterableField: editedTemplateField.isFilterableField,
                    isRingiViewField: editedTemplateField.isRingiViewField,
                    optionAssociatedWithChildFields: editedTemplateField.optionAssociatedWithChildFields,
                    tooltipDescription: editedTemplateField.showTooltip === true ? editedTemplateField.tooltipDescription : templateField.tooltipDescription,
                    showTooltip: editedTemplateField.showTooltip
                });
            await onEdited();
        };

    return (
        <TemplateFieldEditor
            data-testid="existingCustomFieldPanel"
            headerText={HEADER_TEXT}
            onCancel={onCancel}
            onSave={handleSave}
            templateField={templateField} />
    );
}