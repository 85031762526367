import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { post } from 'services/api';
import { useLocale } from 'services/localization/localizationContextProvider';
import { useSubmittedRingi } from 'pages/Ringis/providers/submittedRingiContextProvider';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { requiredRule } from 'services/validationRules';
import { ATTACHMENT_TYPES } from 'constants/enums';
import classnames from 'classnames';
import ValidationFileInput from 'controls/ValidationFileInput/ValidationFileInput';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import CommentCard from 'pages/Ringis/components/CommentCard';
import { attachRingiCommentAttachments } from 'services/attacher';
import 'pages/Ringis/components/CommentList.scss';

export default function CommentList({ ringiId }) {
    const { comments, refreshComments, refreshRingi, refreshHistoryLogs, commentEntryLockedDown, canPauseRingiForReply } = useSubmittedRingi();

    const methods = useForm({ reValidateMode: 'onSubmit' });
    const watchCommentText = methods.watch("commentText");

    const RINGI_COMMENT_ATTACHMENTS_FIELD_NAME = "ringiCommentAttachments";

    const { pages: {
            view_ringi: {
                comments: commentsLocalization
            }
        }
    } = useLocale();

    const handleAddComment = useCallback(async () => {
        const formData = methods.getValues();

        let attachments = [];
        if (formData.ringiCommentAttachments) {
            formData.ringiCommentAttachments.forEach((attachment) => {
                attachments.push({
                    id: attachment.id < 0 ? 0 : attachment.id,
                    name: attachment.name,
                    storageId: attachment.storageId
                });
            });
        }

        const pauseRingi = formData.pauseForSubmitterResponse === undefined || formData.pauseForSubmitterResponse === false ? null : true;

        const commentId = await post(`comments`, {
            ringiComment: {
                id: 0,
                ringiId: ringiId,
                comment: formData.commentText,
                pauseForSubmitterResponse: pauseRingi
            },
            ringiCommentAttachments: attachments
        });

        if (commentId != null) {
            if (attachments.length > 0) {
                await attachRingiCommentAttachments(commentId, formData.ringiCommentAttachments);
            }

            // methods.reset() is causing issues with the AttachFile.js useEffect cleanup methods, getting the "Can't perform a React state
            // update on an unmounted component" warning. Clearing the form fields manually here works without the warning.
            methods.setValue("commentText", "");
            methods.setValue(RINGI_COMMENT_ATTACHMENTS_FIELD_NAME, []);
            methods.setValue("pauseForSubmitterResponse", false);

            if (pauseRingi === true) {
                refreshRingi();
                refreshHistoryLogs();
            }
        }
        refreshComments();
    }, [methods, ringiId, refreshComments]);

    const handleReply = async () => {
        await refreshComments();
    }

    const handleRemove = async () => {
        await refreshComments();
    }

    useEffect(() => {
        refreshComments();
    }, [ringiId, refreshComments]);

    return (
        <>
            <div data-testid="comment-list" className="comment-list__comments">
                {comments.length === 0 &&
                    <div data-testid="no-comments-message" className="comment-list__no-comments-message">{commentsLocalization.no_comments_message}</div>
                }
                {comments.map((ringiComment, i) => (
                    <CommentCard key={i}
                        index={i}
                        ringiComment={ringiComment}
                        ringiId={ringiId}
                        level={ringiComment.level}
                        onReply={handleReply}
                        onRemove={handleRemove}
                    />
                ))}
            </div>
            {!commentEntryLockedDown &&
                <div data-testid="add-comment-bar" className="add-comment-bar">
                    <FormProvider {...methods}>
                        <form data-testid="add-comment-form" onSubmit={methods.handleSubmit(handleAddComment)}>
                            <div data-testid="add-comment-bar__top-row-container" className={classnames("add-comment-bar__top-row-container", { "align-space-between": canPauseRingiForReply }, { "align-end": !canPauseRingiForReply })}>
                                {canPauseRingiForReply &&
                                    <div data-testid="add-comment-bar__pause-checkbox" className="add-comment-bar__pause-checkbox">
                                        <label className="add-comment-bar__pause-checkbox-label">
                                            <input
                                                type="checkbox"
                                                {...methods.register("pauseForSubmitterResponse")}
                                                id="pauseForSubmitterResponse"
                                                data-testid="pauseForSubmitterResponse">
                                            </input>
                                            {commentsLocalization.pause_ringi_for_reply}
                                        </label>
                                    </div>
                                }
                                <div data-testid="add-comment-bar__add-attachment" className="add-comment-bar__add-attachment">
                                    <ValidationFileInput
                                        fieldName={RINGI_COMMENT_ATTACHMENTS_FIELD_NAME}
                                        attachmentType={ATTACHMENT_TYPES.Comment}
                                        multiple
                                        validationLevel='save'
                                        buttonLabelText={commentsLocalization.add_attachment_text} />
                                </div>
                            </div>
                            <div className="add-comment-bar__comment-container">
                                <div>
                                    <ValidationInput
                                        type="text"
                                        fieldName="commentText"
                                        placeholder={commentsLocalization.feedback_placeholder_text}
                                        validationRules={{
                                            required: requiredRule
                                        }}
                                    />
                                </div>
                                <div className="add-comment-bar__add-comment">
                                    <Button type="submit" data-testid="add-comment-button" className="add-comment-button" disabled={!watchCommentText}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            }
        </>
    );
}
