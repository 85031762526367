import React, { useState, useCallback, useRef, useEffect } from 'react';
import ValidationTextArea from 'controls/ValidationTextArea/ValidationTextArea';
import DownloadLink from 'controls/DownloadLink/DownloadLink';
import { put, post, httpDelete } from 'services/api';
import { useLocale } from 'services/localization/localizationContextProvider';
import { requiredRule } from 'services/validationRules';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { useModal, ModalBody, ModalButtons, ModalOkButton, ModalCancelButton } from 'controls/ActionModal/ActionModal';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheck, faTimes, faReply, faTrashAlt, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { useAuthorizationClaims } from 'providers/authorizationClaimsProvider';
import { LocalLongDateTime, LocalTimeZoneAbbr } from 'services/localization/dateTimeService';
import { useSubmittedRingi } from 'pages/Ringis/providers/submittedRingiContextProvider';
import { attachRingiCommentAttachments } from 'services/attacher';
import ValidationFileInput from 'controls/ValidationFileInput/ValidationFileInput';
import { ATTACHMENT_TYPES } from 'constants/enums';
import RingiFormattedTextField from 'pages/Ringis/components/RingiFormattedTextField';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import Chip from 'components/Chip/Chip';
import 'pages/Ringis/components/CommentCard.scss';

export default function CommentCard({ index, ringiComment, ringiId, level, onReply, onRemove }) {
    const [RemoveCommentConfirmationModal, showRemoveCommentConfirmationModal] = useModal();
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [isInReplyMode, setIsInReplyMode] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [hasCommentBeenEdited, setHasCommentBeenEdited] = useState(ringiComment.isCommentEdited);
    const [currentAttachments, setCurrentAttachments] = useState(ringiComment.attachments);
    const { isAdmin } = useAuthorizationClaims();
    const newAttachmentCounter = useRef(-1);

    const methods = useForm({ reValidateMode: 'onSubmit' });

    level = level > 3 ? 3 : level;
    const indent = 100 - (level * 2);
    const margin = level * 2;

    const { commentModsLockedDown, refreshComments, refreshRingi, refreshHistoryLogs } = useSubmittedRingi();

    const { pages: {
        view_ringi: {
            comments: commentsLocalization
        }
    },
        buttons: buttonsLocalization,
        modals: {
            confirmation: {
                commenter: {
                    removing: removingCommentLocalization
                },
                removeFileAttachment: removeFileAttachmentLocalization
            }
        }
    } = useLocale();

    useEffect(() => {
        setIsPaused(ringiComment.pauseForSubmitterResponse === true);
    }, []);


    const handleRingiPauseChange = async (state) => {
        if (state === false) {
            const response = await put(`ringis/${ringiId}/resume`);

            if (response != null) {
                setIsPaused(state);
                refreshComments();
                refreshHistoryLogs();
                refreshRingi();
            }
        }
    };

    const enteringEditMode = () => {
        setIsInEditMode(true);
        methods.setValue('commentText', ringiComment.text);
    };

    const handleCancelEdit = () => {
        setIsInEditMode(false);
    };

    const getAttachments = (formData) => {
        if (!formData || !formData.ringiCommentAttachments) return [];

        return formData.ringiCommentAttachments.map(attachment => {
            return {
                id: attachment.id < 0 ? 0 : attachment.id,
                name: attachment.name,
                storageId: attachment.storageId
            }
        });
    }

    const handleEditComment = async (formData) => {
        const modifiedDateIso8601 = await put(`comments`, {
            id: ringiComment.id,
            comment: formData.commentText,
            ringiCommentAttachments: currentAttachments
        });

        if (ringiComment.id != null) {
            if (currentAttachments.length > 0) {
                await attachRingiCommentAttachments(ringiComment.id, currentAttachments);
            }
            await onReply();
        }

        if (modifiedDateIso8601 != null) {
            methods.reset();
            setIsInEditMode(false);
            ringiComment.text = formData.commentText;
            ringiComment.dateText = modifiedDateIso8601;
            setHasCommentBeenEdited(true);
        }
    };

    const handleRemoveCommentAttachment = async (attachment) => {
        setCurrentAttachments(currentAttachments.filter(x => x != attachment));
    }

    const enteringReplyMode = () => {
        setIsInReplyMode(true);
    };

    const handleCancelReply = () => {
        setIsInReplyMode(false);
    };

    const handleReply = async (replyText) => {
        const formData = methods.getValues();
        const attachments = getAttachments(formData);

        const commentId = await post(`comments/replies`, {
            ringiReply: {
                ringiId: ringiId,
                ringiCommentId: ringiComment.id,
                reply: formData.replyText
            },
            ringiCommentAttachments: attachments
        });

        if (commentId != null) {
            if (attachments.length > 0) {
                await attachRingiCommentAttachments(commentId, formData.ringiCommentAttachments);
            }
            methods.reset(); //Might need to replace with manual calls to avoid issues with attacher.js
            setIsInReplyMode(false);
            await onReply();
        }
    };

    const handleRemove = useCallback((id) => {
        showRemoveCommentConfirmationModal(id);
    }, [showRemoveCommentConfirmationModal]);

    const handleRemoveCommentConfirm = async (commentIdToDelete) => {
        const response = await httpDelete(`comments/${ringiId}/${commentIdToDelete}`);
        if (response != null) {
            refreshRingi();
            methods.reset();
            await onRemove();
        }
    };

    const commentIndentStyle = {
        marginLeft: margin + '%',
        width: indent + '%'
    }


    const handleNewAttachment = (event) => {
        const fileToAttach = event.target.files[0];
        if (fileToAttach) {
            setCurrentAttachments([...currentAttachments, {
                id: newAttachmentCounter.current--,
                name: fileToAttach.name,
                storageId: Date.now(),
                fileToUpload: new File([fileToAttach], fileToAttach.name, { type: fileToAttach?.type })
            }]);
            event.target.value = null;
        }
    };

    return (
        <>
            <div data-testid={`comment-card-${index}`} className="comment-card" style={commentIndentStyle}>
                <div data-testid="comment-card__info" className="comment-card__info">
                    <span data-testid="commenter-name">
                        <Badge pill className="comment-card__name">
                            {ringiComment.user.fullName}
                        </Badge>
                    </span>
                    {ringiComment.isReasonForDenial &&
                        <span className="comment-card__denied-label">
                            {commentsLocalization.ringi_denied}
                        </span>
                    }
                    {ringiComment.isReasonForCancel &&
                        <span className="comment-card__cancelled-label">
                            {commentsLocalization.ringi_canceled}
                        </span>
                    }
                    {!ringiComment.isReasonForDenial && !ringiComment.isReasonForCancel &&
                        <span data-testid="comment-buttons" className="comment-card__icon">
                            {!commentModsLockedDown && ringiComment.isCommentCreator && !isInEditMode && !isInReplyMode &&
                                <FontAwesomeIcon data-testid="comment-buttons__edit" icon={faEdit} title={buttonsLocalization.edit} onClick={() => { enteringEditMode(); }} />
                            }
                            {!commentModsLockedDown && !isInEditMode && !isInReplyMode &&
                                <FontAwesomeIcon data-testid="comment-buttons__reply" icon={faReply} title={buttonsLocalization.reply} onClick={() => { enteringReplyMode(); }} />
                            }
                            {!commentModsLockedDown && (isAdmin || ringiComment.isCommentCreator) && !isInEditMode && !isInReplyMode && !ringiComment.hasReplies &&
                                <FontAwesomeIcon data-testid="comment-buttons__remove" icon={faTrashAlt} title={buttonsLocalization.remove} onClick={() => { handleRemove(ringiComment.id) }} />
                            }
                        </span>
                    }

                    <span data-testid="comment-date" className="comment-card__date">
                        {LocalLongDateTime(ringiComment.dateText)} {LocalTimeZoneAbbr}
                        {hasCommentBeenEdited &&
                            <div data-testid="comment-date-edited">{commentsLocalization.edited_text}</div>
                        }
                    </span>
                    {isInEditMode &&
                        <span data-testid="comment-text" className="comment-card__text comment-card__text-form">
                            <FormProvider {...methods}>
                                <form data-testid="edit-comment-form" onSubmit={methods.handleSubmit(handleEditComment)}>
                                    <ValidationTextArea
                                        title=""
                                        fieldName="commentText"
                                        validationRules={{
                                            required: requiredRule
                                        }} />

                                    <div className="attachment-remove-container" data-testid="attachment-container">
                                        {currentAttachments && currentAttachments.map((attachment, i) => {
                                            return <Chip
                                                key={`removable-attachment-chip-${i}`}
                                                onRemove={() => handleRemoveCommentAttachment(attachment)}
                                                icon={faFile}
                                                label={attachment.name}>
                                            </Chip>
                                        })}
                                    </div>
                                    <div data-testid="attachment-button-container">
                                        <Button variant="link" data-testid="cancel-edit-button" className="cancel-button" title={buttonsLocalization.cancel} onClick={handleCancelEdit}>
                                            <FontAwesomeIcon icon={faTimes} size="1x" />
                                        </Button>
                                        <span className="comment-card__file-input-wrapper">
                                            <label className="attached-files__icon" data-testid="attach-file-button">
                                                <FontAwesomeIcon icon={faPaperclip} />
                                                <input type="file" className="attached-files__file-input" onChange={handleNewAttachment} />
                                            </label>
                                        </span>
                                        <Button variant="link" type="submit" data-testid="save-button" title={buttonsLocalization.save} className="save-button">
                                            <FontAwesomeIcon icon={faCheck} size="1x" />
                                        </Button>
                                    </div>
                                </form>
                            </FormProvider>
                        </span>
                    }
                    {(!isInEditMode || isInReplyMode) &&
                        <>
                            <span data-testid="comment-text" className="comment-card__text">
                                <RingiFormattedTextField ringiField={{ fieldName: '', value: ringiComment.text }} />
                            </span>
                            {ringiComment.isForConditionalApproval === true &&
                                <span data-testid="conditional-approval-text" className="comment-card__text comment-card__conditional-approval-text">
                                    {commentsLocalization.conditional_approval_comment_text}
                                </span>
                            }
                            {ringiComment.showPauseCheckbox === true &&
                                <span data-testid="comment-pause-for-reply" className="comment-card__text">
                                    <label className="comment-card__pause-checkbox">
                                        <input
                                            type="checkbox"
                                            id="pauseForSubmitterResponse"
                                            onChange={e => handleRingiPauseChange(e.currentTarget.checked)}
                                            checked={isPaused}>
                                        </input>
                                        {commentsLocalization.pause_ringi_for_reply_long}
                                    </label>
                                </span>
                            }
                            {ringiComment.showReplyNeededMessage === true &&
                                <span data-testid="comment-pause-reply-needed" className="comment-card__reply-needed">
                                    {commentsLocalization.pause_reply_needed_text}
                                </span>
                            }
                        </>
                    }
                    <div data-testid="comment-card__attachments" className="comment-card__attachments">
                        {(!isInEditMode || isInReplyMode) &&
                            ringiComment.attachments?.map((field, f) => (
                                <span key={f} data-testid={`comment-attachment-${f}`}>
                                    <DownloadLink attachmentType={ATTACHMENT_TYPES.Comment} fileName={field.name} ringiCommentAttachmentId={field.id} />
                                </span>
                            ))
                        }
                    </div>
                    {isInReplyMode &&
                        <span data-testid="reply-text" className="comment-card__text comment-card__text-form">
                            <FormProvider {...methods}>
                                <form data-testid="reply-comment-form" onSubmit={methods.handleSubmit(handleReply)}>
                                    <ValidationTextArea
                                        title=""
                                        fieldName="replyText"
                                        placeholderText={commentsLocalization.reply_placeholder_text}
                                        validationRules={{
                                            required: requiredRule
                                        }} />
                                    <div className="reply-buttons-container">
                                        <Button variant="link" data-testid="cancel-reply-button" className="cancel-button" title={buttonsLocalization.cancel} onClick={handleCancelReply}>
                                            <FontAwesomeIcon icon={faTimes} size="1x" />
                                        </Button>
                                        <div className="reply-buttons-attach-save">
                                            <span className="reply-buttons-attach">
                                                <ValidationFileInput
                                                    fieldName={"ringiCommentAttachments"}
                                                    attachmentType={ATTACHMENT_TYPES.Comment}
                                                    multiple
                                                    validationLevel='save'
                                                    iconOnly={true} />
                                            </span>
                                            <Button variant="link" type="submit" data-testid="save-reply-button" title={buttonsLocalization.save} className="save-button">
                                                <FontAwesomeIcon icon={faCheck} size="1x" />
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </FormProvider>
                        </span>
                    }
                </div>
            </div>
            <RemoveCommentConfirmationModal variant='confirmation' title={removingCommentLocalization.title}>
                <ModalBody>{removingCommentLocalization.message}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton />
                    <ModalOkButton text={removingCommentLocalization.success} onClick={handleRemoveCommentConfirm} />
                </ModalButtons>
            </RemoveCommentConfirmationModal>
        </>
    );
}