import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { useNotificationsClaim } from 'providers/notificationsProvider';
import { useScreenDetect } from 'hooks/useScreenDetect';
import { ROUTES } from 'constants/constants';
import classNames from 'classnames';
import './UserProfileNotification.scss';

const UserProfileNotification = () => {
    const [notificationCount, setNotificationCount] = useState(0);
    const { isDesktop } = useScreenDetect();
    const history = useHistory();
    const notifications = useNotificationsClaim();

    const navigateToNotificationPage = async () => {
        history.push(ROUTES.NOTIFICATIONS_PAGE);
    }

    useEffect(() => {
        setNotificationCount(notifications?.allNotificationsCount);
    }, [notifications]);

    return (
        <div className={classNames("user-profile-notification", { "user-profile-notification__mobile": isDesktop === false })}>
            <i className="user-profile-notification__icon" onClick={navigateToNotificationPage}>
                <FontAwesomeIcon icon={faBell} />
            </i>
            {notificationCount > 0 &&
                <span data-testid="user-profile-notification__number" className="user-profile-notification__number" onClick={navigateToNotificationPage}>
                    {notificationCount < 1000 ?
                        notificationCount :
                        '1k+'}
                </span>
            }
        </div>
    );
}

export { UserProfileNotification }