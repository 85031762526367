import React, { useEffect } from 'react';
import { useLocale } from 'services/localization/localizationContextProvider';
import ViewRingisPage from 'pages/Ringis/ViewRingisPage';
import { RINGI_LIST_FILTER_OPTIONS } from 'constants/enums';
import 'pages/Ringis/ViewRingisPage.scss';
import { usePageHistoryContextProvider } from 'providers/pageHistoryContextProvider';
import { ROUTES } from 'constants/constants';

export default function ViewSubmittedRingisPage() {
    const localizedViewRingis = useLocale().pages.view_ringis;
    const { setLastPage } = usePageHistoryContextProvider();

    useEffect(() => {
        setLastPage(ROUTES.SUBMITTED_RINGIS_PAGE);
    }, [setLastPage]);

    return (
        <ViewRingisPage emptyMessage={localizedViewRingis.empty_message_submitted_ringis}
            fetchRingisUrl={`ringis/my/submitted`}
            fetchColumnsUrl={`ringiColumns/my/current`}
            fetchSelectedColumnsUrl={`ringiColumns/my/currentSelected`}
            defaultSelection={RINGI_LIST_FILTER_OPTIONS.MySubmittedRingis}
        />
    );
}