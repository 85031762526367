import React from 'react';
import { Row } from 'react-bootstrap';
import { Prompt } from 'react-router-dom';
import { useFormContext, useFormState } from "react-hook-form";
import { useLocale } from 'services/localization/localizationContextProvider';
import { useScreenDetect, ScreenDetectColumn } from 'hooks/useScreenDetect';
import classNames from 'classnames';
import RingiHeader from 'controls/RingiHeader/RingiHeader';
import 'controls/RingiPage/RingiPage.scss';

const RingiPage = ({ children, className }) => {
    return (
        <Row className={classNames("ringi-page", className)}>{children}</Row>
    );
}

const PageSidebar = ({ children, primaryMobileContent, className, columnWidthPixels, columnWidth = 3 }) => {
    const { isDesktop } = useScreenDetect();

    return (
        <>
            {(isDesktop || primaryMobileContent) && (
                columnWidthPixels && isDesktop ?
                    <ScreenDetectColumn data-testid="sidebar" desktopColumnWidthPixels={columnWidthPixels}
                        className={classNames('ringi-page__sidebar', { 'ringi-page__sidebar--non-primary': !primaryMobileContent }, className)}>
                        {children}
                    </ScreenDetectColumn>
                    :
                    <ScreenDetectColumn data-testid="sidebar" desktopColumnWidth={columnWidth}
                        className={classNames('ringi-page__sidebar', { 'ringi-page__sidebar--non-primary': !primaryMobileContent }, className)}>
                        {children}
                    </ScreenDetectColumn>
            )
            }
        </>
    );
}

const PageHeader = ({ children, title, goBackTo, alwaysShowBackArrow, className }) => {
    const { isMobile } = useScreenDetect();

    return (
        <div data-testid="page-header" className={classNames("ringi-page-content__header", className)}>
            <div className="ringi-page-content-header__title">
                {(isMobile || alwaysShowBackArrow) && goBackTo &&
                    <RingiHeader data-testid="ringi-page-header-back-button" path={goBackTo}></RingiHeader>
                }
                {title &&
                    <span data-testid="page-title">
                        {title}
                    </span>
                }
            </div>
            {children}
        </div>
    );
}

const PageContent = ({ children, primaryMobileContent, className }) => {
    const { isDesktop } = useScreenDetect();

    return (
        <>
            {(isDesktop || primaryMobileContent) &&
                <ScreenDetectColumn
                    id="ringiPageContent"
                    data-testid="content"
                    desktopColumnWidth={12}
                    className={classNames('ringi-page__content', { 'ringi-page__content--non-primary': !primaryMobileContent }, className)}>
                    {children}
                </ScreenDetectColumn>
            }
        </>
    );
}

const PageDirtyPrompt = () => {
    const { control } = useFormContext();
    const { isDirty } = useFormState({ control });
    const localized = useLocale();
    return (
        <Prompt when={isDirty} message={localized.ringi_dirty_message} />
    );
}


export { PageSidebar, PageContent, PageHeader, PageDirtyPrompt };

export default RingiPage;