import React from "react";
import Table from "react-bootstrap/Table";
import ReportSummaryDropdown from "./ReportSummaryDropdown";
import ReportSummaryRow from "./ReportSummaryRow";
import './ReportTable.scss';

const ReportTable = ({ headers, isTypeReport, reports }) => {

    return (

        <Table striped bordered hover className="report-table">
            <thead>
                <tr>
                    <th>{headers.title}</th>
                    <th className="text-center">{headers.ringi_number}</th>
                    <th className="text-center">{headers.approved}</th>
                    <th className="text-center">{headers.denied}</th>
                    <th>{headers.total}</th>
                </tr>
            </thead>
            <tbody>

                {isTypeReport ?
                    reports.map((report) => (<ReportSummaryDropdown data={report} />))
                    :
                    reports.map((report) => (<ReportSummaryRow data={report} />))}

            </tbody>
        </Table>

    );
}

export default ReportTable;