import React, { useContext, useEffect, useState } from 'react';

const ApprovalTemplateApproversContext = React.createContext();

const useApprovalTemplateApprovers = () => useContext(ApprovalTemplateApproversContext);

const ApprovalTemplateApproversContextProvider = ({ approvalLevels, children }) => {
    const [costCenterApproversOnTemplate, setCostCenterApproversOnTemplate] = useState(0);
    const [additionalApproversOnTemplate, setAdditionalApproversOnTemplate] = useState(0);

    useEffect(() => {
        setCostCenterApproversOnTemplate(approvalLevels.filter(al => al.containsCostCenterChain).length);
        setAdditionalApproversOnTemplate(approvalLevels.filter(al => al.containsAdditionalApprovers).length);
    }, [approvalLevels]);


    return (
        <ApprovalTemplateApproversContext.Provider value={{
            costCenterApproversCount: costCenterApproversOnTemplate,
            additionalApproversCount: additionalApproversOnTemplate
        }}>
            {children}
        </ApprovalTemplateApproversContext.Provider>
    );
}


export {
    ApprovalTemplateApproversContextProvider,
    useApprovalTemplateApprovers
};