import React, { useState, useEffect, useRef } from "react";
import FieldPanel from "pages/Configuration/Panels/FieldPanel";
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocale } from 'services/localization/localizationContextProvider';
import { Button } from 'react-bootstrap';
import NewCustomFieldPanel from 'pages/Configuration/Panels/NewCustomFieldPanel';
import { FIELD_TYPE } from 'constants/enums';
import 'pages/Configuration/Editors/TemplateEditor.scss';

export default function ({ name, layerId, templateFields, locked = false, reload, showDisabledFields }) {

    const
        localized = useLocale(),
        newFieldCounter = useRef(-1),
        [renderedFieldPanels, setRenderedFieldPanels] = useState(),
        [newCustomFields, setNewCustomFields] = useState([]),
        [renderedNewCustomFields, setRenderedNewCustomFields] = useState([]),
        [isExpanded, setIsExpanded] = useState(!locked),
        addCustomField = () => {
            const fieldId = newFieldCounter.current--;
            setNewCustomFields(newCustomField => [...newCustomFields, { id: fieldId, layerId: layerId, isRequired: true, fieldTypeId: FIELD_TYPE.FreeText, tooltipDescription: null, showTooltip: false, isFilterableField: false, isRingiViewField: false }]);
        },
        onTogglePanelClick = () => {

            setIsExpanded(!isExpanded);

        };

    useEffect(() => {
        const removeCustomField = (templateFieldId) => {
            setNewCustomFields(
                newCustomFields.filter(templateField => templateField.id !== templateFieldId)
            );
        };

        const handleAdded = async (templateFieldId) => {
            await reload();
            removeCustomField(templateFieldId);
        }

        const mappedNewCustomFields = newCustomFields.map((templateField, i) => {
            return <NewCustomFieldPanel templateField={templateField} key={templateField.id} onCancel={removeCustomField} onAdded={handleAdded} />;
        });

        setRenderedNewCustomFields(mappedNewCustomFields);
    }, [newCustomFields, reload]);

    useEffect(() => {
        const mappedFieldPanels = templateFields?.map((templateField, i) => {
            if (showDisabledFields || templateField.isEnabled) {
                return <FieldPanel key={i} templateField={templateField}
                    layerId={layerId} locked={locked}
                    fieldReload={reload} showDisabledFields={showDisabledFields}
                    onInEditMode={() => {}} onNotInEditMode={() => {}} />;
            }
            return null;
        });

        setRenderedFieldPanels(mappedFieldPanels);

    }, [templateFields, reload, layerId, locked, showDisabledFields]);

    return (

        <div className="template-detail-container">

            { locked && <FontAwesomeIcon icon={faLock} />}
            <span className="template-detail-container__name">{name}</span>
            <span
                className="template-detail-container__toggle"
                data-testid="expand-button"
                onClick={onTogglePanelClick} >

                {
                    isExpanded
                        ? localized.configuration_page.toggle.collapse
                        : localized.configuration_page.toggle.expand
                }

            </span>

            {
                isExpanded
                    ? (

                        <div data-testid="template-editor-panels" className="template-fields-container">

                            {renderedFieldPanels}
                            {
                                !locked &&
                                <>
                                    { renderedNewCustomFields }
                                    {/*Add new field*/}
                                    <div className="new-field-container">
                                        <Button onClick={addCustomField} className="new-field-button" variant="primary">
                                            + Add Field
                                        </Button>{' '}
                                    </div>
                                </>
                            }
                        </div>
                    )
                    : null
            }

        </div>

    )
}