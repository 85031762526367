import React, { useState, useEffect, useRef } from 'react';
import { useLocale } from 'services/localization/localizationContextProvider';
import { Button } from 'react-bootstrap';
import { ATTACHMENT_TYPES, FIELD_TYPE } from 'constants/enums';
import { useModal, ModalBody } from 'controls/ActionModal/ActionModal';
import FieldPanel from "pages/Configuration/Panels/FieldPanel";
import DownloadLink from 'controls/DownloadLink/DownloadLink';
import NewCustomFieldPanel from 'pages/Configuration/Panels/NewCustomFieldPanel';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipDescription from 'components/TooltipDescription/TooltipDescription';
import 'pages/Configuration/Panels/ReadOnlyFieldPanel.scss';

export default function ReadOnlyFieldPanel({ templateField, fieldReload, showDisabledFields }) {
    const localizedBaseTemplateEditor = useLocale().base_template_editor,
            [subjectTitle, setSubjectTitle] = useState(),
            [placeholderElement, setPlaceholderElement] = useState(),
            [fieldFormClass, setFieldFormClass] = useState(),
            newFieldCounter = useRef(-1),
            [newDependentField, setNewDependentField] = useState({}),
            [isDependentFieldInEditMode, setIsDependentFieldInEditMode] = useState(false),
            [DependentFieldModal, showDependentFieldModal, hideDependentFieldModal] = useModal();

    const handleCancelDependentField = () => {
        hideDependentFieldModal();
    };

    const handleAddDependentField = async () => {
        hideDependentFieldModal();
        await fieldReload();
    }

    useEffect(function computeFieldPanelClassName() {
        let templateFieldFormClassName = 'template-field-form';
        if (!templateField.isEnabled) {
            templateFieldFormClassName += ' template-field-form--disabled';
        }

        setFieldFormClass(templateFieldFormClassName);
    }, [templateField.isEnabled]);

    useEffect(function computeSubjectTitle() {
        let subjectModifierText = '';

        if (templateField.isEnabled) {
            let subjectModifierValue = 0;

            subjectModifierValue += templateField.isAutogenerated === true ? 1 : 0;
            subjectModifierValue += templateField.isRequired === true ? 2 : 0;
            switch (subjectModifierValue) {
                case 1:
                    subjectModifierText = localizedBaseTemplateEditor['autogenerated'];
                    break;

                case 2:
                    subjectModifierText = localizedBaseTemplateEditor['required'];
                    break;

                case 3:
                    subjectModifierText = localizedBaseTemplateEditor['autogenerated'];
                    break;

                default:
                    break;
            }
        }

        setSubjectTitle(`${templateField.fieldName}${subjectModifierText}`);
    }, [localizedBaseTemplateEditor, templateField.fieldName, templateField.isAutogenerated, templateField.isRequired, templateField.isEnabled]);

    useEffect(function computePlaceholderElement() {
        const addDependentField = () => {
            const fieldId = newFieldCounter.current--;
            setNewDependentField({
                id: fieldId,
                parentTemplateFieldId: templateField.id,
                layerId: templateField.layerId,
                fieldName: '', isRequired: true,
                fieldTypeId: FIELD_TYPE.FreeText,
                isFilterableField: false,
                isRingiViewField: false,
                optionAIsDefault: false,
                booleanOptions: [{ id: 'optionA', name: templateField.optionA }, { id: 'optionB', name: templateField.optionB }],
                optionAssociatedWithChildFields: templateField.childTemplateFieldsAssociatedToOptionA === null ? null : (templateField.childTemplateFieldsAssociatedToOptionA === true ? templateField.optionA : templateField.optionB),
                showOptionsAssociatedWithDependentFields: templateField.childTemplateFieldsAssociatedToOptionA === null,
                showTooltip: false
            });
            showDependentFieldModal();
        }

        const inputPlaceholderProps = {
            type: "text",
            className: "data-entry-field-group__input",
            disabled: true,
            readOnly: true
        }
        inputPlaceholderProps["data-testid"] = "placeholder-input";

        if (templateField.defaultOptionIndex >= 0) {
            if ((templateField.parsedOptions[templateField.defaultOptionIndex] + ' ').indexOf(' (default) ') < 0) {
                templateField.parsedOptions[templateField.defaultOptionIndex] += " (default)";
            }
        }
        const dropDownOptions = templateField.parsedOptions ?? [];
        
        const booleanOptionALabel = templateField.optionAIsDefault ? templateField.optionA + " (Default)" : templateField.optionA;

        switch (templateField.fieldTypeId) {

            case FIELD_TYPE.FreeText:
                setPlaceholderElement(<input {...inputPlaceholderProps} value={localizedBaseTemplateEditor['free_text_placeholder']} />);
                break;

            case FIELD_TYPE.Date:
                setPlaceholderElement(<input {...inputPlaceholderProps} value={localizedBaseTemplateEditor['date_placeholder']} />);
                break;

            case FIELD_TYPE.DateRange:
                setPlaceholderElement(<input {...inputPlaceholderProps} value={localizedBaseTemplateEditor['date_range_placeholder']} />);
                break;

            case FIELD_TYPE.Currency:
                setPlaceholderElement(<input {...inputPlaceholderProps} value={localizedBaseTemplateEditor['currency_placeholder']} />);
                break;

            case FIELD_TYPE.Number:
                setPlaceholderElement(<input {...inputPlaceholderProps} value={localizedBaseTemplateEditor['number_placeholder']} />);
                break;

            case FIELD_TYPE.DropDown:
                if(templateField.defaultOptionIndex >= 0){
                    setPlaceholderElement(
                        <select disabled={!templateField.isCustom} data-testid="read-only-select" defaultValue={templateField.defaultOptionIndex} className="data-entry-field-group__select">
                            {dropDownOptions?.map((option, i) => (
                                <option disabled={true} key={i} value={i}>{option}</option>
                            ))}
                        </select>
                    );
                }else{
                    setPlaceholderElement(
                        <select disabled={!templateField.isCustom} data-testid="read-only-select" defaultValue="-1" className="data-entry-field-group__select">
                            {<option disabled={true} value="-1" hidden>{localizedBaseTemplateEditor.dropdown_default_option_text}</option>}
                            {dropDownOptions?.map((option, i) => (
                                <option disabled={true} key={i} value={i}>{option}</option>
                            ))}
                        </select>
                    );
                }
                
                break;

            case FIELD_TYPE.Percentage:
                setPlaceholderElement(<input {...inputPlaceholderProps} value={localizedBaseTemplateEditor['percentage_placeholder']} />);
                break;

            case FIELD_TYPE.Boolean:
                let shownChildFields = 0;

                const handleDependentFieldInEditMode = () => {
                    setIsDependentFieldInEditMode(true);
                };

                const handleDependentFieldNotInEditMode = () => {
                    setIsDependentFieldInEditMode(false);
                };

                const mappedDependentFieldPanels = templateField.childTemplateFields?.map((templateField, i) => {
                    if (showDisabledFields || templateField.isEnabled) {
                        shownChildFields++;
                        return <FieldPanel key={i} templateField={templateField} layerId={templateField.layerId}
                                    onInEditMode={handleDependentFieldInEditMode} onNotInEditMode={handleDependentFieldNotInEditMode} />;
                    }
                    return null;
                });

                setPlaceholderElement(() => {
                    return (<>
                        <div className="wrapper">
                            <div className={classNames({[`frame--optionA__${shownChildFields}`]: templateField.parentTemplateFieldId === null && templateField.childTemplateFieldsAssociatedToOptionA === true },
                                { [`frame--optionB__${shownChildFields}`]: templateField.parentTemplateFieldId === null && templateField.childTemplateFieldsAssociatedToOptionA === false })}></div>
                            <div className={classNames("data-entry-field-group__radio", { "ml-4": templateField.parentTemplateFieldId === null })} data-testid="placeholder-radio">
                                <input type="radio" className="data-entry-field-group__radio-option" disabled={true} 
                                    value={templateField.optionA} data-testid="placeholder-radio-options" defaultChecked={templateField.optionAIsDefault}/>
                                <label className="data-entry-field-group__radio-option-label">{booleanOptionALabel}</label>
                            </div>
                            <div className={classNames("data-entry-field-group__radio", { "ml-4": templateField.parentTemplateFieldId === null })} data-testid="placeholder-radio">
                                <input type="radio" className="data-entry-field-group__radio-option" disabled={true} 
                                    value={templateField.optionB} data-testid="placeholder-radio-options"/>
                                <label className="data-entry-field-group__radio-option-label">{templateField.optionB}</label>
                            </div>
                        </div>
                        <div className="dependent-field-panel-container">
                            { mappedDependentFieldPanels }
                        </div>
                        {templateField.childTemplateFields?.filter(c => c.isEnabled === true).length < 5 &&
                            <div data-testid="new-dependent-field-container" className="new-dependent-field-container">
                                <Button data-testid="new-dependent-field-button" className="new-dependent-field-button" variant="primary" onClick={addDependentField} disabled={isDependentFieldInEditMode || templateField.isEnabled === false}>
                                    + Add Dependent Field
                                </Button>{' '}
                            </div>
                        }
                    </>)
                });
                break;

            case FIELD_TYPE.LongFormFreeText:
                setPlaceholderElement(
                    <textarea data-testid="placeholder-text-area"
                        value={localizedBaseTemplateEditor['long_free_text_placeholder']}
                        className="data-entry-field-group__textarea"
                        readOnly disabled />
                );
                break;
            case FIELD_TYPE.Attachment:
                setPlaceholderElement(
                    <DownloadLink attachmentType={ATTACHMENT_TYPES.TemplateField}
                        fileName={templateField.attachmentName}
                        templateFieldId={templateField.id} />);
                break;
                    
            default:
                break;
        }

    }, [templateField, templateField.fieldTypeId, localizedBaseTemplateEditor, newDependentField, showDependentFieldModal, hideDependentFieldModal, showDisabledFields, isDependentFieldInEditMode]);

    return (
        <>
            <div className={fieldFormClass}>
                <div className="data-entry-container">
                    <div className="data-entry-field-group">
                        <label className="data-entry-field-group__label" data-testid="subject-title">{subjectTitle}</label>
                        {templateField.showTooltip === true &&
                            <TooltipDescription tooltipName={`${templateField.fieldName}-tooltip`} description={templateField.tooltipDescription} />
                        }
                        {placeholderElement}
                    </div>
                </div>
            </div>

            <DependentFieldModal data-testid="dependent-field-modal" variant='confirmation' title={undefined}>
                <ModalBody>
                    <NewCustomFieldPanel templateField={newDependentField} key={newDependentField.id} onCancel={handleCancelDependentField} onAdded={handleAddDependentField} />
                </ModalBody>
            </DependentFieldModal>
        </>
  );
}
