const alphaNumericRule = { value: /^[a-zA-z0-9\s]+$/, message: 'Only Letters and Numbers are allowed' };
const alphaNumericSymbolRule = { value: /^[-\\!$@#%^&*()_+|~=`{}\[\]:";'<>?,.\/a-zA-Z0-9\s]+$/, message: 'Only Letters, Numbers, and Symbols are allowed' };
const dateRule = (dateToCheck) => {
    if (dateToCheck) {
        const date = Date.parse(dateToCheck);
        const invalidDate = isNaN(date);
        if (invalidDate) {
            return "Invalid Date";
        }
        if (date < new Date('1959-04-27')) {
            return 'Invalid Date - Before SMC was founded';
        }
    }
    return true;
};

const dateRangeRule = (toDate, fromDate) => {
    if (toDate && fromDate) {
        const to = Date.parse(toDate);
        const from = Date.parse(fromDate);

        if (from < to) {
            return "Invalid Date Range - From date is less than To date";
        }
    }
    return true;
};

const decimalPlacesRule = (numberToCheck, numberOfDecimalPlaces) => {
    if (Number.isNaN(numberToCheck)) {
        return true;
    }
    const regex = new RegExp(`^-?\\d+(\\.\\d{1,${numberOfDecimalPlaces}})?$`);
    const isValid = regex.test(numberToCheck.toString());
    if (!isValid) {
        return `Only ${numberOfDecimalPlaces} numbers after decimal allowed`;
    }

    return true;
};

const maxLengthRule = (maxLength) => {
    return { value: maxLength, message: `Only ${maxLength} characters are allowed.` };
};

const maxValueRule = (maxValue) => {
    return { value: maxValue, message: `Must be less than or equal to ${maxValue}` };
};

const minValueRule = (minValue) => {
    return { value: minValue, message: `Must be greater than or equal to ${minValue}` };
};

const numericRule = { value: /^\d+$/, message: 'Only numbers are allowed' };
const oversizedFileUploadRule = (file) => {

    const
        FILESIZE_CAP_IN_MEGABYTES = 25,
        NUMBER_OF_BYTES_IN_A_MEGABYTE = 1048576,
        CALCULATED_FILESIZE_LIMIT = NUMBER_OF_BYTES_IN_A_MEGABYTE * FILESIZE_CAP_IN_MEGABYTES,
        ERROR_MESSAGE = `File size is too large. Please upload a file smaller than ${FILESIZE_CAP_IN_MEGABYTES}Mb.`;

    if (file?.size > CALCULATED_FILESIZE_LIMIT) {

        return ERROR_MESSAGE;

    }
    else {

        return true;

    }

};
const requiredRule = { value: true, message: 'Required' };

export {
    alphaNumericRule,
    alphaNumericSymbolRule,
    dateRule,
    dateRangeRule,
    decimalPlacesRule,
    maxLengthRule,
    maxValueRule,
    minValueRule,
    numericRule,
    oversizedFileUploadRule,
    requiredRule
}