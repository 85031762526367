import React from 'react';
import { LocalizationContextProvider } from 'services/localization/localizationContextProvider';
import { ApiStateProvider } from 'providers/apiStateProvider';
import { ValidationServiceContextProvider } from 'providers/validationServiceContextProvider';

const initializationProviders = ({ children }) => {
	return (
		<LocalizationContextProvider>
			<ApiStateProvider>
				<ValidationServiceContextProvider>
					{children}
				</ValidationServiceContextProvider>
			</ApiStateProvider>
		</LocalizationContextProvider>
	)
};

export default initializationProviders;