import React, { useState, useEffect, useCallback } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { getDBLocalizationValue, useLocale } from 'services/localization/localizationContextProvider';
import { post, fetch, put } from 'services/api';
import ContextMenu from 'controls/ContextMenu/ContextMenu';
import 'pages/Division/components/DivisionTab.scss';
import { useModal, ModalBody, ModalButtons, ModalOkButton, ModalCancelButton } from 'controls/ActionModal/ActionModal';
import DivisionEditCard from './DivisionEditCard';

const DivisionTab = ({ division, refreshDivisions }) => {
    const history = useHistory();
    const [menuItems, setMenuItems] = useState();
    const [inEditTitleMode, setInEditTitleMode] = useState(false);
    const [divisionId, setDivisionId] = useState(0);
    const [divisionName, setDivisionName] = useState("");
    const [divisionClassName, setDivisionClassName] = useState("");
    const [divisionClassification, setDivisionClassification] = useState("");
    const [divisionCategoriesAndTypes, setDivisionCategoriesAndTypes] = useState([]);
    const [DisableDivisionModal, showDisableDivisionModal] = useModal();
    const [EnableDivisionModal, showEnableDivisionModal] = useModal();
    const [DivisionInUseModal, showDivisionInUseModal] = useModal();
    const localizedContextMenu = useLocale().pages.division.division_list.context_menu;
    const localizedModals = useLocale().pages.division.division_list.modals;

    useEffect(() => {
        setDivisionId(division.id);
        setDivisionName(division.name);
        setDivisionClassification(division.headquarterClassificationId);
        const getDivisionClassName = async () => {
            const divisionClassifications = await fetch("headquarters/classifications");
            const divisionOptions = divisionClassifications.map((c) => { return { id: c.id, name: getDBLocalizationValue(c.localizationKey) } });
            divisionOptions.forEach((option) => {
                if (option.id === division.headquarterClassificationId) {
                    setDivisionClassName(`(${option.name})`)
                }
            });
        }
        getDivisionClassName();
    }, [division]);

    const editDivision = useCallback(async () => {
        setInEditTitleMode(true);

    }, [setInEditTitleMode]);

    const attemptDisableDivision = useCallback(async () => {
        //check if in use
        let types = await fetch(`headquarters/typesAssociatedWithDivision/${division.id}`);
        if (types?.length > 0) {
            setDivisionCategoriesAndTypes(types);
            showDivisionInUseModal();
        } else {
            showDisableDivisionModal();
        }
    }, [division, showDivisionInUseModal, showDisableDivisionModal]);

    const confirmDisableDivision = async () => {
        let response = await put(`headquarters/division/${division.id}/disable`);

        if (response != null) {
            if (history.location.pathname === `/admin/division/${division.id}`) {
                history.push("/admin/division");
            }
            refreshDivisions();
        }
    }

    const confirmEnableDivision = useCallback(async () => {
        let response = await put(`headquarters/division/${division.id}/enable`);

        if (response != null) {
            refreshDivisions();
        }
    }, []);

    useEffect(function computeMenuItems() {
        setMenuItems([
            {
                text: localizedContextMenu.edit,
                condition: () => { return division.isActive },
                action: editDivision
            },
            {
                text: localizedContextMenu.disable,
                condition: () => { return division.isActive },
                action: attemptDisableDivision
            },
            {
                text: localizedContextMenu.enable,
                condition: () => { return !division.isActive },
                action: showEnableDivisionModal
            },

        ]);
    }, [
        localizedContextMenu,
        division,
        editDivision,
        attemptDisableDivision,
        showEnableDivisionModal
    ]);

    const onSaveEdit = async (divisionName, classificationId) => {

        let response = await post(`headquarters/divisions/update`, JSON.stringify({ id: division.id, name: divisionName, classificationId: classificationId }))
        if (response != null) {
            refreshDivisions();
        }
    }

    const onCloseEdit = () => {
        setInEditTitleMode(false);
    }

    return (
        <>
            <div className={`division-tab-container ${division.isActive === true ? "" : "division-tab-container--disabled"}`} data-testid={`division-tab-${division.id}`} >
                {inEditTitleMode ?
                    <div className="edit-division-tab__container">
                        <DivisionEditCard
                            existingDivisionId={divisionId}
                            existingName={divisionName}
                            existingClassification={divisionClassification}
                            isEditing={true}
                            onSave={onSaveEdit}
                            onClose={onCloseEdit}
                        />
                    </div>
                    :
                    <>
                        <Nav.Link as={NavLink} to={`/admin/division/${division.id}`} className="division-tab" disabled={!division.isActive}>
                            <span data-testid="division-name">{divisionName} {divisionClassName}</span>
                        </Nav.Link>
                        <ContextMenu menuItems={menuItems} className="division-tab__context-menu" dropDirection="left" />
                    </>
                }

                <DivisionInUseModal title={localizedModals.in_use_title}>
                    <ModalBody>
                        {localizedModals.in_use_message}
                        <div className="type-links__container">
                            {divisionCategoriesAndTypes?.map((catType, i) => (
                                <div key={i} data-testid={`cat-type-${i}`}>
                                    {catType.category}{" - "}
                                    {catType.types?.map((theType, k) => (
                                        <span key={k}>
                                            {(k > 0 && k < catType.types.length - 1) && ", "}
                                            {(catType.types.length > 1 && k === catType.types.length - 1) && " & "}
                                            <a href={`${window.location.origin}/admin/configuration/category/${catType.categoryId}/type/${theType.typeId}`} key={k} target="_blank">{theType.type}</a>
                                        </span>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </ModalBody>
                    <ModalButtons>
                        <ModalCancelButton text={localizedModals.cancel} />
                    </ModalButtons>
                </DivisionInUseModal>

                <DisableDivisionModal title={localizedModals.disable_title}>
                    <ModalBody>{localizedModals.disable_message}</ModalBody>
                    <ModalButtons>
                        <ModalCancelButton text={localizedModals.cancel} />
                        <ModalOkButton text={localizedModals.disable_confirm} onClick={confirmDisableDivision} />
                    </ModalButtons>
                </DisableDivisionModal>

                <EnableDivisionModal title={localizedModals.enable_title}>
                    <ModalBody>{localizedModals.enable_message}</ModalBody>
                    <ModalButtons>
                        <ModalCancelButton text={localizedModals.cancel} />
                        <ModalOkButton text={localizedModals.enable_confirm} onClick={confirmEnableDivision} />
                    </ModalButtons>
                </EnableDivisionModal>
            </div>
        </>
    );
};

export default DivisionTab;