import React from 'react';
import { useFormContext } from 'react-hook-form';
import ErrorIcon from 'components/ErrorIcon/ErrorIcon';

const GetValidationError = (fieldName) => {
    const methods = useFormContext();

    const validationErrors = methods ? methods.formState.errors : null;

    if (fieldName && validationErrors) {
        const nestedPropertyNames = fieldName.split('.')
        let errorObject = validationErrors[nestedPropertyNames[0]];
        if (nestedPropertyNames.length > 1) {
            for (var i = 1; i < nestedPropertyNames.length; i++) {
                if (errorObject) {
                    errorObject = errorObject[nestedPropertyNames[i]];
                }
            }
        }

        return errorObject
    }

    return null;
}

const FormValidationError = ({ className, errorMessage, inverseErrorIconColor }) => {
    const methods = useFormContext();
    const hasErrors = methods ? Object.keys(methods.formState.errors).length > 0 : false;

    return (
        <>
            {hasErrors &&
                <ErrorIcon className={className} errorIconName="form-validation-error" errorMessage={errorMessage} inverseColor={inverseErrorIconColor} />
            }
        </>
    );
}

const ValidationError = ({ className, fieldName, inline, inverseErrorIconColor }) => {
    const errorObject = GetValidationError(fieldName);

    return (
        <>
            {errorObject &&
                <ErrorIcon className={className} errorIconName={'error-icon-' + fieldName} errorMessage={errorObject?.message} inline={inline} inverseColor={inverseErrorIconColor} />
            }
        </>
    );
}

const GeneralError = ({ className, errorMessage, inverseErrorIconColor }) => {
    return (
        <>
            {errorMessage &&
                <ErrorIcon className={className} errorIconName={'error-icon-general'} errorMessage={errorMessage} inverseColor={inverseErrorIconColor} />
            }
        </>
    );
}
export default ValidationError;

export { FormValidationError, GetValidationError, GeneralError }