import React from 'react';
import TemplateFieldEditor from 'pages/Configuration/Editors/TemplateFieldEditor';
import { post } from 'services/api';
import { attachTemplateFieldAttachment } from 'services/attacher';
import { FIELD_TYPE } from 'constants/enums';
import 'pages/Configuration/Panels/NewCustomFieldPanel.scss';

export default ({ templateField, onCancel, onAdded }) => {
    const
        HEADER_TEXT = "New Field Creation",
        handleSave = async (newTemplateField) => {
            let attachmentNameToSave = null;
            let attachmentStorageId = null;
            if (parseInt(newTemplateField.fieldTypeId) === FIELD_TYPE.Attachment && newTemplateField.attachedTemplate) {
                const attachmentName = await attachTemplateFieldAttachment(newTemplateField.attachedTemplate);
                if (attachmentName) {
                    attachmentNameToSave = attachmentName
                }
                attachmentStorageId = newTemplateField.attachedTemplate.storageId;
            }

            await post(`layers/${templateField.layerId}/fields`,
                {
                    fieldName: newTemplateField.fieldName,
                    fieldTypeId: newTemplateField.fieldTypeId,
                    options: newTemplateField.options,
                    isRequired: newTemplateField.isRequired,
                    attachmentName: attachmentNameToSave,
                    storageId: attachmentStorageId,
                    optionA: newTemplateField.optionA,
                    optionB: newTemplateField.optionB,
                    optionAIsDefault: newTemplateField.optionAIsDefault,
                    defaultOptionIndex: newTemplateField.defaultOptionIndex,
                    isFilterableField: newTemplateField.isFilterableField,
                    isRingiViewField: newTemplateField.isRingiViewField,
                    parentTemplateFieldId: newTemplateField.parentTemplateFieldId,
                    optionAssociatedWithChildFields: newTemplateField.optionAssociatedWithChildFields,
                    tooltipDescription: newTemplateField.tooltipDescription,
                    showTooltip: newTemplateField.showTooltip
                });
            await onAdded(newTemplateField.id);
        };

    return (
        <div className="new-template-field-container">
            <TemplateFieldEditor
                templateField={templateField}
                headerText={HEADER_TEXT}
                onCancel={onCancel}
                onSave={handleSave} />
        </div>
    );
}