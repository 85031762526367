import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';

function useScreenDetect() {
    const [isMobile, setIsMobile] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const mobileDesktopCutoff = 992;

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < mobileDesktopCutoff);
            setIsDesktop(window.innerWidth >= mobileDesktopCutoff);
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return {
        isMobile,
        isDesktop
    }
}

const ScreenDetectColumn = ({ desktopColumnWidthPixels, desktopColumnWidth, children, ...rest }) => {
    const { isDesktop } = useScreenDetect();

    const desktopStyle = {
        width: desktopColumnWidthPixels + 'px'
    }

    return (
        desktopColumnWidthPixels === undefined ?
            <Col xs={isDesktop ? desktopColumnWidth : 12} {...rest}>
                {children}
            </Col>
            :
            <div style={desktopStyle} {...rest}>
                {children}
            </div>
    );
}

export { useScreenDetect, ScreenDetectColumn };