import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FormValidationError } from 'components/ValidationError';
import { patch, PatchOperation } from 'services/api';
import { useLocale } from 'services/localization/localizationContextProvider';
import HeaderControls from 'pages/Configuration/FormBuilderHeaderControls';
import { useTypeApprovalEditorForm } from 'pages/Configuration/providers/typeApprovalEditorFormContextProvider';
import { LAYER_TYPE } from 'constants/enums';

export default function FormBuilderHeader({ onTypeApprovalChange }) {
    const localized = useLocale();
    const { reactHookFormMethods, typeId, isInDraftState } = useTypeApprovalEditorForm();

    const enableType = async () => {
        var isValid = await reactHookFormMethods.trigger();
        if (isValid) {
            const formData = reactHookFormMethods.getValues();

            var response = await patch(`layers/${typeId}`,
                new PatchOperation('replace', '/TypeProperties/ApprovalTemplateId', formData.approvalTemplateId),
                new PatchOperation('replace', '/TypeProperties/ApprovalRequirementId', formData.requiresApprovalId),
                new PatchOperation('replace', '/TypeProperties/HeadquarterClassificationId', formData.classificationId),
                new PatchOperation('replace', '/TypeProperties/HeadquarterDivisionId', formData.divisionId),
                new PatchOperation('replace', '/TypeProperties/IsDraft', false));

            if (response != null) {
                await onTypeApprovalChange();
            }
        };
    }


    return (
        <>
            {
                isInDraftState &&
                <>
                    <FormProvider {...reactHookFormMethods}>
                        <FormValidationError errorMessage={localized.errors_on_form} className="type-layer__error-icon" />
                    </FormProvider>
                    <Button data-testid="enable-type-button" onClick={enableType} className="type-layer__enable-type">
                        <FontAwesomeIcon icon={faSave} />{localized.configuration_page.type_template_field_editor.enable_type}
                    </Button>
                </>
            }
            {
                !isInDraftState && <HeaderControls layerTypeId={LAYER_TYPE.Type} />
            }
        </>
    );
}