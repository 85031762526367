import React, { useState } from 'react';
import { requiredRule } from 'services/validationRules';
import { useLocale } from 'services/localization/localizationContextProvider';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import ValidationTextArea from 'controls/ValidationTextArea/ValidationTextArea';
import 'pages/Ringis/components/Approvers/ApproverCard.scss';

export default function ApprovalModalContent({ conditionDetails, messageText, conditionText }) {
    const approveLocalization = useLocale().modals.confirmation.approver.approve;
    const [approvalConditionChecked, setApprovalConditionChecked] = useState();

    const onCheckboxChange = (value) => {
        setApprovalConditionChecked(value);
        conditionDetails.current.isConditionalApproval = value;
    }

    const onInputChange = (value) => {
        conditionDetails.current.conditionText = value;
    };

    return (
        <div className="approve-modal__body">
            <span>
                {messageText}
            </span>
            <label className="approve-modal-condition-checkbox">
                <ValidationInput
                    fieldName="isConditionalApproval"
                    onChange={(e) => onCheckboxChange(e.target.checked)}
                    type="checkbox"
                />
                {conditionText}
            </label>
            {approvalConditionChecked &&
                <span>
                    <ValidationTextArea
                        fieldName="conditionText"
                        title={approveLocalization.conditionTitle}
                        className="approve-modal__condition-text"
                        onChange={(e) => onInputChange(e.target.value)}
                        validationRules={{
                            required: requiredRule
                        }}
                    />
                    <div className="approve-modal__comment-message">
                        {approveLocalization.commentMessage}
                    </div>
                </span>
            }
        </div>
    );
}