import React, { useContext, useEffect, useState, useCallback } from 'react';
import { fetch } from 'services/api';

const BaseRingiTemplateContext = React.createContext();
const CategoryTemplateContext = React.createContext();
const TypeTemplateContext = React.createContext();

const useBaseRingiTemplate = () => useContext(BaseRingiTemplateContext);
const useCategoryTemplate = () => useContext(CategoryTemplateContext);
const useTypeTemplate = () => useContext(TypeTemplateContext);

const TemplateContextProvider = ({ children, layerId, layerTypeId = 1 }) => {
    const [templateFields, setTemplateFields] = useState([]);
    const [showDisabledFields, setShowDisabledFields] = useState(false);

    const loadTemplateFields = useCallback(async () => {
        const fields = await fetch(`layers/${layerId}/fields`) ?? [];
        setTemplateFields(fields);
    }, [layerId]);

    useEffect(() => {
        loadTemplateFields();
    }, [loadTemplateFields]);

    const templateContextProps = {
        value: {
            templateFields: templateFields,
            layerId: layerId,
            reload: loadTemplateFields,
            showDisabledFields: showDisabledFields,
            toggleDisabledFieldsVisibility: () => { setShowDisabledFields(sif => !sif); }
        }
    }
    var templateContext;
    if (layerTypeId === 1) {
        templateContext = <BaseRingiTemplateContext.Provider {...templateContextProps}> {children} </BaseRingiTemplateContext.Provider>
    }
    if (layerTypeId === 2) {
        templateContext = <CategoryTemplateContext.Provider {...templateContextProps}>{children} </CategoryTemplateContext.Provider>
    }
    if (layerTypeId === 3) {
        templateContext = <TypeTemplateContext.Provider {...templateContextProps}> {children} </TypeTemplateContext.Provider>
    }

    return (
        <>
            {templateContext}
        </>
    );
}

export {
    TemplateContextProvider,
    useBaseRingiTemplate,
    useCategoryTemplate,
    useTypeTemplate
};