import React, { useState, useContext } from 'react';
import { DEFAULT_LANDING_PAGE, ROUTES } from 'constants/constants';
import { fetch } from 'services/api';

const UserContext = React.createContext();

const UserContextProvider = props => {
    const [userDefaultLandingPage, setUserDefaultLandingPage] = useState();
    const [isUserActiveCalled, setIsUserActiveCalled] = useState(false);
    const [isUserActive, setIsUserActive] = useState(true);

    const fetchUserLandingPage = async () => {
        const response = await fetch(ROUTES.CURRENT_USER_LANDING_PAGE);
        return response ? response : DEFAULT_LANDING_PAGE;
    }

    const refreshLandingPage = async () => {
        setUserDefaultLandingPage(await fetchUserLandingPage());
    }

    const isUserActiveInDatabase = async (loginName) => {
        const result = await fetch(`users/${loginName}/activeInDatabase`);
        setIsUserActiveCalled(true);
        setIsUserActive(result);
        return result;
    }

    const serviceMethods = {
        userDefaultLandingPage,
        refreshLandingPage,
        fetchUserLandingPage,
        isUserActiveInDatabase,
        isUserActiveCalled,
        isUserActive
    };

    return (
        <UserContext.Provider value={serviceMethods}>
            {props.children}
        </UserContext.Provider>
    );
};

const useUserContextProvider = () => {
    return useContext(UserContext);
};

export {
    UserContextProvider,
    useUserContextProvider
};
