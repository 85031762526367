import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { getDBLocalizationValue, useLocale } from 'services/localization/localizationContextProvider';
import { useForm, FormProvider } from 'react-hook-form';
import { fetch } from 'services/api';
import { requiredRule, alphaNumericRule } from 'services/validationRules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import 'pages/Division/components/DivisionEditCard.scss';
import ValidationSelect from 'controls/ValidationSelect/ValidationSelect';

export default function DivisionEditCard({existingDivisionId, existingName, existingClassification, isEditing, onSave, onClose}) {
    const methods = useForm({ reValidateMode: 'onSubmit' });
    const localized = useLocale().pages.division.division_list;

    const [divisionClassificationOptions, setDivisionClassificationOptions] = useState([]);
    const [selectedClassification, setSelectedClassification] = useState();

    const onSubmit = async (formData) => {
        await onSave(formData.editDivisionInput, formData.editDivisionSelect ? formData.editDivisionSelect : selectedClassification);
        onClose();
    }

    useEffect(() => {
        const setDivisionClassifications = async () => {
            let divisionClassifications = await fetch(`headquarters/classifications`);
            let divisionOptions = divisionClassifications.map((c) => {return {id: c.id, name: getDBLocalizationValue(c.localizationKey)}});
            setDivisionClassificationOptions([{id: "", name: localized.division_select_placeholder, hidden: true}, ...divisionOptions]);
        };
        setDivisionClassifications();
    }, [localized, setDivisionClassificationOptions, existingName]);

    const isDivisionNameUnique = async (divisionName) => {
        var response = await fetch(`headquarters/divisions/uniqueName/${divisionName}/${existingDivisionId?existingDivisionId:0}`);
        if(!response){
            return localized.add_division_unique_error;
        }
        return true;
    }

    useEffect(() => {
        setSelectedClassification(existingClassification);
    },[existingClassification]);

    return(
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className="edit-division-card__container" data-testid="editDivisionForm">
                <ValidationInput
                    autoFocus
                    type="text"
                    defaultValue={(existingName && isEditing) ? existingName : ""}
                    fieldName="editDivisionInput"
                    className="division-edit-card__row-spacing"
                    title={isEditing? localized.edit_division_input_title : localized.add_division_input_title}
                    maxLength={100}
                    minLength={1}
                    validationRules={
                        {
                            required: requiredRule,
                            pattern: alphaNumericRule,
                            validate: async (divisionName) => await isDivisionNameUnique(divisionName)
                        }}
                />
                {existingClassification ?
                    <>
                        <label htmlFor={"editDivisionSelect"} className="division-edit-card__select-label">{localized.add_division_select_title}</label>
                        <select 
                            className="division-edit-card__select division-edit-card__row-spacing" 
                            data-testid="editDivisionSelect"
                            id="editDivisionSelect" 
                            name="editDivisionSelect" 
                            value={selectedClassification} 
                            onChange={(e) => setSelectedClassification(e.target.value)}>
                                {divisionClassificationOptions?.map((option, i) => (
                                    <option disabled={option.disabled} hidden={option.hidden} value={option.id} key={i}>{option.name}</option>
                                ))}
                        </select>
                    </>
                :
                    <ValidationSelect 
                        fieldName="editDivisionSelect"
                        className="division-edit-card__row-spacing"
                        title={localized.add_division_select_title}
                        defaultValue=""
                        options={divisionClassificationOptions}
                        validationRules={{
                            required: requiredRule,
                        }}
                    />
                }
                <div className="division-edit-card__button-container">
                    <Button variant="link" data-testid="division-edit-card-cancel" onClick={onClose}>{localized.cancel}</Button>
                    <Button type="submit" data-testid="division-edit-card-save" className="division-edit-card__save">
                        <FontAwesomeIcon icon={faSave} /> &nbsp; {localized.save}
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}