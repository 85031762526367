import React from 'react';
import { useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { GetOktaConfig } from 'configurations/okta.config';
import { useUserContextProvider } from 'providers/userContextProvider';
import 'App/App.scss'

export default function OktaSecurity({ children }) {
    const oktaConfig = GetOktaConfig();
    const oktaAuth = new OktaAuth(oktaConfig);
    const history = useHistory();
    const { fetchUserLandingPage, isUserActiveInDatabase } = useUserContextProvider();

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        const isActive = await isUserActiveInDatabase(_oktaAuth.authStateManager._authState.idToken.claims.preferred_username.trim().toLocaleLowerCase());
        if (isActive === true) {
            const userLandingPage = await fetchUserLandingPage();
            history.replace(toRelativeUrl(originalUri || userLandingPage, window.location.origin));
        }
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            {children}
        </Security>
    );
}