import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

const TypeApprovalEditorFormContext = React.createContext();

const useTypeApprovalEditorForm = () => useContext(TypeApprovalEditorFormContext);

const TypeApprovalEditorFormContextProvider = ({ children, typeProperties }) => {
    const methods = useForm({
        defaultValues: {
            approvalTemplateId: typeProperties.approvalTemplateId,
            requiresApprovalId: typeProperties.approvalRequirementId,
            classificationId: typeProperties.headquarterClassificationId,
            divisionId: typeProperties.headquarterDivisionId
        },
        reValidateMode: 'onSubmit'
    });

    return (
        <TypeApprovalEditorFormContext.Provider value={{
            reactHookFormMethods: methods,
            isInDraftState: typeProperties.isDraft,
            typeId: typeProperties.layerId
        }}>
            {children}
        </TypeApprovalEditorFormContext.Provider>
    );
}


export {
    TypeApprovalEditorFormContextProvider,
    useTypeApprovalEditorForm
};