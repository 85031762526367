import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocale } from "services/localization/localizationContextProvider";
import PillText from 'components/PillText/PillText';
import "./ReportSummaryRow.scss";

const ReportSummaryRow = ({ data, className }) => {
    const localizedCurrencyIcon = useLocale().ringi_input.currency_unit_icon;
    return (
        <tr className={className}>
            <td>{data.title}</td>
            <td className="text-center">{data.numberOfRingis}</td>
            <td className="text-center"><PillText text={data.approved} variant={"Positive"} /></td>
            <td className="text-center"><PillText text={data.denied} variant={"Negative"} /></td>
            <td className="dollar-value"><FontAwesomeIcon data-testid='prefix-icon' icon={localizedCurrencyIcon} />{data.total.toLocaleString("en-US", { maximumFractionDigits: 2 })}</td>
        </tr>

    );
}

export default ReportSummaryRow;