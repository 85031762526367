import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { useLocale } from 'services/localization/localizationContextProvider';
import { fetch, post } from 'services/api';
import { useForm, FormProvider } from 'react-hook-form';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import { requiredRule, alphaNumericRule } from 'services/validationRules';
import { uniqueRule } from 'services/serverValidationRules';
import ApprovalTemplateTab from 'pages/Approval/components/ApprovalTemplateTab';
import ToggleSwitch from 'controls/ToggleSwitch/ToggleSwitch';
import 'pages/Approval/components/ApprovalList.scss';


const ApprovalList = () => {
    const localized = useLocale();
    const history = useHistory();

    const localizedButtons = localized.buttons;
    const localizedApproval = localized.pages.approval;
    const [approvalTemplates, setApprovalTemplates] = useState([]);
    const [showNewApprovalTemplateForm, setShowNewApprovalTemplateForm] = useState(false);
    const [showDisabledTemplates, setShowDisabledTemplates] = useState(false);

    const methods = useForm({ reValidateMode: 'onSubmit' });

    const fetchApprovalList = useCallback(async () => {
        const approvalTemplates = await fetch(`approvaltemplates`);
        setApprovalTemplates(approvalTemplates);
    }, []);

    const handleAddApprovalTemplate = () => {
        setShowNewApprovalTemplateForm(true);
    };

    const onSubmit = async (formData) => {
        const newApprovalTemplateId = await post(`approvaltemplates`, JSON.stringify(formData.newApprovalTemplateName));
        if (newApprovalTemplateId != null) {
            setShowNewApprovalTemplateForm(false);
            methods.reset();
            await fetchApprovalList();
            history.push(`/admin/approval/${newApprovalTemplateId}`);
        }
    };

    const onSwitch = (isSetToViewEnabledTemplates) => {
        setShowDisabledTemplates(isSetToViewEnabledTemplates);
        history.push("/admin/approval");
    }

    useEffect(() => {
        fetchApprovalList();
    }, [fetchApprovalList]);


    return (
        <>
            <div className="approval-list__header approval-list__section">
                <div className="approval-list-header__controls">
                    <Button onClick={handleAddApprovalTemplate}
                        className="approval-list_add-approval-template-button--small"
                        disabled={showNewApprovalTemplateForm || showDisabledTemplates}
                        data-testid="add-approval-template-button-small">
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <ToggleSwitch label={localizedApproval.approval_list_toggle_label} onSwitch={onSwitch} />
                </div>
                {
                    showNewApprovalTemplateForm &&
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)} className="approval-list__add-approval-template-section approval-list__section">
                            <ValidationInput
                                autoFocus
                                type="text"
                                data-testid="new-approval-template-input"
                                title={localizedApproval.new_approval_template_button_text}
                                className="approval-list__new-approval-template-input"
                                maxLength={100}
                                minLength={1}
                                fieldName="newApprovalTemplateName"
                                validationRules={
                                    {
                                        required: requiredRule,
                                        pattern: alphaNumericRule,
                                        validate: async (newApprovalTemplateName) => await uniqueRule(`approvaltemplates?name=${newApprovalTemplateName}`)
                                    }}
                            />
                            <div className="approval-list-header__new-approval-template-buttons">
                                <Button variant="link" onClick={() => setShowNewApprovalTemplateForm(false)}>{localizedButtons.cancel}</Button>
                                <Button type="submit" data-testid="save-new-approval-template">
                                    <FontAwesomeIcon icon={faSave} /> &nbsp; {localizedButtons.save}
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                }
            </div>
            <div className="approval-list__content approval-list__section">
            {
                approvalTemplates.length === 0 &&
                <span data-testid="no-approval-templates-message" className="approval-list__no-templates-message">
                    {localizedApproval.no_approval_templates_message}
                </span>
            }
            {
                approvalTemplates.map((approvalTemplate, i) => {
                    return (
                        <ApprovalTemplateTab key={i} approvalTemplate={approvalTemplate} 
                                                     refreshApprovalTemplates={fetchApprovalList} 
                                                     showDisabledTemplates={showDisabledTemplates}/>
                    )
                })
            }
            </div>
            <div className="approval-list__footer">
                <Button onClick={handleAddApprovalTemplate}
                    className="approval-list_add-approval-template-button--large"
                    disabled={showNewApprovalTemplateForm}
                    data-testid="add-approval-template-button-large">
                    <FontAwesomeIcon icon={faPlus} /> Add Approval Template {/*TODO: localize this*/}
                </Button>
            </div>
        </>
    );
};

export default ApprovalList;