const GetOktaConfig = () => {

    const SCOPES = process.ringiEnv.REACT_APP_OKTA_SCOPES.split(',');

    return {
        issuer: process.ringiEnv.REACT_APP_OKTA_ISSUER,
        redirectUri: process.ringiEnv.REACT_APP_OKTA_REDIRECT_URI,
        clientId: process.ringiEnv.REACT_APP_OKTA_CLIENTID,
        pkce: process.ringiEnv.REACT_APP_OKTA_ENABLE_PKCE,
        scopes: SCOPES,
        disableHttpsCheck: process.ringiEnv.REACT_APP_OKTA_DISABLE_HTTPS_CHECKS,
        tokenManager: {
            expireEarlySeconds: process.ringiEnv.REACT_APP_OKTA_EXPIRE_EARLY_SECONDS
        }
    };
}

export { GetOktaConfig };