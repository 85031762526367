import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { fetch, post, httpDelete } from 'services/api';
import { ApprovalTemplateApproversContextProvider } from 'pages/Approval/providers/approvalTemplateApproversContextProvider';
import ApprovalLevel from 'pages/Approval/components/ApprovalLevel';
import './ApprovalTemplate.scss';

export default function ApprovalTemplate({ approvalTemplateId }) {
    const [approvalLevels, setApprovalLevels] = useState([]);

    const fetchApprovalLevels = useCallback(async () => {
        if (approvalTemplateId) {
            const approvalLevels = await fetch(`approvalTemplates/${approvalTemplateId}/approvalLevels`);
            setApprovalLevels(approvalLevels);
        }

    }, [approvalTemplateId]);

    const deleteApprovalLevel = async (approvalLevelId) => {
        await httpDelete(`approvalLevels/${approvalLevelId}`);
        await fetchApprovalLevels();
    };

    const addApprovalLevel = async () => {
        await post(`approvalTemplates/${approvalTemplateId}/approvalLevels`);
        await fetchApprovalLevels();
    };

    useEffect(() => {
        fetchApprovalLevels();
    }, [fetchApprovalLevels]);


    return (
        <>
            <div className="approval-template">
                <ApprovalTemplateApproversContextProvider approvalLevels={approvalLevels}>
                    <div className="approval-template__header">Approval Structure</div>
                    {approvalLevels?.map((approvalLevel, i) => {
                        return (<ApprovalLevel key={i} approvalLevel={approvalLevel} onDelete={deleteApprovalLevel} reload={fetchApprovalLevels} />);

                    })}
                    <Button onClick={addApprovalLevel} data-testid="add-approval-level" className="new-approval-level__button" variant="primary">+ Add Approval Level</Button>
                </ApprovalTemplateApproversContextProvider>
            </div>
        </>
    );
}