import React, { useEffect, useState } from "react";
import classNames from 'classnames';
import './ToggleSwitch.scss';

export default function ToggleSwitch({ label, onSwitch, enabled = true, toggleOn = false }) {
    const switchId = `switch-${label}`;
    const [isToggled, setIsToggled] = useState(toggleOn);

    const onToggleSwitch = (state) => {
        setIsToggled(state);
        onSwitch(state);
    };

    useEffect(() => {
        if (enabled === false) {
            onToggleSwitch(false);
        }
    }, [enabled])

    return (
        <div className="toggleSwitch_container">
            <label>{label}</label>
            <div className={classNames("toggleSwitch", { "toggleSwitch--disabled": !enabled, "toggleSwitch--enabled": enabled })}>
                <input data-testid={switchId} type="checkbox" id={switchId} checked={isToggled} onChange={e => onToggleSwitch(e.currentTarget.checked)} />
                <label htmlFor={switchId} />
            </div>
        </div>
    );
};