import React from "react";
import Card from 'react-bootstrap/Card';
import "./ReportCard.scss";

const ReportCard = ({ title, children }) => {
    return (
        <Card className="report-card">
            <Card.Title>{title}</Card.Title>
            <Card.Body>
                {children}
            </Card.Body>
        </Card>
    );
}

export default ReportCard;