import React from 'react';
import { FormProvider } from 'react-hook-form'
import TemplateEditor from 'pages/Configuration/Editors/TemplateEditor';
import TypeApprovalTemplateEditor from 'pages/Configuration/Type/Editors/TypeApprovalTemplateEditor';
import TypeAdditionalApproversEditor from 'pages/Configuration/Type/Editors/TypeAdditionalApproversEditor';
import TypeAdditionalReadersEditor from 'pages/Configuration/Type/Editors/TypeAdditionalReadersEditor';
import { useLocale } from 'services/localization/localizationContextProvider';
import { useBaseRingiTemplate, useCategoryTemplate, useTypeTemplate } from 'pages/Configuration/providers/templateContextProvider';
import { useTypeApprovalEditorForm } from 'pages/Configuration/providers/typeApprovalEditorFormContextProvider';
import 'pages/Configuration/Type/FormBuilder.scss';

export default () => {
    const baseRingiTemplate = useBaseRingiTemplate();
    const categoryTemplate = useCategoryTemplate();
    const typeTemplate = useTypeTemplate();
    const localized = useLocale();
    const localizedConfigurationPage = localized.configuration_page;
    const { reactHookFormMethods, typeId } = useTypeApprovalEditorForm();

    return (
        <>
            <TemplateEditor name={localizedConfigurationPage.base_editor.title} layerId={baseRingiTemplate.layerId}
                templateFields={baseRingiTemplate.templateFields} locked={true} reload={baseRingiTemplate.reload} />
            <TemplateEditor name={localizedConfigurationPage.category_editor.title} layerId={categoryTemplate.layerId}
                templateFields={categoryTemplate.templateFields} locked={true} reload={categoryTemplate.reload} />
            <TemplateEditor name={localizedConfigurationPage.type_editor.title} layerId={typeTemplate.layerId} showDisabledFields={typeTemplate.showDisabledFields}
                templateFields={typeTemplate.templateFields} reload={typeTemplate.reload} />
            <div data-testid="approval-section" className="type-approval-editor">
                <span data-testid="approval-section-name" className="type-approval-editor__name">{localizedConfigurationPage.type_editor.approval_section_title}</span>
                <div className="type-approval-editor__cards">
                    <FormProvider {...reactHookFormMethods}>
                        <TypeApprovalTemplateEditor typeLayerId={typeId} />
                    </FormProvider>
                    <TypeAdditionalApproversEditor typeLayerId={typeId} />
                    <TypeAdditionalReadersEditor typeLayerId={typeId} />
                </div>
            </div>
        </>
    );
}