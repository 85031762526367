import React, { useState, useEffect, useCallback } from 'react';
import { fetch } from 'services/api';
import { Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocale } from 'services/localization/localizationContextProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import ValidationSelect from 'controls/ValidationSelect/ValidationSelect';
import ValidationTypeahead from 'controls/ValidationTypeahead/ValidationTypeahead';
import { requiredRule } from 'services/validationRules';
import { inactiveRule } from 'services/serverValidationRules';
import { APPROVAL_TYPES } from 'constants/enums';
import { useApprovalTemplateApprovers } from 'pages/Approval/providers/approvalTemplateApproversContextProvider';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "./ApproverEditor.scss";

const ApproverEditor = ({headerText, onClose }) => {
    const localized = useLocale();
    const localizedButtons = localized.buttons;
    const localizedApprovalPage = localized.pages.approval;
    const localizedValidationMessages = localized.validation_messages;
    const localizedTypeaheadMessages = localized.typeahead_messages;
    const approvalTemplateApprovers = useApprovalTemplateApprovers();

    const [userList, setUserList] = useState([]);
    const methods = useForm({ reValidateMode: 'onSubmit' });
    const watchApprovalType = methods.watch("approvalType");

    const approvalTypes = [
        { id: "", name: localizedApprovalPage.approval_type_default_value, selected: true },
        { id: APPROVAL_TYPES.SMCEmployee, name: localizedApprovalPage.approval_type_smc_employee },
        { id: APPROVAL_TYPES.CostCenter, name: localizedApprovalPage.approval_type_cost_center },
        { id: APPROVAL_TYPES.AdditionalApprovers, name: localizedApprovalPage.approval_type_additional_approvers }
    ];

    const resetEditor = () => {
        methods.reset();
    }

    const handleClose = async () => {
        await onClose(false);
        resetEditor();
    }

    const onSubmit = async (formData) => {
        await onClose(true, formData["approvalType"], formData["Approver"]);
        resetEditor();
    };
    
    useEffect(() => {
        const fetchUsers = async () => {
            const users = await fetch(`users`);
            setUserList(users);
        };
        fetchUsers();
    }, []);


    const onlyOneCostCenterApprovalTypeRule = useCallback((approvalType) => {
        if (approvalType === APPROVAL_TYPES.CostCenter.toString() && approvalTemplateApprovers.costCenterApproversCount > 0) {
            return localizedValidationMessages.only_one_cost_center_approval_type_message;
        }
        return true;
    }, [approvalTemplateApprovers, localizedValidationMessages]);

    const onlyOneAdditionalApprovalTypeRule = useCallback((approvalType) => {
        if (approvalType === APPROVAL_TYPES.AdditionalApprovers.toString() && approvalTemplateApprovers.additionalApproversCount > 0) {
            return localizedValidationMessages.only_one_additional_approver_approval_type_message;
        }
        return true;
    }, [approvalTemplateApprovers, localizedValidationMessages]);

    return (
        <FormProvider {...methods}>
            <form className="add-approver-editor-content" data-testid="approver-editor" onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="template-field-header">
                    <span data-testid="add-approver-editor-header">{headerText}</span>
                    <FontAwesomeIcon data-testid="add-approver-editor-header-close" className="template-field-header__cancel" icon={faTimes} onClick={() => handleClose()} />
                </div>
                <ValidationSelect
                    fieldName="approvalType"
                    defaultValue=""
                    options={approvalTypes}
                    title={localizedApprovalPage.approval_type_label_text}
                    validationRules={{
                        required: requiredRule,
                        validate: {
                            onlyOneCostCenterApprovalType: onlyOneCostCenterApprovalTypeRule,
                            onlyOneAdditionalApproversApprovalType: onlyOneAdditionalApprovalTypeRule
                        }
                    }}
                />
                {watchApprovalType === APPROVAL_TYPES.SMCEmployee.toString() &&
                    <>
                        <div data-testid="add-approver-typeahead" className="add-approver-editor-body__typeahead">
                            <ValidationTypeahead
                                fieldName="Approver"
                                validationRules={{ 
                                    required: requiredRule,
                                    validate: async (userId) => await inactiveRule(`users/${userId}/active`)
                                }}
                                options={userList}
                                title={localizedApprovalPage.smc_employee_approver_label_text}
                                labelKey={option => `${option.firstName} ${option.lastName}, ${option.emailAddress}`}
                                filterBy={["firstName", "lastName", "emailAddress"]}
                                emptyLabel={localizedTypeaheadMessages.no_approvers_available} />
                        </div>
                    </>
                }
                {watchApprovalType === APPROVAL_TYPES.CostCenter.toString() &&
                    <span data-testid="cost-center-descriptor" className="approver-editor__display-text">
                        {localizedApprovalPage.approval_type_cost_center_description}
                    </span>
                }
                {watchApprovalType === APPROVAL_TYPES.AdditionalApprovers.toString() &&
                    <span data-testid="additional-approval-descriptor" className="approver-editor__display-text">
                        {localizedApprovalPage.approval_type_additional_approvers_description}
                    </span>
                }
                <div className="approver-editor__buttons">
                    <Button variant="link" data-testid="add-approver-editor-cancel" onClick={handleClose}>
                        {localizedButtons.cancel}
                    </Button>
                    <Button type="submit" data-testid="approver-editor-submit">
                        <FontAwesomeIcon icon={faSave} /> &nbsp; {localizedButtons.save}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );

}
export default ApproverEditor;