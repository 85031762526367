import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { fetch } from 'services/api';
import { useUserContextProvider } from 'providers/userContextProvider';

const NotificationsContext = React.createContext();
const useNotificationsClaim = () => useContext(NotificationsContext);

const NotificationsProvider = ({ children }) => {
    const { authState } = useOktaAuth();
    const [normalNotificationsCount, setNormalNotificationsCount] = useState(0);
    const [adminNotificationsCount, setAdminNotificationsCount] = useState(0);
    const { isUserActive } = useUserContextProvider();

    const fetchNormalNotificationsCount = useCallback(async () => {
        if (isUserActive && authState?.isAuthenticated){
            const numberOfNormalNotifications = await fetch(`notifications/count`);
            if(numberOfNormalNotifications != null){
                setNormalNotificationsCount(numberOfNormalNotifications);
            } else{
                setNormalNotificationsCount(0);
            }
        }
    }, [authState]);
    
    const fetchAdminNotificationsCount = useCallback(async () => {
        if (isUserActive && authState?.isAuthenticated){
            const numberOfAdminNotifications = await fetch(`notifications/admin/count`);
            if(numberOfAdminNotifications != null){
                setAdminNotificationsCount(numberOfAdminNotifications);
            } else{
                setAdminNotificationsCount(0);
            }
        }
    }, [authState]);
    
    const fetchAllNotificationsCount = useCallback(async () => {
       await fetchNormalNotificationsCount();
       await fetchAdminNotificationsCount();
    }, [fetchNormalNotificationsCount, fetchAdminNotificationsCount]);

    useEffect(() => {
        fetchAllNotificationsCount();
    }, [fetchAllNotificationsCount])


    return (
        <NotificationsContext.Provider value={{
            normalNotificationsCount: normalNotificationsCount,
            adminNotificationsCount: adminNotificationsCount,
            allNotificationsCount: normalNotificationsCount + adminNotificationsCount,
            fetchNormalNotificationsCount: fetchNormalNotificationsCount,
            fetchAdminNotificationsCount: fetchAdminNotificationsCount,
            fetchAllNotificationsCount: fetchAllNotificationsCount
        }}>
            {children}
        </NotificationsContext.Provider>
    );
}


export {
    NotificationsProvider,
    useNotificationsClaim
};