import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import ValidationError, { GetValidationError } from 'components/ValidationError';
import classnames from 'classnames';
import TooltipDescription from 'components/TooltipDescription/TooltipDescription';
import 'controls/ValidationTextArea/ValidationTextArea.scss';

export default function ValidationTextArea({ fieldName, validationRules, title, className, placeholderText, hideControl = false, tooltipDescription = null, showTooltip = false, ...rest }) {
    const { register, unregister, setValue } = useFormContext();
    const textAreaId = `${fieldName}-text-area`;

    const validationError = GetValidationError(fieldName);

    useEffect(() => {
        return () => {
            if (setValue) { // make sure the parent FormProvider has not already been cleaned up
                setValue(fieldName, '');
                unregister(fieldName);
            }
        }
    }, [fieldName, unregister, setValue]);

    return (
        <div hidden={hideControl}>
            <div className={classnames("validation-text-area", { "validation-text-area--error": validationError })}>
                {title && <label htmlFor={textAreaId} data-testid={`${textAreaId}-label`} className="validation-text-area__label">{title}</label>}
                {showTooltip === true && <TooltipDescription tooltipName={`${fieldName}-tooltip`} description={tooltipDescription} validationError={validationError} />}
                <textarea id={textAreaId}
                    data-testid={textAreaId}
                    {...register(fieldName, validationRules)}
                    placeholder={placeholderText}
                    className={classnames("validation-text-area__text-area", className)} {...rest}></textarea>
                <ValidationError fieldName={fieldName} className="validation-text-area__icon" />
            </div>
        </div>
    );
}