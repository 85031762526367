import React from 'react';
import classNames from 'classnames';
import 'pages/Ringis/components/RingiTab.scss';
import DaysUntilPill from 'components/DaysUntilPill';
import { useLocale } from 'services/localization/localizationContextProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { RINGI_STATUS } from 'constants/enums';

export default function RingiTab({ ringi }) {
    const showDaysToApprove =
        ringi.statusId !== RINGI_STATUS.Draft &&
        (ringi.daysToApprove != null &&
            ringi.daysToApprove !== '');

    const daysToApproveLabelText = useLocale().pages.view_ringis.days_to_approve_text;
    return (
        <div className={classNames('ringi-tab', ringi.classNameModifier ? { [`ringi-tab--${ringi.classNameModifier}`]: true } : {})}>
            <span data-testid="ringi-tab__subject" className='ringi-tab__subject'>{ringi.subject} {ringi.isLegacyRingi && <FontAwesomeIcon icon={faStickyNote} />}</span>
            <span data-testid="ringi-tab__initiator" className='ringi-tab__initiator'>{ringi.initiatorName}</span>
            <span data-testid="ringi-tab__date" className='ringi-tab__date'>{ringi.displayDate}</span>
            <span data-testid="ringi-tab__type" className='ringi-tab__type'>{ringi.typeName}</span>
            <span data-testid="ringi-tab__status" className='ringi-tab__status'>{ringi.status}</span>
            <span></span>
            {showDaysToApprove &&
                <div data-testid="ringi-tab__daystoapprove" className="ringi-tab__approvalDays--container">
                    <span className="ringi-tab--pill__label">{daysToApproveLabelText}</span>
                    <DaysUntilPill daysUntil={ringi.daysToApprove} ringiStatus={ringi.statusId}></DaysUntilPill>
                </div>}
        </div>
    );
}
