import { postFormData } from 'services/api';

const attachRingiAttachments = async (ringiId, files) => {
    return await Promise.all(files.map(async (file) => {
        if (file && file.fileToUpload) {
            const formData = new FormData();
            formData.append('attachment', file.fileToUpload);

            return await postFormData(`attachments/ringis/${ringiId}/${file.storageId}`, formData);
        }
    }));
};

const attachRingiCommentAttachments = async (ringiCommentId, files) => {
    return await Promise.all(files.map(async (file) => {
        if (file && file.fileToUpload) {
            const formData = new FormData();
            formData.append('attachment', file.fileToUpload);

            return await postFormData(`attachments/comments/${ringiCommentId}/${file.storageId}`, formData);
        }
    }));
};

const attachTemplateFieldAttachment = async (templateFieldAttachment) => {
    if (templateFieldAttachment && templateFieldAttachment.fileToUpload) {
        const formData = new FormData();
        formData.append('attachment', templateFieldAttachment.fileToUpload);

        return await postFormData(`attachments/templatefields/${templateFieldAttachment.storageId}`, formData);
    }
};

export { attachRingiAttachments, attachRingiCommentAttachments, attachTemplateFieldAttachment };