import React from 'react';
import { useOktaAuth, LoginCallback } from '@okta/okta-react';
import { Route, useLocation, Switch } from "react-router-dom";
import Login from 'pages/Login/Login';
import NavMenu from 'controls/NavMenu/NavMenu';
import Routes from 'components/Routes';
import AutoLogout from 'components/AutoLogout';
import { AuthorizationClaimsProvider } from 'providers/authorizationClaimsProvider';
import { NotificationsProvider } from 'providers/notificationsProvider';
import { PageHistoryContextProvider } from '../providers/pageHistoryContextProvider';

const Main = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const location = useLocation();

    const handleLogout = async () => {
        await oktaAuth.signOut("/");
    };

    return (
        <AuthorizationClaimsProvider>
            <NotificationsProvider>
                <PageHistoryContextProvider>
                    <Switch>
                        <Route exact path='/' component={Login} />
                        <Route path="/login/callback" component={LoginCallback} />
                        <AutoLogout logout={handleLogout}>
                            {
                                (authState?.isAuthenticated ?? false) && location.pathname !== "/" &&
                                <NavMenu />
                            }
                            <main className="page__main">
                                <Routes />
                            </main>
                        </AutoLogout>
                    </Switch>
                </PageHistoryContextProvider>
            </NotificationsProvider>
        </AuthorizationClaimsProvider>
    );
};

export default Main;