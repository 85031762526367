import React, { useState } from 'react'
import { useEffect, useCallback } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ContextMenu from 'controls/ContextMenu/ContextMenu';
import classNames from 'classnames';
import { useLocale } from 'services/localization/localizationContextProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import DaysUntilPill from 'components/DaysUntilPill';
import 'controls/Grid/Grid.scss';


const CONTEXT_MENU_COLUMN_KEY = "contextMenuColumn";
const SELECT_COLUMN_KEY = "selectColumn"
const defaultMaxWidth = 200;
const columnAttributeNames = [
    {id: "Subject", name: "Subject"},
    {id: "Category", name: "Category.Name"},
    {id: "Type", name: "Type.Name"},
    {id: "Ringi Number", name: "RingiNumber"},
    {id: "Cost Center", name: "CostCenter.Description"},
    {id: "Status", name: "RingiStatus.Name"},
    {id: "FirstName", name: "CreatedByUser.FirstName"},
    {id: "LastName", name: "CreatedByUser.LastName"},
    {id: "Submitted Date", name: "SubmittedDate"},
    {id: "Completed Date", name: "CompletedDate" },
    {id: "Dollar Amount Requested", name: "DollarAmountRequested" },
    {id: "Days to Approve", name: "ApprovalByDate"},
];

const ContextMenuColumn = (menuItems = [], rotated) => {
    return {
        key: CONTEXT_MENU_COLUMN_KEY,
        name: '',
        defaultHTML: <ContextMenu key={CONTEXT_MENU_COLUMN_KEY} menuItems={menuItems} rotated={rotated} className="grid-row__cell" />
    }
};

const SelectColumn = {
    key: SELECT_COLUMN_KEY,
    name: '',
    defaultHTML: (
        <div key={SELECT_COLUMN_KEY} className="grid-row__cell grid-row__cell--centered">
            <input type="checkbox" />
        </div>)
};

const Grid = ({ children }) => {
    return (
        <div className="grid">{children}</div>
    );
}

const GridHeader = ({ columns, onSortColumn }) => {
    const [columnsSorted, setColumnsSorted] = useState([]);
    useEffect(() => {
        setColumnsSorted(columns.map(c => {return {name: c.name, sorted: ""}}));
    }, [columns]);

    const sortColumn = (columnName, sortOrder) => {
        setColumnsSorted(columnsSorted.map(c => {
            if(c.name === columnName){
                return {name: c.name, sorted: sortOrder}
            }else{
                return {name: c.name, sorted: ""}
            }
        }));

        if(columnName === "Initiator"){
            const firstColumnAttributeName = columnAttributeNames.filter(c => c.id === "FirstName")[0].name;
            const lastColumnAttributeName = columnAttributeNames.filter(c => c.id === "LastName")[0].name;
            onSortColumn(`${firstColumnAttributeName} ${sortOrder}, ${lastColumnAttributeName} ${sortOrder}`);
        }else{
            const columnAttributeName = columnAttributeNames.filter(c => c.id === columnName)[0].name;
            onSortColumn(`${columnAttributeName} ${sortOrder}`);
        }
    }

    const displayDesc = useCallback((columnName) => {
        return (
            columnsSorted.filter(c => 
                c.name === columnName && 
                (c.sorted === "" || c.sorted === "desc")
                ).length === 1
            );
    }, [columnsSorted]);

    return (
        <div className="grid__header" key="header">
            {
                columns.map((column, i) => {
                    return (
                        <span key={i} data-testid={`grid-header-${column.key}`} className={classNames("grid-header__cell", { "grid-header__cell--empty": !column.name })}>
                            <span>
                                {column.name}
                                {displayDesc(column.name) ?
                                <FontAwesomeIcon icon={faArrowDown} onClick={() => sortColumn(column.name, "asc")} className='faArrow'/>
                                :
                                <FontAwesomeIcon icon={faArrowUp} onClick={() => sortColumn(column.name, "desc")} className='faArrow'/>
                                }
                            </span>
                        </span>
                    );
                })
            }
        </div>
    );
}

const GridRow = ({ row, children }) => {
    return (
        <div data-testid={`grid-row-${row.id}`} className={classNames("grid__row", { [`grid__row--${row.classNameModifier}`]: row.classNameModifier != null })}>
            {children}
        </div>
    );
}

const GridRowCell = ({ row, column, className, type }) => {
    const cellValue = row?.[column?.key] ?? "";
    const dynamicStyle = { maxWidth: column.maxWidth ?? defaultMaxWidth };
    const localized = useLocale();

    if (type == 'NumberPill') {
        return (
            <>
                <div className={"grid-row__cell"}>
                    <DaysUntilPill daysUntil={cellValue} className={'grid-pill'} ringiStatus={ row.statusId } ></DaysUntilPill>
                </div>
            </>
        )
    }

    if (type == 'LegacyRingiSubject') {
        return (
            <>
                <span style={dynamicStyle} data-testid={`cell-value-${row.id}-${column.key}`}
                    className={classNames("grid-row__cell", { [className]: className != null, [column.className]: column.className != null })}>
                    {cellValue} <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip id={`cell-value-tooltip${row.id}-${column.key}`} className="tooltip-description__tooltip">{localized.legacy_ringi_message}</Tooltip>}>
                        <FontAwesomeIcon icon={faStickyNote} />
                    </OverlayTrigger>
                </span>
            </>
        )
    }

    return (
        <>
            {column.defaultHTML ? column.defaultHTML
                :
                <span style={dynamicStyle} data-testid={`cell-value-${row.id}-${column.key}`}
                    className={classNames("grid-row__cell", { [className]: className != null, [column.className]: column.className != null })}>
                    {cellValue}
                </span>
            }
        </>
    );
}


export { ContextMenuColumn, SelectColumn, GridHeader, GridRow, GridRowCell };

export default Grid;