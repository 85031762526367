import React, { useEffect, useCallback } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import ValidationError, { GetValidationError } from 'components/ValidationError';
import { Typeahead } from 'react-bootstrap-typeahead';
import classnames from 'classnames';
import './ValidationTypeahead.scss';

export default function ValidationTypeahead({ fieldName, validationRules, options, className, title, inverseErrorIconColor, ...rest }) {
    const { unregister, setValue, control } = useFormContext();
    const typeaheadId = `${fieldName}-typeahead`;

    const validationError = GetValidationError(fieldName);

    useEffect(() => {
        return () => {
            if (setValue) { // make sure the parent FormProvider has not already been cleaned up
                setValue(fieldName, '');
                unregister(fieldName);
            }
        }
    }, [fieldName, unregister, setValue]);

    const mapTypeAheadValueToReactHookForm = (typeAheadValue) => {
        setValue(fieldName, typeAheadValue?.[0]?.id)
    }

    const mapReactHookFormValueToTypeAhead = useCallback((reactHookFormValue) => {
        return [options.find(option => option.id === reactHookFormValue)].filter(sel => sel != null)
    }, [options]);

    return (
        <div data-testid="typeahead-wrapper" className={classnames("validation-typeahead", className, { "validation-typeahead--error": validationError })}>
            {title && <label data-testid={typeaheadId + "-label"} htmlFor={typeaheadId} className="validation-typeahead__label">{title}</label>}
            <Controller
                control={control}
                name={fieldName}
                rules={validationRules}
                render={({ field: { value } }) => (
                    <Typeahead
                        name={typeaheadId}
                        id={typeaheadId}
                        options={options}
                        onChange={mapTypeAheadValueToReactHookForm}
                        selected={mapReactHookFormValueToTypeAhead(value)}
                        {...rest}
                    />)}
            />
            <ValidationError fieldName={fieldName} className="validation-typeahead__icon" inverseErrorIconColor={inverseErrorIconColor} />
        </div>
    );
}