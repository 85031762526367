import React, { useContext, useEffect, useState } from 'react';

const ApiStateContext = React.createContext();
const useApiState = () => useContext(ApiStateContext);

let isLoadingSubscriber;
let apiErrorMessageSubscriber;
let apiErrorDetailsSubscriber;

const notifyIsLoading = (isLoading) => {
    if (isLoadingSubscriber) {
        isLoadingSubscriber(isLoading);
    }
};

const notifyApiErrorMessage = (apiErrorMessage) => {
    if (apiErrorMessageSubscriber) {
        apiErrorMessageSubscriber(apiErrorMessage);
    }
}

const notifyApiErrorDetails = (apiErrorDetails) => {
    if (apiErrorDetailsSubscriber) {
        apiErrorDetailsSubscriber(apiErrorDetails);
    }
}

const ApiStateProvider = ({ children }) => {
    const [activeAPICallsCount, setActiveAPICallsCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState();
    const [errorDetails, setErrorDetails] = useState({});

    useEffect(() => {
        isLoadingSubscriber = (isLoading) => {
            if (isLoading) {
                setActiveAPICallsCount(count => count + 1);
            }
            else {
                setActiveAPICallsCount(count => count - 1);
            }
        }
        apiErrorMessageSubscriber = setErrorMessage;
        apiErrorDetailsSubscriber = setErrorDetails;

        return () => {
            isLoadingSubscriber = null;
            apiErrorMessageSubscriber = null;
            apiErrorDetailsSubscriber = null;
        }
    }, []);

    return (
        <ApiStateContext.Provider value={{ isLoading: activeAPICallsCount > 0, errorMessage: errorMessage, errorDetails: errorDetails, clearErrorMessage: () => { setErrorMessage(null); } }}>
            {children}
        </ApiStateContext.Provider>
    );
}

export {
    ApiStateProvider,
    useApiState,
    notifyIsLoading,
    notifyApiErrorMessage,
    notifyApiErrorDetails
};