import React, { useState, useEffect, useCallback } from 'react';
import { fetch } from 'services/api';
import { useLocale } from 'services/localization/localizationContextProvider';
import { Row } from 'react-bootstrap';
import classNames from 'classnames';
import ValidationError, { GetValidationError } from 'components/ValidationError';
import DownloadLink from 'controls/DownloadLink/DownloadLink';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import ValidationSelect from 'controls/ValidationSelect/ValidationSelect';
import ValidationTextArea from 'controls/ValidationTextArea/ValidationTextArea';
import ValidationFileInput from 'controls/ValidationFileInput/ValidationFileInput';
import RingiInput from 'pages/Ringis/components/RingiInput';
import { requiredRule, minValueRule, dateRule, decimalPlacesRule } from 'services/validationRules';
import { useFormContext } from 'react-hook-form';
import { ScreenDetectColumn } from 'hooks/useScreenDetect';
import { ATTACHMENT_TYPES, FIELD_TYPE, LAYER_TYPE, RINGI_STATUS } from 'constants/enums';
import 'pages/Ringis/components/RingiEditor.scss';

const RingiEditor = ({ ringiId, copyFromRingiId, resetRingiRef, onInvalidRingi, onRingiStatusDraftError }) => {
    const localizedRingiEditor = useLocale().ringi_editor;
    const localizedRingiField = useLocale().ringi_field;
    const localizedRingiInput = useLocale().ringi_input;
    const requiredAttachmentMessage = useLocale().validation_messages.attachment_count_less_than_required;
    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [costCenters, setCostCenters] = useState([]);
    const [baseFields, setBaseFields] = useState([]);
    const [categoryFields, setCategoryFields] = useState([]);
    const [typeFields, setTypeFields] = useState([]);
    const [additionalDocuments, setAdditionalDocuments] = useState([]);
    const [requiredDocuments, setRequiredDocuments] = useState([]);
    const [minimumApprovalDate, setMinimumApprovalDate] = useState("");
    const [isDraftError, setIsDraftError] = useState(false);
    const [draftRingiFields, setDraftRingiFields] = useState({});
    const { reset, watch, setValue } = useFormContext();
    const watchCategoryId = watch("categoryId");

    const RINGI_ATTACHMENTS_FIELD_NAME = "ringiAttachments";
    const requiredDocumentsError = GetValidationError(RINGI_ATTACHMENTS_FIELD_NAME);

    const convertDateToDateString = (date) => {
        var year = `${date.getFullYear()}`;
        var month = `${date.getMonth() + 1}`;
        month = month.padStart(2, '0');
        var day = `${date.getDate()}`;
        day = day.padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    var today = new Date();
    today.setDate(today.getDate() + 7)
    var minApprovalDate = convertDateToDateString(today);
    useEffect(() => {
        setMinimumApprovalDate(minApprovalDate);
    }, [minApprovalDate]);

    const emptyPromise = () => {
        new Promise(() => { });
    }

    const fetchRingi = useCallback(async () => {
        if (ringiId) {
            return await fetch(`ringis/${ringiId}/draft`);
        }
        else if (copyFromRingiId) {
            const defaultRingiValues = await fetch(`ringis/${copyFromRingiId}/copy`);
            return defaultRingiValues;
        }
        else {
            const defaultRingiValues = await fetch(`ringis/my/defaultRingi`);
            return defaultRingiValues;
        }
    }, [ringiId]);

    const getTypesPromise = useCallback((categoryId) => {
        if (categoryId) {
            return fetch(`categories/${categoryId}/types/enabled`)
        }
        return emptyPromise();
    }, []);
    const getCategoryFieldsPromise = useCallback((categoryId) => {
        if (categoryId) {
            return fetch(`layers/${categoryId}/fields/enabled`)
        }
        return emptyPromise();
    }, []);
    const getTypeFieldsPromise = useCallback((typeId) => {
        if (typeId) {
            return fetch(`layers/${typeId}/fields/enabled`)
        }
        return emptyPromise();
    }, []);

    const setDocumentsForType = (additionalDocumentsForType, layerTypeId) => {
        setAdditionalDocuments(prevAdditionalDocuments => {
            const additionalDocumentsForOtherTypes = prevAdditionalDocuments.filter(additionalDocument => additionalDocument.layerTypeId !== layerTypeId);
            return [...additionalDocumentsForOtherTypes, ...additionalDocumentsForType.filter(additionalDocument => !additionalDocument.isRequired)];
        });
        setRequiredDocuments(prevRequiredDocuments => {
            const requiredDocumentsForOtherTypes = prevRequiredDocuments.filter(requiredDocument => requiredDocument.layerTypeId !== layerTypeId);
            return [...requiredDocumentsForOtherTypes, ...additionalDocumentsForType.filter(additionalDocument => additionalDocument.isRequired)];
        });
    }

    const partitionTemplateFields = (templateFields, layerTypeId) => {
        const nonAttachments = [];
        const attachments = [];
        if (templateFields) {
            for (var i = 0; i < templateFields.length; i++) {
                const templateField = templateFields[i];
                templateField.layerTypeId = layerTypeId;
                if (parseInt(templateField.fieldTypeId) === FIELD_TYPE.Attachment) {
                    attachments.push(templateField);
                }
                else {
                    nonAttachments.push(templateField);
                }
            }
        }

        return {
            nonAttachmentTemplateFields: nonAttachments,
            attachmentTemplateFields: attachments
        };
    }

    const populateBaseFields = useCallback((baseFields) => {
        const { attachmentTemplateFields, nonAttachmentTemplateFields } = partitionTemplateFields(baseFields, LAYER_TYPE.Base);
        setDocumentsForType(attachmentTemplateFields, LAYER_TYPE.Base);
        setBaseFields(nonAttachmentTemplateFields);
    }, []);
    const populateCategoryFields = useCallback((categoryFields) => {
        const { attachmentTemplateFields, nonAttachmentTemplateFields } = partitionTemplateFields(categoryFields, LAYER_TYPE.Category);
        setDocumentsForType(attachmentTemplateFields, LAYER_TYPE.Category);
        setCategoryFields(nonAttachmentTemplateFields);
    }, []);
    const populateTypeFields = useCallback((typeFields) => {
        const { attachmentTemplateFields, nonAttachmentTemplateFields } = partitionTemplateFields(typeFields, LAYER_TYPE.Type);
        setDocumentsForType(attachmentTemplateFields, LAYER_TYPE.Type);
        setTypeFields(nonAttachmentTemplateFields);
    }, []);

    const populateLoadedRingi = useCallback((draftRingi) => {
        if (draftRingi) {
            const ringiFields = draftRingi.ringiFields;
            if (ringiFields?.length > 0) {
                draftRingi["custom-fields"] = {};
                ringiFields.forEach(function (field) {
                    if (parseInt(field.fieldTypeId) === FIELD_TYPE.DateRange && field.value) {
                        var datesInRange = field.value.split(";");

                        draftRingi['custom-fields'][`field-id-${field.id}-start-date`] = datesInRange[0];
                        draftRingi['custom-fields'][`field-id-${field.id}-end-date`] = datesInRange[1];
                    }
                    else {
                        draftRingi['custom-fields'][`field-id-${field.id}`] = field.value;
                    }
                });
            }
            var splitDate = draftRingi.approvalByDate?.split('/');
            if (splitDate?.length === 3) {
                draftRingi.approvalByDate = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
            }
        }
        reset(draftRingi);
    }, [reset]);

    const fetchAllSecondaryData = useCallback(async (categoryId, typeId) => {
        const categoriesPromise = fetch(`categories/user/enabled`);
        const typesPromise = categoryId ? getTypesPromise(categoryId) : emptyPromise();
        const costCenterPromise = fetch(`costcenters/enabled`);
        const baseFieldsPromise = fetch(`layers/1/fields/enabled`);
        const categoryFieldsPromise = categoryId ? getCategoryFieldsPromise(categoryId) : emptyPromise();
        const typeFieldsPromise = typeId ? getTypeFieldsPromise(typeId) : emptyPromise();

        let values = await Promise.all([categoriesPromise, typesPromise, costCenterPromise, baseFieldsPromise, categoryFieldsPromise, typeFieldsPromise]);
        return {
            categories: values[0],
            types: values[1],
            costCenters: values[2],
            baseFields: values[3],
            categoryFields: values[4],
            typeFields: values[5]
        }
    }, [getTypesPromise, getCategoryFieldsPromise, getTypeFieldsPromise]);

    const fetchAllDataOnLoad = useCallback(async () => {
        const draftRingi = await fetchRingi();
        if (draftRingi.ringiStatusId !== RINGI_STATUS.Draft && draftRingi.ringiStatusId !== RINGI_STATUS.DraftError) {
            onInvalidRingi(draftRingi.id);
            if (draftRingi.id) {
                return;
            }
        }

        if (draftRingi.ringiStatusId === RINGI_STATUS.DraftError) {
            setIsDraftError(true);
            setDraftRingiFields({ name: draftRingi?.createdByUser?.fullName, email: draftRingi?.createdByUser?.emailAddress });
            onRingiStatusDraftError();
        }

        const { categories, types, costCenters, baseFields, categoryFields, typeFields } = await fetchAllSecondaryData(draftRingi?.categoryId, draftRingi?.typeId);

        setCategories(categories.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            } else {
                return 1;
            }
        }));
        setTypes(types ?? []);
        setCostCenters(costCenters?.map((costCenter, i) => {
            return {
                id: costCenter.id, name: `(${costCenter.number}) ${costCenter.description}`
            };
        }));
        populateBaseFields(baseFields);
        populateCategoryFields(categoryFields);
        populateTypeFields(typeFields);

        populateLoadedRingi(draftRingi);
    }, [
        fetchRingi,
        fetchAllSecondaryData,
        populateBaseFields,
        populateCategoryFields,
        populateTypeFields,
        populateLoadedRingi,
        onInvalidRingi,
        onRingiStatusDraftError
    ]);

    useEffect(() => {
        fetchAllDataOnLoad();
    }, [fetchAllDataOnLoad]);

    const onCategoryChange = async (event) => {
        const newCategoryId = event.target.value;
        setValue("typeId", "");
        const typesPromise = getTypesPromise(newCategoryId);
        const categoryFieldsPromise = getCategoryFieldsPromise(newCategoryId);

        let values = await Promise.all([typesPromise, categoryFieldsPromise]);
        setTypes(values[0] ?? []);
        populateCategoryFields(values[1]);
        populateTypeFields(null);
    }

    const onTypeChange = async (event) => {
        const newTypeId = event.target.value;
        const typeFields = await getTypeFieldsPromise(newTypeId);

        populateTypeFields(typeFields);
    }

    const sevenDayRule = (dateToCheck) => {
        if (dateToCheck) {
            var approvalDate = new Date(dateToCheck + 'T00:00:00');

            var minDate = new Date();
            minDate.setHours(0, 0, 0, 0);
            minDate.setDate(minDate.getDate() + 7)

            if (approvalDate < minDate) {
                return 'Invalid Date - Must be at least 7 days from now'
            }
        }
        return true;
    };

    const resetRingi = useCallback(async () => {
        const draftRingi = await fetchRingi();
        populateLoadedRingi(draftRingi);
    }, [fetchRingi, reset]);

    useEffect(() => {
        resetRingiRef.current = resetRingi
    }, [resetRingiRef, resetRingi]);

    return (
        <>
            {isDraftError &&
                <div>
                    <p>{localizedRingiField.ringi_submitted_by_label + ': ' + draftRingiFields?.name + ", " + draftRingiFields?.email}</p>
                </div>
            }
            <div data-testid="base-field-section" className="ringi-editor__fields">
                <Row>
                    <ScreenDetectColumn desktopColumnWidth={12}>
                        <div className="ringi-editor-field">
                            <ValidationInput
                                title={`${localizedRingiField.subject_label}${localizedRingiEditor.required_indicator}`}
                                fieldName="subject"
                                validationLevel='save'
                                validationRules={{
                                    required: requiredRule
                                }} />
                        </div>
                    </ScreenDetectColumn>
                    <ScreenDetectColumn desktopColumnWidth={6}>
                        <div className="ringi-editor-field">
                            <ValidationSelect
                                title={`${localizedRingiField.category_label}${localizedRingiEditor.required_indicator}`}
                                fieldName="categoryId"
                                onChange={onCategoryChange}
                                validationRules={{
                                    required: requiredRule
                                }}
                                options={[{ id: "", name: localizedRingiEditor.category_default_value }, ...categories]} />
                        </div>
                    </ScreenDetectColumn>
                    <ScreenDetectColumn desktopColumnWidth={6}>
                        <div className="ringi-editor-field">
                            <ValidationSelect
                                disabled={!watchCategoryId}
                                title={`${localizedRingiField.type_label}${localizedRingiEditor.required_indicator}`}
                                fieldName="typeId"
                                onChange={onTypeChange}
                                validationRules={{
                                    required: requiredRule
                                }}
                                options={[{ id: "", name: localizedRingiEditor.type_default_value }, ...types]} />
                        </div>
                    </ScreenDetectColumn>
                    <ScreenDetectColumn desktopColumnWidth={6}>
                        <div className="ringi-editor-field">
                            <ValidationSelect
                                title={`${localizedRingiField.cost_center_label}${localizedRingiEditor.required_indicator}`}
                                fieldName="costCenterId"
                                validationRules={{
                                    required: requiredRule
                                }}
                                options={[{ id: "", name: localizedRingiEditor.cost_center_default_value }, ...costCenters]} />
                        </div>
                    </ScreenDetectColumn>
                    <ScreenDetectColumn desktopColumnWidth={6}>
                        <div className="ringi-editor-field">
                            <ValidationInput type="number" min={0} step={1}
                                title={`${localizedRingiField.dollar_amount_requested}${localizedRingiEditor.required_indicator}`}
                                fieldName="dollarAmountRequested"
                                prefixIconName={localizedRingiInput.currency_unit_icon}
                                validationRules={{
                                    valueAsNumber: true,
                                    required: requiredRule,
                                    validate: { validDecimalPlaces: number => decimalPlacesRule(number, 2) },
                                    min: minValueRule(0)
                                }}
                            />
                        </div>
                    </ScreenDetectColumn>
                    <ScreenDetectColumn desktopColumnWidth={6}>
                        <div className="ringi-editor-field">
                            <ValidationInput type="date"
                                title={`${localizedRingiField.approval_by_label}`}
                                fieldName="approvalByDate"
                                min={minimumApprovalDate}
                                validationRules={{
                                    required: false,
                                    validate: {
                                        validDate: date => dateRule(date),
                                        sevenDayRule: date => sevenDayRule(date)
                                    }
                                }}
                            />
                        </div>
                    </ScreenDetectColumn>
                    {baseFields?.map((baseField, i) => (
                        <ScreenDetectColumn key={i} desktopColumnWidth={6}>
                            <div className="ringi-editor-field">
                                <RingiInput field={baseField} ringiId={ringiId} />
                            </div>
                        </ScreenDetectColumn>
                    ))}
                    <ScreenDetectColumn desktopColumnWidth={6}>
                        <ValidationTextArea
                            title={`${localizedRingiField.purpose_label}${localizedRingiEditor.required_indicator}`}
                            fieldName="purpose"
                            validationRules={{
                                required: requiredRule
                            }} />
                    </ScreenDetectColumn>
                    <ScreenDetectColumn desktopColumnWidth={6}>
                        <ValidationTextArea
                            title={`${localizedRingiField.opportunity_costs}${localizedRingiEditor.required_indicator}`}
                            fieldName="opportunityCosts"
                            validationRules={{
                                required: requiredRule
                            }} />
                    </ScreenDetectColumn>
                    <ScreenDetectColumn desktopColumnWidth={6}>
                        <ValidationTextArea
                            title={`${localizedRingiField.alternatives}${localizedRingiEditor.required_indicator}`}
                            fieldName="alternatives"
                            validationRules={{
                                required: requiredRule
                            }} />
                    </ScreenDetectColumn>
                </Row>
            </div>

            {(categoryFields.length > 0 || typeFields.length > 0) &&
                <div data-testid="category-type-field-section" className="ringi-editor__fields">

                    <Row>
                        {categoryFields?.map((field, f) => (
                            <ScreenDetectColumn key={f} desktopColumnWidth={6}>
                                <div className="category-field ringi-editor-field">
                                    <RingiInput field={field} ringiId={ringiId} />
                                </div>
                            </ScreenDetectColumn>
                        ))}

                        {typeFields?.map((field, f) => (
                            <ScreenDetectColumn key={f} desktopColumnWidth={6}>
                                <div className="type-field ringi-editor-field">
                                    <RingiInput field={field} ringiId={ringiId} />
                                </div>
                            </ScreenDetectColumn>
                        ))}
                    </Row>

                </div>
            }
            <div data-testid="attachment-section" className="ringi-editor__attachments">
                {requiredDocuments.length > 0 &&
                    <Row>
                        <ScreenDetectColumn desktopColumnWidth={12}>
                            <div className={classNames({ "ringi-editor-attachments--required-document-error": requiredDocumentsError })}>
                                <label>{localizedRingiField.required_documents_label}</label>
                                <ValidationError fieldName={RINGI_ATTACHMENTS_FIELD_NAME} className="ringi-editor-attachments-required-document__icon" inline />
                            </div>
                            {requiredDocuments.map((field, f) => (
                                <ScreenDetectColumn key={f} desktopColumnWidth={6}>
                                    <div className="attachment-field ringi-editor-field" data-testid={`req-attachment-field-${f}-div`}>
                                        <DownloadLink attachmentType={ATTACHMENT_TYPES.TemplateField} fileName={field.attachmentName} templateFieldId={field.id} />
                                    </div>
                                </ScreenDetectColumn>
                            ))}
                        </ScreenDetectColumn>
                    </Row>}
                {additionalDocuments.length > 0 &&
                    <Row>
                        <ScreenDetectColumn desktopColumnWidth={12}>
                            <div>
                                <label>{localizedRingiField.additional_documents_label}</label>
                            </div>
                            {additionalDocuments.map((field, f) => (
                                <ScreenDetectColumn key={f} desktopColumnWidth={6}>
                                    <div className="attachment-field ringi-editor-field" data-testid={`attachment-field-${f}-div`}>
                                        <DownloadLink attachmentType={ATTACHMENT_TYPES.TemplateField} fileName={field.attachmentName} templateFieldId={field.id} />
                                    </div>
                                </ScreenDetectColumn>
                            ))}
                        </ScreenDetectColumn>
                    </Row>}
                <Row>
                    <ScreenDetectColumn desktopColumnWidth={12}>
                        <div data-testid="attached-files-section">
                            <ValidationFileInput
                                fieldName={RINGI_ATTACHMENTS_FIELD_NAME}
                                attachmentType={ATTACHMENT_TYPES.Ringi}
                                multiple
                                validationLevel='save'
                                validationRules={{
                                    validate: { requiredAttachmentsOk: (files) => files.length >= requiredDocuments.length || requiredAttachmentMessage }
                                }} />
                        </div>
                    </ScreenDetectColumn>
                </Row>
            </div>
        </>
    );
}

export default RingiEditor;