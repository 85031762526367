import React, { useEffect, useState } from "react";
import RingiPage, { PageContent, PageHeader } from 'controls/RingiPage/RingiPage';
import { useLocale } from 'services/localization/localizationContextProvider';
import { Row, Col } from 'react-bootstrap';
import ReportTable from "./components/ReportTable";
import ReportCard from "./components/ReportCard";
import { fetch } from 'services/api';
import ReportYearPicker from "./components/ReportYearPicker";
import 'pages/Reports/ReportsPage.scss';

const ReportsPage = () => {
    const localizedReports = useLocale().pages.reports;
    const primaryColumnHeaders = localizedReports.primary_column_headers;
    const costCenterColumnHeaders = localizedReports.cost_center_column_headers;
    const categoryTitle = localizedReports.category_title;
    const typeTitle = localizedReports.type_title;
    const costCenterTitle = localizedReports.cost_center_title;

    const [categoryReports, setCategoryReports] = useState([]);
    const [typeReports, setTypeReports] = useState([]);
    const [costCenterReports, setCostCenterReports] = useState([]);
    const [reportYear, setReportYear] = useState(new Date().getFullYear())

    const fetchCategoryReports = async () => {
        const categoryReports = await fetch(`reports/category/${reportYear}`) ?? [];
        setCategoryReports(categoryReports ?? []);
    };

    const fetchTypeReports = async () => {
        const typeReports = await fetch(`reports/type/${reportYear}`) ?? [];
        setTypeReports(typeReports ?? []);
    };

    const fetchCostCenterReports = async () => {
        const costCenterReports = await fetch(`reports/costCenter/${reportYear}`) ?? [];
        setCostCenterReports(costCenterReports ?? []);
    };

    useEffect(() => {
        const getAllReports = async () => {
            await Promise.all([fetchCategoryReports(), fetchCostCenterReports(), fetchTypeReports()])
        }
        getAllReports();
    }, [reportYear])


    return (
        <RingiPage className="summary-report">
            <PageContent primaryMobileContent>
                <div className="summary-report__header-row">
                    <PageHeader>{localizedReports.heading}</PageHeader>
                    <ReportYearPicker reportYear={reportYear} setReportYear={setReportYear} />
                </div>
                <p className="description" data-testid="reports-description">{localizedReports.description}</p>
                <Row className="summary-report__card-wrapper">
                    <Col>
                        <ReportCard title={categoryTitle}>
                            <ReportTable headers={primaryColumnHeaders} isTypeReport={false} reports={categoryReports} />
                        </ReportCard>
                    </Col>
                    <Col>
                        <ReportCard title={typeTitle}>
                            <ReportTable headers={primaryColumnHeaders} isTypeReport={true} reports={typeReports} />
                        </ReportCard>
                    </Col>
                    <Col>
                        <ReportCard title={costCenterTitle}>
                            <ReportTable headers={costCenterColumnHeaders} isTypeReport={false} reports={costCenterReports} />
                        </ReportCard>
                    </Col>
                </Row>

            </PageContent>
        </RingiPage >
    );
}

export default ReportsPage;