import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { fetch, put } from 'services/api';
import { Prompt } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPlus, faEnvelope, faSave, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useLocale } from 'services/localization/localizationContextProvider';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import TooltipDescription from 'components/TooltipDescription/TooltipDescription';
import ErrorIcon from 'components/ErrorIcon/ErrorIcon';
import './DivisionEditor.scss';

export default function DivisionEditor({ division }) {
    const localized = useLocale();
    const localizedButtons = localized.buttons;
    const localizedManageDivisionPage = localized.pages.division;
    const [selectedEmailRecipients, setSelectedEmailRecipients] = useState([]);
    const [addDivisionEmailAddress, setAddDivisionEmailAddress] = useState([]);
    const [addDivisionFullName, setAddDivisionFullName] = useState([]);
    const [validSendToJapanDomains, setValidSendToJapanDomains] = useState("");
    const [fullNameErrorIconMessage, setFullNameErrorIconMessage] = useState("");
    const [emailErrorIconMessage, setEmailErrorIconMessage] = useState("");
    const [dataChanged, setDataChanged] = useState(false);

    const methods = useForm({ reValidateMode: 'onSubmit' });

    useEffect(() => {
        async function fetchData() {
            setValidSendToJapanDomains(await fetch(`headquarters/sendToJapanEmailDomains`));
        }
        setDataChanged(false);
        fetchData();
        setSelectedEmailRecipients(division?.headquarterDivisionEmailRecipients?.map((recipient) => {
            return {
                email: recipient.email,
                fullName: recipient.fullName,
                isPrimaryRecipient: recipient.isPrimaryRecipient
            };
        }));
        setAddDivisionFullName("");
        setAddDivisionEmailAddress("");
        setFullNameErrorIconMessage("");
        setEmailErrorIconMessage("");
    }, [division]);

    const removeDivisionEmailRecipient = (emailAddress) => {
        setDataChanged(true);
        setSelectedEmailRecipients(selectedEmailRecipients.filter(recipient => { return recipient.email.toLowerCase() !== emailAddress.toLowerCase(); }));
    }

    const onAddDivisionAddressClick = () => {
        const validateEmailMessage = validateEmail(addDivisionEmailAddress);
        setEmailErrorIconMessage(validateEmailMessage);
        setFullNameErrorIconMessage("");
        if (addDivisionFullName.trim() === "") {
            setFullNameErrorIconMessage(localizedManageDivisionPage.full_name_required_message);
        } else if (validateEmailMessage === "") {
            if (!selectedEmailRecipients.find(recipient => recipient.email.toLowerCase() === addDivisionEmailAddress.toLowerCase())) {
                setDataChanged(true);
                setSelectedEmailRecipients([...selectedEmailRecipients,
                    {
                        email: addDivisionEmailAddress,
                        fullName: addDivisionFullName,
                        isPrimaryRecipient: selectedEmailRecipients.length === 0
                    }]);
            }
            setAddDivisionFullName("");
            setAddDivisionEmailAddress("");
        }
    }

    const validateEmail = (emailAddress) => {
        if(emailAddress === "" || emailAddress === undefined){
            return localizedManageDivisionPage.missing_email_address_format_message;
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)) {
            return localizedManageDivisionPage.invalid_email_address_format_message;
        }

        if (validSendToJapanDomains !== "") {
            const domain = emailAddress.substr(emailAddress.indexOf("@") + 1);
            if (`;${validSendToJapanDomains.toLowerCase()};`.indexOf(`;${domain.toLowerCase()};`) === -1) {
                return localizedManageDivisionPage.invalid_email_address_domain_message;
            }
        }

        return "";
    }

    const sendDataToApi = async (recipients) => {
        await put(`headquarters/division/${division.id}`, JSON.stringify(recipients));
        setDataChanged(false);
    };

    const onSubmit = async (formData) => {
        sendDataToApi(selectedEmailRecipients);
    };

    const onChangeIsPrimaryHandler = (emailAddress) => {
        setDataChanged(true);
        setSelectedEmailRecipients(selectedEmailRecipients.map((recipient) => {
            return { ...recipient, isPrimaryRecipient: recipient.email === emailAddress };
        }));
    };
    
    useEffect(() => {
        const primaryRecipientCount = selectedEmailRecipients.filter(r => r.isPrimaryRecipient === true).length;
        if(selectedEmailRecipients.length > 0 && primaryRecipientCount !== 1){
            if (primaryRecipientCount === 0) {
                setSelectedEmailRecipients(selectedEmailRecipients.map((recipient) => {
                    return { ...recipient, isPrimaryRecipient: recipient.email === selectedEmailRecipients[0].email };
                }));
            } else if (primaryRecipientCount > 1){
                let primaryRecipientSelected = false;
                const modifiedRecipients = selectedEmailRecipients.map((recipient) => {
                    if(!primaryRecipientSelected && recipient.isPrimaryRecipient){
                        primaryRecipientSelected = true;
                        return {...recipient}
                    }
                    return {...recipient, isPrimaryRecipient: false}
                });

                sendDataToApi(modifiedRecipients);
                setSelectedEmailRecipients(modifiedRecipients);
            }
        }
    }, [selectedEmailRecipients]);

    return (
        <FormProvider {...methods}>
            <form className="division-editor-content" data-testid="approver-editor" onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="division-editor">
                    <div className="division-editor__header">{division.name}</div>
                    <div className="division-editor__sub-header">{localizedManageDivisionPage.division_email_select_header}</div>

                    <div className="division-editor__selected-emails">
                        {selectedEmailRecipients.length === 0 &&
                            <span className="division-editor__selected-emails-empty">{localizedManageDivisionPage.no_division_recipients_message}</span>
                        }
                        {selectedEmailRecipients?.map((recipient, i) => {
                            return (
                                <div key={i} data-testid="division-editor__recipient" className="division-editor__selected-emails-outer-container">
                                    <div className="division-editor__selected-emails-container">
                                        <FontAwesomeIcon
                                            className="division-editor__selected-emails-email-icon"
                                            icon={faEnvelope} size="1x" />
                                        <div className="division-editor__selected-emails-name">{recipient.fullName}</div>
                                        <div className="division-editor__selected-emails-email">{recipient.email}</div>
                                        <FontAwesomeIcon
                                            className="division-editor__selected-emails-remove-icon"
                                            icon={faTimesCircle} size="1x"
                                            onClick={() => { removeDivisionEmailRecipient(recipient.email); }} />
                                    </div>
                                    <div className="division-editor__selected-emails-primary-container">
                                        <input type="radio" className="data-entry-field-group__radio-option" disabled={false}
                                            value={recipient.email} data-testid="placeholder-radio-options"
                                            checked={recipient.isPrimaryRecipient}
                                            onChange={(e) => onChangeIsPrimaryHandler(e.target.value)} />
                                        <label className="division-editor__selected-emails-primary-label">{localizedManageDivisionPage.set_as_primary}</label>
                                        <TooltipDescription tooltipName={`$set-primary-{i}-tooltip`} description={localizedManageDivisionPage.set_primary_tooltip} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="division-editor__add-email-container">
                        <div className="division-editor__add-email-field-group">
                            <div className="division-editor__add-full-name">
                                <label htmlFor="addDivisionFullName" className="division-editor__add-email-label">{localizedManageDivisionPage.add_full_name_label}</label>
                                <ValidationInput
                                    type="text"
                                    fieldName="addDivisionFullName"
                                    className="division-editor__add-full-name-input"
                                    value={addDivisionFullName}
                                    onChange={(e) => setAddDivisionFullName(e.target.value)}
                                />
                                {fullNameErrorIconMessage &&
                                    <ErrorIcon className={'division-editor__full-name-error'} errorIconName={'error-icon-addDivisionFullName'} errorMessage={fullNameErrorIconMessage} inline={true} />
                                }
                            </div>
                            <div className="division-editor__add-email-address">
                                <label htmlFor="addDivisionEmailAddress" className="division-editor__add-email-label">{localizedManageDivisionPage.add_email_address_label}</label>
                                <ValidationInput
                                    type="text"
                                    fieldName="addDivisionEmailAddress"
                                    className="division-editor__add-email-input"
                                    value={addDivisionEmailAddress}
                                    onChange={(e) => setAddDivisionEmailAddress(e.target.value)}
                                />
                                {emailErrorIconMessage &&
                                    <ErrorIcon className={'division-editor__email-error'} errorIconName={'error-icon-addDivisionEmail'} errorMessage={emailErrorIconMessage} inline={true} />
                                }
                            </div>
                        </div>
                        <div className="division-editor__add-email-button">
                            <Button data-testid="division-editor__add-button" onClick={onAddDivisionAddressClick}>
                                <FontAwesomeIcon icon={faPlus} className="mr-2" />{localizedManageDivisionPage.add_email_address_button}
                            </Button>
                        </div>
                    </div>

                    <div className="division-editor-save__container pull-right">
                        <Button type="submit"
                            data-testid="division-editor__save-button"
                            className="division-editor-save__button"
                            disabled={selectedEmailRecipients.length === 0}
                            variant="primary">
                            <FontAwesomeIcon icon={faSave} className="mr-2" />{localizedButtons.save}
                        </Button>
                    </div>

                </div>
            </form>
            <Prompt when={dataChanged} message={localizedManageDivisionPage.division_dirty_message} />
        </FormProvider>
    );
}