import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { fetch } from 'services/api';
import RingiPage, { PageContent, PageSidebar, PageHeader } from 'controls/RingiPage/RingiPage';
import TypeFormBuilder from 'pages/Configuration/Type/FormBuilder';
import TypeFormBuilderHeader from 'pages/Configuration/Type/FormBuilderHeader';
import CategoryList from 'pages/Configuration/Category/components/CategoryList';
import { TemplateContextProvider } from 'pages/Configuration/providers/templateContextProvider';
import { TypeApprovalEditorFormContextProvider } from 'pages/Configuration/providers/typeApprovalEditorFormContextProvider';
import { LAYER_TYPE } from 'constants/enums';
import 'pages/Configuration/TypeLayerPage.scss';

export default function TypeLayerPage() {
    const { categoryId, typeId } = useParams();
    const [type, setType] = useState();

    const fetchType = useCallback(async () => {
        const type = await fetch(`layers/${typeId}`);
        setType(type);
    }, [typeId]);

    useEffect(() => {
        fetchType();
    }, [fetchType]);

    return (
        <RingiPage>
            <PageSidebar>
                <CategoryList />
            </PageSidebar>
            <PageContent primaryMobileContent className="type-layer">
                {type &&
                    <TemplateContextProvider layerId={categoryId} layerTypeId={LAYER_TYPE.Category}>
                        <TemplateContextProvider layerId={typeId} layerTypeId={LAYER_TYPE.Type}>
                            <TypeApprovalEditorFormContextProvider typeProperties={type.typeProperties}>
                                <PageHeader title={type.name} className="type-layer__header" goBackTo={`/admin/configuration/category/${type.parentLayerId}`} alwaysShowBackArrow>
                                    <TypeFormBuilderHeader onTypeApprovalChange={fetchType} />
                                </PageHeader>
                                <TypeFormBuilder />
                            </TypeApprovalEditorFormContextProvider>
                        </TemplateContextProvider>
                    </TemplateContextProvider>
                }
            </PageContent>
        </RingiPage>
    );
}