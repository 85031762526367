import { SecureRoute, useOktaAuth } from '@okta/okta-react';
import React from 'react';

const AuthorizedRoute = ({ isAuthorized, path, component, ...rest }) => {
    const { authState } = useOktaAuth();
    if (!authState?.isAuthenticated || isAuthorized) {
        return <SecureRoute {...rest} path={path} component={component} />;
    }
    else {
        return null;
    }
}

export default AuthorizedRoute;