import React, { useEffect, useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { useFormContext } from 'react-hook-form';
import ValidationSelect from 'controls/ValidationSelect/ValidationSelect';
import { requiredRule } from 'services/validationRules';
import { fetch, patch, PatchOperation } from 'services/api';
import { useLocale, getDBLocalizationValue } from 'services/localization/localizationContextProvider';
import 'pages/Configuration/Type/Editors/TypeApprovalTemplateEditor.scss';

export default function TypeApprovalTemplateEditor({ typeLayerId }) {
    const {
        configuration_page: localizedConfigurationPage,
        buttons: localizedButtons
    } = useLocale();
    const methods = useFormContext();
    const { reset } = methods;
    const [approvalTemplates, setApprovalTemplates] = useState([]);
    const [requiresApprovalOptions, setRequiresApprovalOptions] = useState([]);
    const [classificationOptions, setClassificationOptions] = useState([]);
    const [divisionOptions, setDivisionOptions] = useState([]);
    const [filteredDivisionOptions, setFilteredDivisionOptions] = useState([]);
    const watchClassificationId = methods.watch("classificationId");

    const defaultTemplateOption = { id: "", name: localizedConfigurationPage.type_editor.approval_template_default_option };
    const defaultApprovalOption = { id: "", name: localizedConfigurationPage.type_editor.requires_approval_default_option };
    const defaultClassificationOption = { id: "", name: localizedConfigurationPage.type_editor.classification_default_option };
    const defaultDivisionOption = { id: "", name: localizedConfigurationPage.type_editor.division_default_option };

    const onRequiresApprovalSubmit = async (formData) => {
        await patch(`layers/${typeLayerId}`,
            new PatchOperation('replace', '/TypeProperties/ApprovalTemplateId', formData.approvalTemplateId),
            new PatchOperation('replace', '/TypeProperties/ApprovalRequirementId', formData.requiresApprovalId),
            new PatchOperation('replace', '/TypeProperties/HeadquarterClassificationId', formData.classificationId),
            new PatchOperation('replace', '/TypeProperties/HeadquarterDivisionId', formData.divisionId));
    }

    const fetchAllDataOnLoad = useCallback(async () => {
        const populateApprovalTemplates = (approvalTemplates) => {
            setApprovalTemplates(approvalTemplates ?? []);
        };

        const populateApprovalRequirements = (approvalRequirements) => {
            const localizedApprovalRequirements = approvalRequirements.map(approvalRequirement => ({
                ...approvalRequirement, name: getDBLocalizationValue(approvalRequirement.localizationKey)
            }));

            setRequiresApprovalOptions(localizedApprovalRequirements);
        };
        const populateHeadquarterClassifications = (headquarterClassifications) => {
            const localizedHeadquarterClassifications = headquarterClassifications.map(headquarterClassification => ({
                ...headquarterClassification, name: getDBLocalizationValue(headquarterClassification.localizationKey)
            }));

            setClassificationOptions(localizedHeadquarterClassifications);

        };
        const populateHeadquarterDivision = (headquarterDivisions) => {
            const localizedHeadquarterDivisions = headquarterDivisions.map(headquarterDivision => ({
                ...headquarterDivision,
                id: headquarterDivision.divisionId,
                name: headquarterDivision.name, 
                hidden: !headquarterDivision.isActive, 
                hasEmails: headquarterDivision.emailRecipientAddresses.length > 0
            }));

            setDivisionOptions(localizedHeadquarterDivisions);
        };
        const approvalTemplatePromise = fetch(`approvaltemplates/enabled`);
        const approvalRequirementsPromise = fetch(`headquarters/approvalRequirements`);
        const headquarterClassificationsPromise = fetch(`headquarters/classifications`);
        const headquarterDivisionsPromise = fetch(`headquarters/divisionsWithEmailRecipients`);

        let values = await Promise.all([approvalTemplatePromise, approvalRequirementsPromise, headquarterClassificationsPromise, headquarterDivisionsPromise]);
        populateApprovalTemplates(values[0] ?? []);
        populateApprovalRequirements(values[1] ?? []);
        populateHeadquarterClassifications(values[2] ?? []);
        populateHeadquarterDivision(values[3] ?? []);

        reset();
    }, [reset]);

    useEffect(() => {
        fetchAllDataOnLoad();
    }, [fetchAllDataOnLoad]);

    useEffect(() => {
        setFilteredDivisionOptions(divisionOptions.filter(divisionOption => 
            divisionOption.headquarterClassificationId === parseInt(watchClassificationId) 
            && divisionOption.hasEmails));
    }, [divisionOptions, watchClassificationId]);

    const onClassificationChange = (event) => {
        methods.setValue('divisionId', null);
    }

    return (
        <form data-testid="type-approval-template-form" onSubmit={methods.handleSubmit(onRequiresApprovalSubmit)} className="type-approval-template-editor">
            <div className="type-approval-template-form__field">
                <ValidationSelect
                    title={localizedConfigurationPage.type_editor.approval_template_title}
                    validationRules={{ required: requiredRule }}
                    fieldName="approvalTemplateId"
                    defaultValue={defaultTemplateOption.id} options={[defaultTemplateOption, ...approvalTemplates]} />
            </div>
            <div className="type-approval-template-form__field">
                <ValidationSelect
                    title={localizedConfigurationPage.type_editor.requires_approval_title}
                    validationRules={{ required: requiredRule }}
                    fieldName="requiresApprovalId"
                    defaultValue={defaultApprovalOption.id} options={[defaultApprovalOption, ...requiresApprovalOptions]} />
            </div>
            <div className="type-approval-template-form__field">
                <ValidationSelect
                    title={localizedConfigurationPage.type_editor.classification_title}
                    validationRules={{ required: requiredRule }}
                    fieldName="classificationId"
                    onChange={onClassificationChange}
                    defaultValue={defaultClassificationOption.id} options={[defaultClassificationOption, ...classificationOptions]} />
            </div>
            <div className="type-approval-template-form__field">
                <ValidationSelect
                    disabled={!watchClassificationId}
                    title={localizedConfigurationPage.type_editor.division_title}
                    validationRules={{ required: requiredRule }}
                    fieldName="divisionId"
                    defaultValue={defaultDivisionOption.id} options={[defaultDivisionOption, ...filteredDivisionOptions]} />
            </div>
            <div className="type-approval-template-editor__buttons">
                <Button data-testid="requires-approval-save" type="submit">
                    <FontAwesomeIcon icon={faSave} /> {localizedButtons.save}
                </Button>
            </div>
        </form>
    );
}