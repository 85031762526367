import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { useLocale } from 'services/localization/localizationContextProvider';
import { put, post, fetch } from 'services/api';
import ContextMenu from 'controls/ContextMenu/ContextMenu';
import { useModal, ModalBody, ModalButtons, ModalOkButton, ModalSubmitButton, ModalCancelButton, ModalForm } from 'controls/ActionModal/ActionModal';
import { requiredRule } from 'services/validationRules';
import { uniqueRule } from 'services/serverValidationRules';
import classNames from 'classnames';
import 'pages/Configuration/Category/TypePanel.scss';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import TypePanelEditor from 'pages/Configuration/Category/TypePanelEditor';
import ValidationSelect from 'controls/ValidationSelect/ValidationSelect';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export default function TypePanel({ type, outOfFocus, onTypeChange, showingSearchResults = false }) {
    const
        [menuItems, setMenuItems] = useState(),
        [DisableTypeModal, showDisableTypeModal] = useModal(),
        [EnableTypeModal, showEnableTypeModal] = useModal(),
        [DivisionIsDisabledModal, showDivisionIsDisabledModal] = useModal(),
        [CopyTypeModal, showCopyTypeModal, hideCopyTypeModal] = useModal(),
        [DuplicateFieldNameModal, showDuplicateFieldNameModal, hideDuplicateFieldNameModal] = useModal(),
        [EnabledTypeInUseDisableModal, showEnabledTypeInUseDisableModal] = useModal(),
        [inEditMode, setInEditMode] = useState(false),
        [usedInRingi, setUsedInRingi] = useState(false),
        [displayName, setDisplayName] = useState(),
        [disabledDivisionModalBody, setDisabledDivisionModalBody] = useState(""),
        [copyTypeFormData, setCopyTypeFormData] = useState(),
        [categoryOptions, setCategoryOptions] = useState([]),
        localizedButtons = useLocale().buttons,
        localizedDisableTypeModal = useLocale().modals.confirmation.field.disabling,
        localizedEnableTypeModal = useLocale().modals.confirmation.field.enabling,
        localizedCopyTypeModal = useLocale().modals.confirmation.field.copying,
        localizedTypeInUseDisableWarningModal = useLocale().modals.warning.typeNameInUseDisable,
        localizedDivisionIsDisabledWarningModal = useLocale().modals.warning.divisionIsDisabled,
        localizedTypePanelEditOption = useLocale().configuration_page.type_panel_menu.edit_type,
        history = useHistory();
    const localized = useLocale();

    const handleSave = async (typeName, sendEmailToZendesk) => {
        var response = await put(`categories/${type.parentLayerId}/types/${type.id}/${typeName}/${sendEmailToZendesk}`);
        if (response != null) {
            await onTypeChange();
            setInEditMode(false);
        }
    };

    const handleContainerClick = () => {
        if (!inEditMode && ((type.isEnabled && !outOfFocus) || showingSearchResults)) {
            history.push(`/admin/configuration/category/${type.parentLayerId}/type/${type.id}`);
        }
    };

    const disableType = async () => {
        await post(`layers/${type.id}/disable`);
        await onTypeChange();
    };

    const enableType = async () => {
        await post(`layers/${type.id}/enable`);
        await onTypeChange();
    };

    const tryCopyType = async (formData) => {
        var targetCategoryHasDuplicateNames = await fetch(`layers/${type.id}/fields/${formData.copyTypeCategory}/duplicates`);

        if (targetCategoryHasDuplicateNames) {
            setCopyTypeFormData(formData);
            hideCopyTypeModal();
            showDuplicateFieldNameModal();
        }
        else {
            await put(`layers/type/${type.id}/copy/${formData.typeName}/${formData.copyTypeCategory}`);
            hideCopyTypeModal();
            await onTypeChange();
            history.push(`/admin/configuration/category/${formData.copyTypeCategory}`);
        }
    };

    const confirmDuplicateFieldModal = async () => {
        await put(`layers/type/${type.id}/copy/${copyTypeFormData.typeName}/${copyTypeFormData.copyTypeCategory}`);
        history.push(`/admin/configuration/category/${copyTypeFormData.copyTypeCategory}`);
    };

    const cancelDuplicateFieldModal = async () => {
        hideDuplicateFieldNameModal();
        showCopyTypeModal();
    };

    const disableTypeAction = useCallback(async () => {
        const isTypeInUse = await fetch(`layers/type/${type.id}/isInUse`);
        if (isTypeInUse) {
            showEnabledTypeInUseDisableModal();
        } else {
            showDisableTypeModal();
        }
    }, [type.id, showEnabledTypeInUseDisableModal, showDisableTypeModal]);

    useEffect(() => {
        let draftModifierText = "";
        if (type.typeProperties?.isDraft) {
            draftModifierText = " (Draft)";
        }
        setDisplayName(`${type.name}${draftModifierText}`);
    }, [type.name, type.typeProperties]);

    useEffect(function computeMenuItems() {
        setMenuItems([
            {
                text: localizedCopyTypeModal.type_menu_option_title,
                condition: () => type.isEnabled,
                action: showCopyTypeModal
            },

            {
                text: localizedEnableTypeModal.type_menu_option_title,
                condition: () => !type.isEnabled,
                action: async () => {
                    let disabledDivisionName = await fetch(`layers/type/${type.id}/getDisabledDivisionName`);
                    if(disabledDivisionName){
                        setDisabledDivisionModalBody(localizedDivisionIsDisabledWarningModal.body.replaceAll("{divisionName}", disabledDivisionName));
                        showDivisionIsDisabledModal();
                    }
                    else{
                        showEnableTypeModal();
                    }
                }
            },

            {
                text: localizedTypePanelEditOption,
                condition: () => type.isEnabled,
                action: async () => {
                    let listOfRingisWithTypeId = await fetch(`Ringis?typeId=${type.id}`);

                    setUsedInRingi(listOfRingisWithTypeId?.length > 0)
                    setInEditMode(true);
                }
            },
            {
                text: localizedDisableTypeModal.type_menu_option_title,
                condition: () => type.isEnabled,
                action: disableTypeAction
            }
        ]);
    }, [
        localizedDisableTypeModal.type_menu_option_title,
        localizedEnableTypeModal.type_menu_option_title,
        localizedCopyTypeModal.type_menu_option_title,
        localizedTypePanelEditOption,
        type.id,
        type.isEnabled,
        disableTypeAction,
        showDisableTypeModal,
        showEnableTypeModal,
        showCopyTypeModal
    ]);

    useEffect(() => {
        const fetchCategories = async () => {
            var categories = await fetch('categories/enabled');
            setCategoryOptions(categories);
        }
        fetchCategories();
    }, []);

    var currentCategorySelected = categoryOptions !== undefined ? categoryOptions[0]?.id : 0;
    const onCopyTypeCategoryChange = (event) => {
        currentCategorySelected = event.currentTarget.value;
    };

    return (
        <>
            <div data-testid='type-panel'
                className={classNames('type-panel', {
                    'type-panel--disabled': !type.isEnabled,
                    'type-panel--out-of-focus': outOfFocus,
                    'type-panel--draft': type.typeProperties?.isDraft
                })}
                data-is-active={type.isEnabled}
                onClick={handleContainerClick}
            >
                {inEditMode ?
                    <TypePanelEditor onClose={() => { setInEditMode(false); }} type={type} onSubmit={handleSave} usedInRingi={usedInRingi}>
                        <FontAwesomeIcon icon={faSave} />{localizedButtons.save}
                    </TypePanelEditor>
                    :
                    <>
                        <span
                            className="type-panel__name"
                            data-testid={`type-field-${type.id}`}
                            data-type-id={type.id}
                            data-type-name={type.name}>
                            {displayName}
                        </span>
                        {type.hasInactiveUser &&
                            <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip className="type-template-tab-warning_tooltip">{localized.configuration_page.type_list.type_contains_inactive_user}</Tooltip>}>
                                <span className="type-template-tab-warning__container">
                                    <FontAwesomeIcon data-testid="error-icon" icon={faExclamationTriangle} size="1x" className="type-template-tab-warning" />
                                </span>
                            </OverlayTrigger>
                        }
                        {!showingSearchResults &&
                            <div onClick={(event) => { event.preventDefault(); event.stopPropagation(); }}>
                                <ContextMenu className="type-panel__context-menu" menuItems={menuItems} />
                            </div>
                        }
                    </>
                }
            </div>

            <DisableTypeModal variant='confirmation' title={localizedDisableTypeModal.type_title}>
                <ModalBody>{localizedDisableTypeModal.type_message}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton />
                    <ModalOkButton text={localizedDisableTypeModal.button_text} onClick={disableType} />
                </ModalButtons>
            </DisableTypeModal>

            <EnableTypeModal variant='confirmation' title={localizedEnableTypeModal.type_title}>
                <ModalBody>{localizedEnableTypeModal.type_message}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton />
                    <ModalOkButton text={localizedEnableTypeModal.button_text} onClick={enableType} />
                </ModalButtons>
            </EnableTypeModal>

            <CopyTypeModal data-testid="copy-type-modal" variant='confirmation' title={localizedCopyTypeModal.title}>
                <ModalForm onSubmit={tryCopyType}>
                    <ModalBody>
                        <ValidationInput
                            title={localizedCopyTypeModal.textbox_title}
                            type="text"
                            defaultValue={`${type.name} (Copy)`}
                            placeholder={localizedCopyTypeModal.placeholder}
                            boldTitle={true}
                            fieldName="typeName"
                            validationRules={{
                                required: requiredRule,
                                validate: async (typeName) => await uniqueRule(`categories/${currentCategorySelected}/types?name=${typeName}`, 0)
                            }}
                        />
                        <ValidationSelect
                            title={localizedCopyTypeModal.dropdown_title}
                            boldTitle={true}
                            fieldName="copyTypeCategory"
                            defaultValue={"Select Category"}
                            options={categoryOptions}
                            onChange={onCopyTypeCategoryChange}
                            validationRules={{
                                required: requiredRule,
                                min: { value: 0, message: 'Must select a category' }
                            }}
                        />
                    </ModalBody>
                    <ModalButtons className="headquarter-approval-modal__buttons">
                        <ModalCancelButton />
                        <ModalSubmitButton text={localizedCopyTypeModal.button_text} />
                    </ModalButtons>
                </ModalForm>
            </CopyTypeModal>

            <DuplicateFieldNameModal variant='confirmation' title={localizedCopyTypeModal.duplicate_field_modal_title}>
                <ModalBody>{localizedCopyTypeModal.duplicate_field_modal_message}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton onClick={cancelDuplicateFieldModal} />
                    <ModalOkButton onClick={confirmDuplicateFieldModal} />
                </ModalButtons>
            </DuplicateFieldNameModal>

            <EnabledTypeInUseDisableModal variant='warning' title={localizedTypeInUseDisableWarningModal.title}>
                <ModalBody>{localizedTypeInUseDisableWarningModal.body}</ModalBody>
                <ModalButtons>
                    <ModalOkButton />
                </ModalButtons>
            </EnabledTypeInUseDisableModal>
            
            <DivisionIsDisabledModal variant='warning' title={localizedDivisionIsDisabledWarningModal.title}>
                <ModalBody>{disabledDivisionModalBody}</ModalBody>
                <ModalButtons>
                    <ModalOkButton />
                </ModalButtons>
            </DivisionIsDisabledModal>
        </>
    );
}