import React, { useState, useContext, useCallback } from 'react';

const ValidationServiceContext = React.createContext();

const ValidationServiceContextProvider = props => {
    const [fieldsForValidation, setFieldsForValidation] = useState([]);

    const registerField = useCallback((fieldName, validationLevel) => {
        setFieldsForValidation(previousFields => {
            const currentFieldsInValidationLevel = previousFields[validationLevel] ?? [];
            const currentFieldsWithNewField = [...currentFieldsInValidationLevel, fieldName];
            const mergedFieldsForValidation = { ...previousFields, [validationLevel]: currentFieldsWithNewField }

            return mergedFieldsForValidation;
        });
    }, []);

    const unRegisterField = useCallback((fieldName, validationLevel) => {
        setFieldsForValidation(previousFields => {
            const currentFieldsInValidationLevel = previousFields[validationLevel] ?? [];
            const currentFieldsWithoutField = currentFieldsInValidationLevel.filter(field => field !== fieldName);
            const mergedFieldsForValidation = { ...previousFields, [validationLevel]: currentFieldsWithoutField }

            return mergedFieldsForValidation;
        });
    }, []);

    const getFieldsForValidation = useCallback((validationLevel) => {
        return fieldsForValidation[validationLevel];
    }, [fieldsForValidation]);

    const serviceMethods = {
        registerField,
        unRegisterField,
        getFieldsForValidation
    };

    return (
        <ValidationServiceContext.Provider value={serviceMethods}>
            {props.children}
        </ValidationServiceContext.Provider>
    );
};

const useValidationService = () => {
     return useContext(ValidationServiceContext);
 };

export {
    ValidationServiceContextProvider,
    useValidationService
};
