import React from 'react';
import { SecureRoute } from '@okta/okta-react';
import AuthorizedRoute from 'components/AuthorizedRoute';
import { Switch } from 'react-router-dom';
import ViewRingiPage from 'pages/Ringis/ViewRingiPage';
import DraftRingiPage from 'pages/Ringis/DraftRingiPage';
import { ConfigurationPageContextProvider } from 'pages/Configuration/providers/configurationPageContextProvider';
import { TemplateContextProvider } from 'pages/Configuration/providers/templateContextProvider';
import BaseLayerPage from 'pages/Configuration/BaseLayerPage';
import CategoryLayerPage from 'pages/Configuration/CategoryLayerPage';
import TypeLayerPage from 'pages/Configuration/TypeLayerPage';
import CostCenterPage from 'pages/CostCenter/CostCenterPage';
import ApprovalPage from 'pages/Approval/ApprovalPage';
import DivisionPage from 'pages/Division/DivisionPage';
import ApplicationSettingsPage from 'pages/ApplicationSettings/ApplicationSettingsPage';
import ReportsPage from 'pages/Reports/ReportsPage';
import ViewDraftRingisPage from 'pages/Ringis/ViewDraftRingisPage';
import ViewCurrentRingisPage from 'pages/Ringis/ViewCurrentRingisPage';
import ViewSubmittedRingisPage from 'pages/Ringis/ViewSubmittedRingisPage';
import ViewNotificationsPage from 'pages/Notification/ViewNotificationsPage';
import ViewRingisToApprovePage from 'pages/Ringis/ViewRingisToApprovePage';
import ViewRingisToReadPage from 'pages/Ringis/ViewRingisToReadPage';
import ViewArchivedRingisPage from 'pages/Ringis/ViewArchivedRingisPage';
import NotificationPreferencesPage from 'pages/Notification/NotificationPreferencesPage';
import { useAuthorizationClaims } from 'providers/authorizationClaimsProvider';
import { LAYER_TYPE, BASE_LAYER_ID } from 'constants/enums';
import { ROUTES as Paths } from 'constants/constants';

const Routes = () => {
    const { isAdmin } = useAuthorizationClaims();

    return (
        <Switch>
            <SecureRoute exact path={Paths.CURRENT_RINGIS_PAGE} component={ViewCurrentRingisPage} />
            <SecureRoute exact path={Paths.DRAFT_RINGIS_PAGE} component={ViewDraftRingisPage} />
            <SecureRoute exact path={Paths.SUBMITTED_RINGIS_PAGE} component={ViewSubmittedRingisPage} />
            <SecureRoute exact path={Paths.RINGIS_TO_APPROVE_PAGE} component={ViewRingisToApprovePage} />
            <SecureRoute exact path={Paths.RINGIS_TO_READ_PAGE} component={ViewRingisToReadPage} />
            <SecureRoute exact path={Paths.ARCHIVED_RINGIS_PAGE} component={ViewArchivedRingisPage} />
            <SecureRoute path={Paths.NEW_RINGIS} component={DraftRingiPage} />
            <SecureRoute path={Paths.COPY_RINGI_PAGE} component={DraftRingiPage} />
            <SecureRoute path={Paths.DRAFT_RINGI_PAGE} component={DraftRingiPage} />
            <SecureRoute path={Paths.VIEW_RINGI_PAGE} component={ViewRingiPage} />
            <SecureRoute exact path={Paths.NOTIFICATIONS_PAGE} component={ViewNotificationsPage} />
            <SecureRoute exact path={Paths.NOTIFICATION_PREFERENCES_PAGE} component={NotificationPreferencesPage} />
            <SecureRoute path="/admin/configuration">
                <ConfigurationPageContextProvider>
                    <TemplateContextProvider layerId={BASE_LAYER_ID} layerTypeId={LAYER_TYPE.Base}>
                        <AuthorizedRoute exact isAuthorized={isAdmin} path="/admin/configuration/:viewDisabledCategories" component={BaseLayerPage} />
                        <AuthorizedRoute exact isAuthorized={isAdmin} path="/admin/configuration/category/:categoryId/" component={CategoryLayerPage} />
                        <AuthorizedRoute isAuthorized={isAdmin} path="/admin/configuration/category/:categoryId/type/:typeId" component={TypeLayerPage} />
                    </TemplateContextProvider>
                </ConfigurationPageContextProvider>
            </SecureRoute>
            <AuthorizedRoute isAuthorized={isAdmin} path="/admin/costcenter" component={CostCenterPage} />
            <AuthorizedRoute exact isAuthorized={isAdmin} path="/admin/approval" component={ApprovalPage} />
            <AuthorizedRoute isAuthorized={isAdmin} path="/admin/approval/:approvalTemplateId" component={ApprovalPage} />
            <AuthorizedRoute exact isAuthorized={isAdmin} path="/admin/division" component={DivisionPage} />
            <AuthorizedRoute isAuthorized={isAdmin} path="/admin/division/:divisionId" component={DivisionPage} />
            <AuthorizedRoute exact isAuthorized={isAdmin} path="/admin/applicationSettings" component={ApplicationSettingsPage} />
            <AuthorizedRoute isAuthorized={isAdmin} path={Paths.RINGI_REPORTS_PAGE} component={ReportsPage} />
        </Switch>
    );
}

export default Routes;