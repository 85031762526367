import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useLocale } from 'services/localization/localizationContextProvider';
import { fetch } from 'services/api';
import RingiPage, { PageContent, PageSidebar, PageHeader } from 'controls/RingiPage/RingiPage';
import BaseRingiFormBuilder from 'pages/Configuration/BaseRingi/FormBuilder';
import HeaderControls from 'pages/Configuration/FormBuilderHeaderControls';
import SearchBox from 'controls/SearchBox/SearchBox';
import CategoryList from 'pages/Configuration/Category/components/CategoryList';
import TypeList from 'pages/Configuration/Category/TypeList';
import { LAYER_TYPE } from 'constants/enums';
import 'pages/Configuration/BaseLayerPage.scss';

export default function BaseLayerPage() {
    const localizedConfigurationPage = useLocale().configuration_page;
    const { viewDisabledCategories } = useParams();
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [typesRetrieved, setTypesRetrieved] = useState([]);

    const fetchTypes = useCallback(async (searchTypeString) => {
        const types = await fetch(`categories/types?name=${searchTypeString}`);
        setTypesRetrieved(types);
    }, []);

    const handleOnSearchTypes = async (searchTypeString) => {
        await fetchTypes(searchTypeString);
        setShowSearchResults(true);
    };

    const handleClearTypes = () => {
        setTypesRetrieved([]);
        setShowSearchResults(false);
    }

    const handleOnBaseCategorySelected = async () => {
        setShowSearchResults(false);
    };

    return (
        <RingiPage>
            <PageSidebar>
                <CategoryList toggleOn={parseInt(viewDisabledCategories) === 0 ? false : true} removeSelectedStyling={showSearchResults} onBaseCategorySelected={handleOnBaseCategorySelected} />
            </PageSidebar>
            <PageContent primaryMobileContent className="base-layer">
                {
                    parseInt(viewDisabledCategories) === 0 &&
                    <>
                        <PageHeader title={showSearchResults ? localizedConfigurationPage.base_layer.type_search_results : localizedConfigurationPage.base_layer.title} className="base-layer__header">
                            {
                                <>
                                    <SearchBox placeholderText={localizedConfigurationPage.search_types.placeholder_text} fieldName="type-fields" onSearch={handleOnSearchTypes} onClear={handleClearTypes}/>
                                    {!showSearchResults ?
                                        <HeaderControls layerTypeId={LAYER_TYPE.Base} />
                                        :
                                        <div className="header-controls-placeholder"></div>
                                    }
                                </>
                            }
                        </PageHeader>
                        {showSearchResults ?
                            <TypeList types={typesRetrieved} showingDisabledTypes={true} showingSearchResults={true} />
                            :
                            <BaseRingiFormBuilder />
                        }
                    </>
                }
            </PageContent>
        </RingiPage>
    );
}