import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { requiredRule, alphaNumericSymbolRule, numericRule } from 'services/validationRules';
import { uniqueRule } from 'services/serverValidationRules';
import { useLocale } from 'services/localization/localizationContextProvider';
import 'pages/CostCenter/components/CostCenterEditor.scss';

const CostCenterEditor = ({ onClose, costCenter, headerText, addMode }) => {
    const methods = useForm({ defaultValues: addMode ? {} : costCenter, reValidateMode: 'onSubmit' });
    const localized = useLocale();
    const localizedButtons = localized.buttons;
    const localizedCostCenters = localized.pages.cost_center;
    const onSubmit = async (formData) => {
        await onClose(true, formData);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} data-testid="cost-center-editor" className="cost-center-editor">
                <div className="template-field-header">

                    <span data-testid="cost-center-editor-header">{headerText}</span>
                    <FontAwesomeIcon data-testid="cost-center-editor-header-close" className="template-field-header__cancel" icon={faTimes} onClick={() => onClose(false)} />

                </div>
                <div className="cost-center-editor__line">
                    {addMode &&
                        <ValidationInput
                            type="text"
                            autoFocus
                            fieldName="number"
                            data-testid="cost-center-number"
                            min={1}
                            max={10}
                            title={localizedCostCenters.number_label}
                            className="cost-center-editor__input--half-length"
                            validationRules={{
                                required: requiredRule,
                                validate: async (number) => await uniqueRule(`costcenters?number=${number}`),
                                pattern: numericRule
                            }} />
                    }
                    <ValidationInput
                        type="text"
                        fieldName="description"
                        data-testid="cost-center-description"
                        min={1}
                        max={50}
                        title={localizedCostCenters.description_label}
                        className="cost-center-editor__input--half-length"
                        validationRules={{
                            required: requiredRule,
                            validate: async (description) => await uniqueRule(`costcenters?description=${description}`, costCenter.id),
                            pattern: alphaNumericSymbolRule,
                        }} />
                </div>
                <div className="cost-center-editor__buttons">
                    <Button variant="link" onClick={() => onClose(false)}>
                        {localizedButtons.cancel}
                    </Button>
                    <Button type="submit">
                        <FontAwesomeIcon icon={faSave} /> &nbsp; {localizedButtons.save}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default CostCenterEditor;