import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { fetch } from 'services/api';
import classNames from 'classnames';
import { useLocale } from 'services/localization/localizationContextProvider';
import RingiPage, { PageContent, PageSidebar, PageHeader } from 'controls/RingiPage/RingiPage';
import ToggleSwitch from 'controls/ToggleSwitch/ToggleSwitch';
import CategoryList from 'pages/Configuration/Category/components/CategoryList';
import CategoryFormBuilder from 'pages/Configuration/Category/FormBuilder';
import HeaderControls from 'pages/Configuration/FormBuilderHeaderControls';
import TypeList from 'pages/Configuration/Category/TypeList';
import { useConfigurationPage } from 'pages/Configuration/providers/configurationPageContextProvider';
import { TemplateContextProvider } from 'pages/Configuration/providers/templateContextProvider';
import { LAYER_TYPE } from 'constants/enums';
import 'pages/Configuration/CategoryLayerPage.scss';

export default function CategoryLayerPage() {
    const { categoryId } = useParams();
    const { categories } = useConfigurationPage();
    const [category, setCategory] = useState();
    const [types, setTypes] = useState([]);
    const [showingDisabledTypes, setShowingDisabledTypes] = useState(false);
    const localizedTypeList = useLocale().configuration_page.type_list;

    const TYPE_LIST_TAB_ID = 1;
    const FORM_BUILDER_TAB_ID = 2;
    const [selectedTab, setSelectedTab] = useState(TYPE_LIST_TAB_ID);

    useEffect(() => {
        const categoryIdAsInt = parseInt(categoryId);
        const category = categories.find(cat => cat.id === categoryIdAsInt);
        setCategory(category);
    }, [categories, categoryId]);

    const fetchTypes = useCallback(async () => {
        const types = await fetch(`Categories/${categoryId}/Types`);
        setTypes(types);
    }, [categoryId]);

    useEffect(() => {
        fetchTypes();
    }, [fetchTypes]);

    const handleViewDisabledTypesToggle = (isToggled) => {
        setShowingDisabledTypes(isToggled);
    }

    return (
        <RingiPage>
            <PageSidebar>
                <CategoryList />
            </PageSidebar>
            <PageContent primaryMobileContent className="category-layer">
                <TemplateContextProvider layerId={categoryId} layerTypeId={LAYER_TYPE.Category}>
                    <PageHeader title={category?.name} className="category-layer__header">
                        <div aria-label="Type navigation buttons" role="group" className="category-layer__nav-buttons">
                            <button type="button" data-testid="type-list-button"
                                className={classNames('category-layer__nav-button', { 'category-layer__nav-button--selected': selectedTab === TYPE_LIST_TAB_ID })}
                                onClick={() => setSelectedTab(TYPE_LIST_TAB_ID)}>
                                Type List
                            </button>
                            <button type="button" data-testid="form-builder-button"
                                className={classNames('category-layer__nav-button', { 'category-layer__nav-button--selected': selectedTab === FORM_BUILDER_TAB_ID })}
                                onClick={() => setSelectedTab(FORM_BUILDER_TAB_ID)}>
                                Form Builder
                            </button>
                        </div>
                        {selectedTab === TYPE_LIST_TAB_ID ?
                            <ToggleSwitch label={localizedTypeList.disable_type_toggle_text} onSwitch={handleViewDisabledTypesToggle} />
                            :
                            <HeaderControls layerTypeId={LAYER_TYPE.Category} />
                        }
                    </PageHeader>
                    {selectedTab === TYPE_LIST_TAB_ID ?
                        <TypeList types={types} onTypeChange={fetchTypes} showingDisabledTypes={showingDisabledTypes} />
                        :
                        <CategoryFormBuilder categoryLayerId={categoryId} />
                    }
                </TemplateContextProvider>
            </PageContent>
        </RingiPage>
    );
}