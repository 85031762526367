import React, { useState, useEffect, useCallback } from 'react';
import { useAuthorizationClaims } from 'providers/authorizationClaimsProvider';
import Authorized from 'components/Authorized';
import ReaderMenu from 'pages/Ringis/components/Readers/ReaderMenu';
import { useSubmittedRingi } from 'pages/Ringis/providers/submittedRingiContextProvider';
import { useLocale } from 'services/localization/localizationContextProvider';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useModal, ModalBody, ModalButtons, ModalOkButton, ModalCancelButton } from 'controls/ActionModal/ActionModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faEnvelopeOpenText, faCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { put, httpDelete } from 'services/api';
import 'pages/Ringis/components/Readers/ReaderList.scss';
import { useForm } from 'react-hook-form';

export default function ReaderList() {
    const { approvers, readers, ringi, refreshRingi, refreshReaders } = useSubmittedRingi();
    const { loginName, isAdmin } = useAuthorizationClaims();
    const [RemoveReaderConfirmationModal, showRemoveReaderConfirmationModal] = useModal();
    const [canAddReader, setCanAddReader] = useState(false);
    const [ringiReaders, setRingiReaders] = useState(readers);
    const methods = useForm({ reValidateMode: 'onSubmit' });
    const localizedReadersPage = useLocale().pages.view_ringi.readers;



    const handleReadIconClicked = async (ringiReader) => {
        if (loginName === ringiReader.user?.loginName?.trim().toLocaleLowerCase()) {
            var response = await put(`ringis/${ringi.id}/changeReaderReadStatus`);

            if (response != null) {
                const theRingiReaders = [...ringiReaders];
                if (ringiReader.readDate === null) {
                    theRingiReaders.find(rr => rr.id === ringiReader.id).readDate = 'notNull';
                }
                else {
                    theRingiReaders.find(rr => rr.id === ringiReader.id).readDate = null;
                }
                setRingiReaders(theRingiReaders);
            }
        }
    };

    const handleRemove = useCallback((id) => {
        showRemoveReaderConfirmationModal(id);
    }, [showRemoveReaderConfirmationModal]);

    const handleRemoveReaderConfirm = async (readerId) => {
        const response = await httpDelete(`ringis/${ringi.id}/${readerId}`);
        if (response != null) {
            refreshRingi();
            methods.reset();
            await refreshReaders();
        }
    };



    useEffect(() => {
        setRingiReaders(readers);
    }, [readers]);

    

    return (
        <>
            <div data-testid="reader-list" className="reader-list__readers">
                {ringiReaders.map((reader, i) => (
                    <div key={i} data-testid="reader-card" className="reader-list__reader" >
                        <div className="reader-detail__flex">

                            <div className={reader.readDate === null ? "d-flex unread" : "read"}>
                                <div className="unread-dot">
                                    <FontAwesomeIcon icon={faCircle} size="xs" />
                                </div>
                                <span data-testid="reader-name" className="reader-list__name">
                                    {reader.user.fullName}
                                </span></div>

                            {loginName === reader.user?.loginName?.trim().toLocaleLowerCase() && (<div> {reader.readDate === null ?
                                <span
                                    data-testid="reader-icons"
                                    className={classNames('reader-detail__grid-last-cell', { 'reader-detail__button': loginName === reader.user?.loginName?.trim().toLocaleLowerCase() })}
                                    onClick={() => { handleReadIconClicked(reader); }}
                                >
                                    <FontAwesomeIcon icon={faEnvelope} size="1x" data-testid={`reader-icon-${i}`} />
                                    <span>{localizedReadersPage.action_text} {localizedReadersPage.read_text}</span>
                                </span>
                                :
                                <span
                                    data-testid="reader-icons"
                                    className={classNames('reader-detail__grid-last-cell', { 'reader-detail__button': loginName === reader.user?.loginName?.trim().toLocaleLowerCase() })}
                                    onClick={() => { handleReadIconClicked(reader); }}
                                >
                                    <FontAwesomeIcon icon={faEnvelopeOpenText} size="1x" data-testid={`reader-icon-${i}`} />
                                    <span>{localizedReadersPage.action_text} {localizedReadersPage.unread_text}</span>
                                </span>
                            }</div>)}

                            {isAdmin &&
                                <FontAwesomeIcon data-testid="comment-buttons__remove" icon={faTimes} title={localizedReadersPage.action_text} onClick={() => { handleRemove(reader.id) }} />
                            }



                        </div>

                        <div data-testid="reader-title" className="reader-subtext">
                            {reader.user.title}
                        </div>
                        <div data-testid="reader-costcenter" className="reader-subtext reader-detail__grid-last-cell">
                            {reader.user.costCenter ? `(${reader.user.costCenter.number}) ${reader.user.costCenter.description}` : ''}
                        </div>

                    </div>
                ))}
            </div>
            
            <ReaderMenu />
           

            <RemoveReaderConfirmationModal variant='confirmation' title={localizedReadersPage.title}>
                <ModalBody>{localizedReadersPage.message}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton />
                    <ModalOkButton text={localizedReadersPage.success} onClick={handleRemoveReaderConfirm} />
                </ModalButtons>
            </RemoveReaderConfirmationModal>
        </>
    );
}