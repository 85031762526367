import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

function useOutsideClickHandler(ref, props) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (props.returnFunc) {
                    props.returnFunc();
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, props]);
}

function OutsideClickHandler(props) {
    const wrapperRef = useRef(null);
    useOutsideClickHandler(wrapperRef, props);

    return <div ref={wrapperRef}>{props.children}</div>;
}

OutsideClickHandler.propTypes = {
    children: PropTypes.element.isRequired
};

export default OutsideClickHandler;
