import PillText from 'components/PillText/PillText';
import React from 'react';
import { RINGI_STATUS } from 'constants/enums';

const DaysUntilPill = ({ daysUntil, className, ringiStatus }) => {
    const daysUntilNaN = daysUntil == null || daysUntil === '' || isNaN(daysUntil);
    if (daysUntilNaN || [RINGI_STATUS.Approved, RINGI_STATUS.ApprovedWithConditions, RINGI_STATUS.Denied, RINGI_STATUS.Cancelled].includes(ringiStatus)) {
        return <div className={className}>--</div>;
    }
    return <PillText text={daysUntil} className={className} variant={daysUntil > 0 ? "Positive" : "Negative"}></PillText>
}

export default DaysUntilPill;