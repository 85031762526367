import React, { useContext, useEffect, useState, useCallback } from 'react';
import { fetch } from 'services/api';

const ConfigurationPageContext = React.createContext();

const useConfigurationPage = () => useContext(ConfigurationPageContext);

const ConfigurationPageContextProvider = ({ children }) => {
    const [categories, setCategories] = useState([]);
    const [showEnabledCategories, setShowEnabledCategories] = useState(true);
    const [fieldTypes, setFieldTypes] = useState([]);


    const fetchCategories = useCallback(async () => {
        const url = showEnabledCategories ? 'categories/enabled' : 'categories/disabled';
        const categories = await fetch(url) ?? [];
        setCategories(categories);
    }, [showEnabledCategories]);

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    const toggleEnabledCategories = () => {
        setShowEnabledCategories(prevShowingEnabledCategories => !prevShowingEnabledCategories);
    }

    useEffect(() => {
        const fetchFieldTypes = async () => {
            var types = await fetch(`FieldTypes`);
            setFieldTypes(types ?? []);
        };

        fetchFieldTypes();
    }, []);

    return (
        <ConfigurationPageContext.Provider value={{
            fieldTypes: fieldTypes,

            categories: categories,
            toggleViewDisabledCategories: toggleEnabledCategories,
            refreshCategories: fetchCategories,
            showingEnabledCategories: showEnabledCategories
        }}>
            {children}
        </ConfigurationPageContext.Provider>
    );
}


export {
    ConfigurationPageContextProvider,
    useConfigurationPage
};