import React, { useState, useContext } from 'react';
import { DEFAULT_LANDING_PAGE } from 'constants/constants';

const PageHistoryContext = React.createContext();

const PageHistoryContextProvider = props => {
    const [lastVisitedViewPage, setlastVisitedViewPage] = useState(DEFAULT_LANDING_PAGE);

    const setLastPage = (newLastPage) => {
        setlastVisitedViewPage(newLastPage)
    }

    const getLastPage = () => { return lastVisitedViewPage; }

    const serviceMethods = {
        getLastPage,
        setLastPage
    };

    return (
        <PageHistoryContext.Provider value={serviceMethods}>
            {props.children}
        </PageHistoryContext.Provider>
    );
};

const usePageHistoryContextProvider = () => {
    return useContext(PageHistoryContext);
};

export {
    PageHistoryContextProvider,
    usePageHistoryContextProvider
};
