import React, { useState, useEffect, useCallback } from 'react';
import { FIELD_TYPE, RINGI_SETTING } from 'constants/enums';
import { FormProvider, useForm } from 'react-hook-form';
import { decimalPlacesRule, requiredRule, alphaNumericSymbolRule, maxLengthRule, minValueRule } from 'services/validationRules';
import { ScreenDetectColumn } from 'hooks/useScreenDetect';
import { Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { fetch, put } from 'services/api';
import { useLocale } from 'services/localization/localizationContextProvider';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import RingiField from 'pages/Ringis/components/RingiField';
import RingiPage, { PageSidebar, PageContent, PageHeader } from 'controls/RingiPage/RingiPage';
import 'pages/ApplicationSettings/ApplicationSettingsPage.scss';

const ApplicationSettingsPageDivisionPage = () => {
    const [settings, setSettings] = useState();
    const [isInEditMode, setIsInEditMode] = useState(false);
    const localizedRingiInput = useLocale().ringi_input;
    const localizedApplicationSettings = useLocale().pages.application_settings;

    const methods = useForm({ reValidateMode: 'onSubmit' });

    const fetchSettings = useCallback(async () => {
        const settings = await fetch('ringiSettings');

        if (settings) {
            settings.map((setting, i) => {
                setting['fieldName'] = setting.caption;
            })
            setSettings(settings);
        }
    }, []);

    useEffect(() => {
        fetchSettings();
    }, [fetchSettings]);

    const enteringEditMode = () => {
        setIsInEditMode(true);
        for (let i = 0; i < settings.length; i++) {
            methods.setValue(`settingId-${i}`, settings[i].value);
        }
    };

    const handleSettingsSave = async (formData) => {
        let formSettings = [];
        for (let i = 0; i < settings.length; i++) {
            formSettings.push({
                id: i + 1,
                value: formData[`settingId-${i}`]
            });
        };

        await put('ringiSettings', {
            "ringiSettings": formSettings
        });

        methods.reset();
        setIsInEditMode(false);

        fetchSettings();
    };

    return (
        <RingiPage>
            <PageContent primaryMobileContent={false}>
                <FormProvider {...methods}>
                    <form data-testid="edit-settings-form" onSubmit={methods.handleSubmit(handleSettingsSave)}>
                        <PageHeader className="application-settings-header" title={localizedApplicationSettings.page_title}>
                            <Button data-testid="application-setings-button__edit" variant="primary" className="edit-settings-form__button" onClick={() => enteringEditMode()} hidden={isInEditMode}>
                                <FontAwesomeIcon icon={faEdit} />
                                <span>{localizedApplicationSettings.edit_button}</span>
                            </Button>
                            <Button data-testid="application-setings-button__cancel" variant="link" className="application-settings-footer__cancel" onClick={() => setIsInEditMode(false)} hidden={!isInEditMode}>
                                {localizedApplicationSettings.cancel}
                            </Button>
                            <Button data-testid="application-setings-button__save" variant="primary" type="submit" className="edit-settings-form__button" hidden={!isInEditMode}>
                                <FontAwesomeIcon icon={faSave} />
                                <span>{localizedApplicationSettings.save}</span>
                            </Button>
                        </PageHeader>
                        <Row>
                            {isInEditMode ?
                                settings?.map((setting, i) => (
                                    <ScreenDetectColumn key={i} desktopColumnWidth={6}>
                                        <div className="ringi-editor-field application-setting-field">
                                            {setting.fieldTypeId === FIELD_TYPE.FreeText &&
                                                <>
                                                    <ValidationInput type="text" maxLength={200}
                                                        title={setting.caption}
                                                        fieldName={`settingId-${i}`}
                                                        validationRules={{
                                                            //Story 10676 - Make ReportAppIssueCcEmail Option
                                                            required: setting.id !== RINGI_SETTING.ReportAnAppIssueCcEmail ? requiredRule : false,
                                                            pattern: alphaNumericSymbolRule,
                                                            maxLength: maxLengthRule(200)
                                                        }}
                                                    />
                                                    {setting.id === RINGI_SETTING.ReportAnAppIssueToEmail &&
                                                        <span className="application-setting-help-text">{localizedApplicationSettings.app_issue_email_addresses}</span>
                                                    }
                                                    {setting.id === RINGI_SETTING.SendToJapanEmailDomains &&
                                                        <span className="application-setting-help-text">{localizedApplicationSettings.japan_email_domains}</span>
                                                    }
                                                    {setting.id === RINGI_SETTING.SendGridFromEmail &&
                                                        <span className="application-setting-help-text">{localizedApplicationSettings.sendgrid_email_address}</span>
                                                    }
                                                    {setting.id === RINGI_SETTING.SupportEmail &&
                                                        <span className="application-setting-help-text">{localizedApplicationSettings.single_email_address}</span>
                                                    }
                                                    {setting.id === RINGI_SETTING.ReportAnAppIssueCcEmail &&
                                                        <span className="application-setting-help-text">{localizedApplicationSettings.single_email_address}</span>
                                                    }
                                                </>
                                            }
                                            {setting.fieldTypeId === FIELD_TYPE.Currency &&
                                                <ValidationInput type="number" min={0} step={.01}
                                                    title={setting.caption}
                                                    fieldName={`settingId-${i}`}
                                                    prefixIconName={localizedRingiInput.currency_unit_icon}
                                                    validationRules={{
                                                        valueAsNumber: true,
                                                        required: requiredRule,
                                                        validate: { validDecimalPlaces: number => decimalPlacesRule(number, 2) },
                                                        min: minValueRule(0)
                                                    }}
                                                />
                                            }
                                            {setting.fieldTypeId === FIELD_TYPE.Number &&
                                                <ValidationInput type="number" step={1}
                                                    title={setting.caption}
                                                    fieldName={`settingId-${i}`}
                                                    validationRules={{
                                                        valueAsNumber: true,
                                                        required: requiredRule
                                                    }}
                                                />
                                            }
                                        </div>
                                    </ScreenDetectColumn>
                                ))
                                :
                                settings?.map((setting, i) => (
                                    <ScreenDetectColumn key={i} desktopColumnWidth={6}>
                                        <div className="application-settings__ringi-field">
                                            <RingiField ringiField={setting} />
                                        </div>
                                    </ScreenDetectColumn>
                                ))
                            }
                        </Row>
                    </form>
                </FormProvider>
            </PageContent>
        </RingiPage>
    );
};

export default ApplicationSettingsPageDivisionPage;