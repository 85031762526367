async function fetchEnvironment() {
    const res = await fetch(`${process.env.PUBLIC_URL}/environment.json`);
    if (res.ok)
        return await res.json();
    throw new Error('Failed to retrieve environment variables');
}

async function loadEnvironmentIntoProcess(json) {
    process.ringiEnv = {
        ...json,
    };
}

async function loadEnv() {
    const json = await fetchEnvironment();
    loadEnvironmentIntoProcess(json);
}

export { loadEnv }