import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import RingiPage, { PageContent, PageHeader } from 'controls/RingiPage/RingiPage';
import NotificationPreferencePanel from 'pages/Notification/NotificationPreferencePanel';
import classNames from 'classnames';
import { useScreenDetect } from 'hooks/useScreenDetect';
import { Button } from 'react-bootstrap';
import { fetch, put } from 'services/api';
import { useLocale } from 'services/localization/localizationContextProvider';
import { NOTIFICATION_GROUP_CODES } from 'constants/constants';
import 'pages/Notification/NotificationPreferencesPage.scss';

export default function NotificationPreferencesPage() {
    const [userEmail, setUserEmail] = useState('');
    const [notificationPreferencesOptedOut, setNotificationPreferencesOptedOut] = useState([]);
    const [showSavedMessage, setShowSavedMessage] = useState(false);
    const [showOptedOutMessage, setOptedOutMessage] = useState(false);

    const { isDesktop } = useScreenDetect();
    const localized = useLocale().pages.notifications.preferences;

    const location = useLocation();
    const history = useHistory();

    const fetchNotificationPreferences = useCallback(async () => {
        const prefsOptedOut = await fetch("notifications/preferences");
        const groupCodesOptedOut = prefsOptedOut.notificationOptOutDTOs.map(o => o.notificationGroup.code);
        setUserEmail(prefsOptedOut.userEmail);
        setNotificationPreferencesOptedOut(groupCodesOptedOut);
    }, []);

    useEffect(() => {
        fetchNotificationPreferences();

        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has('optOutAll')) {
            handleOptOutAll();
            queryParams.delete('optOutAll')
            history.replace({
                search: ''
            })
        }
    }, [fetchNotificationPreferences, location, history]);

    const handleOnPreferenceChanged = (preferenceId, state) => {
        if (state === false) {
            const theOptOuts = [...notificationPreferencesOptedOut];
            theOptOuts.push(preferenceId);
            setNotificationPreferencesOptedOut(theOptOuts);
        } else {
            const theOptOuts = [...notificationPreferencesOptedOut];
            const theOptOutsFiltered = theOptOuts.filter(o => o !== preferenceId);
            setNotificationPreferencesOptedOut(theOptOutsFiltered);
        }
        setShowSavedMessage(false);
        setOptedOutMessage(false);
    };

    const handleOptOutAll = async () => {
        const prefsOptedOut = await put('notifications/optOutAll');
        if (prefsOptedOut != null) {
            const groupCodesOptedOut = prefsOptedOut.notificationOptOutDTOs.map(o => o.notificationGroup.code);
            setNotificationPreferencesOptedOut(groupCodesOptedOut);
            setShowSavedMessage(false);
            setOptedOutMessage(true);
        }
    };

    const handleSave = async () => {
        await put('notifications/optOut', JSON.stringify(notificationPreferencesOptedOut));
        setOptedOutMessage(false);
        setShowSavedMessage(true);
    };

    return (
        <RingiPage>
            <PageContent primaryMobileContent>
                <PageHeader className="header" title={localized.page_title}>
                </PageHeader>
                <div data-testid="instructions" className={classNames("instructions", { "instructions--desktop": isDesktop })}>
                    {localized.instructions.replace("{emailPlaceholder}", userEmail)}
                </div>
                {showSavedMessage &&
                    <div data-testid="saved-message">
                        {localized.confirmation_saved}
                    </div>
                }
                {showOptedOutMessage &&
                    <div data-testid="opted-out-message">
                        {localized.confirmation_opted_out}
                    </div>
                }
                <NotificationPreferencePanel preferenceId={NOTIFICATION_GROUP_CODES.APPROVALS} title={localized.approvals.title} description={localized.approvals.description} onChanged={handleOnPreferenceChanged} isChecked={notificationPreferencesOptedOut.indexOf(NOTIFICATION_GROUP_CODES.APPROVALS) === -1} />
                <NotificationPreferencePanel preferenceId={NOTIFICATION_GROUP_CODES.DENIALS} title={localized.denials.title} description={localized.denials.description} onChanged={handleOnPreferenceChanged} isChecked={notificationPreferencesOptedOut.indexOf(NOTIFICATION_GROUP_CODES.DENIALS) === -1} />
                <NotificationPreferencePanel preferenceId={NOTIFICATION_GROUP_CODES.PENDING} title={localized.pending.title} description={localized.pending.description} onChanged={handleOnPreferenceChanged} isChecked={notificationPreferencesOptedOut.indexOf(NOTIFICATION_GROUP_CODES.PENDING) === -1} />
                <NotificationPreferencePanel preferenceId={NOTIFICATION_GROUP_CODES.READERS_ADDED} title={localized.readers_added.title} description={localized.readers_added.description} onChanged={handleOnPreferenceChanged} isChecked={notificationPreferencesOptedOut.indexOf(NOTIFICATION_GROUP_CODES.READERS_ADDED) === -1} />
                <NotificationPreferencePanel preferenceId={NOTIFICATION_GROUP_CODES.APPROVERS_ADDED} title={localized.approvers_added.title} description={localized.approvers_added.description} onChanged={handleOnPreferenceChanged} isChecked={notificationPreferencesOptedOut.indexOf(NOTIFICATION_GROUP_CODES.APPROVERS_ADDED) === -1} />
                <NotificationPreferencePanel preferenceId={NOTIFICATION_GROUP_CODES.COMMENTS_REPLIES} title={localized.comments_replies.title} description={localized.comments_replies.description} onChanged={handleOnPreferenceChanged} isLast={true} isChecked={notificationPreferencesOptedOut.indexOf(NOTIFICATION_GROUP_CODES.COMMENTS_REPLIES) === -1} />
                <div className="button-container">
                    <Button variant="link" data-testid="button-container__opt-out-all" className="button-container__opt-out-all" onClick={handleOptOutAll}>
                        {localized.buttons.opt_out_all}
                    </Button>
                </div>
                <div className="button-container">
                    <Button data-testid='button-container__save' className="button-container__save" onClick={handleSave}>
                        {localized.buttons.save}
                    </Button>
                </div>
            </PageContent>
        </RingiPage>
    );
};
