import React from 'react';
import { LocalLongDateTime, LocalTimeZoneAbbr } from 'services/localization/dateTimeService';
import 'pages/Ringis/components/HistoryLogCard.scss';

export default function HistoryLogCard({ index, historyLog }) {

    return (
        <>
            <div data-testid={`history-log-card-${index}`} className="history-log-card">
                <div data-testid="history-log-card__wrapper" className="history-log-card__wrapper">
                    <span data-testid="history-log-card__date" className="history-log-card__date">
                        {LocalLongDateTime(historyLog.dateText)} {LocalTimeZoneAbbr}
                    </span>
                    <span data-testid="history-log-card__text" className="history-log-card__text">
                        {historyLog.logText}
                    </span>
                </div>
            </div>
        </>
    );
}
