const RESET_RESPONSE = 'reset';

const ROUTES = {
    CURRENT_RINGIS_PAGE: `/ringis/current`,
    RINGI_REPORTS_PAGE: `/reports`,
    DRAFT_RINGIS_PAGE: `/ringis/drafts`,
    SUBMITTED_RINGIS_PAGE: `/ringis/submitted`,
    RINGIS_TO_APPROVE_PAGE: `/ringis/toApprove`,
    RINGIS_TO_READ_PAGE: `/ringis/toRead`,
    ARCHIVED_RINGIS_PAGE: `/ringis/archived`,
    NEW_RINGIS: `/ringis/new`,
    COPY_RINGI_PAGE: `/ringis/copy/:copyFromRingiId`,    
    DRAFT_RINGI_PAGE: `/ringis/draft/:ringiId`,
    VIEW_RINGI_PAGE: `/ringis/:ringiId`,
    NOTIFICATIONS_PAGE: `/notifications`,
    NOTIFICATION_PREFERENCES_PAGE: `/notifications/preferences`,
    NOTIFICATION_PREFERENCES: `notifications/preferences`,
    RINGI_FILTERS_UPDATE: `ringiFilters/update`,
    RINGI_COLUMNS_UPDATE: `ringiColumns/update`,
    CURRENT_USER_LANDING_PAGE: `users/me/landing`
}

const DEFAULT_LANDING_PAGE = ROUTES.CURRENT_RINGIS_PAGE;

const NOTIFICATION_GROUP_CODES = {
    ADMIN: 'admin',
    APPROVALS: 'approvals',
    DENIALS: 'denials',
    PENDING: 'pending',
    READERS_ADDED: 'readers_added',
    APPROVERS_ADDED: 'approvers_added',
    COMMENTS_REPLIES: 'comments_replies',
    TRANSFERRED_JAPAN: 'transferred_japan',
    NO_OPT_OUT: 'no_opt_out'
}

export {
    DEFAULT_LANDING_PAGE,
    RESET_RESPONSE,
    ROUTES,
    NOTIFICATION_GROUP_CODES
}