import React, { useState, useEffect, useCallback } from 'react';
import { useLocale } from 'services/localization/localizationContextProvider';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { fetch, post } from 'services/api';
import RingiPage, { PageSidebar, PageContent, PageHeader } from 'controls/RingiPage/RingiPage';
import DivisionList from './components/DivisionList';
import DivisionEditor from './components/DivisionEditor';
import DivisionEditCard from './components/DivisionEditCard';
import ToggleSwitch from 'controls/ToggleSwitch/ToggleSwitch';
import 'pages/Division/DivisionPage.scss';

const DivisionPage = () => {
    const { divisionId } = useParams();
    const localizedDivisionPage = useLocale().pages.division;
    const [division, setDivision] = useState();
    const history = useHistory();
    const [showNewDivisionForm, setShowNewDivisionForm] = useState(false);
    const [refreshDivisionList, setRefreshDivisionList] = useState(false);
    const [showDisabledDivisions, setShowDisabledDivisions] = useState(false);

    const fetchDivision = useCallback(async () => {
        const division = await fetch(`headquarters/division/${divisionId}`);

        setDivision(division);

    }, [divisionId]);

    useEffect(function fetchDivisionOnLoad() {
        divisionId ? fetchDivision() : setDivision();
    }, [divisionId, fetchDivision]);

    const handleAddNewDivision = () => {
        setShowNewDivisionForm(true);
    }

    const handleDivisionSave = async (newDivisionName, classificationId) => {
        const newDivisionId = await post(`headquarters/divisions`, JSON.stringify({ name: newDivisionName, classificationId: classificationId }))
        if (newDivisionId != null) {
            setRefreshDivisionList(!refreshDivisionList);
            history.push(`/admin/division/${newDivisionId}`);
        }
    }

    const onSwitch = async (state) => {
        history.push(`/admin/division`);
        setShowDisabledDivisions(state);
    }

    return (
        <RingiPage>
            <PageSidebar primaryMobileContent={divisionId == null}>
                <div className="division-header" data-testid="divisionSidebarHeader">
                    <div className="division-header__header">
                        <Button className="division-list__add-new-division"
                            onClick={handleAddNewDivision}
                            disabled={showNewDivisionForm || showDisabledDivisions}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                        <ToggleSwitch label={localizedDivisionPage.toggle_view_disabled_divisions} onSwitch={onSwitch} />
                    </div>
                    {showNewDivisionForm &&
                        <DivisionEditCard onSave={handleDivisionSave} onClose={() => setShowNewDivisionForm(false)} />
                    }
                </div>
                <DivisionList refreshTrigger={refreshDivisionList} showDisabledDivisions={showDisabledDivisions} />
                <div className="division-page__footer">
                    <Button
                        onClick={handleAddNewDivision}
                        className="division-page_add-division-page-button--large"
                        disabled={showNewDivisionForm || showDisabledDivisions}
                        data-testid="add-division-page-button-large">
                        <FontAwesomeIcon icon={faPlus} /> Add Division {/*TODO: localize this*/}
                    </Button>
                </div>
            </PageSidebar>
            <PageContent primaryMobileContent={divisionId != null}>
                {divisionId === undefined &&
                    <span data-testid="select-division-message" className="division-page__content">{localizedDivisionPage.select_division_message}</span>
                }
                {division &&
                    <>
                        <PageHeader title={localizedDivisionPage.division_management_title} goBackTo="/admin/division"></PageHeader>
                        <DivisionEditor division={division} />
                    </>
                }
            </PageContent>
        </RingiPage>
    );
};

export default DivisionPage;