import React, { useState, useCallback, useEffect } from 'react';
import { fetch, post, httpDelete } from 'services/api';
import { useLocale } from 'services/localization/localizationContextProvider';
import TypeAdditionalUsersEditor from './TypeAdditionalUsersEditor';

export default function TypeAdditionalReadersEditor({ typeLayerId }) {
    const {
        configuration_page: localizedConfigurationPage,
        typeahead_messages: localizedTypeaheadMessages,
        buttons: localizedButtons,
        validation_messages: localizedValidationMessages
    } = useLocale();

    const localization = {
        section_title: localizedConfigurationPage.type_editor.additional_reader_section_title,
        editor_title: localizedConfigurationPage.type_editor.additional_reader_editor_title,
        editor_open_button: localizedConfigurationPage.type_editor.additional_reader_add_button,
        no_users_available: localizedTypeaheadMessages.no_readers_available,
        add_button: localizedButtons.add,
        duplicate_additional_user: localizedValidationMessages.duplicate_additional_user
    };

    const [selectedAdditionalReaders, setSelectedAdditionalUsers] = useState([]);

    const fetchSelectedAdditionalReaders = useCallback(async () => {
        const selectedAdditionalReaders = await fetch(`layers/${typeLayerId}/readers`);
        setSelectedAdditionalUsers(selectedAdditionalReaders);
    }, [typeLayerId]);

    const onAdditionalReaderSubmit = useCallback(async (readerId) => {
        var response = await post(`layers/${typeLayerId}/readers/${readerId}`);
        if(response != null){
            fetchSelectedAdditionalReaders();
        }
        return response;
    }, [fetchSelectedAdditionalReaders, typeLayerId]);

    const onAdditionalReaderRemove = useCallback(async (readerId) => {
        var response = await httpDelete(`layers/${typeLayerId}/readers/${readerId}`);
        if(response != null){
            fetchSelectedAdditionalReaders();
        }
        return response;
    }, [fetchSelectedAdditionalReaders, typeLayerId]);

    useEffect(() => {
        fetchSelectedAdditionalReaders();
    }, [fetchSelectedAdditionalReaders]);

    return (
        <TypeAdditionalUsersEditor
            selectedAdditionalUsers={selectedAdditionalReaders}
            postUser={onAdditionalReaderSubmit}
            deleteUser={onAdditionalReaderRemove}
            layerId={typeLayerId}
            localization={localization}
        />
    );
}