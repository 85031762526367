import React, { useEffect, useState } from 'react';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import Container from 'react-bootstrap/Container';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { requiredRule } from 'services/validationRules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import 'controls/ValidationOptionList/ValidationOptionList.scss';

export default function ValidationOptionList({ fieldName, onDefaultOptionIndexChanged }) {
    const { unregister, setValue, getValues } = useFormContext();
    const [theOptions, setTheOptions] = useState([]);
    const [defaultOptionIndex, setDefaultOptionIndex] = useState([]);

    useEffect(() => {
        const formData = getValues();
        let idx = -1;
        let options = ['', ''];

        if (formData.options) {
            idx = formData.defaultOptionIndex
            options = formData.options.split(";");
        } else {
            setValue('options', ';');
            setValue('defaultOptionIndex', -1);
        }

        for (let i = 0; i < options.length; i++) {
            setValue(`${fieldName}-option-${i}`, options[i]);
        }

        setTheOptions(options);
        setDefaultOptionIndex(idx);
    }, [getValues, setValue, fieldName]);

    const removeOption = (optionIndex) => {
        if (defaultOptionIndex === optionIndex) {
            setDefaultOptionIndex(-1);
            onDefaultOptionIndexChanged(-1);
        }

        for (let i = 0; i < theOptions.length; i++) {
            unregister(`${fieldName}-option-${i}`);
        }

        const newOptions = [...theOptions];
        newOptions.splice(optionIndex, 1);
        setTheOptions(newOptions);

        setValue('options', newOptions.join(';'));

        for (let i = 0; i < newOptions.length; i++) {
            setValue(`${fieldName}-option-${i}`, newOptions[i]);
        }
    };

    const addOption = () => {
        const newOptions = [...theOptions];
        newOptions.push("");
        setTheOptions(newOptions);
        setValue('options', newOptions.join(';'));
    };

    const setDefaultOption = (optionIndex) => {
        setDefaultOptionIndex(optionIndex)
        onDefaultOptionIndexChanged(optionIndex);
    };

    const optionClicked = (optionIndex) => {
        if (defaultOptionIndex === optionIndex) {
            setDefaultOptionIndex(-1)
        }
        onDefaultOptionIndexChanged(-1);
    };

    return (
        <div data-testid="option-list-wrapper">
            <Container>
                {theOptions?.map((option, i) => {
                    return (
                        <div key={i}>
                            <Row data-testid="option-list-header-row">
                                <Col className="option-list-wrapper__col">
                                    Option{i > 1 ? '' : '*'}
                                </Col>
                                <Col className="option-list-wrapper__col" xs={8}>
                                    <input type="radio" data-testid={`${fieldName}-option-${i}`} id={`${fieldName}-option-${i}`} name={`${fieldName}`} checked={i === defaultOptionIndex} onChange={() => setDefaultOption(i)} onClick={() => optionClicked(i)} /> Set as Default Selection
                                </Col>
                            </Row>
                            <Row data-testid="option-list-option-row">
                                <Col className="option-list-wrapper__input">
                                    <ValidationInput
                                        type="text"
                                        fieldName={`${fieldName}-option-${i}`}
                                        validationRules={{
                                            required: requiredRule
                                        }}
                                    />
                                </Col>
                                <Col className={classnames("option-list-wrapper__col", { "option-list-wrapper__col-hidden": i < 2 })} xs={1}>
                                    <span data-testid={`${fieldName}-remove-option-${i}`} className="option-list-wrapper__x" onClick={() => removeOption(i)}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    )
                })}
                <Row className="option-list-wrapper__button-row">
                    <Button data-testid={`${fieldName}-add-option`} className="new-option-button" variant="primary" onClick={addOption} disabled={theOptions.length > 9}>
                        + Add Option
                    </Button>
                </Row>
            </Container>
        </div>
    );
}