import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faAngleDown, faCog, faExclamationTriangle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useLocale } from 'services/localization/localizationContextProvider';
import { useAuthorizationClaims } from 'providers/authorizationClaimsProvider';
import { useScreenDetect } from 'hooks/useScreenDetect';
import { ROUTES } from 'constants/constants';
import classNames from 'classnames';
import OutsideClickHandler from 'hooks/useOutsideClickHandler';
import { UserProfileNotification } from 'controls/NavMenu/components/UserProfile/UserProfileNotification';
import { fetch } from 'services/api';
import './UserProfile.scss';

const UserProfile = () => {
    const { oktaAuth } = useOktaAuth();
    const localized = useLocale();
    const [slideoutOpen, setSlideoutOpen] = useState(false);
    const { isDesktop, isMobile } = useScreenDetect();
    const history = useHistory();

    const handleLogout = async (event) => {
        event.preventDefault();
        await oktaAuth.signOut("/");
    };

    const handleNotificationPreferences = async (event) => {
        history.push(ROUTES.NOTIFICATION_PREFERENCES_PAGE);
    };

    const { userName, userTitle } = useAuthorizationClaims();
    const showTitle = userTitle && userTitle.trim().length > 0 ? true : false;

    const handleReportAppIssue = async (event) => {
        const reportAppIssueConfig = await fetch('notifications/reportAppIssueEmailSettings');
        var ccEmailInfo = reportAppIssueConfig?.emailCc ? `cc=${reportAppIssueConfig?.emailCc}&` : '';
        
        window.open(`mailto:${reportAppIssueConfig?.emailTo}?${ccEmailInfo}subject=${localized.report_app_issue.subject}&body=${localized.report_app_issue.body}`);
    };

    const toggleSlideoutOpenClosed = () => {
        setSlideoutOpen(!slideoutOpen);
    }

    return (
        <div className={classNames("user-profile-container", { "user-profile-container-mobile": isMobile })}>
            {isDesktop &&
                <UserProfileNotification />
            }
            <div className={classNames({ "user-profile-mobile-container": isMobile })}>
                <OutsideClickHandler returnFunc={() => setSlideoutOpen(false)}>
                    <>
                        <div className={classNames("user-profile", { "user-profile-mobile": isMobile })} onClick={toggleSlideoutOpenClosed}>
                            <div className={classNames("user-profile--sections", { "user-profile--sections-mobile": isMobile })} >
                                <div className="user-profile__avatar">
                                    <FontAwesomeIcon icon={faUser} className="user-profile-avatar__icon" />
                                </div>
                                <div className="user-profile__user-info">
                                    <div className={classNames("user-profile__name", { "user-profile__name--fullHeight": showTitle === false })}>{userName}</div>
                                    {{ showTitle } &&
                                        <div className="user-profile__position">{userTitle}</div>
                                    }
                                </div>
                                {isDesktop &&
                                    <div className="user-profile__slideout-actuator">
                                        <FontAwesomeIcon
                                            className={classNames("user-profile-slideout-actuator__caret", { "user-profile-slideout-actuator__caret--rotated": slideoutOpen })}
                                            icon={faAngleDown} />

                                    </div>
                                }
                            </div>
                            {isDesktop &&
                                <div className={classNames("user-profile__slideout", { "user-profile__slideout--visible": slideoutOpen })}>
                                    <div className="user-profile-slideout__row">
                                        <Button variant="link" className="user-profile-slideout__link" onClick={handleNotificationPreferences}>
                                            <FontAwesomeIcon
                                                className="user-profile-slideout__icon"
                                                icon={faCog} />
                                            {localized.nav_menu_links.notification_preferences}
                                        </Button>
                                    </div>
                                    <div className="user-profile-slideout__divider" />
                                    <div className="user-profile-slideout__row">
                                        <Button variant="link" className="user-profile-slideout__link" onClick={handleReportAppIssue}>
                                            <FontAwesomeIcon
                                                className="user-profile-slideout__icon"
                                                icon={faExclamationTriangle} />
                                            {localized.nav_menu_links.report_app_issue}
                                        </Button>
                                    </div>
                                    <div className="user-profile-slideout__divider" />
                                    <div className="user-profile-slideout__row">
                                        <Button variant="link" className="user-profile-slideout__link" onClick={handleLogout}>
                                            <FontAwesomeIcon
                                                className="user-profile-slideout__icon"
                                                icon={faSignOutAlt} />
                                            {localized.nav_menu_links.logout}
                                        </Button>
                                    </div>
                                    <div className="user-profile-slideout__divider" />
                                    <div className="user-profile-slideout__version">{`Version: ${process.ringiEnv.REACT_APP_VERSION}`}</div>
                                </div>
                            }
                        </div>
                    </>
                </OutsideClickHandler>
            </div>
        </div>
    );
}

export { UserProfile }