import React, { useContext } from "react";
import LocalizedStrings from 'react-localization';
import { en } from './langs/supportedLanguages'

const LocalizationContext = React.createContext();

const LocalizationContextProvider = props => {
    return (
        <LocalizationContext.Provider value={ strings }>
            {props.children}
        </LocalizationContext.Provider>
    )
}

const formatLocalized = (...args) => strings.formatString(...args);
const getDBLocalizationValue = (key) => {
    return strings.database_localization_keys[key];
}

const getLocaleLanguage = () => {
    const langCode = navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;

    switch (langCode) {
        case 'en-US':
            return { en };
        case 'ja-JP':
            // ToDo: Change this to return the Japanese language object when it's available
            return { en };
        default:
            return { en };
    }
}

const strings = new LocalizedStrings(getLocaleLanguage());
const useLocale = () => useContext(LocalizationContext);

export { LocalizationContextProvider, useLocale, formatLocalized, getDBLocalizationValue };