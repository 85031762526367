import React, { useState, useEffect } from 'react';
import TooltipDescription from 'components/TooltipDescription/TooltipDescription';
import 'pages/Ringis/components/RingiFormattedTextField.scss';

const RingiFormattedTextField = ({ ringiField }) => {
    const [ringiFieldValue, setRingiFieldValue] = useState();

    useEffect(() => {
        let rows = ringiField.value?.split(/\r\n|\r|\n/).length
        if (ringiField.value?.indexOf('\n') === -1 && ringiField.value?.length > 140) {
            rows = 4;  //Allow for scrolling
        }

        const ringiFieldValueProps = {
            className: "ringi-field__value-formatted"
        };
        ringiFieldValueProps['data-testid'] = `${ringiField.fieldName}-value`;
        ringiFieldValueProps['rows'] = rows;
        ringiFieldValueProps['value'] = ringiField.value
        ringiFieldValueProps['readOnly'] = 'readOnly'

        setRingiFieldValue(<textarea {...ringiFieldValueProps} ></textarea>);
    }, [ringiField]);

    return (
        <div className="ringi-field">
            {ringiField.fieldName !== '' &&
                <span data-testid="ringi-field-name" className="ringi-field__name">{ringiField.fieldName}</span>
            }
            {ringiField.showTooltip === true && <TooltipDescription tooltipName={`${ringiField.fieldName}-tooltip`} description={ringiField.tooltipDescription} />}
            <div>
                {ringiFieldValue}
            </div>
            <div className='display-contents-on-print'>
                <p>{ringiField.value}</p>
            </div>
        </div>
    );
}

export default RingiFormattedTextField;