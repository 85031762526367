import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { put } from 'services/api';
import { useLocale } from 'services/localization/localizationContextProvider';
import ValidationTypeahead from 'controls/ValidationTypeahead/ValidationTypeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { requiredRule } from 'services/validationRules';
import { existsRule, inactiveRule } from 'services/serverValidationRules';
import { useSubmittedRingi } from 'pages/Ringis/providers/submittedRingiContextProvider';
import 'pages/Ringis/components/Readers/ReaderMenu.scss';

export default function ReaderMenu() {
    const methods = useForm({ reValidateMode: 'onSubmit' });
    const watchReaderSelection = methods.watch("readerUserId");
    const {
        pages: {
            view_ringi: {
                readers: readersLocalization
            }
        },
        typeahead_messages: typeaheadMessagesLocalization
    } = useLocale();
    const { ringi, refreshReaders, approverAndReaderCandidates, addReaderLockedDown } = useSubmittedRingi();

    const addReader = async (formData) => {
        const response = await put(`ringis/${ringi.id}/readers/${formData.readerUserId}`);
        if (response != null) {
            await refreshReaders();
        }
        methods.reset();
    } 

    return (
        <>
            {!addReaderLockedDown &&
                <div className="reader-menu__button-bar">
                    <FormProvider {...methods}>
                        <form data-testid="add-reader-form" onSubmit={methods.handleSubmit(addReader)} className="add-reader">
                            <div className="add-reader-bar__reader-container">
                                <ValidationTypeahead
                                    fieldName="readerUserId"
                                    validationRules={{
                                        required: requiredRule,
                                        validate: {
                                            alreadyReader: async (readerUserId) =>
                                                await existsRule(`ringis/${ringi.id}/readers/${readerUserId}`, readersLocalization.add_reader_exists_as_reader_message),
                                            alreadyApprover: async (readerUserId) =>
                                                await existsRule(`ringis/${ringi.id}/approvers/${readerUserId}`, readersLocalization.add_reader_exists_as_approver_message),
                                            readerIsNotOwner: (readerUserId) => { if (readerUserId === ringi.createdByUserId) return readersLocalization.add_reader_is_creator_message },
                                            inactiveUser: async (readerUserId) => await inactiveRule(`users/${readerUserId}/active`)
                                        }
                                    }}
                                    options={approverAndReaderCandidates}
                                    dropup={true}
                                    labelKey={option => `${option.firstName} ${option.lastName}, ${option.emailAddress}`}
                                    filterBy={["firstName", "lastName", "emailAddress"]}
                                    placeholder={readersLocalization.add_reader_placeholder}
                                    emptyLabel={typeaheadMessagesLocalization.no_approvers_available} />
                                <div className="add-reader-bar__add-reader">
                                    <Button type="submit" data-testid="add-reader-button" className="add-reader-button" disabled={!watchReaderSelection}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            }
        </>
    );
}