import React, { useEffect } from 'react';
import { useSubmittedRingi } from 'pages/Ringis/providers/submittedRingiContextProvider';
import { useLocale } from 'services/localization/localizationContextProvider';
import HistoryLogCard from 'pages/Ringis/components/HistoryLogCard';
import 'pages/Ringis/components/HistoryLogList.scss';

export default function HistoryLogList({ ringiId }) {
    const { approvers, readers, historyLogs, refreshHistoryLogs } = useSubmittedRingi();

    const { pages: {
        view_ringi: {
            history_logs: historyLogsLocalization
        }
    }
    } = useLocale();

    useEffect(() => {
        refreshHistoryLogs();
    }, [ringiId, approvers, readers, refreshHistoryLogs]);

    return (
        <>
            <div data-testid="history-log-list" className="history-log-list__logs">
                {historyLogs.length === 0 &&
                    <div data-testid="no-history-logs-message" className="history-log-list__no-logs-message">{historyLogsLocalization.no_history_logs_message}</div>
                }
                {historyLogs.map((historyLog, i) => (
                    <HistoryLogCard key={i}
                        index={i}
                        historyLog={historyLog}
                    />
                ))}
            </div>
        </>
    );
}
