import React from 'react'
import { useLocale, formatLocalized } from 'services/localization/localizationContextProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useModal, ModalBody, ModalButtons, ModalOkButton, ModalCancelButton } from 'controls/ActionModal/ActionModal';
import 'pages/Approval/components/ApproverTab.scss';

export default function ApproverTab({ approver, onDelete }) {
    const localizedDeleteApproverConfirmation = useLocale().modals.confirmation.approver.deleting;
    const [DeleteApproverConfirmationModal, showDeleteApproverConfirmationModal] = useModal();

    const deleteApprover = () => {
        onDelete(approver.id);
    }

    return (
        <>
            <div className="approver-tab">
                <div className="approver-tab__form">
                    <span data-testid="approver-tab-title">
                        {approver.title}
                    </span>
                    <span data-testid="approver-tab-description" className="approver-tab-form__description">
                        {approver.description}
                    </span>
                </div>
                <div className="approver-tab__icons">
                    <FontAwesomeIcon data-testid="approver-tab-delete" className="approver-tab__delete" icon={faTimes} onClick={showDeleteApproverConfirmationModal} />
                </div>
            </div>

            <DeleteApproverConfirmationModal variant='confirmation' title={localizedDeleteApproverConfirmation.title}>
                <ModalBody>{formatLocalized(localizedDeleteApproverConfirmation.message, { approverCardTitle: approver.title })}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton />
                    <ModalOkButton text={localizedDeleteApproverConfirmation.success} onClick={deleteApprover} />
                </ModalButtons>
            </DeleteApproverConfirmationModal>
        </>
    );
}