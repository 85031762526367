import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useLocale, getDBLocalizationValue } from 'services/localization/localizationContextProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faEnvelope, faPlus, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { fetch } from 'services/api';
import ValidationSelect from 'controls/ValidationSelect/ValidationSelect';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import ErrorIcon from 'components/ErrorIcon/ErrorIcon';
import 'pages/Ringis/components/Approvers/ApproverList.scss';

export default function SendToJapanEmailSelection({defaultValue, currentEmailAddresses}) {

    const sendToJapanLocalization = useLocale().modals.confirmation.adminReview.sendToJapan;
    const [selectedSendToJapanDivisionId, setSelectedSendToJapanDivisionId] = useState("");
    const [selectedEmailAddresses, setSelectedEmailAddresses] = useState([]);
    const [validSendToJapanDomains, setValidSendToJapanDomains] = useState("");
    const [divisionOptions, setDivisionOptions] = useState([]);
    const [errorIconMessage, setErrorIconMessage] = useState("");
    const [addSendToJapanEmailAddress, setAddSendToJapanEmailAddress] = useState("");

    const fetchAllDataOnLoad = useCallback(async () => {
        const populateHeadquarterDivision = (headquarterDivisions) => {
            const localizedHeadquarterDivisions = headquarterDivisions.map(headquarterDivision => ({
                ...headquarterDivision, id: headquarterDivision.divisionId, name: headquarterDivision.name
            }));

            setDivisionOptions(localizedHeadquarterDivisions);
        };

        populateHeadquarterDivision(await fetch(`headquarters/divisionsWithEmailRecipients`));
        setValidSendToJapanDomains(await fetch(`headquarters/sendToJapanEmailDomains`));
    }, []);

    useEffect(() => {
        fetchAllDataOnLoad();
    }, [fetchAllDataOnLoad]);

    useEffect(() => {
        currentEmailAddresses.current = selectedEmailAddresses;
    }, [selectedEmailAddresses, currentEmailAddresses]);

    const onDivisionSelection = useCallback((divisionId) => {
        if (divisionId) {
            const division = divisionOptions.find(divisionOption => divisionOption.id === parseInt(divisionId));
            if (division) {
                setSelectedEmailAddresses(division.emailRecipientAddresses);
                setSelectedSendToJapanDivisionId(divisionId);
            }
        }
        else{
            setSelectedSendToJapanDivisionId("");
            setSelectedEmailAddresses([]);
        }
    }, [divisionOptions]);
        
    useEffect(() => {
        onDivisionSelection(defaultValue);
    }, [defaultValue, onDivisionSelection]);

    const removeSendToJapanEmailAddress = (emailAddress) => {
        setSelectedEmailAddresses(selectedEmailAddresses.filter(x => { return x !== emailAddress; }));
        if (selectedEmailAddresses.length === 1) {
            setSelectedSendToJapanDivisionId("");
        }
    }

    const onAddSendToJapanEmailAddressClick = () => {
        const validateEmailMessage = validateEmail(addSendToJapanEmailAddress);
        setErrorIconMessage(validateEmailMessage);
        if (validateEmailMessage === "") {
            if (!selectedEmailAddresses.find(em => em.toLowerCase() === addSendToJapanEmailAddress.toLowerCase())) {
                setSelectedEmailAddresses([...selectedEmailAddresses, addSendToJapanEmailAddress]);
            }
            setAddSendToJapanEmailAddress("");
        }
    }

    const validateEmail = (emailAddress) => {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)) {
            return sendToJapanLocalization.invalidEmailAddressFormatMessage;
        }

        if (validSendToJapanDomains !== "") {
            const domain = emailAddress.substr(emailAddress.indexOf("@") + 1);
            if (`;${validSendToJapanDomains.toLowerCase()};`.indexOf(`;${domain.toLowerCase()};`) === -1) {
                return sendToJapanLocalization.invalidEmailAddressDomainMessage;
            }
        }

        return "";
    }
    
    return(
    <>
        <div className="send-to-japan-modal__header">
            {sendToJapanLocalization.message}
        </div>
        <div className="send-to-japan-modal__sub-header">
            {sendToJapanLocalization.subMessage}
        </div>
        <div data-testid="send-to-japan__dropdown" className="send-to-japan-modal__division-select">
            <ValidationSelect
                title={sendToJapanLocalization.dropdownTitle}
                fieldName="selectedDivisionId"
                onChange={(e) => onDivisionSelection(e.target.value)}
                value={selectedSendToJapanDivisionId}
                validationRules={{
                    required: false
                }}
                options={[{ id: "", name: sendToJapanLocalization.dropdownDefaultText }, ...divisionOptions]} />
        </div>
        <div className="send-to-japan-modal__selected-emails">
            {selectedEmailAddresses?.map((emailAddress, i) => {
                return (
                    <div key={i} className="send-to-japan-modal__selected-emails-container">
                        <FontAwesomeIcon
                            className="send-to-japan-modal__selected-emails-email-icon"
                            icon={faEnvelope} size="1x" />
                        <span className="send-to-japan-modal__selected-emails-email">{emailAddress}</span>
                        <FontAwesomeIcon
                            className="send-to-japan-modal__selected-emails-remove-icon"
                            icon={faTimesCircle} size="1x"
                            onClick={() => { removeSendToJapanEmailAddress(emailAddress); } } />
                    </div>
                );
            })}
        </div>
        <div className="send-to-japan-modal__add-email-container">
            <div className="send-to-japan-modal__add-email-field-group">
                <label htmlFor="addSendToJapanEmailAddress" className="send-to-japan-modal__add-email-label">{sendToJapanLocalization.addEmailAddressLabel}</label>
                <ValidationInput
                    type="text"
                    fieldName="addSendToJapanEmailAddress"
                    className="send-to-japan-modal__add-email-input"
                    value={addSendToJapanEmailAddress}
                    onChange={(e) => setAddSendToJapanEmailAddress(e.target.value)} />
                    {errorIconMessage &&
                        <ErrorIcon className={'send-to-japan-modal__error'} errorIconName={'error-icon-emailAddress'} errorMessage={errorIconMessage} inline={true} />
                    }
            </div>
            <div className="send-to-japan-modal__add-email-button">
                <Button onClick={onAddSendToJapanEmailAddressClick}>
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />{sendToJapanLocalization.addEmailAddressButton}
                </Button>
            </div>
        </div>
    </>
)}