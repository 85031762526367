import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import 'components/ErrorIcon/ErrorIcon.scss';
import classnames from 'classnames';

const ErrorIcon = ({ errorIconName, className, errorMessage, inline, inverseColor }) => {
    const toolTipId = "tt-" + errorIconName;
    return (
        <>
            {inline ?
                <span id={errorIconName} data-testid={errorIconName} className={className}>
                    <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip id={toolTipId} className="error-tooltip">{errorMessage}</Tooltip>}>
                        <span>
                            <FontAwesomeIcon data-testid="error-icon" icon={faExclamationTriangle} color="red" size="1x" className="error-icon" />
                        </span>
                    </OverlayTrigger>
                </span>
                :
                <div id={errorIconName} data-testid={errorIconName} className={className}>
                    <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip id={toolTipId} className="error-tooltip">{errorMessage}</Tooltip>}>
                        <span>
                            <FontAwesomeIcon data-testid="error-icon" icon={faExclamationTriangle} color="red" size="1x" className="error-icon" />
                        </span>
                    </OverlayTrigger>
                </div>
            }
        </>
    );
}

export default ErrorIcon;
