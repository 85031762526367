
import React from "react";
import TemplateEditor from 'pages/Configuration/Editors/TemplateEditor';
import { useBaseRingiTemplate } from 'pages/Configuration/providers/templateContextProvider';

export default function () {
    const baseRingiTemplate = useBaseRingiTemplate();

    return (

        <>
            <TemplateEditor name="Base Fields" layerId={baseRingiTemplate.layerId} showDisabledFields={baseRingiTemplate.showDisabledFields}
                    templateFields={baseRingiTemplate.templateFields} reload={baseRingiTemplate.reload} />
        </>
    );
}