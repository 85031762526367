import { DateTime } from 'luxon'

const invalidDateIndicator = '(!)';
const allowedDateExpressions = ['--'];
const LocalTimeZoneAbbr = DateTime.local().toFormat('ZZZZ');

const clientLocale =
    navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;

const isValidIso8601Format = (d) => {
    return d !== undefined && d.length === 20 && d.substring(10, 11) === 'T' && d.substring(19, 20) === 'Z';
}

const getInvalidDateReturnValue = (d) => {
    return d === undefined ? '' : (allowedDateExpressions.indexOf(d) >= 0 ? d : invalidDateIndicator);
}

const padDateElement = (e) => {
    return e.toString().padStart(2, '0');
}

const LocalDate = (utcDateTime) => {
    if (!isValidIso8601Format(utcDateTime)) {
        return getInvalidDateReturnValue(utcDateTime);
    }

    const localDateTime = new Date(utcDateTime);

    let month = padDateElement(localDateTime.getMonth() + 1);
    let day = padDateElement(localDateTime.getDate());
    let year = localDateTime.getFullYear();

    switch (clientLocale) {
        case 'ja-JP':
            return `${year}/${month}/${day}`;
        case 'en-US':
        default:
            return `${month}/${day}/${year}`;
    }
}

const LocalDateInputFormat = (date) => {
    if (date.indexOf('-') > 0) {
        return date;
    }

    const localDateTime = new Date(date);

    let month = padDateElement(localDateTime.getMonth() + 1);
    let day = padDateElement(localDateTime.getDate());
    let year = localDateTime.getFullYear();

    return `${year}-${month}-${day}`;
}

const LocalDateRangeStartAddUtcOffset = (dateString) => {
    const dateParts = dateString.split("-");

    if (dateParts.length !== 3) {
        return dateString;
    }

    const year = Number(dateParts[0]);
    const month = Number(dateParts[1]);
    const day = Number(dateParts[2]);

    const tzOffsetMins = (new Date()).getTimezoneOffset();
    const dtLocal = DateTime.local(year, month, day, 0, 0, 0);
    const dtOffset = dtLocal.plus({ minutes: tzOffsetMins });

    return dtOffset.toFormat('yyyy-MM-dd hh:mm:ss');
}

const LocalDateRangeEndAddUtcOffset = (dateString) => {
    const dateParts = dateString.split("-");

    if (dateParts.length !== 3) {
        return dateString;
    }

    const year = Number(dateParts[0]);
    const month = Number(dateParts[1]);
    const day = Number(dateParts[2]);

    const tzOffsetMins = (new Date()).getTimezoneOffset();
    const dtLocal = DateTime.local(year, month, day, 0, 0, 0).plus({ days: 1 });
    const dtOffset = dtLocal.plus({ minutes: tzOffsetMins });

    return dtOffset.toFormat('yyyy-MM-dd hh:mm:ss');
}

const LocalLongDateTime = (utcDateTime) => {
    if (!isValidIso8601Format(utcDateTime)) {
        return getInvalidDateReturnValue(utcDateTime);
    }

    const localDateTime = new Date(utcDateTime);

    let month = padDateElement(localDateTime.getMonth() + 1);
    let day = padDateElement(localDateTime.getDate());
    let year = localDateTime.getFullYear();
    let hours = localDateTime.getHours();
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = padDateElement(hours);
    let mins = padDateElement(localDateTime.getMinutes());
    let secs = padDateElement(localDateTime.getSeconds());
    let ampm = localDateTime.getHours() >= 12 ? 'PM' : 'AM';

    switch (clientLocale) {
        case 'ja-JP':
            return `${year}/${month}/${day} ${hours}:${mins} ${ampm}`;
        case 'en-US':
        default:
            return `${month}/${day}/${year} ${hours}:${mins} ${ampm}`;
    }
}

export { LocalDate, LocalLongDateTime, LocalTimeZoneAbbr, LocalDateInputFormat, LocalDateRangeStartAddUtcOffset, LocalDateRangeEndAddUtcOffset };
