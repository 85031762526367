import React, { useEffect } from 'react';
import { useLocale } from 'services/localization/localizationContextProvider';
import { useModal, ModalBody, ModalButtons, ModalOkButton, ModalCancelButton } from 'controls/ActionModal/ActionModal';

export default function RouterPrompt({ message, handleLeave, shouldShow }) {
    const localizedNavigateAway = useLocale().modals.confirmation.navigateAway;
    const [RouterPromptModal, showRouterPromptModal, hideRouterPromptModal] = useModal();

    useEffect(() => {
        if (shouldShow) {
            showRouterPromptModal();
        }
        else {
            hideRouterPromptModal();
        }
    }, [shouldShow, showRouterPromptModal, hideRouterPromptModal]);

    return (
        <RouterPromptModal variant='confirmation' title={localizedNavigateAway.title} onHide={handleLeave}>
            <ModalBody>{message}</ModalBody>
            <ModalButtons>
                <ModalCancelButton text={localizedNavigateAway.cancel} onClick={() => handleLeave(false)} />
                <ModalOkButton text={localizedNavigateAway.success} onClick={() => handleLeave(true)} hideIcon />
            </ModalButtons>
        </RouterPromptModal>
    );
}