import React, { useState, useCallback, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import classNames from 'classnames';
import { useLocale } from 'services/localization/localizationContextProvider';
import 'controls/ActionModal/ActionModal.scss';

const ModalContext = React.createContext();

const useModal = () => {
    const [show, setShow] = useState(false);
    const [internalDataToPassToSuccess, setInternalDataToPassToSuccess] = useState({});
    const openModal = useCallback((dataToPassToSuccess) => {
        setInternalDataToPassToSuccess(dataToPassToSuccess);
        setShow(true);
    }, []);
    const closeModal = useCallback(() => { setShow(false); }, []);

    const ModalComponent = ({ variant, title, onHide, children }) => {
        return (
            <ModalContext.Provider value={{ closeModal: () => { setShow(false); }, dataToPassToSuccess: internalDataToPassToSuccess }}>
                <Modal
                    data-testid="modal"
                    className={`action-modal action-modal--${variant}`}
                    backdrop="static"
                    centered
                    onHide={() =>  {
                        if(typeof onHide === "function"){
                            onHide(false);
                        }    
                        setShow(false);
                    }}
                    show={show}>
                    {title &&
                        <Modal.Header data-testid="modal-header" className="action-modal__header" closeButton>
                            <Modal.Title data-testid="modal-title" className="action-modal-header__title">
                                {title}
                            </Modal.Title>
                        </Modal.Header>
                    }
                    {children}
                </Modal>
            </ModalContext.Provider>
        )
    };


    return [ModalComponent, openModal, closeModal];
}

const ModalForm = ({ children, onSubmit }) => {
    const methods = useForm({ reValidateMode: 'onSubmit' });

    return (
        <FormProvider {...methods}>
            <form data-testid="modal-form" onSubmit={methods.handleSubmit(onSubmit)}>
                {children}
            </form>
        </FormProvider>
    );
}

const ManualModalForm = ({ children, onSubmit, methods }) => {
    return (
        <FormProvider {...methods}>
            <form data-testid="modal-form" onSubmit={methods.handleSubmit(onSubmit)}>
                {children}
            </form>
        </FormProvider>
    );
}

const ModalBody = ({ children }) => {
    return (
        <Modal.Body data-testid="modal-body" className="action-modal__body">{children}</Modal.Body>
    );
}

const ModalSection = ({ id, children }) => {
    return (
        <div data-testid={`modal-section-${id}`} className="action-modal__section">{children}</div>
    );
}

const ModalButtons = ({ className, children }) => {
    return (
        <Modal.Footer data-testid="modal-footer" className={classNames("action-modal__footer", className)}>
            {children}
        </Modal.Footer>
    );
}

const ModalOkButton = ({ text, icon, onClick, hideIcon = false }) => {
    const modalContext = useContext(ModalContext);

    const buttonText = text ?? useLocale().buttons.ok;
    const buttonIcon = icon ?? faCheck;

    const onOkClick = async () => {
        if (typeof onClick === 'function') {
            await onClick(modalContext.dataToPassToSuccess);
        }

        modalContext.closeModal();
    }

    return (
        <Button data-testid={`modal-${buttonText}-button`} className="action-modal__button" onClick={onOkClick}>
            {!hideIcon && <FontAwesomeIcon data-testid={`modal-${buttonText}-button-icon`} icon={buttonIcon} className="action-modal-button__icon" />}
            {buttonText}
        </Button>
    );
}

const ModalSubmitButton = ({ children, text, disabled }) => {
    return (
        <Button data-testid='modal-submit-button' className="action-modal__button" type="submit" disabled={disabled === true}>
            {children}
            {text}
        </Button>
    );
}

const ModalCancelButton = ({ text, onClick }) => {
    const modalContext = useContext(ModalContext);

    const buttonText = text ?? useLocale().buttons.cancel;

    const onCancelClick = async () => {
        if (typeof onClick === 'function') {
            await onClick();
        }

        modalContext.closeModal();
    }

    return (
        <Button variant="link" data-testid={`modal-${buttonText}-button`} onClick={onCancelClick}>
            {buttonText}
        </Button>
    );
}

export { useModal, ModalForm, ManualModalForm, ModalBody, ModalSection, ModalButtons, ModalOkButton, ModalCancelButton, ModalSubmitButton };