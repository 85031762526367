import React, { useState, useEffect, useRef } from 'react';
import { Button, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCheck, faTimesCircle, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { put, post } from 'services/api';
import { requiredRule } from 'services/validationRules';
import { useModal, ModalForm, ModalBody, ModalButtons, ModalOkButton, ModalCancelButton, ModalSubmitButton } from 'controls/ActionModal/ActionModal';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import Authorized from 'components/Authorized';
import { useLocale } from 'services/localization/localizationContextProvider';
import { useAuthorizationClaims } from 'providers/authorizationClaimsProvider';
import { useSubmittedRingi } from 'pages/Ringis/providers/submittedRingiContextProvider';
import { APPROVER_RESPONSE } from 'constants/enums';
import { RESET_RESPONSE } from 'constants/constants';
import { LocalDate } from 'services/localization/dateTimeService';
import ApprovalModalContent from './ApprovalModalContent';
import 'pages/Ringis/components/Approvers/ApproverCard.scss';

export default function ApproverCard({ ringiApprover, isCurrentApprovalLevel, refreshComments, onDelete, onApprove, onDeny, proxyEnabled }) {
    const [statusColorModifier, setStatusColorModifier] = useState();
    const [canDeleteApprover, setCanDeleteApprover] = useState(false);
    const [isCurrentUser, setIsCurrentUser] = useState(false);
    const [cardDisabled, setCardDisabled] = useState(false);
    const [cardActive, setCardActive] = useState(false);
    const conditionDetails = useRef({
        isConditionalApproval: false,
        conditionText: ""
    });
    const [ApproveConfirmationModal, showApproveConfirmationModal, closeApproveConfirmationModal] = useModal();
    const [HeadquarterApprovalModal, showHeadquarterApprovalModal, closeHeadquarterApprovalModal] = useModal();
    const [DenyConfirmationModal, showDenyConfirmationModal, closeDenyConfirmationModal] = useModal();
    const {
        pages: {
            view_ringi: {
                approval_chain: approvalChainLocalization
            }
        },
        modals: {
            confirmation: {
                approver: {
                    approve: approveLocalization,
                    deny: denyLocalization
                },
                headquarterApproval: headquarterApprovalModal
            },
        }
    } = useLocale();
    const { isAdmin, loginName } = useAuthorizationClaims();
    const { isLockedDown } = useSubmittedRingi();

    const approvalConfirmationOnClick = () => {
        if (ringiApprover.isJapaneseApprovalApprover) {
            showHeadquarterApprovalModal(true);
        }
        else {
            showApproveConfirmationModal(true);
        }
    }

    const handleHeadquarterApprovalConfirmation = async (formData) => {
        closeHeadquarterApprovalModal();
        await submitRingiApproval(formData.consentNumber);
    };

    useEffect(() => {
        switch (ringiApprover.approverResponseId) {
            case APPROVER_RESPONSE.Approved:
                setStatusColorModifier("approver-card__field--approved");
                break;
            case APPROVER_RESPONSE.Denied:
                setStatusColorModifier("approver-card__field--denied");
                break;
            default:
                setStatusColorModifier(null);
                break;
        }

    }, [ringiApprover.approverResponseId]);

    useEffect(() => {
        setCanDeleteApprover(isAdmin &&
            ringiApprover.approverResponseId === APPROVER_RESPONSE.Pending &&
            !isLockedDown);
    }, [ringiApprover.approverResponseId, isAdmin, isLockedDown]);

    useEffect(() => {
        const currentUser = ringiApprover.user;
        setIsCurrentUser(loginName === currentUser?.loginName?.trim().toLocaleLowerCase())
    }, [ringiApprover.user, loginName]);

    useEffect(() => {
        setCardDisabled(ringiApprover.approverResponseId === APPROVER_RESPONSE.Pending && !ringiApprover.hasBeenNotified && !cardActive);
    }, [ringiApprover.approverResponseId, ringiApprover.hasBeenNotified, cardActive]);

    useEffect(() => {
        const isPending = ringiApprover.approverResponseId === APPROVER_RESPONSE.Pending;
        const validProxy = proxyEnabled && APPROVER_RESPONSE.Pending;
        const currentUserCanApprove = validProxy || (isCurrentUser || (isAdmin && ringiApprover.isJapaneseApprovalApprover));
        setCardActive(!isLockedDown &&
            isPending &&
            currentUserCanApprove &&
            isCurrentApprovalLevel);
    }, [isLockedDown, ringiApprover.approverResponseId, isCurrentUser, proxyEnabled, isCurrentApprovalLevel, isAdmin, ringiApprover.isJapaneseApprovalApprover]);

    const onApproveRingiSubmit = async () => {
        closeApproveConfirmationModal();
        await submitRingiApproval();
    }

    const submitRingiApproval = async (consentNumber) => {
        let response;
        await HandleConditionalApprovalComment();
        if (ringiApprover.isJapaneseApprovalApprover) {
            response = await put(`ringis/${ringiApprover.ringiId}/approvers/headquarters/approval/approve/${conditionDetails.current.isConditionalApproval}?consentNumber=${consentNumber}`);
        }
        else {
            let uri = `ringis/${ringiApprover.ringiId}/approvers/me/approval/approve/${conditionDetails.current.isConditionalApproval}`;

            if (proxyEnabled) {
                uri = `ringis/${ringiApprover.ringiId}/approvers/${ringiApprover.userId}/approval/approve/${conditionDetails.current.isConditionalApproval}`;
            }

            response = await put(uri);
        }
        if (response != null) {
            await onApprove(response === RESET_RESPONSE);
        }
    };

    const handleDenyRingi = async (formData) => {
        let denialResponse;
        if (ringiApprover.isJapaneseApprovalApprover) {
            denialResponse = await put(`ringis/${ringiApprover.ringiId}/approvers/headquarters/approval/deny`, JSON.stringify(formData.denialReason));
        }
        else {
            let uri = `ringis/${ringiApprover.ringiId}/approvers/me/approval/deny`;

            if (proxyEnabled) {
                uri = `ringis/${ringiApprover.ringiId}/approvers/${ringiApprover.userId}/approval/deny`;
            }
            denialResponse = await put(uri, JSON.stringify(formData.denialReason));
        }
        if (denialResponse != null) {
            closeDenyConfirmationModal();
            await onDeny(denialResponse === RESET_RESPONSE);
        }
    };


    const HandleConditionalApprovalComment = (async () => {
        if (conditionDetails.current.isConditionalApproval) {
            let conditionText = conditionDetails.current.conditionText ?? "";

            await post(`comments`, {
                ringiComment: {
                    id: 0,
                    ringiId: ringiApprover.ringiId,
                    comment: conditionText,
                    isForConditionalApproval: true
                }
            });

            await refreshComments();
        }
    });

    const isPendingApproval = () => {
        const pendingStatus = ringiApprover.approverResponseId === APPROVER_RESPONSE.Pending;
        const notNotified = !ringiApprover.hasBeenNotified;

        return (pendingStatus && notNotified);
    }

    return (
        <>
            <div data-testid="approver" className={classNames("approver-card", { "approver-card--disabled": cardDisabled, "approver-card--enabled": cardActive })} >
                <div className="approver-card__flex">
                    <span data-testid="approver-name" className={classNames("approver-card__field", statusColorModifier)}>
                        {ringiApprover.isJapaneseApprovalApprover ? approvalChainLocalization.headquarter_approval_card_name : ringiApprover.user.fullName}
                    </span>
                    <span data-testid="approval-level" className="approver-card__field approver-card__field--light">
                        Approval Level {ringiApprover.approvalLevel.toString().padStart(2, '0')}
                    </span>
                </div>

                <div data-testid="approver-title" className={classNames("approver-card__field approver-card__field--light")}>
                    {ringiApprover.user && ringiApprover.user.title ? ringiApprover.user.title : ''}
                </div>
                <div data-testid="approver-cost-center" className={classNames("approver-card__field approver-card__field--light")}>
                    {ringiApprover.user && ringiApprover.user.costCenter ? `(${ringiApprover.user.costCenter.number}) - ${ringiApprover.user.costCenter.description}` : ''}
                </div>

                <div>
                    <div className='status-row'>
                        <span data-testid="approver-status" className={classNames("approver-card__field", "approver-card__field--dark", statusColorModifier)}>
                            {ringiApprover.approverResponseId === APPROVER_RESPONSE.Approved && !ringiApprover.proxyActorUserId &&
                                <>
                                    <FontAwesomeIcon icon={faCheckCircle} /> {approvalChainLocalization.approved_status}
                                </>
                            }
                            {ringiApprover.approverResponseId === APPROVER_RESPONSE.Approved && ringiApprover.proxyActorUserId &&
                                <>
                                    <FontAwesomeIcon icon={faCheckCircle} /> {approvalChainLocalization.proxy_approved_status}
                                </>
                            }
                            {ringiApprover.approverResponseId === APPROVER_RESPONSE.Denied && !ringiApprover.proxyActorUserId &&
                                <>
                                    <FontAwesomeIcon icon={faTimesCircle} /> {approvalChainLocalization.denied_status}
                                </>
                            }
                            {ringiApprover.approverResponseId === APPROVER_RESPONSE.Denied && ringiApprover.proxyActorUserId &&
                                <>
                                    <FontAwesomeIcon icon={faTimesCircle} /> {approvalChainLocalization.proxy_denied_status}
                                </>
                            }
                            {ringiApprover.approverResponseId === APPROVER_RESPONSE.Pending && ringiApprover.hasBeenNotified &&
                                <div className='notified-status'>
                                    <FontAwesomeIcon icon={faSpinner} /> {approvalChainLocalization.pending_approval_status}
                                </div>
                            }
                            {isPendingApproval() &&
                                <>
                                    <FontAwesomeIcon icon={faSpinner} /> {approvalChainLocalization.pending_notification_status}
                                </>
                            }
                        </span>
                        
                        {cardActive ?
                            <div data-testid="approval-buttons" className="approver-card__field">
                                <Button data-testid="approve-ringi" className="approver_card__button approver-card__approve-ringi" onClick={approvalConfirmationOnClick}>
                                    <FontAwesomeIcon icon={faCheck} className="fa-sm" />
                                </Button>
                                <Button data-testid="deny-ringi" className="approver_card__button approver-card__deny-ringi" onClick={() => showDenyConfirmationModal()}>
                                    <FontAwesomeIcon icon={faTimes} className="fa-sm" />
                                </Button>
                            </div>
                            :
                            <span data-testid="action-date" className="approver-card__field approver-card__field--light action-date">
                                {ringiApprover.approverResponseId !== APPROVER_RESPONSE.Pending ?
                                    LocalDate(ringiApprover.responseDateIso8601)
                                    :
                                    !ringiApprover.hasBeenNotified && " "
                                }
                            </span>
                        }
                    </div>

                    {cardActive && proxyEnabled &&
                        <span data-testid="proxy-approve-label" className="approver-card__proxy-label">{approvalChainLocalization.approve_by_proxy_label}</span>
                    }
                </div>
                <Authorized isAuthorized={canDeleteApprover}>
                    <FontAwesomeIcon data-testid="approver-card-delete" className="approver-card__delete" icon={faTimes} onClick={() => onDelete(ringiApprover)} />
                </Authorized>
            </div>

            <ApproveConfirmationModal variant='confirmation' title={approveLocalization.title}>
                <ModalForm onSubmit={onApproveRingiSubmit}>
                    <ModalBody>
                        <ApprovalModalContent
                            conditionDetails={conditionDetails}
                            messageText={approveLocalization.message}
                            conditionText={approveLocalization.condition}
                        />
                    </ModalBody>
                    <ModalButtons>
                        <ModalCancelButton />
                        <ModalSubmitButton text={approveLocalization.success} />
                    </ModalButtons>
                </ModalForm>
            </ApproveConfirmationModal>

            <HeadquarterApprovalModal variant='confirmation' title={headquarterApprovalModal.title}>
                <ModalForm onSubmit={handleHeadquarterApprovalConfirmation}>
                    <ModalBody>
                        {headquarterApprovalModal.message}
                        <ValidationInput
                            type="text"
                            placeholder={headquarterApprovalModal.placeholder}
                            fieldName="consentNumber"
                            validationRules={{
                                required: requiredRule
                            }}
                        />
                        <ApprovalModalContent
                            conditionDetails={conditionDetails}
                            messageText={""}
                            conditionText={headquarterApprovalModal.condition} />
                    </ModalBody>
                    <ModalButtons className="headquarter-approval-modal__buttons">
                        <ModalCancelButton text={headquarterApprovalModal.cancel} />
                        <ModalSubmitButton text={headquarterApprovalModal.success} />
                    </ModalButtons>
                </ModalForm>
            </HeadquarterApprovalModal>

            <DenyConfirmationModal variant='confirmation' title={denyLocalization.title}>
                <div className="deny-modal__body">
                    <ModalForm onSubmit={handleDenyRingi}>
                        <ModalBody >
                            <label className="deny-modal__label" htmlFor={"denialReason-input"}>{denyLocalization.message}</label>
                            <ValidationInput
                                type="text"
                                placeholder={denyLocalization.placeholder}
                                fieldName="denialReason"
                                validationRules={{
                                    required: requiredRule
                                }}
                            />
                        </ModalBody>
                        <ModalButtons>
                            <ModalCancelButton />
                            <ModalSubmitButton text={denyLocalization.success}>
                                <FontAwesomeIcon className="deny-modal-submit__check" icon={faCheck} size="1x" />
                            </ModalSubmitButton>
                        </ModalButtons>
                    </ModalForm>
                </div>
            </DenyConfirmationModal>
        </>
    );
}