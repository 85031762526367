import React, { useState, useEffect } from 'react';
import { useLocale } from 'services/localization/localizationContextProvider';
import { useScreenDetect } from 'hooks/useScreenDetect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowLeft, faTimes} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import 'pages/Ringis/ViewRingisPage.scss';
import 'pages/Ringis/components/Flyouts/FilterFlyoutSearchList.scss';

export default function FilterFlyoutSearchList({
    label,
    isVisible,
    setIsVisible,
    closeFlyouts,
    filterOptions,
    setSelectedOption //handles submission of {name: , id: }
}){
    const localizedFilterFlyout = useLocale().pages.view_ringis.filter_flyout;
    const { isDesktop } = useScreenDetect();
    const [searchedFilterOptions, setSearchedFilterOptions] = useState([]);
    const [searchInput, setSearchInput] = useState("");

    useEffect(() => {
        setSearchedFilterOptions(filterOptions);
    }, [filterOptions]);

    const onSearch = () => {
        if(searchInput !== "" && filterOptions.length > 0){
            var searchedFilterOptions = filterOptions.filter((option) => {
                return option.name.toLowerCase().includes(searchInput.toLocaleLowerCase());
            });
            setSearchedFilterOptions(searchedFilterOptions);
        }
        else if(isEmptyOrSpaces(searchInput)){
            resetSearchedFilterOptions();
        }
    };

    const resetSearchedFilterOptions = () => {
        setSearchedFilterOptions(filterOptions);
    };

    function isEmptyOrSpaces(str){
        return str === null || str.match(/^ *$/) !== null;
    };

    const onSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
        event.preventDefault();
        onSearch();
        }
    };

    const onClearSearch = () => {
        setSearchInput("");
        resetSearchedFilterOptions();
    }

    return (
        <>
            <div className={isVisible ? 'flyout__overlay' : ""} />
                <div id="filter-flyout-field" className={classNames("flyout",
                    { "flyout--active": isVisible },
                    { "flyout--mobile": !isDesktop && isVisible })}>
                <div className="flyout__header">
                    <div className="filter-flyout__header">
                        <div data-testid="filter-flyout-search-list__back" className="flyout__rounded-button" onClick={() => setIsVisible(false)}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <label data-testid={"filter-flyout-search-list-label"}>
                            {label}
                        </label>
                    </div>
                    <div data-testid="filter-flyout-search-list__close" className="flyout__rounded-button" onClick={closeFlyouts}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>
                <div className="filter-flyout-search-list__search-container">
                        <input data-testid="filter-flyout-search-list-input" placeholder={localizedFilterFlyout.search_placeholder} onChange={(e) => setSearchInput(e.target.value)} value={searchInput} onKeyDown={onSearchKeyDown} className={"filter-flyout-search-list__input"}></input>
                        <span className="filter-flyout-search-list__buttons">
                            {searchInput &&
                            <span className="filter-flyout-search-list__button" onClick={onClearSearch}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>}
                            <button data-testid="filter-flyout-search-list-button" type="button" className="filter-flyout-search-list__button" onClick={onSearch} >
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </span>
                    </div>
                <div className="filter-flyout-search-list__options-container">
                    {searchedFilterOptions &&
                    searchedFilterOptions.map((option, i) => {
                        return (
                        <div 
                        key={i} 
                        data-testid={`filter-flyout-search-list-option`} 
                        className="filter-flyout-input__option-button" 
                        onClick={e => setSelectedOption(option)}>
                                {option?.name}
                        </div>);
                    })}
                </div>
            </div>
        </>
    );
}