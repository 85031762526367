import React, { useEffect, useState } from 'react';
import { fetch } from 'services/api';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { useForm, FormProvider } from 'react-hook-form';
import ValidationTypeahead from 'controls/ValidationTypeahead/ValidationTypeahead';
import { requiredRule } from 'services/validationRules';
import 'pages/Configuration/Type/Editors/TypeAdditionalUsersEditor.scss';
import { inactiveRule } from 'services/serverValidationRules';

export default function TypeAdditionalUsers({selectedAdditionalUsers, postUser, deleteUser, layerId, localization}) {

    const methods = useForm({ reValidateMode: 'onSubmit' });
    const [additionalUsers, setAdditionalUsers] = useState([]);
    const [isAddingUser, setIsAddingUser] = useState(false);

    const onAdditionalUserSubmit = async (formData) => {
        var additionalUserId = formData["additionalUserId"];
        var response = await postUser(additionalUserId);
        if(response != null){
            methods.reset();
            setIsAddingUser(false);
        }
    }

    const onCardRemoveUser = async (additionalUserId) => {
        var response = await deleteUser(additionalUserId)
        if(response != null){
            methods.reset();
        }
    };

    const selectedUserAlreadyExists = async () => {
        const existingUsers = await fetch(`layers/${layerId}/users`);
        const selectedUserId = methods.getValues().additionalUserId;

        if (!existingUsers || !selectedUserId) {
            return false;
        }

        return existingUsers.map(x => x.id).includes(selectedUserId);
    }

    useEffect(() => {
        const fetchAdditionalUsers = async () => {
            const additionalUsers = await fetch(`users`);
            setAdditionalUsers(additionalUsers ?? []);
        }
        fetchAdditionalUsers();
    }, []);

    return (
        <div className="type-additional-users">
            <span data-testid="approval-template-section-name" className="type-approval-template-editor__name">{localization.section_title}</span>
            <div className="type-additional-users-editor__user-list">
                {selectedAdditionalUsers?.length > 0 &&
                    selectedAdditionalUsers?.map((additionalUser, i) => {
                        return <div key={i}>
                            <div className={isAddingUser ? "type-additional-user-card__inactive" : "type-additional-user-card"} 
                                 data-testid="type-additional-user-card">
                                <span>
                                    {additionalUser.fullName}
                                </span>
                                <span>
                                    <i className="type-additional-user-card__icons" onClick={() => onCardRemoveUser(additionalUser.id)}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </i>
                                </span>
                            </div>
                        </div>
                    })
                }
                {isAddingUser ?
                <FormProvider {...methods}>
                    <form data-testid="additional-users-form" onSubmit={methods.handleSubmit(onAdditionalUserSubmit)} className="type-additional-users-card__add">
                        <div className="type-additional-users-editor-header">
                                <span>New {localization.editor_title}</span>
                                <i className="type-additional-users-editor-header__cancel" onClick={() => setIsAddingUser(false)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </i>
                        </div>
                        <ValidationTypeahead
                            fieldName="additionalUserId"
                            validationRules={{
                                required: requiredRule,
                                validate: {
                                    duplicateUser: async () => { return await selectedUserAlreadyExists() ? localization.duplicate_additional_user : true },
                                    inactiveUser: async (userId) => await inactiveRule(`users/${userId}/active`)
                                }
                            }}
                            options={additionalUsers}
                            title={localization.editor_title}
                            flip={true}
                            labelKey={option => `${option.firstName} ${option.lastName}, ${option.emailAddress}`}
                            filterBy={["firstName", "lastName", "emailAddress"]}
                            emptyLabel={localization.no_users_available} />
                        
                        <div className="type-additional-users-editor__buttons">
                            <Button variant="link" onClick={() => setIsAddingUser(false)}>
                                Cancel
                            </Button>
                            <Button data-testid="additional-users-save" type="submit">
                                <FontAwesomeIcon icon={faSave} /> &nbsp; Save
                            </Button>
                        </div>
                    </form>
                </FormProvider>
                : 
                <div className='type-additional-users-card__button'>
                    <Button type="button" data-testid="additional-users-open-add-card" onClick={() => setIsAddingUser(true)}>
                        <FontAwesomeIcon icon={faPlus} /> {localization.editor_open_button}
                    </Button>
                </div>
                }
            </div>
        </div>
    );
}