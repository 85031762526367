import React, { useState } from 'react';
import { useLocale } from 'services/localization/localizationContextProvider';
import { Button } from 'react-bootstrap';
import { httpDelete, post, put } from 'services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ApproverTab from 'pages/Approval/components/ApproverTab';
import ApproverEditor from 'pages/Approval/components/ApproverEditor';
import { useModal, ModalBody, ModalButtons, ModalOkButton, ModalCancelButton } from 'controls/ActionModal/ActionModal';
import { APPROVAL_TYPES } from 'constants/enums';
import './ApprovalLevel.scss';

export default function ApprovalLevel({ approvalLevel, onDelete, reload }) {
    const localized = useLocale();
    const localizedDeleteApprovalLevelConfirmation = localized.modals.confirmation.approvalLevel.deleting;
    const localizedApprovalPage = localized.pages.approval;
    const [DeleteApprovalConfirmationModal, showDeleteApprovalConfirmationModal] = useModal();
    const [showAddApproverModal, setShowAddApproverModal] = useState(false);

    const handleApprovalLevelDelete = async (approvalLevel) => {
        var approversForApprovalLevel = approvalLevel.approverUsers;
        if (approversForApprovalLevel?.length > 0 || approvalLevel.containsCostCenterChain || approvalLevel.containsAdditionalApprovers) {
            showDeleteApprovalConfirmationModal(approvalLevel.id);
        }
        else {
            await onDelete(approvalLevel.id);
        }
    };

    const handleAddApproverClick = async () => {
        setShowAddApproverModal(true);
    };

    const handleApproverEditorClose = async (shouldSave, approverTypeId, userIdToAdd) => {
        if (shouldSave) {
            let shouldReload = await addApprover(parseInt(approverTypeId), userIdToAdd);
            if (shouldReload) {
                await reload();
            }
        }
        setShowAddApproverModal(false);
    }

    const deleteUserApprover = async (userIdToDelete) => {
        await httpDelete(`approvalLevels/${approvalLevel.id}/users/${userIdToDelete}`);
        await reload();
    };

    const deleteCostCenterApprovers = async () => {
        await put(`approvalLevels/${approvalLevel.id}/includeCostCenterApprovers`, 'false');
        await reload();
    }

    const deleteAdditionalApprovers = async () => {
        await put(`approvalLevels/${approvalLevel.id}/includeAdditionalApprovers`, 'false');
        await reload();
    }

    const addApprover = async (approverTypeId, userIdToAdd) => {
        switch (approverTypeId) {
            case APPROVAL_TYPES.SMCEmployee:
                await post(`approvalLevels/${approvalLevel.id}/users`, userIdToAdd);
                break;
            case APPROVAL_TYPES.CostCenter:
                await put(`approvalLevels/${approvalLevel.id}/includeCostCenterApprovers`, 'true');
                break;
            case APPROVAL_TYPES.AdditionalApprovers:
                await put(`approvalLevels/${approvalLevel.id}/includeAdditionalApprovers`, 'true');
                break;
            default:
                return false;
        }

        return true;
    }

    return (
        <>
            <div className="approval-level" data-testid={`approval-level-${approvalLevel.id}`}>
                <div className="approval-level__id">
                    <div data-testid="approval-level-order">{approvalLevel.order.toLocaleString('en-US', { minimumIntegerDigits: 2 })}</div>
                    <div className="approval-level__delete" data-testid="approval-level-delete" onClick={() => handleApprovalLevelDelete(approvalLevel)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>
                <div className="approval-level__cards">
                    {approvalLevel.containsCostCenterChain &&
                        <ApproverTab approver={{
                            id: "cost-center",
                            title: localizedApprovalPage.approval_type_cost_center,
                            description: localizedApprovalPage.approval_type_cost_center_short_description
                        }} onDelete={deleteCostCenterApprovers} />
                    }
                    {approvalLevel.containsAdditionalApprovers &&
                        <ApproverTab approver={{
                            id: "additional-approver",
                            title: localizedApprovalPage.approval_type_additional_approvers,
                            description: localizedApprovalPage.approval_type_additional_approvers_short_description
                        }} onDelete={deleteAdditionalApprovers} />
                    }
                    {approvalLevel.approverUsers?.map((user, i) => {
                        return (<ApproverTab key={i} approver={{
                            id: user.id, title: `${user.firstName} ${user.lastName}`, description: user.title
                        }}
                            onDelete={deleteUserApprover} />);
                    })}
                    {showAddApproverModal &&
                        <ApproverEditor
                            onClose={handleApproverEditorClose}
                            headerText={localizedApprovalPage.new_approver_title}
                            costCenterApproversOnTemplate={ approvalLevel.containsCostCenterChain ? 1 : 0 }
                            additionalApproversOnTemplate={ approvalLevel.containsAdditionalApprovers ? 1 : 0 }
                        />
                    }
                    <div className="approval-level__approvers">
                        <Button data-testid="add-approver-button" onClick={() => handleAddApproverClick(approvalLevel.id)} variant="primary">+ Add Approver</Button>
                    </div>
                </div>
            </div>

            <DeleteApprovalConfirmationModal variant='confirmation' title={localizedDeleteApprovalLevelConfirmation.title}>
                <ModalBody>{localizedDeleteApprovalLevelConfirmation.message}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton />
                    <ModalOkButton text={localizedDeleteApprovalLevelConfirmation.success} onClick={onDelete} />
                </ModalButtons>
            </DeleteApprovalConfirmationModal>
        </>
    )
}