import React, { useState, useEffect } from 'react';
import { FIELD_TYPE } from 'constants/enums';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import ValidationSelect from 'controls/ValidationSelect/ValidationSelect';
import ValidationBoolean from 'controls/ValidationBoolean/ValidationBoolean';
import ValidationTextArea from 'controls/ValidationTextArea/ValidationTextArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons';
import TooltipDescription from 'components/TooltipDescription/TooltipDescription';
import { decimalPlacesRule, requiredRule, alphaNumericSymbolRule, maxLengthRule, maxValueRule, minValueRule, dateRule, dateRangeRule } from 'services/validationRules';
import { useFormContext } from "react-hook-form";
import { useLocale } from 'services/localization/localizationContextProvider';
import 'pages/Ringis/components/RingiInput.scss';

const RingiInput = ({ field, ringiId }) => {
    const
        [inputElement, setInputElement] = useState(),
        [dateRangeValidationError, setDateRangeValidationError] = useState(),
        { register, getValues } = useFormContext(),
        localizedRingiInput = useLocale().ringi_input,
        reactHookFormFieldName = `custom-fields.field-id-${field.id}`,
        startDateId = `${reactHookFormFieldName}-start-date`,
        endDateId = `${reactHookFormFieldName}-end-date`;

    const handleDateRangeValidationError = (validationError) => {
        if (validationError && validationError.message.trim() !== '') {
            setDateRangeValidationError(validationError);
        }
        else {
            setDateRangeValidationError(undefined);
        }
    };

    const handleStartDateSelect = (event) => {
        var startDate = event.target.value;
        var endDateField = document.getElementById(endDateId);

        endDateField.setAttribute("min", startDate);
    }

    const handleEndDateSelect = (event) => {
        var endDate = event.target.value;
        var startDateField = document.getElementById(startDateId);
        startDateField.setAttribute("max", endDate);
    }

    useEffect(() => {
        var endDateField = document.getElementById(endDateId);
        var startDateField = document.getElementById(startDateId);
        if(endDateField){
            endDateField.setAttribute("min", getValues(startDateId))
        }
        if(startDateField){
            startDateField.setAttribute("max", getValues(endDateId))
        }
    });

    useEffect(function computePlaceholderElement() {
        const ringiInputProps = {
            fieldName: reactHookFormFieldName,
            title: `${field.fieldName}${field.isRequired ? "*" : ""}`,
            id: field.id,
            tooltipDescription: field.tooltipDescription,
            showTooltip: field.showTooltip
        }
        const fieldRequired = field.isRequired ? requiredRule : false;
        const labelHTML = <label data-testid={`ringi-input-${field.id}-label`} className="data-entry-field-group__label" htmlFor={field.id}>{field.fieldName}{field.isRequired ? "*" : ""}</label>;
        const dropDownOptions = field.parsedOptions ?? [];

        switch (field.fieldTypeId) {
            case FIELD_TYPE.FreeText:
                setInputElement(<ValidationInput type="text" {...ringiInputProps} maxLength={100}
                    validationRules={{
                        required: fieldRequired,
                        pattern: alphaNumericSymbolRule,
                        maxLength: maxLengthRule(100)
                    }} />);
                break;

            case FIELD_TYPE.Date:
                setInputElement(<ValidationInput type="date" {...ringiInputProps}
                    validationRules={{
                        required: fieldRequired,
                        validate: { validDate: date => dateRule(date) }
                    }}
                />);
                break;

            case FIELD_TYPE.DateRange:
                var startDateId = `${reactHookFormFieldName}-start-date`;
                var endDateId = `${reactHookFormFieldName}-end-date`;
                setInputElement(
                    <>
                        <div className="date-range-input">
                            {labelHTML}{field.showTooltip === true && <TooltipDescription tooltipName={`${field.fieldName}-tooltip`} description={field.tooltipDescription} validationError={dateRangeValidationError} />}
                            <div className="ringi-input__date-range">
                                <ValidationInput type="date" id={startDateId} fieldName={startDateId}
                                    title={localizedRingiInput.date_range_start_label}
                                    className="ringi-input-date-range__input"
                                    onValidationError={handleDateRangeValidationError}
                                    validationRules={{
                                        required: fieldRequired,
                                        validate: {
                                            validDate: date => dateRule(date),
                                            validDateRange: date => dateRangeRule(getValues(startDateId), getValues(endDateId))
                                        }
                                    }}
                                    onChange={handleStartDateSelect}
                                />
                                <FontAwesomeIcon className="ringi-input-date-range__arrow-icon" icon={faArrowsAltH} />
                                <ValidationInput type="date" id={endDateId} fieldName={endDateId}
                                    title={localizedRingiInput.date_range_end_label}
                                    className="ringi-input-date-range__input"
                                    onValidationError={handleDateRangeValidationError}
                                    validationRules={{
                                        required: fieldRequired,
                                        validate: {
                                            validDate: date => dateRule(date),
                                            validDateRange: date => dateRangeRule(getValues(startDateId), getValues(endDateId))
                                        }
                                    }}
                                    onChange={handleEndDateSelect}
                                />
                            </div>
                        </div>
                    </>
                );
                break;

            case FIELD_TYPE.Currency:
                setInputElement(
                    <ValidationInput type="number" {...ringiInputProps} min={0} step={.01}
                        prefixIconName={localizedRingiInput.currency_unit_icon}
                        validationRules={{
                            valueAsNumber: true,
                            required: fieldRequired,
                            validate: { validDecimalPlaces: number => decimalPlacesRule(number, 2) },
                            min: minValueRule(0)
                        }}
                    />
                );
                
                break;

            case FIELD_TYPE.Number:
                setInputElement(
                    <ValidationInput type="number" {...ringiInputProps} step={1}
                        validationRules={{
                            valueAsNumber: true,
                            required: fieldRequired
                        }}
                    />);
                break;

            case FIELD_TYPE.DropDown:
                setInputElement(
                    <ValidationSelect {...ringiInputProps}
                        options={[{ id: "", name: "Select an option..." }, ...dropDownOptions.map((option, i) => { return { id: option, name: option }; })]}
                        defaultValue={field.defaultOptionIndex === -1 ? '' : field.parsedOptions[field.defaultOptionIndex]}
                        validationRules={{
                            required: fieldRequired
                        }}
                    />
                );
                break;

            case FIELD_TYPE.Percentage:
                setInputElement(
                    <ValidationInput type="number" {...ringiInputProps} min={0} max={100} step={.01}
                        suffixIconName="percent"
                        validationRules={{
                            valueAsNumber: true,
                            required: fieldRequired,
                            validate: { validDecimalPlaces: number => decimalPlacesRule(number, 2) },
                            max: maxValueRule(999),
                            min: minValueRule(-999)
                        }}
                    />
                );
                break;

            case FIELD_TYPE.Boolean:
                var defaultValue = field.optionAIsDefault ? field.optionA : "";
                setInputElement(
                    <ValidationBoolean {...ringiInputProps}
                        field={field}
                        options={[field.optionA, field.optionB]}
                        validationRules={{
                            required: fieldRequired
                        }}
                        defaultValue={defaultValue}
                        ringiId={ringiId}
                    />);
                break;

            case FIELD_TYPE.LongFormFreeText:
                setInputElement(
                    <ValidationTextArea {...ringiInputProps}
                        validationRules={{
                            required: fieldRequired
                        }}
                    />);
                break;
            default:
                break;
        }

    }, [field, register, localizedRingiInput, getValues, ringiId, dateRangeValidationError]);

    return (
        <> 
            {inputElement}
        </>
    );
}

export default RingiInput;