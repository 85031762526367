import React, { useEffect, useState, useCallback } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useLocale } from 'services/localization/localizationContextProvider';
import { fetch, post, put } from 'services/api';
import ContextMenu from 'controls/ContextMenu/ContextMenu';
import { useModal, ModalBody, ModalButtons, ModalOkButton, ModalCancelButton } from 'controls/ActionModal/ActionModal';
import CategoryEditor from 'pages/Configuration/Category/components/CategoryEditor';
import { useConfigurationPage } from 'pages/Configuration/providers/configurationPageContextProvider';
import { useAuthorizationClaims } from 'providers/authorizationClaimsProvider';
import 'pages/Configuration/Category/components/CategoryCard.scss';

export default function CategoryCard({ category, isEnabled }) {
    const localized = useLocale();
    const history = useHistory();
    const { refreshCategories } = useConfigurationPage();

    const [menuItems, setMenuItems] = useState([]);
    const [CannotDisableModal, showCannotDisableModal] = useModal();
    const [CategoryInUseEditModal, showCategoryInUseEditModal] = useModal();
    const [CategoryInUseDisableModal, showCategoryInUseDisableModal] = useModal();
    const [DisableCategoryConfirmationModal, showDisableCategoryConfirmationModal] = useModal();
    const [EnableCategoryConfirmationModal, showEnableCategoryConfirmationModal] = useModal();
    const [inEditTitleMode, setInEditTitleMode] = useState(false);
    const [inEditPermissionsMode, setInEditPermissionsMode] = useState(false);
    const { isAdmin } = useAuthorizationClaims();

    const handleDisableCategoryClick = useCallback(async () => {
        const isCategoryInUse = await fetch(`layers/category/${category.id}/isInUse`);
        if (isCategoryInUse) {
            showCategoryInUseDisableModal();
        }
        else {
            const hasEnabledTypes = await fetch(`layers/${category.id}/hasEnabledTypes`);

            if (hasEnabledTypes) {
                showCannotDisableModal();
            }
            else {
                showDisableCategoryConfirmationModal();
            }
        }
    }, [category.id, showCannotDisableModal, showCategoryInUseDisableModal, showDisableCategoryConfirmationModal]);

    const handleEnableCategoryClick = useCallback(() => {
        setInEditPermissionsMode(false);
        showEnableCategoryConfirmationModal();
    }, [showEnableCategoryConfirmationModal]);

    const handleEditCategoryTitleClick = useCallback(async () => {
        setInEditPermissionsMode(false);
        const isCategoryOrTypeInUse = await fetch(`layers/category/${category.id}/hasbeenused`);
        if (isCategoryOrTypeInUse) {
            showCategoryInUseEditModal();
        }
        else {
            setInEditTitleMode(true);
        }
    }, [category.id, showCategoryInUseEditModal]);

    const handleEditCategoryPermissionsClick = useCallback(async () => {
        setInEditTitleMode(false);
        const isCategoryOrTypeInUse = await fetch(`layers/category/${category.id}/hasbeenused`);
        if (!isCategoryOrTypeInUse || isAdmin) {
            setInEditPermissionsMode(true);
        } else {
            showCategoryInUseEditModal();
        }
    }, []);

    const handleEditClosed = useCallback(async () => {
        setInEditTitleMode(false);
        setInEditPermissionsMode(false);
    }, []);

    const disableCategory = async () => {
        var response = await post(`layers/${category.id}/disable`);
        if (response != null) {
            if (history.location.pathname === `/admin/configuration/category/${category.id}`) {
                history.push("/admin/configuration/0");
            }
            await refreshCategories();
        }
    };

    const enableCategory = async () => {
        var response = await post(`layers/${category.id}/enable`);
        if (response != null) {
            await refreshCategories();
        }
    }

    const handleSaveCategory = async (categoryName, categoryUsersList, categoryId, isEditingUserPermissions) => {
        let response;
        if (isEditingUserPermissions === true) {
            response = await put(`categories/users`, JSON.stringify({ id: categoryId, name: categoryName, userIds: categoryUsersList?.map((u) => { return u["id"] }) }));
        }
        else {
            response = await put(`categories/${categoryId}/${categoryName}`);
        }

        if (response != null) {
            await refreshCategories();
        }
    }

    useEffect(() => {
        setMenuItems([
            {
                text: localized.category_list.category_card.edit_title,
                condition: () => isEnabled,
                action: async () => await handleEditCategoryTitleClick()
            },
            {
                text: localized.category_list.category_card.edit_permissions,
                condition: () => isEnabled,
                action: async () => await handleEditCategoryPermissionsClick()
            },
            {
                text: localized.category_list.category_card.disable,
                condition: () => isEnabled,
                action: async () => await handleDisableCategoryClick()
            },
            {
                text: localized.category_list.category_card.enable,
                condition: () => !isEnabled,
                action: () => handleEnableCategoryClick()
            }
        ]);
    }, [localized.category_list.category_card, isEnabled, handleEditCategoryTitleClick, handleEditCategoryPermissionsClick, handleDisableCategoryClick, handleEnableCategoryClick]);

    return (
        <>
            <div className={classNames("category-card", { "category-card--editing": inEditTitleMode, "category-card--disabled": !isEnabled })}>
                {inEditTitleMode || inEditPermissionsMode ?
                    <>
                        <CategoryEditor title="Category Name*"
                            className="category-card__edit-category-input"
                            category={category}
                            editTitleOnly={inEditTitleMode}
                            onSave={handleSaveCategory}
                            onClose={handleEditClosed}
                        />
                    </>
                    :
                    <>
                        <Nav.Link as={NavLink} to={`/admin/configuration/category/${category.id}`} className="category-card__name" disabled={!isEnabled}>
                            <span data-testid={`category-card-${category.id}-name`} className="category-card__content">{category.name}</span>
                        </Nav.Link>
                        {category.hasInactiveUser &&
                            <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip className="category-template-tab-warning_tooltip">{localized.category_list.category_contains_inactive_user}</Tooltip>}>
                                <span className="category-template-tab-warning__container">
                                    <FontAwesomeIcon data-testid="error-icon" icon={faExclamationTriangle} size="1x" className="category-template-tab-warning" />
                                </span>
                            </OverlayTrigger>
                        }
                        <ContextMenu menuItems={menuItems} className="category-card__menu-items" dropDirection="left" />
                    </>
                }
            </div>

            <CannotDisableModal variant='warning' title={localized.modals.warning.categoryHasActiveTypes.title}>
                <ModalBody>{localized.formatString(localized.modals.warning.categoryHasActiveTypes.body, { categoryNamePlaceholder: category.name })}</ModalBody>
                <ModalButtons>
                    <ModalOkButton />
                </ModalButtons>
            </CannotDisableModal>

            <CategoryInUseEditModal variant='warning' title={localized.modals.warning.categoryInUseEdit.title}>
                <ModalBody>{localized.modals.warning.categoryInUseEdit.body}</ModalBody>
                <ModalButtons>
                    <ModalOkButton />
                </ModalButtons>
            </CategoryInUseEditModal>

            <CategoryInUseDisableModal variant='warning' title={localized.modals.warning.categoryInUseDisable.title}>
                <ModalBody>{localized.modals.warning.categoryInUseDisable.body}</ModalBody>
                <ModalButtons>
                    <ModalOkButton />
                </ModalButtons>
            </CategoryInUseDisableModal>

            <DisableCategoryConfirmationModal variant='confirmation' title={localized.modals.confirmation.category.disabling.title}>
                <ModalBody>{localized.formatString(localized.modals.confirmation.category.disabling.body, { categoryNamePlaceholder: category.name })}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton />
                    <ModalOkButton text={localized.modals.confirmation.category.disabling.button_text}
                        onClick={async () => { await disableCategory(); }} />
                </ModalButtons>
            </DisableCategoryConfirmationModal>

            <EnableCategoryConfirmationModal variant='confirmation' title={localized.modals.confirmation.category.enable.title}>
                <ModalBody>{localized.formatString(localized.modals.confirmation.category.enable.body, { categoryNamePlaceholder: category.name })}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton />
                    <ModalOkButton text={localized.modals.confirmation.category.enable.button_text}
                        onClick={async () => { await enableCategory(); }} />
                </ModalButtons>
            </EnableCategoryConfirmationModal>
        </>
    );
}