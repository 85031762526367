import React, { useCallback } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import AttachedFiles from 'controls/ValidationFileInput/AttachedFiles';

export default function ValidationFileInput({ fieldName, validationRules, validationLevel, attachmentType, multiple, buttonLabelText, iconOnly }) {
    const { control, setValue, clearErrors } = useFormContext();

    const mapFileInputValueToReactHookForm = useCallback((fileInputValue) => {
        const value = multiple ? fileInputValue : fileInputValue[0] ?? null;
        setValue(fieldName, value);
        clearErrors(fieldName);
    }, [setValue, fieldName, multiple, clearErrors]);

    const mapReactHookFormValueToFileInput = useCallback((reactHookFormValue) => {
        if (multiple) {
            return reactHookFormValue;
        }
        else {
            return reactHookFormValue != null ? [reactHookFormValue] : [];
        }
    }, [multiple]);

    return (
        <Controller
            control={control}
            name={fieldName}
            rules={validationRules}
            render={({ field: { value } }) => {
                return (
                    <AttachedFiles validationLevel={validationLevel} attachmentType={attachmentType}
                        onChange={mapFileInputValueToReactHookForm}
                        files={mapReactHookFormValueToFileInput(value)}
                        multiple={multiple}
                        buttonLabelText={buttonLabelText}
                        iconOnly={iconOnly}
                    />
                )
            }}
        />
    );
}