import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import ContextMenu from 'controls/ContextMenu/ContextMenu';
import { useLocale } from 'services/localization/localizationContextProvider';
import { put } from 'services/api';
import CostCenterEditor from 'pages/CostCenter/components/CostCenterEditor';
import 'pages/CostCenter/components/CostCenterTab.scss';
import { useModal, ModalBody, ModalButtons, ModalOkButton, ModalCancelButton } from 'controls/ActionModal/ActionModal';

const CostCenterTab = ({ costCenter, onUpdate }) => {
    const localizedCostCenters = useLocale().pages.cost_center;
    const disableCostCenterModalLocale = useLocale().modals.confirmation.disableCostCenter;
    const [menuItems, setMenuItems] = useState([]);
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [DisableCostCenterModal, showDisableCostCenterModal] = useModal();

    const handleEditorClosing = async (shouldSave, costCenterToSave) => {
        if (shouldSave) {
            var response = await put(`costcenters/${costCenter.id}?description=${costCenterToSave.description}`);

            if (response != null) {
                await onUpdate();
            }
        }
        setIsInEditMode(false);
    };

    const editCostCenterEnabledState = async (costCenter, newEnabledState) => {
        const actionToTake = newEnabledState === true ? "enable" : "disable";
        var response = await put(`costcenters/${costCenter.id}/${actionToTake}`);

        if (response != null) {
            await onUpdate();
        }
    };

    useEffect(function computeMenuItem() {
        setMenuItems([
            {
                text: localizedCostCenters.context_menu.edit,
                condition: () => costCenter.isEnabled,
                action: () => setIsInEditMode(true)
            },
            {
                text: localizedCostCenters.context_menu.enable,
                condition: () => costCenter.isEnabled === false,
                action: () => { editCostCenterEnabledState(costCenter, true); }
            },
            {
                text: localizedCostCenters.context_menu.disable,
                condition: () => costCenter.isEnabled === true,
                action: () => { showDisableCostCenterModal(costCenter) }
            }
        ]);
    }, [localizedCostCenters.context_menu, costCenter.isEnabled, costCenter.id, onUpdate, setIsInEditMode]);

    return (
        <>
            {
                isInEditMode ?
                    <CostCenterEditor onClose={handleEditorClosing} costCenter={costCenter} headerText={localizedCostCenters.edit_cost_center_title} /> :
                    <div data-testid={`cost-center-tab-${costCenter.id}`} className={classnames("cost-center-tab", { "cost-center-tab--disabled": costCenter.isEnabled === false })}>
                        <span data-testid={`cost-center-tab-${costCenter.id}-content`} className="cost-center-tab__content">
                            {`(${costCenter.number}) ${costCenter.description}`}
                        </span>
                        <ContextMenu menuItems={menuItems} className="cost-center-tab__options" dropDirection="left" />
                    </div>
            }

            <DisableCostCenterModal title={disableCostCenterModalLocale.title}>
                <ModalBody>{disableCostCenterModalLocale.message}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton text={disableCostCenterModalLocale.cancel} />
                    <ModalOkButton text={disableCostCenterModalLocale.success} onClick={() => editCostCenterEnabledState(costCenter, false)} />
                </ModalButtons>
            </DisableCostCenterModal>
        </>
    );
};

export default CostCenterTab;