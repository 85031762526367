import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useLocale } from 'services/localization/localizationContextProvider';
import Copyright from 'pages/Login/Copyright';
import Logo from 'pages/Login/Logo';
import { useUserContextProvider } from 'providers/userContextProvider';
import 'pages/Login/Login.scss';

export default function Login() {
    const { oktaAuth, authState } = useOktaAuth();
    const history = useHistory();
    const localized = useLocale();
    // Initializing to null because when the user authenticates in Okta but the user is not in the User table in the DB,
    // Okta redirects to this component and the component is rendered twice. To evaluate this correctly and show the
    //inactive message a null check is done in the useEffect on showInactiveMessage.
    const [showInactiveMessage, setShowInactiveMessage] = useState(null);

    const { fetchUserLandingPage } = useUserContextProvider();

    const handleLogin = async () => {
        await oktaAuth.signInWithRedirect();
    };

    useEffect(() => {
        if (showInactiveMessage === null) {
            const isUserActive = window.localStorage.getItem('isUserActive');

            if (isUserActive && isUserActive === "false") {
                setShowInactiveMessage(true);
                window.localStorage.removeItem('isUserActive');
            }
            else {
                setShowInactiveMessage(false);
            }
        }
    }, []);

    useEffect(() => {
        async function redirectAuth() {
            if (authState?.isAuthenticated) {
                const userLandingPage = await fetchUserLandingPage();
                history.replace(userLandingPage);
            }
        }
        redirectAuth();
    }, [authState, history]);

    return (
        <>
            <div className="login">
                <div className="login__content">
                    <Logo className="login__logo" />
                    <div className="login__button">
                        <Button variant="primary" onClick={handleLogin}>{localized["login_button"]}</Button>
                        {showInactiveMessage === true &&
                            <div className="login__inactive-user">
                                {localized["user_is_inactive"]}<br />
                                {localized["contact_support"]}
                            </div>
                        }
                    </div>
                    <Copyright />
                </div>
            </div>
        </>
    );
}