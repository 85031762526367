import { fetch } from 'services/api';

const existsRule = async (fetchUrl, message) => {
    var responseData = await fetch(fetchUrl);

    if (responseData) {
        return message ?? "Already Exists";
    }
    return true;
};

const uniqueRule = async (fetchUrl, idEditing) => {
    var responseData = await fetch(fetchUrl);
    
    if (responseData && responseData.length > 0 && responseData[0].id !== idEditing) {
        return 'Enter unique value';
    }
    return true;
};

const inactiveRule = async (fetchUrl, message) => {
    var isActive = await fetch(fetchUrl);

    if (!isActive) {
        return message ?? 'User is Inactive';
    }

    return true;
};

export {
    existsRule,
    uniqueRule,
    inactiveRule,
}