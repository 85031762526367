import React, { useState, useRef, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import { Modal, Button } from 'react-bootstrap';

const AutoLogout = ({ children, logout }) => {
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [secondsToLogout, setSecondsToLogout] = useState();
    var intervalId = useRef(null);

    useEffect(() => {
        return () => {
            clearTimer();
        }
    }, []);

    useEffect(() => {
        if (secondsToLogout === 0) {
            clearTimer();
            logout();
        }
    }, [secondsToLogout, logout]);

    const countdownTimer = () => {
        setSecondsToLogout(prevSecondsToLogout => prevSecondsToLogout - 1);
    };

    const clearTimer = () => {
        clearInterval(intervalId.current);
        intervalId.current = null;
    }

    const handleOnIdle = event => {
        if (!intervalId.current) {
            const stillThereSeconds = process.ringiEnv.REACT_APP_STILL_THERE_RESPONSE_SECONDS;
            const msSinceLastActive = Math.abs(getLastActiveTime() - new Date())
            const roundedSecondsSinceActive = Math.round(stillThereSeconds - (msSinceLastActive / 1000));
            const secondsToTimeout = roundedSecondsSinceActive + stillThereSeconds;

            if (secondsToTimeout < 0) {
                logout();
            }
            else if (roundedSecondsSinceActive <= stillThereSeconds) {
                setSecondsToLogout(secondsToTimeout);
                setShowLogoutModal(true);
            }

            intervalId.current = setInterval(countdownTimer, 1000);
        }
    }

    const { reset, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * process.ringiEnv.REACT_APP_INACTIVITY_LOGOUT_TIME_MINUTES,
        onIdle: handleOnIdle,
        debounce: 500,
    })

    const handleStayLoggedIn = () => {
        setShowLogoutModal(false);
        clearTimer();
        reset();
    }

    return (
        <>
            {children}
            {
                showLogoutModal &&
                <Modal show={true} backdrop='static'>
                    <Modal.Body data-testid="auto-logout-modal-body">You will be logged out in {secondsToLogout} seconds</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" data-testid="stay-logged-in-button" onClick={handleStayLoggedIn}>
                            Stay Logged In
                    </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default AutoLogout;