import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from "react-hook-form";
import { requiredRule, alphaNumericRule } from 'services/validationRules';
import { uniqueRule } from 'services/serverValidationRules';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'pages/Configuration/Category/TypePanelEditor.scss';
import { useEffect } from 'react';
import { useLocale } from 'services/localization/localizationContextProvider';

function TypePanelEditor({ children: submitButtonContent, onClose, type, onSubmit, usedInRingi }) {
    const { categoryId } = useParams();
    const [sendEmailToZendesk, setSendEmailToZendesk] = useState(false);
    const
        methods = useForm({
            defaultValues:
            {
                typeName: type.name
            },
            reValidateMode: 'onSubmit'
        }),
        onTypeSubmit = async (formData) => {
            await onSubmit(formData.typeName, sendEmailToZendesk);
        },
        localized = useLocale().configuration_page.type_list;
    
    useEffect(() => {
        setSendEmailToZendesk(type?.typeProperties ? type.typeProperties.sendEmailToZendesk : false);
    }, [type]);

    return (
        <div className="type-panel-editor">
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onTypeSubmit)}>
                    <div>
                        {usedInRingi ? 
                            <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip id={"type-in-use-tooltip"} className={"tooltip-description__tooltip"}>{localized.disabled_name_tooltip}</Tooltip>}>
                                <input type="text" value={type.name} className={"type-panel-editor__disabled-name"} disabled></input>
                            </OverlayTrigger>
                            :
                            <ValidationInput
                                data-testid="type-name-input"
                                autoFocus
                                type="text"
                                title="Type Title"
                                minLength={1}
                                maxLength={100}
                                fieldName="typeName"
                                className="data-entry-field-group__input"
                                validationRules={{
                                    required: requiredRule,
                                    pattern: alphaNumericRule,
                                    validate: async (typeName) => await uniqueRule(`categories/${categoryId}/types?name=${typeName}`, type.id)
                                }}
                            />
                        }
                    </div>
                    <div className="type-panel-editor__zendesk">
                        <div>
                            <div className="type-panel-editor__zendesk-text">
                                <input
                                    type="checkbox"
                                    data-testid="typePanelEditorCheckbox"
                                    checked={sendEmailToZendesk}
                                    onChange={(e) => setSendEmailToZendesk(e.target.checked)}
                                />
                                {localized.send_email_to_zendesk}
                            </div>
                            <div className="type-panel-editor__zendesk-subtext">
                                {localized.send_email_subtext}
                            </div>
                        </div>
                    </div>
                    <div className="type-panel-editor__buttons">
                        <Button onClick={() => onClose(type.id)} variant="link" >
                            Cancel
                        </Button>
                        <Button data-testid="save-btn" className="type-panel-editor__save-type-name" type="submit">
                            {submitButtonContent}
                        </Button>
                    </div>
                </form >
            </FormProvider >
        </div>
    );
}

export default TypePanelEditor;

