// THESE MUST MATCH THE COORESPONDING SERVER SIDE ENUMS
// TODO: find way to generate these automatically from the server side enums
import { ROUTES } from './constants';

const APPROVER_RESPONSE = {
    Pending: 1,
    Approved: 2,
    Denied: 3
};

const APPROVAL_TYPES = {
    SMCEmployee: 1,
    CostCenter: 2,
    AdditionalApprovers: 3
};

const ATTACHMENT_TYPES = {
    Ringi: 1,
    TemplateField: 2,
    Comment: 3
}

const BASE_LAYER_ID = 1;

const FIELD_TYPE = {
    FreeText: 1,
    Date: 2,
    DateRange: 3,
    Currency: 4,
    Number: 5,
    DropDown: 6,
    Percentage: 7,
    Boolean: 8,
    LongFormFreeText: 9,
    Attachment: 10
};

const LAYER_TYPE = {
    Base: 1,
    Category: 2,
    Type: 3
};

const RINGI_LIST_FILTER_OPTIONS = {
    CurrentRingis: 1,
    MyDraftRingis: 2,
    MySubmittedRingis: 3,
    RingisToApprove: 4,
    RingisToRead: 5,
    ArchivedRingis: 6
}

const ROUTE_MAP = {
    [RINGI_LIST_FILTER_OPTIONS.CurrentRingis]: ROUTES.CURRENT_RINGIS_PAGE,
    [RINGI_LIST_FILTER_OPTIONS.MyDraftRingis]: ROUTES.DRAFT_RINGIS_PAGE,
    [RINGI_LIST_FILTER_OPTIONS.MySubmittedRingis]: ROUTES.SUBMITTED_RINGIS_PAGE,
    [RINGI_LIST_FILTER_OPTIONS.RingisToApprove]: ROUTES.RINGIS_TO_APPROVE_PAGE,
    [RINGI_LIST_FILTER_OPTIONS.RingisToRead]: ROUTES.RINGIS_TO_READ_PAGE,
    [RINGI_LIST_FILTER_OPTIONS.ArchivedRingis]: ROUTES.ARCHIVED_RINGIS
}

const FILTER_TYPE = {
    Current: 1,
    Draft: 2,
    Submitted: 3,
    ToApprove: 4,
    ToRead: 5,
    Archived: 6
}

const RINGI_STATUS = {
    Draft: 1,
    Pending: 2,
    PendingAdminReview: 3,
    PendingJapan: 4,
    Approved: 5,
    Denied: 6,
    DraftError: 7,
    Cancelled: 8,
    Paused: 9,
    ApprovedWithConditions: 10
};

const RINGI_SETTING = {
    HeadquarterApprovalThreshold: 1,
    SendGridFromEmail: 2,
    SupportEmail: 3,
    NameOfPresidentForSignature: 4,
    DaysUntilNotificationsExpire: 5,
    DaysWhenRingiApproverResponsesAreOverdue: 6,
    ReportAnAppIssueCcEmail: 7,
    SendToJapanEmailDomains: 8,
    ReportAnAppIssueToEmail: 9
};

export {
    APPROVER_RESPONSE,
    APPROVAL_TYPES,
    ATTACHMENT_TYPES,
    BASE_LAYER_ID,
    FIELD_TYPE,
    LAYER_TYPE,
    RINGI_LIST_FILTER_OPTIONS,
    RINGI_STATUS,
    FILTER_TYPE,
    ROUTE_MAP,
    RINGI_SETTING
}