import React, { useState, useEffect, useCallback } from 'react';
import { useLocale } from 'services/localization/localizationContextProvider';
import { useParams } from 'react-router-dom';
import RingiPage, { PageSidebar, PageContent, PageHeader } from 'controls/RingiPage/RingiPage';
import ApprovalList from 'pages/Approval/components/ApprovalList';
import { fetch } from 'services/api';
import 'pages/Approval/ApprovalPage.scss';
import ApprovalTemplate from './components/ApprovalTemplate';


const ApprovalPage = () => {
    const { approvalTemplateId } = useParams();
    const localizedApprovalPage = useLocale().pages.approval;
    const [approvalTemplate, setApprovalTemplate] = useState();
    
    const fetchApprovalTemplate = useCallback(async () => {
        const template = await fetch(`approvalTemplates/${approvalTemplateId}`);

        setApprovalTemplate(template);

    }, [approvalTemplateId]);

    useEffect(function fetchApprovalTemplateOnLoad() {
        approvalTemplateId ? fetchApprovalTemplate() : setApprovalTemplate();
    }, [approvalTemplateId, fetchApprovalTemplate]);

    return (
        <RingiPage>
            <PageSidebar primaryMobileContent={approvalTemplateId == null}>
                <ApprovalList />
            </PageSidebar>
            <PageContent primaryMobileContent={approvalTemplateId != null}>
                {approvalTemplateId === undefined &&
                    <span data-testid="select-approval-template-message" className="approval-page__content">{localizedApprovalPage.select_approval_template_message}</span>
                }
                {approvalTemplate &&
                    <>
                        <PageHeader title={approvalTemplate.name} goBackTo="/admin/approval"></PageHeader>
                        <ApprovalTemplate approvalTemplateId={approvalTemplate.id} />
                    </>
                }
            </PageContent>
        </RingiPage>
    );
};

export default ApprovalPage;