import React, { useState, useEffect, useCallback } from 'react';
import { useLocale } from 'services/localization/localizationContextProvider';
import { fetch } from 'services/api';
import DivisionTab from 'pages/Division/components/DivisionTab';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import 'pages/Division/components/DivisionList.scss';

const DivisionList = ({ refreshTrigger, showDisabledDivisions }) => {
    const localized = useLocale();
    const localizedDivision = localized.pages.division;
    const [divisions, setDivisions] = useState([]);

    const fetchDivisionList = useCallback(async () => {
        const divisions = await fetch(`headquarters/divisions`);
        setDivisions(divisions);
    }, []);

    const compareDivisionName = (a, b) => {
        const aName = a.name?.toLowerCase();
        const bName = b.name?.toLowerCase();

        return aName < bName ? -1 : (aName === bName ? 0 : 1);
    }

    useEffect(() => {
        fetchDivisionList();
    }, [fetchDivisionList, refreshTrigger]);

    return (
        <>
            <div className="division-list__content division-list__section">
                {
                    divisions.length === 0 &&
                    <span data-testid="no-division-message" className="division-list__no-divisions-message">
                        {localizedDivision.no_divisions_message}
                    </span>
                }
                {
                    divisions.sort((a, b) => { return compareDivisionName(a, b) }).map((division, i) => {
                        if (division.isActive === !showDisabledDivisions) {
                            return (
                                <DivisionTab key={i} division={division} refreshDivisions={fetchDivisionList} />
                            )
                        }
                    })
                }
            </div>

        </>
    );
};

export default DivisionList;