import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactTimeAgo from 'react-time-ago';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEnvelopeOpenText, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { put } from 'services/api';
import { useScreenDetect } from 'hooks/useScreenDetect';
import { useLocale, getDBLocalizationValue } from 'services/localization/localizationContextProvider';
import { LocalLongDateTime } from 'services/localization/dateTimeService';
import { useNotificationsClaim } from 'providers/notificationsProvider';
import './NotificationsList.scss';

export default function NotificationsList({ notifications }) {
    const { isMobile } = useScreenDetect();
    const [updatedNotifications, setUpdatedNotifications] = useState([]);
    const clientLocale =
        navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language
        ;
    const history = useHistory();
    const { fetchAllNotificationsCount, fetchNormalNotificationsCount, fetchAdminNotificationsCount } = useNotificationsClaim();

    const localized = useLocale();
    const localizedNotifications = useLocale().pages.notifications.list;

    const toggleNotificationReadStatus = async (notificationId) => {
        const response = await put(`notifications/${notificationId}`);
        if (response != null) {
            var notifications = updatedNotifications.map(notification => {
                if (notification.id === notificationId) {
                    fetchAllNotificationsCount();
                    return {
                        id: notification.id,
                        userId: notification.userId,
                        ringiId: notification.ringiId,
                        ringiNumber: notification.ringiNumber,
                        isRead: !notification.isRead,
                        isAdminNotification: notification.isAdminNotification,
                        message: getDBLocalizationValue(notification.localizationKey),
                        userSubject: notification.userSubject,
                        lastNotifiedDate: notification.lastNotifiedDate,
                        localizationKey: notification.localizationKey,
                        createdDate: notification.createdDate,
                        createdDateIso8601: notification.createdDateIso8601
                    }
                }
                return notification;
            });
            setUpdatedNotifications(notifications);
        }
    }

    const handleMarkAllReadButtonClick = async () => {
        const areAdminNotifications = updatedNotifications[0]?.isAdminNotification === true;
        var response;
        if (areAdminNotifications) {
            response = await put(`notifications/admin/markAllAsRead`);
        } else {
            response = await put(`notifications/markAllAsRead`);
        }
        if (response != null) {
            var notifications = updatedNotifications.map(notification => {
                return {
                    id: notification.id,
                    userId: notification.userId,
                    ringiId: notification.ringiId,
                    ringiNumber: notification.ringiNumber,
                    isRead: true,
                    isAdminNotification: notification.isAdminNotification,
                    message: getDBLocalizationValue(notification.localizationKey),
                    userSubject: notification.userSubject,
                    lastNotifiedDate: notification.lastNotifiedDate,
                    localizationKey: notification.localizationKey,
                    createdDate: notification.createdDate,
                    createdDateIso8601: notification.createdDateIso8601
                }
            });
            if (areAdminNotifications) {
                fetchAdminNotificationsCount();
            } else {
                fetchNormalNotificationsCount();
            }
            setUpdatedNotifications(notifications);
        }
    }

    const handleRingiUrlClick = async (notification) => {
        if (!notification.isRead) {
            await toggleNotificationReadStatus(notification.id)
        }
        if (notification.ringiNumber !== '' && notification.message !== localized.database_localization_keys.NotificationType_RingiHasInvalidApprovalChain) {
            history.push(`/ringis/${notification.ringiId}`);
        }
        else {
            history.push(`/ringis/draft/${notification.ringiId}`);
        }
    }

    const getOverdueDays = (lastNotifiedDate) => {
        if (lastNotifiedDate !== null) {
            return Math.trunc(((new Date()).getTime() - (new Date(lastNotifiedDate)).getTime()) / (1000 * 3600 * 24));
        }
        return '';
    }

    useEffect(() => {
        setUpdatedNotifications(notifications.map(n => {
            return { ...n, message: getDBLocalizationValue(n.localizationKey) }
        }));
    }, [notifications])

    return (
        <>
            {updatedNotifications?.length === 0 ?
                <div data-testid="notification-empty-message" className="notification-empty-message">{localizedNotifications.empty_message}</div>
                :
                <div className={classNames("", { 'notifications-list-container__mobile': isMobile, 'notifications-list-container__fullscreen': !isMobile })}>
                    <div className="notification-list__mark-all-read">
                        <div data-testid="notification-list-mark-all-read__button"
                            className="notifications-list-mark-all-read__button"
                            onClick={() => handleMarkAllReadButtonClick()}
                            data-toggle="tooltip">
                            {localizedNotifications.mark_all_as_read}
                            <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip id='notification-list-mark-all-read__tooltip' className="tooltip-description__tooltip">{localizedNotifications.mark_all_read}</Tooltip>}>
                                <FontAwesomeIcon icon={faEnvelopeOpenText} size="1x" />
                            </OverlayTrigger>
                        </div>
                    </div>
                    {updatedNotifications?.map((notification, i) => {
                        var localDate = new Date(LocalLongDateTime(notification.createdDateIso8601));
                        return (notification.isRead ?
                            <div key={i} data-testid={`notification-${notification.id}`} className="notifications-list-notification">
                                <div data-testid={`notification-read-${notification.id}`} className="notifications-list-notification__read">
                                    <div className="notifications-list-notification__read-dot"></div>
                                    <div className="notifications-list-notification__message">
                                        {localized.formatString(notification.message, { userSubject: notification.userSubject, overdueDays: getOverdueDays(notification.lastNotifiedDate) }) + " "}
                                        {notification.ringiId > 0 &&
                                            <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip id='notifications-list-notification__message-tooltip' className="tooltip-description__tooltip">{localizedNotifications.hyperlink_message}</Tooltip>}>
                                                <span className="notifications-list-notifications__url"
                                                    onClick={() => handleRingiUrlClick(notification)}>
                                                    {(notification.ringiNumber === '' || notification.message === localized.database_localization_keys.NotificationType_RingiHasInvalidApprovalChain) ? 'Draft Ringi' : 'Ringi #' + notification.ringiNumber}
                                                </span>
                                            </OverlayTrigger>}
                                    </div>
                                    <div className="notifications-list-notification__time">
                                        <ReactTimeAgo date={localDate} locale={clientLocale} />
                                    </div>
                                </div>
                                <div data-testid={`notification-read-button-${notification.id}`}
                                    className="notifications-list__button"
                                    onClick={() => toggleNotificationReadStatus(notification.id)}
                                    data-toggle="tooltip">
                                    <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip id='`notification-read-tooltip-${notification.id}`' className="tooltip-description__tooltip">{localizedNotifications.mark_one_unread}</Tooltip>}>
                                        <FontAwesomeIcon icon={faEnvelopeOpenText} size="1x" />
                                    </OverlayTrigger>
                                </div>
                            </div>
                            :
                            <div key={i} data-testid={`notification-${notification.id}`} className="notifications-list-notification ">
                                <div data-testid={`notification-unread-${notification.id}`} className="notifications-list-notification__unread">
                                    <div className="notifications-list-notification__unread-dot">
                                        <FontAwesomeIcon icon={faCircle} size="xs" />
                                    </div>
                                    <div className="notifications-list-notification__message">
                                        {localized.formatString(notification.message, { userSubject: notification.userSubject, overdueDays: getOverdueDays(notification.lastNotifiedDate) }) + " "}
                                        {notification.ringiId > 0 &&
                                            <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip id='notifications-list-notification__message-tooltip' className="tooltip-description__tooltip">{localizedNotifications.hyperlink_message}</Tooltip>}>
                                                <span className="notifications-list-notifications__url"
                                                    onClick={() => handleRingiUrlClick(notification)}>
                                                    {(notification.ringiNumber === '' || notification.message === localized.database_localization_keys.NotificationType_RingiHasInvalidApprovalChain) ? 'Draft Ringi' : 'Ringi #' + notification.ringiNumber}
                                                </span>
                                            </OverlayTrigger>}
                                    </div>
                                    <div className="notifications-list-notification__time">
                                        <ReactTimeAgo date={localDate} locale={clientLocale} />
                                    </div>
                                </div>
                                <div data-testid={`notification-unread-button-${notification.id}`}
                                    className="notifications-list__button"
                                    onClick={() => toggleNotificationReadStatus(notification.id)}
                                    data-toggle="tooltip">
                                    <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip id='`notification-unread-tooltip-${notification.id}`' className="tooltip-description__tooltip">{localizedNotifications.mark_one_read}</Tooltip>}>
                                        <FontAwesomeIcon icon={faEnvelope} size="1x" />
                                    </OverlayTrigger>
                                </div>
                            </div>
                        );
                    })}
                </div>
            }
        </>
    );
}
