import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import ValidationError, { GetValidationError } from 'components/ValidationError';
import classnames from 'classnames';
import TooltipDescription from 'components/TooltipDescription/TooltipDescription';
import classNames from 'classnames';
import 'controls/ValidationSelect/ValidationSelect.scss';

export default function ValidationSelect({ fieldName, validationRules, options, title, className, defaultValue, onChange, boldTitle, tooltipDescription = null, showTooltip = false, ...rest }) {
    const { register, unregister, setValue } = useFormContext();
    const selectId = `${fieldName}-select`;
    const validationError = GetValidationError(fieldName);

    useEffect(() => {
        return () => {
            if (setValue) { // make sure the parent FormProvider has not already been cleaned up
                setValue(fieldName, '');
                unregister(fieldName);
            }
        }
    }, [fieldName, unregister, setValue]);

    return (
        <div data-testid="select-wrapper" className={classnames("validation-select", { "validation-select--error": validationError })}>
            {title && <label htmlFor={selectId} data-testid={`${selectId}-label`} className={classNames("validation-select__label", boldTitle ? "text-bold" : '')}>{title}</label>}
            {showTooltip === true && <TooltipDescription tooltipName={`${fieldName}-tooltip`} description={tooltipDescription} validationError={validationError} />}
            <select id={selectId} data-testid={selectId} name={fieldName} defaultValue={defaultValue}
                {...register(fieldName, {
                    ...validationRules,
                    onChange: onChange
                })}
                className={classnames("validation-select__select", className)} {...rest}>
                {options?.map((option, i) => (
                    <option data-testid={`${fieldName}-option-${option.id}`} disabled={option.disabled} hidden={option.hidden} value={option.id} key={i}>{option.name}</option>
                ))}
            </select>
            <ValidationError fieldName={fieldName} className="validation-select__icon" />
        </div>
    );
}