import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltH, faEdit } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import TooltipDescription from 'components/TooltipDescription/TooltipDescription';
import 'pages/Ringis/components/RingiField.scss';

const RingiField = ({ ringiField, canEdit=false, onEditClick }) => {
    const [ringiFieldValue, setRingiFieldValue] = useState();

    useEffect(() => {
        const ringiFieldValueProps = {
            className: classNames("ringi-field__value", { [`ringi-field__value--${ringiField.statusModifier}`]: ringiField.statusModifier != null })
        }
        ringiFieldValueProps['data-testid'] = `${ringiField.fieldName}-value`

        if (ringiField.to != null && ringiField.from != null) {
            setRingiFieldValue(
                <div className="ringi-field__date-range">
                    <div className="ringi-field-date-range__date">
                        <span className="ringi-field__name">From</span>
                        <span {...ringiFieldValueProps} data-testid={`${ringiField.fieldName}-from-value`}>{ringiField.from}</span>
                    </div>
                    <FontAwesomeIcon icon={faArrowsAltH} className="ringi-field-date-range__arrow-icon" />
                    <div className="ringi-field-date-range__date">
                        <span className="ringi-field__name">To</span>
                        <span {...ringiFieldValueProps} data-testid={`${ringiField.fieldName}-to-value`}>{ringiField.to}</span>
                    </div>
                </div>
            );
        }
        else {
            let fieldIsPercentage = new RegExp('-.+%');
            if(fieldIsPercentage.test(ringiField.value)){
                ringiFieldValueProps.className += " ringi-field__value--negative";
            }
            setRingiFieldValue(<span {...ringiFieldValueProps}>{ringiField.value}</span>);
        }
    }, [ringiField]);

    return (
        <div className="ringi-field">
            <span data-testid="ringi-field-name" className={classNames("ringi-field__name", { "ringi-field__name--standout": ringiField.to != null && ringiField.from != null })}>{ringiField.fieldName}</span>
            {ringiField.showTooltip === true && <TooltipDescription tooltipName={`${ringiField.fieldName}-tooltip`} description={ringiField.tooltipDescription} />}
            <div className="value-and-icon">
                {ringiFieldValue}
                {canEdit && <span onClick={onEditClick} data-testid='edit-button' className='edit-button'>
                    <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                </span>}
            </div>
        </div>
    );
}

export default RingiField;