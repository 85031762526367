import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import './Chip.scss';
const Chip = ({click, onRemove, label, icon}) => {

    return (
        <div 
            data-testid='chip-container' 
            onClick={click ? click : () => {}} 
            className={classNames("chip__container", click ? "clickable" : '')}
        >
            <div className='icon__container'>
                {icon && 
                    <FontAwesomeIcon data-testid="external-icon" className='left-icon' icon={icon}/>
                }
            </div>
            <div data-testid="label-text" className="center-text">{label}</div>
            <div className='close-icon__container'>
                {onRemove &&
                    <FontAwesomeIcon data-testid='remove-icon' className='close-icon' icon={faTimesCircle} onClick={onRemove} />
                }
            </div>
        </div>
    )
}

export default Chip;