import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import InitializationProvider from 'providers/initializationProviders';
import { BrowserRouter as Router } from 'react-router-dom';
import Main from 'components/Main';
import OktaSecurity from 'components/OktaSecurity';
import Loading from 'controls/Loading/Loading';
import RouterPrompt from 'controls/RouterPrompt/RouterPrompt';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import { faDollarSign, faPercent } from '@fortawesome/free-solid-svg-icons'
import 'App/App.scss'
import { UserContextProvider } from 'providers/userContextProvider';


export default function App() {
    const [promptMessage, setPromptMessage] = useState();
    const [navigateAwayCallback, setNavigateAwayCallback] = useState();
    const [showPrompt, setShowPrompt] = useState(false);

    const loadDynamicFontAwesomeIcons = () => {
        library.add(faDollarSign, faPercent);
    }

    config.autoAddCss = false;
    loadDynamicFontAwesomeIcons();

    const handleShowPrompt = (message, callback) => {
        setPromptMessage(message);
        setNavigateAwayCallback(() => callback);
        setShowPrompt(true);
    };

    const handlePromptSelection = (navigateAway) => {
        setShowPrompt(false);
        navigateAwayCallback(navigateAway);
    };

    return (
            <InitializationProvider>
                <Loading>
                    <UserContextProvider>
                        <Container fluid>
                            <Router getUserConfirmation={handleShowPrompt}>
                                <OktaSecurity>
                                    <Main />
                                </OktaSecurity>
                                <RouterPrompt message={promptMessage} handleLeave={handlePromptSelection} shouldShow={showPrompt} />
                            </Router>
                        </Container>
                    </UserContextProvider>
                </Loading>
            </InitializationProvider>
    );
}