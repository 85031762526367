import React from 'react';
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { useLocale } from 'services/localization/localizationContextProvider';
import { Button } from "react-bootstrap";
import './RingiHeader.scss';

export default function RingiHeader({
                                    children, 
                                    label, 
                                    path, 
                                    className, 
                                    onCancelClick, 
                                    showCancel, 
                                    hideBackButton, 
                                    onCopyRingiClick, 
                                    showCopyRingi, 
                                    showLegacyRingiIcon }) 
    {
    const localized = useLocale();
    const history = useHistory();
    

    const goBack = () => {
        if (path != null) {
            history.push(path);
        }
        else {
            history.goBack();
        }
    }

    return (
        <>
            <div className={className}>
                <div className="heading__container">              
                    <div className="title__container">
                        {!hideBackButton &&
                            <div>
                                <Button className="back-button__link" variant="link" data-testid='back-button-link' onClick={goBack}>
                                    <FontAwesomeIcon icon={faArrowLeft} className="back__icon" />
                                </Button>
                            </div>
                        }
                        <div data-testid='back-button-label' className='icon__label'>
                            {label ?? ''} {showLegacyRingiIcon && <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip id='back-button-tooltip' className="tooltip-description__tooltip">{localized.legacy_ringi_message}</Tooltip>}>
                                <FontAwesomeIcon icon={faStickyNote} />
                            </OverlayTrigger>}                          
                        </div>                       
                    </div>
                    
                    <div className="button__container">
                        {showCancel &&
                            <div data-testid='cancel-button__flexitem' className='cancel-button__flexitem'>
                                <Button data-testid='btn-cancel-ringi' className="btn-cancel-ringi" onClick={() => onCancelClick()}>
                                    <span>{localized.buttons.cancel_ringi}</span>
                                </Button>
                            </div>
                        }
                        {showCopyRingi &&
                            <div data-testid='copy-ringi-button__flexitem' className='copy-ringi-button__flexitem'>
                                <Button data-testid='btn-copy-ringi' className="btn-copy-ringi" onClick={() => onCopyRingiClick()}>
                                    <span>{localized.buttons.copy_ringi}</span>
                                </Button>
                            </div>
                        } 
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};