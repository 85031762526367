
import React from 'react';
import TemplateEditor from 'pages/Configuration/Editors/TemplateEditor';
import { useBaseRingiTemplate, useCategoryTemplate } from 'pages/Configuration/providers/templateContextProvider';

export default ({ categoryLayerId, isLocked }) => {
    const
        baseRingiTemplate = useBaseRingiTemplate(),
        categoryTemplate = useCategoryTemplate();

    return (
        <>
            <TemplateEditor name="Base Fields" layerId={baseRingiTemplate.layerId}
                templateFields={baseRingiTemplate.templateFields} locked={true} reload={baseRingiTemplate.reload} />
            <TemplateEditor name="Category Fields" layerId={categoryLayerId} showDisabledFields={categoryTemplate.showDisabledFields}
                templateFields={categoryTemplate.templateFields} locked={isLocked} reload={categoryTemplate.reload} />
        </>
    )
}