import React, { useEffect, useState, useCallback } from "react";
import { fetch } from 'services/api';
import { useScreenDetect } from 'hooks/useScreenDetect';
import InfiniteScroll from 'react-infinite-scroll-component';
import NotificationsList from "./NotificationsList";
import { useNotificationsClaim } from "providers/notificationsProvider";
import { useAuthorizationClaims } from 'providers/authorizationClaimsProvider';
import { useLocale } from "services/localization/localizationContextProvider";
import './ViewNotificationsPage.scss';

export default function ViewNotificationsPage(){
    const { isMobile } = useScreenDetect();
    const { isAdmin } = useAuthorizationClaims();
    const notifications = useNotificationsClaim();
    const localizedNotifications = useLocale().pages.notifications.list;


    const NOTIFICATION_PAGE_COUNT = 100;
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    const [hasMoreNotifications, setHasMoreNotifications] = useState(true);
    const [notificationNextPage, setNotificationNextPage] = useState(1);
    const [isViewingAdminNotifications, setIsViewingAdminNotifications] = useState(false); //set via tabs at top of screen

    const onTabSelect = async (isViewingAdminTab) => {
        if(isViewingAdminNotifications !== isViewingAdminTab){
            await setSelectedNotifications([]);
            await setIsViewingAdminNotifications(isViewingAdminTab);
            await setNotificationNextPage(1);
        }
    }

    const fetchNotifications = useCallback(async () => {
        let notifications = [];
        if(isViewingAdminNotifications){
            notifications = await fetch(`notifications/admin?page=${notificationNextPage}&count=${NOTIFICATION_PAGE_COUNT}`) ?? [];
        }else{
            notifications = await fetch(`notifications?page=${notificationNextPage}&count=${NOTIFICATION_PAGE_COUNT}`) ?? [];
        }

        if (notifications.length < NOTIFICATION_PAGE_COUNT) {
            setHasMoreNotifications(false);
        }

        setSelectedNotifications(previousNotifications => [...previousNotifications, ...notifications])
    }, [notificationNextPage, isViewingAdminNotifications]);

    const fetchMoreData = async () => {
        setNotificationNextPage(rnp => rnp + 1);
    }

    useEffect(() => {
        fetchNotifications();
    }, [fetchNotifications]);

    return (
        <>
            <div className={isMobile ? "notifications__mobile-header" : "notifications__header"}>
                <div data-testid="notifications-header__notifications-tab" 
                     onClick={() => onTabSelect(false)} className={isViewingAdminNotifications ? "" : "active"}>
                    {localizedNotifications.notifications_tab}
                    {(notifications?.normalNotificationsCount > 0) &&
                        <i className="notifications__notification-number">
                            {notifications?.normalNotificationsCount}
                        </i> 
                    }
                </div>
                {isAdmin &&
                <div data-testid="notifications-header__admin-notifications-tab" 
                     onClick={() => onTabSelect(true)} className={isViewingAdminNotifications ? "active" : ""}>
                    {localizedNotifications.admin_notifications_tab}
                    {(notifications?.adminNotificationsCount > 0) &&
                        <i className="notifications__notification-number">
                            {notifications?.adminNotificationsCount}
                        </i>
                    }
                </div>
                }
            </div>
            <InfiniteScroll
            dataLength={selectedNotifications.length}
            next={fetchMoreData}
            hasMore={hasMoreNotifications}>
                    <NotificationsList notifications={selectedNotifications} />
            </InfiniteScroll>
        </>
    )
};