import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { useLocale } from 'services/localization/localizationContextProvider';
import { httpDelete } from 'services/api';
import AttachedFile from 'controls/ValidationFileInput/AttachedFile';
import { useModal, ModalBody, ModalButtons, ModalOkButton, ModalCancelButton } from 'controls/ActionModal/ActionModal';
import { ATTACHMENT_TYPES } from 'constants/enums';
import 'controls/ValidationFileInput/AttachedFiles.scss';

export default function AttachedFiles({ files, validationLevel, attachmentType, onChange, multiple, buttonLabelText, iconOnly }) {
    let attachButtonLabel = useLocale().buttons.attach;
    const localizedRemoveFileAttachment = useLocale().modals.confirmation.removeFileAttachment;
    const [attachedFiles, setAttachedFiles] = useState([]);
    const [FileRemoveConfirmationModal, showFileRemoveConfirmationModal] = useModal();
    const newAttachmentCounter = useRef(-1);

    if (buttonLabelText) {
        attachButtonLabel = buttonLabelText;
    }

    const detachRingiAttachment = async (ringiId, storageId) => {
        const databaseDeletePromise = httpDelete(`ringis/${ringiId}/attachments/${storageId}`);
        const storageDeletePromise = httpDelete(`attachments/ringis/${ringiId}/${storageId}`);

        await Promise.all([databaseDeletePromise, storageDeletePromise]);
    };

    const detachTemplateFieldAttachment = async (storageId) => {
        const storageDeletePromise = httpDelete(`attachments/templatefields/${storageId}`);

        await Promise.all([storageDeletePromise]);
    };

    const detachCommentFieldAttachment = async (ringiCommentId, storageId) => {
        const commentDeletePromise = httpDelete(`attachments/comments/${ringiCommentId}/${storageId}`);
        await Promise.all([commentDeletePromise]);
    }

    const detachFile = async (attachedFileToRemove) => {
        switch(attachmentType){
            case ATTACHMENT_TYPES.Ringi: 
                await detachRingiAttachment(attachedFileToRemove.ringiId, attachedFileToRemove.storageId);
                break;
            case ATTACHMENT_TYPES.TemplateField:
                await detachTemplateFieldAttachment(attachedFileToRemove.storageId);
                break;    
            case ATTACHMENT_TYPES.Comment:
                await detachCommentFieldAttachment(attachedFileToRemove.ringiId, attachedFileToRemove.storageId);
                break;
        }
    }

    const handleDeleteFileConfirmation = async (attachedFileToRemove) => {
        await detachFile(attachedFileToRemove);
        removeFileFromList(attachedFileToRemove);
    };

    const updateAttachedFiles = (updatedAttachedFiles) => {
        setAttachedFiles(updatedAttachedFiles);
        onChange(updatedAttachedFiles);
    }

    const removeFileFromList = (attachedFileToRemove) => {
        var updatedAttachedFiles = attachedFiles.filter(attachedFile => attachedFile.storageId !== attachedFileToRemove.storageId);
        updateAttachedFiles(updatedAttachedFiles);
    };

    const confirmFileRemoval = (attachedFile) => {
        showFileRemoveConfirmationModal(attachedFile);
    };

    const handleFileRemove = (attachedFile) => {
        const fileHasNotYetBeenUploaded = attachedFile.fileToUpload && attachedFile.fileToUpload !== null;
        if (fileHasNotYetBeenUploaded) {
            removeFileFromList(attachedFile);
        }
        else {
            confirmFileRemoval(attachedFile);
        }
    };

    const handleNewAttachment = (event) => {
        const fileToAttach = event.target.files[0];

        if (fileToAttach) {
            var updatedAttachedFiles = [...attachedFiles, {
                id: newAttachmentCounter.current--,
                name: fileToAttach.name,
                storageId: Date.now(),
                fileToUpload: new File([fileToAttach], fileToAttach.name, { type: fileToAttach?.type })
            }];
            updateAttachedFiles(updatedAttachedFiles);
            event.target.value = null;
        }
    };

    useEffect(() => {
        setAttachedFiles(files ?? []);
    }, [files]);

    return (
        <>
            { (multiple || attachedFiles.length === 0) &&
                <>
                {iconOnly ?
                    <span className="attached-files__file-input-wrapper">
                        <label className="attached-files__icon" data-testid="attach-file-button">
                            <FontAwesomeIcon icon={faPaperclip} />
                            <input type="file" className="attached-files__file-input" onChange={handleNewAttachment} />
                        </label>
                    </span>
                    :
                    <span className="attached-files__file-input-wrapper">
                        <label className="btn btn-primary" data-testid="attach-file-button">
                            <FontAwesomeIcon icon={faPaperclip} /> {attachButtonLabel}
                            <input type="file" className="attached-files__file-input" onChange={handleNewAttachment} />
                        </label>
                    </span>
                }
                </>
            }
            {
                attachedFiles?.map((attachedFile, i) => {
                    return (
                        <AttachedFile key={i} file={attachedFile} fieldName={`attachedFile-${attachedFile.id}`}
                            validationLevel={validationLevel} attachmentType={attachmentType}
                            onRemove={handleFileRemove} />
                    );
                })
            }
            <FileRemoveConfirmationModal variant='confirmation' title={localizedRemoveFileAttachment.title}>
                <ModalBody>{localizedRemoveFileAttachment.message}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton text={localizedRemoveFileAttachment.cancel} />
                    <ModalOkButton text={localizedRemoveFileAttachment.success} onClick={handleDeleteFileConfirmation} hideIcon />
                </ModalButtons>
            </FileRemoveConfirmationModal>
        </>
    );
}