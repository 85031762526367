import React, { useState, useRef, useEffect } from 'react';
import Grid, { GridHeader, GridRow, GridRowCell } from 'controls/Grid/Grid';
import { NavLink } from 'react-router-dom';
import RingiTab from 'pages/Ringis/components/RingiTab';
import { useScreenDetect } from 'hooks/useScreenDetect';
import { RINGI_STATUS } from 'constants/enums';
import { LocalDate } from 'services/localization/dateTimeService';
import 'pages/Ringis/components/RingiList.scss';

export default function RingiList({ ringis, columns, emptyMessage, onSortOrderChanged }) {
    const { isMobile } = useScreenDetect();
    const [columnsToDisplay, setColumnsToDisplay] = useState([]);
    const [formattedRingis, setFormattedRingis] = useState([]);

    const getColumnWithAdditionalMetadata = (column) => {
        switch (column.key) {
            case 'subject':
                column.maxWidth = 250;
                break;
            case 'status':
                column.className = "ringi-list__status";
                break;
            case 'daysToApprove':
                column.maxWidth = 180;
                break;
            default:
                break;
        }
        return column;
    };

    const FormatRingiForDisplay = (ringi) => {
        ringi.submittedDate = LocalDate(ringi.submittedDateIso8601);
        ringi.completedDate = LocalDate(ringi.completedDateIso8601);

        switch (ringi.statusId) {
            case RINGI_STATUS.Pending:
            case RINGI_STATUS.PendingAdminReview:
            case RINGI_STATUS.PendingJapan:
                ringi.classNameModifier = "pending";
                ringi.displayDate = ringi.submittedDate;
                break;
            case RINGI_STATUS.Approved:
            case RINGI_STATUS.ApprovedWithConditions:
                ringi.classNameModifier = "approved";
                ringi.displayDate = ringi.completedDate;
                break;
            case RINGI_STATUS.Denied:
                ringi.classNameModifier = "denied";
                ringi.displayDate = ringi.completedDate;
                break;
            case RINGI_STATUS.DraftError:
                ringi.classNameModifier = "draftError";
                ringi.displayDate = ringi.completedDate;
                break;
            case RINGI_STATUS.Cancelled:
                ringi.classNameModifier = "cancelled";
                ringi.displayDate = ringi.completedDate;
                break;
            case RINGI_STATUS.Paused:
                ringi.classNameModifier = "paused";
                ringi.displayDate = ringi.submittedDate;
                break;
            default:
                ringi.classNameModifier = "";
                ringi.displayDate = "--";
                break;
        }

        return ringi;
    };

    useEffect(() => {
        if (columns == null || columns.length === 0) {
            setColumnsToDisplay([]);
        }
        else {
            const columnsWithMetadata = columns
                .filter(col => col.isShown)
                .map((col) => getColumnWithAdditionalMetadata(col));

            setColumnsToDisplay([
                ...columnsWithMetadata
            ]);
        }
    }, [columns]);

    useEffect(() => {
        setFormattedRingis(ringis.map((ringi) => FormatRingiForDisplay(ringi)));
    }, [ringis]);

    const getNavLink = (ringi) => {
        return ringi.statusId === RINGI_STATUS.Draft || ringi.statusId === RINGI_STATUS.DraftError ? `/ringis/draft/${ringi.id}` : `/ringis/${ringi.id}`;
    }

    return (
        <>
            {isMobile ?
                formattedRingis.length === 0 ?
                    <span data-testid="empty-message">{emptyMessage}</span>
                    :
                    ringis.map((ringi, i) => {
                        return (
                            <NavLink key={i} to={getNavLink(ringi)} className="ringi-list__nav-item">
                                <RingiTab ringi={ringi} />
                            </NavLink>
                        );
                    })
                :
                <Grid rows={formattedRingis} columns={columnsToDisplay}>
                    <GridHeader 
                        columns={columnsToDisplay}
                        onSortColumn={onSortOrderChanged}
                    >
                    </GridHeader>
                    {

                        formattedRingis.length === 0 ?
                            <span data-testid="empty-message">{emptyMessage}</span>
                            :
                            formattedRingis.map((ringi, i) => {
                                return (
                                    <GridRow key={i} row={ringi} columns={columnsToDisplay}>
                                        <NavLink to={getNavLink(ringi)} className="ringi-list__nav-item">
                                            {
                                                columnsToDisplay.map((column, i) => {
                                                    if (column.key === 'daysToApprove') {
                                                        return (
                                                            <GridRowCell type='NumberPill' key={i} row={ringi} column={column}></GridRowCell>
                                                        );
                                                    }
                                                    else if (column.key === 'ringiNumber'){
                                                        return (
                                                            <GridRowCell key={i} row={ringi} column={column} className={"ringi-list__cell-text ringi-list__ringi-number-cells"} />
                                                        );
                                                    }
                                                    else if (ringi.isLegacyRingi && column.key === 'subject') {
                                                        return (
                                                            <GridRowCell type='LegacyRingiSubject' key={i} row={ringi} column={column} className={"ringi-list__cell-text ringi-list__ringi-number-cells"} />
                                                        );
                                                    }
                                                    return (
                                                        <GridRowCell key={i} row={ringi} column={column} className={"ringi-list__cell-text"} />
                                                    );
                                                })
                                            }
                                        </NavLink>
                                    </GridRow>
                                );
                            })
                    }
                </Grid>
            }
        </>
    );
}
