import React from 'react';
import ToggleSwitch from 'controls/ToggleSwitch/ToggleSwitch';
import { useLocale } from 'services/localization/localizationContextProvider';
import { useBaseRingiTemplate, useCategoryTemplate, useTypeTemplate } from 'pages/Configuration/providers/templateContextProvider';

export default ({ layerTypeId }) => {
    const localizedConfigurationPage = useLocale().configuration_page;
    let toggleDisabled = null;

    if (layerTypeId === 1) {
        toggleDisabled = useBaseRingiTemplate().toggleDisabledFieldsVisibility;
    }
    if (layerTypeId === 2) {
        toggleDisabled = useCategoryTemplate().toggleDisabledFieldsVisibility;
    }
    if (layerTypeId === 3) {
        toggleDisabled = useTypeTemplate().toggleDisabledFieldsVisibility;
    }

    return (
        <>
            <ToggleSwitch data-testid="toggle-disable-fields" label={localizedConfigurationPage.disable_field_toggle_text} onSwitch={toggleDisabled} />
        </>
    )
}