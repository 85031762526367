import React from 'react';
import { Image } from 'react-bootstrap';
import CorporateLogo from 'images/SMC-logo.svg';
import 'pages/Login/Logo.scss';

const Logo = ({ ...rest }) => {

    return (
        <div className="logo" {...rest}>
            <div className="logo__content">
                <Image className="logo__image" src={CorporateLogo} alt="SMC corporate logo" />
                <div className="logo__appName">Ringi Approval Application</div>
            </div>
        </div>
    );
};



export default Logo;
