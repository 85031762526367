import React, { useEffect, useState } from 'react';
import { useLocale } from 'services/localization/localizationContextProvider';
import { Image, Navbar, Nav, Dropdown } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faArrowLeft, faCog, faExclamationTriangle, faSignOutAlt, faFileSignature, faFolder } from '@fortawesome/free-solid-svg-icons';
import Authorized from 'components/Authorized';
import { useOktaAuth } from '@okta/okta-react';
import { useScreenDetect } from 'hooks/useScreenDetect';
import { UserProfile } from 'controls/NavMenu/components/UserProfile/UserProfile';
import { UserProfileNotification } from 'controls/NavMenu/components/UserProfile/UserProfileNotification';
import { useAuthorizationClaims } from 'providers/authorizationClaimsProvider';
import { fetch } from 'services/api';
import classNames from 'classnames';
import SMCRingiImg from 'images/SMC-Ringi.png';
import 'controls/NavMenu/NavMenu.scss';
import { useUserContextProvider } from 'providers/userContextProvider';
import { DEFAULT_LANDING_PAGE } from 'constants/constants';

export default function NavMenu() {

    const
        { oktaAuth } = useOktaAuth(),
        localized = useLocale(),
        { isAdmin } = useAuthorizationClaims(),
        [slideoutIsVisible, setSlideoutIsVisible] = useState(false),
        [configurationSelected, setConfigurationSelected] = useState(false),
        { isDesktop, isMobile } = useScreenDetect(),
        history = useHistory(),
        { userDefaultLandingPage } = useUserContextProvider(),

        hideSlideout = () => {
            setSlideoutIsVisible(false);
        };

    useEffect(() => {
        document.addEventListener("click", (event) => {
            const clickInsideSlideout = event.target.closest("#basic-navbar-nav");
            const clickOnToggle = event.target.closest('#hamburgerToggle');

            if (clickInsideSlideout || clickOnToggle) {
                return;
            }

            setSlideoutIsVisible(false);
        });
    }, []);

    const configurationMenuItems = [
        {
            text: localized.nav_menu_links.category,
            action: () => history.push('/admin/configuration/0')
        },
        {
            text: localized.nav_menu_links.cost_center,
            action: () => history.push('/admin/costcenter')
        },
        {
            text: localized.nav_menu_links.approval,
            action: () => history.push('/admin/approval')
        },
        {
            text: localized.nav_menu_links.division_management,
            action: () => history.push('/admin/division')
        },
        {
            text: localized.nav_menu_links.application_settings,
            action: () => history.push('/admin/applicationSettings')
        }];

    const handleLogout = async (event) => {
        event.preventDefault();
        await oktaAuth.signOut("/");
    };

    const handleReportAppIssue = async (event) => {
        hideSlideout();

        const reportAppIssueConfig = await fetch('notifications/reportAppIssueEmailSettings');
        var ccEmailInfo = reportAppIssueConfig?.emailCc ? `cc=${reportAppIssueConfig?.emailCc}&` : '';
        
        window.open(`mailto:${reportAppIssueConfig?.emailTo}?${ccEmailInfo}subject=${localized.report_app_issue.subject}&body=${localized.report_app_issue.body}`);
    };

    useEffect(() => {
        setConfigurationSelected(
            history.location.pathname.includes("/configuration") ||
            history.location.pathname.includes("/costcenter") ||
            history.location.pathname.includes("/division") ||
            history.location.pathname.includes("/approval"));
    }, [history.location.pathname])

    return (
        <header className="page__header">
            <Navbar className="row navbar-custom" expand="lg" onToggle={() => setSlideoutIsVisible(!slideoutIsVisible)}>
                {slideoutIsVisible &&
                    <span>
                        <Navbar.Toggle id="navBarBackArrowToggle" className="navbar-back-arrow" aria-controls="basic-navbar-nav">
                            <FontAwesomeIcon icon={faArrowLeft} color="white" size="1x" />
                        </Navbar.Toggle>
                    </span>
                }
                {!slideoutIsVisible &&
                    <span>
                        <Navbar.Toggle id="hamburgerToggle" className="navbar-hamburger" aria-controls="basic-navbar-nav">
                            <FontAwesomeIcon icon={faBars} color="white" size="1x" />
                        </Navbar.Toggle>
                    </span>
                }
                <span className={classNames({ 'navbar-brand': isMobile })}>
                    <Navbar.Brand as={NavLink} to={userDefaultLandingPage ?? DEFAULT_LANDING_PAGE} className="ringi-navbar-brand">
                        <Image className={isDesktop ? "ringi-navbar-brand__logo" : "ringi-navbar-brand__logo-mobile"} src={SMCRingiImg} alt="SMC corporate logo" />
                    </Navbar.Brand>
                </span>
                <Navbar.Collapse id="basic-navbar-nav" className={classNames('ringi-navbar ringi-slideout', { 'ringi-slideout--visible': slideoutIsVisible })}>

                    {isMobile &&
                        <UserProfile />
                    }

                    <Nav className="mr-auto ml-auto navbar-container">
                        {!isMobile &&
                            <Nav.Link
                                onClick={hideSlideout}
                                aria-label={localized.nav_menu_links.ringis}
                                data-testid="ringis-link"
                                className="ringi-navbar__link ringis"
                                as={NavLink}
                                to="/ringis/current">
                                {localized.nav_menu_links.ringis}
                            </Nav.Link>
                        }
                        {isMobile &&
                            <div className="ringi-navbar__link-container">
                                <Nav.Link
                                    onClick={hideSlideout}
                                    aria-label={localized.nav_menu_links.ringis}
                                    data-testid="ringis-link"
                                    className="ringi-navbar__link ringi-navbar__link-mobile"
                                    as={NavLink}
                                    to="/ringis/current">
                                    <FontAwesomeIcon
                                        className="ringi-navbar__link-icon"
                                        icon={faFileSignature} />
                                    {localized.nav_menu_links.ringis}
                                </Nav.Link>
                                <div className="ringi-navbar__link-divider" />
                                <Nav.Link
                                    onClick={hideSlideout}
                                    aria-label={localized.nav_menu_links.notification_preferences}
                                    data-testid="notification-preferences-link"
                                    className="ringi-navbar__link ringi-navbar__link-mobile"
                                    as={NavLink}
                                    to="/notifications/preferences">
                                    <FontAwesomeIcon
                                        className="ringi-navbar__link-icon"
                                        icon={faCog} />
                                    {localized.nav_menu_links.notification_preferences}
                                </Nav.Link>
                                <div className="ringi-navbar__link-divider" />
                                <Authorized isAuthorized={isAdmin}>
                                    <Nav.Link
                                        onClick={hideSlideout}
                                        aria-label={localized.nav_menu_links.notification_preferences}
                                        data-testid="notification-preferences-link"
                                        className="ringi-navbar__link ringi-navbar__link-mobile"
                                        as={NavLink}
                                        to="/reports">
                                        <FontAwesomeIcon
                                            className="ringi-navbar__link-icon"
                                            icon={faFolder} />
                                        {localized.nav_menu_links.reports}
                                    </Nav.Link>
                                    <div className="ringi-navbar__link-divider" />
                                </Authorized>
                                <Nav.Link
                                    onClick={handleReportAppIssue}
                                    aria-label={localized.nav_menu_links.report_app_issue}
                                    data-testid="report-app-issue-link"
                                    className="ringi-navbar__link ringi-navbar__link-mobile">
                                    <FontAwesomeIcon
                                        className="ringi-navbar__link-icon"
                                        icon={faExclamationTriangle} />
                                    {localized.nav_menu_links.report_app_issue}
                                </Nav.Link>
                                <div className="ringi-navbar__link-divider" />
                                <Nav.Link
                                    onClick={handleLogout}
                                    aria-label={localized.nav_menu_links.logout}
                                    data-testid="logout-link"
                                    className="ringi-navbar__link ringi-navbar__link-mobile">
                                    <FontAwesomeIcon
                                        className="ringi-navbar__link-icon"
                                        icon={faSignOutAlt} />
                                    {localized.nav_menu_links.logout}
                                </Nav.Link>
                                <div className="ringi-navbar__link-divider" />
                                <div className="ringi-navbar__version">{`Version: ${process.ringiEnv.REACT_APP_VERSION}`}</div>
                            </div>
                        }

                        {isDesktop &&
                            <Authorized isAuthorized={isAdmin}>
                                <Dropdown className={classNames("ringi-navbar__configuration-toggle", { "active": configurationSelected })} drop="down" >
                                    <Dropdown.Toggle as="span">
                                        {localized.nav_menu_links.configuration}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="ringi-navbar__configuration-menu">
                                        {configurationMenuItems?.map((menuOption, i) => {
                                            return (
                                                <Dropdown.Item key={i} className="configuration-context-menu__item" onSelect={menuOption.action}>
                                                    {menuOption.text}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Authorized>
                        }
                        {isDesktop &&
                            <Authorized isAuthorized={isAdmin}>
                                <Nav.Link
                                    onClick={hideSlideout}
                                    aria-label={localized.nav_menu_links.reports}
                                    data-testid="reports-link"
                                    className="ringi-navbar__link reports"
                                    as={NavLink}
                                    to="/reports">
                                    {localized.nav_menu_links.reports}
                                </Nav.Link>
                            </Authorized>
                        }
                    </Nav>

                    {isDesktop &&
                        <div className="navbar-custom">
                            <UserProfile />
                        </div>
                    }

                </Navbar.Collapse>

                {isMobile &&
                    <UserProfileNotification />
                }

            </Navbar>

        </header>
    );
}