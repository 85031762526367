import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import 'components/TooltipDescription/TooltipDescription.scss';

const TooltipDescription = ({ tooltipName, description, validationError }) => {
    const [showAsError, setShowAsError] = useState(false);
    const tooltipId = "tt-" + tooltipName;

    useEffect(() => {
        if (validationError && validationError.message.trim() !== '') {
            setShowAsError(true);
        }
        else {
            setShowAsError(false);
        }
    }, [validationError]);

    return (
        <>
            <span id={tooltipName} data-testid={tooltipName}>
                <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip id={tooltipId} className={classnames("tooltip-description__tooltip", { "tooltip-description__tooltip--error": showAsError })}>{description}</Tooltip>}>
                    <span>
                        <FontAwesomeIcon data-testid="tooltip-icon" icon={faInfoCircle} size="1x" className={classnames("tooltip-description__icon", { "tooltip-description__icon--error": showAsError })} />
                    </span>
                </OverlayTrigger>
            </span>
        </>
    );
}

export default TooltipDescription;
