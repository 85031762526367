import React from 'react'
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { downloadFile } from 'services/api';
import { ATTACHMENT_TYPES } from 'constants/enums';
import 'controls/DownloadLink/DownloadLink.scss';
import Chip from 'components/Chip/Chip';

export default function DownloadLink({ attachmentType, fileName, ringiAttachmentId, templateFieldId, ringiCommentAttachmentId, className }) {
    const handleFileDownload = async () => {
        switch (attachmentType) {
            case ATTACHMENT_TYPES.Ringi:
                await downloadFile(`attachments/ringis/${ringiAttachmentId}`, fileName);
                break;
            case ATTACHMENT_TYPES.TemplateField:
                await downloadFile(`attachments/templatefields/${templateFieldId}`, fileName);
                break;
            case ATTACHMENT_TYPES.Comment:
                await downloadFile(`attachments/ringis/comments/${ringiCommentAttachmentId}`, fileName);
                break;
            default:
        }
    };

    return (
        <div className="download-link__container" data-testid="download-link">
            <Chip 
                label={fileName}
                click={handleFileDownload}
                icon={faFile}
                data-testid="download-link-chip">
            </Chip>
        </div>
    );
};