import React, { useEffect, useState } from 'react';
import { Nav, Button } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useLocale } from 'services/localization/localizationContextProvider';
import { post } from 'services/api';
import { useConfigurationPage } from 'pages/Configuration/providers/configurationPageContextProvider';
import CategoryCard from 'pages/Configuration/Category/components/CategoryCard.js';
import CategoryEditor from 'pages/Configuration/Category/components/CategoryEditor';
import classNames from 'classnames';
import ToggleSwitch from 'controls/ToggleSwitch/ToggleSwitch';
import 'pages/Configuration/Category/components/CategoryList.scss';

export default function CategoryList({ toggleOn = false, removeSelectedStyling = false, onBaseCategorySelected }) {
    const localized = useLocale();
    const [showNewCategoryForm, setShowNewCategoryForm] = useState(false);
    const [addCategoryDisabled, setAddCategoryDisabled] = useState(false);
    const { categories, showingEnabledCategories, toggleViewDisabledCategories, refreshCategories } = useConfigurationPage();
    const localizedCategoryList = useLocale().category_list;
    const history = useHistory();

    const handleAddNewCategory = () => {
        setShowNewCategoryForm(true);
    }

    const handleCategoryAdd = async (newCategoryName, categoryUsersList) => {
        const newCategoryId = await post(`categories`, JSON.stringify({ name: newCategoryName, userIds: categoryUsersList?.map((u) => { return u["id"] }) }));
        if (newCategoryId != null) {
            await refreshCategories(newCategoryId);
            history.push(`/admin/configuration/category/${newCategoryId}`);
        }
    }

    useEffect(() => {
        setAddCategoryDisabled(showNewCategoryForm || !showingEnabledCategories);
    }, [showNewCategoryForm, showingEnabledCategories, history]);

    const onSwitch = () => {
        toggleViewDisabledCategories();
        if (showingEnabledCategories) {
            history.push("/admin/configuration/1");
        } else {
            history.push("/admin/configuration/0");
        }
    }

    return (
        <>

            <div className="category-list__header category-list__section">
                <div className="category-list-header__controls">
                    <Button data-testid="add-category-button-small" className="category-list__add-new-category-small"
                        onClick={handleAddNewCategory}
                        disabled={addCategoryDisabled}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <ToggleSwitch label={localizedCategoryList.disable_category_toggle_text} onSwitch={onSwitch} toggleOn={toggleOn} />
                </div>

                {showNewCategoryForm &&
                    <CategoryEditor title="New Category Name*" className="category-list__new-category-section category-list__section"
                        onSave={handleCategoryAdd}
                        onClose={() => { setShowNewCategoryForm(false) }}
                    />
                }
            </div>

            <div className="category-list__content category-list__section">
                {showingEnabledCategories &&
                    <div className="category-card">
                        <Nav.Link as={NavLink} to="/admin/configuration/0" onClick={onBaseCategorySelected} data-testid="base-tab" className={classNames("category-card__name", { "category-card__name-unselected": removeSelectedStyling })} exact={true}>
                            <span>{localized.configuration_page.menu_base}</span>
                            <FontAwesomeIcon icon={faLock} />
                        </Nav.Link>
                    </div>
                }
                {categories?.sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    } else {
                        return -1;
                    }
                }).map((category, i) => {
                    return (
                        <CategoryCard key={i} category={category} isEnabled={showingEnabledCategories} />
                    )
                })}
            </div>

            <div className="category-list__footer">
                <Button className="category-list__add-new-category-full" data-testid="add-category-button-large" onClick={handleAddNewCategory} disabled={addCategoryDisabled}>
                    <FontAwesomeIcon icon={faPlus} />
                    <span>Add Category</span>
                </Button>
            </div>

        </>
    );
}