import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import RingiPage, { PageContent, PageHeader } from 'controls/RingiPage/RingiPage';
import CostCenterEditor from 'pages/CostCenter/components/CostCenterEditor';
import CostCenterTab from 'pages/CostCenter/components/CostCenterTab';
import { fetch, post } from 'services/api';
import { useLocale } from 'services/localization/localizationContextProvider';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'pages/CostCenter/CostCenterPage.scss';
import ToggleSwitch from 'controls/ToggleSwitch/ToggleSwitch';
import { useAuthorizationClaims } from 'providers/authorizationClaimsProvider';

const CostCenterPage = () => {
    const defaultShowDisabledCostCentersToggleState = false;
    const [costCenters, setCostCenters] = useState([]);
    const [filteredCostCenters, setFilteredCostCenters] = useState(costCenters);
    const [addingCostCenter, setAddingCostCenter] = useState(false);
    const [showDisabledCostCenters, setShowDisabledCostCenters] = useState(defaultShowDisabledCostCentersToggleState);
    const localizedCostCenters = useLocale().pages.cost_center;
    const { isAdmin } = useAuthorizationClaims();

    const fetchCostCenters = useCallback(async () => {
        setCostCenters(await fetch(`costcenters`));
    }, []);

    useEffect(() => {
        setFilteredCostCenters(showDisabledCostCenters ? costCenters : costCenters.filter(x => x.isEnabled));
    }, [showDisabledCostCenters, costCenters])

    const handleEditorClosing = async (shouldSave, costCenterToSave) => {
        if (shouldSave) {
            await post(`costcenters`, {
                number: costCenterToSave.number,
                id: costCenterToSave.id,
                description: costCenterToSave.description
            });
            await fetchCostCenters();
        }
        setAddingCostCenter(false);
    }

    useEffect(() => {
        fetchCostCenters();
    }, [fetchCostCenters]);

    return (
        <RingiPage>
            <PageContent primaryMobileContent>
                <PageHeader className="cost-center-header" title={localizedCostCenters.page_title}>
                    <Button className="new-cost-center-button" variant="primary" onClick={() => setAddingCostCenter(true)}>
                        <FontAwesomeIcon icon={faPlus} />
                        <span>{localizedCostCenters.new_button}</span>
                    </Button>
                </PageHeader>
                {isAdmin &&
                    <div data-testid='hide-inactive-toggle-container' className='disabled-toggle'>
                        <ToggleSwitch label={localizedCostCenters.display_disabled_cost_centers_toggle} toggleOn={defaultShowDisabledCostCentersToggleState} onSwitch={(event) => setShowDisabledCostCenters(event)} />
                    </div>
                }
                <div className="cost-centers">
                    {addingCostCenter &&
                        <CostCenterEditor
                            onClose={handleEditorClosing}
                            costCenter={{ id: -1 }}
                            headerText={localizedCostCenters.new_cost_center_title}
                            addMode />
                    }
                    {
                        filteredCostCenters.map((costCenter, i) => {
                            return <CostCenterTab key={i} costCenter={costCenter} onUpdate={fetchCostCenters} />
                        })

                    }
                </div>
            </PageContent>
        </RingiPage>
    );
};

export default CostCenterPage;