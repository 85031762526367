import React from 'react';
import './PillText.scss';

const PillText = ({ className, text, variant }) => {
    const innerClassAddendum = variant == 'Positive' ? '--positive' : '--negative';
    const shouldRender = text != null && text !== '';

    return (
        <>
            <div className={className}>
            {shouldRender &&
                <div className={'pilltext__body' + innerClassAddendum}>
                    {text}
                </div>}
            </div>
        </>
    )
}

export default PillText;