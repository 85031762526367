import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import 'controls/ContextMenu/ContextMenu.scss';


export default function ContextMenu({ menuItems, className, rotated = false, dropDirection = "down" }) {
    return (
        <Dropdown className={className} drop={dropDirection} >
            <Dropdown.Toggle as="span"
                bsPrefix={classNames('context-menu', { 'context-menu--disabled': menuItems == null || menuItems.length === 0, 'context-menu--rotated': rotated })}
                id="context-menu" data-testid="context-menu-toggle">
                <FontAwesomeIcon icon={faEllipsisH} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    menuItems?.map((menuOption, i) => {
                        if (menuOption.condition == null || menuOption.condition()) {
                            return (
                                <Dropdown.Item key={i} className="context-menu__item" data-testid={`menu-item-${i}`} onSelect={menuOption.action}>
                                    {menuOption.text}
                                </Dropdown.Item>
                            );
                        }
                        return null;
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    );
}