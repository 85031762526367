import React, { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import RingiPage, { PageContent, PageDirtyPrompt } from 'controls/RingiPage/RingiPage';
import { put, post } from 'services/api';
import { FormValidationError, GeneralError } from 'components/ValidationError';
import RingiEditor from 'pages/Ringis/components/RingiEditor';
import { useLocale } from 'services/localization/localizationContextProvider';
import { useHistory } from 'react-router-dom';
import { useModal, ModalBody, ModalButtons, ModalOkButton } from 'controls/ActionModal/ActionModal';
import { useValidationService } from 'providers/validationServiceContextProvider';
import { attachRingiAttachments } from 'services/attacher';
import 'pages/Ringis/DraftRingiPage.scss';
import { ROUTES } from 'constants/constants';
import { usePageHistoryContextProvider } from 'providers/pageHistoryContextProvider';
import RingiHeader from 'controls/RingiHeader/RingiHeader';

export default function DraftRingiPage({ location }) {
    const { ringiId, copyFromRingiId } = useParams();
    const methods = useForm({ mode: 'all' });
    const localized = useLocale();
    const localizedDraftRingiPage = localized.pages.draft_ringi;
    const history = useHistory();
    const [RingiSavedNotification, showRingiSavedNotification] = useModal();
    const [RingiSubmittedNotification, showRingiSubmittedNotification] = useModal();
    const [RingiSavedDraftErrorNotification, showRingiSavedDraftErrorNotification] = useModal();
    const newRingiId = useRef();
    const resetRingiRef = useRef();
    const { getFieldsForValidation } = useValidationService();
    const [generalErrorMessage, setGeneralErrorMessage] = useState();
    const { getLastPage } = usePageHistoryContextProvider();

    const onSaveSubmit = useCallback(async (submitting) => {

        const mapFormDataForSave = (ringiId, formData) => {
            var data = [];
            for (const property in formData['custom-fields']) {
                const propertyNameWithoutPrefix = property.replace('field-id-', '');
                const templateFieldId = parseInt(propertyNameWithoutPrefix);
                const existingField = data.find((ringiField) => ringiField.templateFieldId === templateFieldId);
                let propertyValue = formData['custom-fields'][property];
                if (Number.isNaN(propertyValue) || !propertyValue) {
                    propertyValue = null;
                }

                if (existingField) {
                    if (propertyValue && existingField.value) {
                        existingField.value += `;${propertyValue}`;
                    }else{
                        existingField.value = propertyValue;
                    }
                }
                else {
                    data.push({
                        templateFieldId: templateFieldId,
                        value: propertyValue?.toString().trim()
                    });
                }
            }

            let attachments = [];
            if (formData.ringiAttachments) {
                formData.ringiAttachments.forEach((attachment) => {
                    attachments.push({
                        id: attachment.id < 0 ? 0 : attachment.id,
                        name: attachment.name,
                        storageId: attachment.storageId
                    });
                });
            }

            return {
                ringi: {
                    subject: formData.subject,
                    categoryId: formData.categoryId,
                    typeId: formData.typeId,
                    costCenterId: formData.costCenterId,
                    ringiStatusId: formData.ringiStatusId,
                    createdByUserId: formData.createdByUserId,
                    dollarAmountRequested: formData.dollarAmountRequested,
                    opportunityCosts: formData.opportunityCosts,
                    alternatives: formData.alternatives,
                    purpose: formData.purpose,
                    id: ringiId,
                    submittedDate: submitting ? new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) : null
                },
                approvalByDate: formData.approvalByDate,
                ringiFieldsToSave: data,
                ringiAttachments: attachments
            };
        }

        const processFormData = (formData) => {
            formData.purpose = formData.purpose === '' ? null : formData.purpose;
        }

        const createNewRingi = async (formData) => {
            processFormData(formData);
            var response = await post(`ringis`, mapFormDataForSave(0, formData));

            if (response != null) {
                newRingiId.current = response.id;

                if (formData.ringiAttachments) {
                    await attachRingiAttachments(newRingiId.current, formData.ringiAttachments);
                }

                methods.reset(formData);

                if (response.status === 'Draft - Error') {
                    showRingiSavedDraftErrorNotification();
                } else {
                    submitting ? showRingiSubmittedNotification() : showRingiSavedNotification();
                }
            }
        }

        const saveExistingRingi = async (formData) => {
            processFormData(formData);
            var response = await put(`ringis`, mapFormDataForSave(ringiId, formData));

            if (response != null) {
                newRingiId.current = ringiId;
                if (formData.ringiAttachments) {
                    await attachRingiAttachments(ringiId, formData.ringiAttachments);
                }
                if (resetRingiRef.current) {
                    await resetRingiRef.current();
                }

                if (response.status === 'Draft - Error') {
                    showRingiSavedDraftErrorNotification();
                } else {
                    submitting ? showRingiSubmittedNotification() : showRingiSavedNotification();
                }
            }
        }

        let isValid;
        if (submitting) {
            isValid = await methods.trigger();
        }
        else {
            var theSaveFieldsToValidate = getFieldsForValidation('save');
            isValid = await methods.trigger(theSaveFieldsToValidate);
        }

        if (isValid) {
            var formData = methods.getValues();
            if (ringiId == null) {
                await createNewRingi(formData);
            }
            else {
                await saveExistingRingi(formData);
            }
        }
    }, [ringiId, methods, getFieldsForValidation, showRingiSubmittedNotification, showRingiSavedNotification, showRingiSavedDraftErrorNotification]);

    const handleCloseSave = useCallback(() => {
        if (ringiId == null) {
            history.push(ROUTES.DRAFT_RINGIS_PAGE);
        } else {
            setGeneralErrorMessage('');
        }
    }, [history, ringiId]);

    const handleCloseSaveDraftError = useCallback(() => {
        if (ringiId == null) {
            history.push(ROUTES.DRAFT_RINGIS_PAGE);
        } else {
            setGeneralErrorMessage(localizedDraftRingiPage.ringi_admin_notified_for_corrections_message);
        }
    }, [history, ringiId, localizedDraftRingiPage]);

    const handleCloseSubmit = useCallback(() => {
        history.push(`/ringis/${newRingiId.current}`);
    }, [newRingiId, history]);

    const handleInvalidRingiLoaded = useCallback((ringiIdLoaded) => {
        if (ringiIdLoaded) {
            history.push(`/ringis/${ringiIdLoaded}`);
        }
        else if (history.location.pathname !== ROUTES.NEW_RINGIS && !copyFromRingiId) {
            history.push(ROUTES.NEW_RINGIS, location.state);
        }
    }, [history, location.state]);

    const handleRingiStatusDraftError = useCallback(() => {
        setGeneralErrorMessage(localizedDraftRingiPage.ringi_admin_notified_for_corrections_message);
    }, [localizedDraftRingiPage]);

    return (
        <RingiPage>
            <PageContent primaryMobileContent className="draft-ringi-page">
                <FormProvider {...methods}>
                    <form>
                        <RingiHeader
                            className="draft-ringi-page__header"
                            path={getLastPage()}
                            label={ringiId == null ? localizedDraftRingiPage.page_title_when_new : localizedDraftRingiPage.page_title_when_editing}
                            hideBackButton={false}
                        >
                            <div className="draft-ringi-page__buttons">
                                <GeneralError errorMessage={generalErrorMessage} className="draft-ringi-page__icon" />
                                <FormValidationError errorMessage={localized.errors_on_form} className="draft-ringi-page__icon" />
                                <Button variant="link" onClick={() => history.push(getLastPage())} data-testid="cancel-ringi-button" className="draft-ringi__cancel">
                                    {localizedDraftRingiPage.cancel_button}
                                </Button>
                                <Button onClick={() => onSaveSubmit(false)} data-testid="save-ringi-button" className="draft-ringi__save">
                                    <FontAwesomeIcon icon={faSave} />{localizedDraftRingiPage.save_button}
                                </Button>
                                <Button onClick={() => onSaveSubmit(true)} data-testid="submit-ringi-button" className="draft-ringi__submit">
                                    <FontAwesomeIcon icon={faFile} />{localizedDraftRingiPage.submit_button}
                                </Button>
                            </div>
                        </RingiHeader>
                        <RingiEditor ringiId={ringiId} copyFromRingiId={copyFromRingiId} resetRingiRef={resetRingiRef} onInvalidRingi={handleInvalidRingiLoaded} onRingiStatusDraftError={handleRingiStatusDraftError} />
                    </form>
                    <PageDirtyPrompt />
                </FormProvider>
            </PageContent>

            <RingiSavedNotification variant='confirmation' title={localizedDraftRingiPage.save_as_draft_notification_title}>
                <ModalBody>{localizedDraftRingiPage.save_as_draft_notification_message}</ModalBody>
                <ModalButtons>
                    <ModalOkButton onClick={handleCloseSave} />
                </ModalButtons>
            </RingiSavedNotification>

            <RingiSubmittedNotification variant='confirmation' title={localizedDraftRingiPage.submitted_notification_title}>
                <ModalBody>{localizedDraftRingiPage.submitted_notification_message}</ModalBody>
                <ModalButtons>
                    <ModalOkButton onClick={handleCloseSubmit} />
                </ModalButtons>
            </RingiSubmittedNotification>

            <RingiSavedDraftErrorNotification variant='confirmation' title={localizedDraftRingiPage.save_as_draft_error_notification_title}>
                <ModalBody>{localizedDraftRingiPage.save_as_draft_error_notification_message}</ModalBody>
                <ModalButtons>
                    <ModalOkButton onClick={handleCloseSaveDraftError} />
                </ModalButtons>
            </RingiSavedDraftErrorNotification>
        </RingiPage>

    );
}
