import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLocale } from 'services/localization/localizationContextProvider';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { post } from 'services/api';
import TypePanel from 'pages/Configuration/Category/TypePanel';
import TypePanelEditor from 'pages/Configuration/Category/TypePanelEditor';
import classNames from 'classnames';
import 'pages/Configuration/Category/TypeList.scss';

const TypeList = ({ types, onTypeChange, showingDisabledTypes, showingSearchResults = false }) => {
    const { categoryId } = useParams();
    const [showNewTypePanel, setShowNewTypePanel] = useState(false);
    const history = useHistory();

    const localizedButtons = useLocale().buttons;
    const localizedTypeList = useLocale().configuration_page.type_list;

    const handleAddNewType = async (typeName, sendEmailToZendesk) => {
        var newTypeId = await post(`categories/${categoryId}/types/${sendEmailToZendesk}`, {
            name: typeName
        });
        if (newTypeId != null) {
            await onTypeChange();
            history.push(`/admin/configuration/category/${categoryId}/type/${newTypeId}`);
        }
    };

    return (
        <div>
            <div data-testid="type-list" className="type-list">
                {types?.map((type, i) => {
                    if (showingDisabledTypes || type.isEnabled) {
                        return (
                            <TypePanel key={i} type={type} outOfFocus={showNewTypePanel} onTypeChange={onTypeChange} showingSearchResults={showingSearchResults} />
                        );
                    }
                    return null;
                })}
                {!showingSearchResults && showNewTypePanel &&
                    <div data-testid="add-type-editor-wrapper" className="type-list__add-type-editor">
                        <TypePanelEditor onClose={() => setShowNewTypePanel(false)} type={{}} onSubmit={handleAddNewType}>
                            <FontAwesomeIcon icon={faSave} />{localizedButtons.save}
                        </TypePanelEditor>
                    </div>
                }
                {!showingSearchResults && 
                    <div data-testid="add-type-button-wrapper" className={classNames("type-list__add-type", { "type-list__add-type--out-of-focus": showNewTypePanel })}>
                        <Button data-testid="add-type-button" onClick={() => setShowNewTypePanel(true)} className="type-list__add-type-button" disabled={showNewTypePanel}>
                            <FontAwesomeIcon icon={faPlus} />{localizedTypeList.add_type_text}
                        </Button>
                    </div>
                }
            </div>
        </div>
    );
};

export default TypeList;