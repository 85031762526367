import React, { useEffect, useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import ValidationError, { GetValidationError } from 'components/ValidationError';
import classnames from 'classnames';
import RingiInput from 'pages/Ringis/components/RingiInput';
import { fetch } from 'services/api';
import TooltipDescription from 'components/TooltipDescription/TooltipDescription';
import 'controls/ValidationBoolean/ValidationBoolean.scss';

export default function ValidationBoolean({ field, fieldName, validationRules, options, title, defaultValue, ringiId, tooltipDescription = null, showTooltip = false, ...rest }) {

    const { register, unregister, setValue, getValues } = useFormContext();
    const validationError = GetValidationError(fieldName);
    const [showChildFields, setShowChildFields] = useState(false);
    const [hasControlLoaded, setHasControlLoaded] = useState(false);

    const onOptionChanged = useCallback((e) => {
        if (field.childTemplateFieldsAssociatedToOptionA !== null) {
            if (field.childTemplateFieldsAssociatedToOptionA === true) {
                if (e === field.optionA) {
                    setShowChildFields(true);
                }
                else {
                    setShowChildFields(false);
                }
            }
            else {
                if (e === field.optionB) {
                    setShowChildFields(true);
                }
                else {
                    setShowChildFields(false);
                }
            }
        }
    }, [field.childTemplateFieldsAssociatedToOptionA, field.optionA, field.optionB]);

    const fetchInitialValue = useCallback(async () => {
        if (ringiId) {
            const val = await fetch(`ringis/${ringiId}/templateFieldValue/${field.id}`);
            onOptionChanged(val);
        }
    }, [ringiId, field.id, onOptionChanged]);

    useEffect(() => {
        fetchInitialValue();
        if (defaultValue !== "" && hasControlLoaded === false) {
            onOptionChanged(defaultValue);
            setHasControlLoaded(true);
        }
    }, [fetchInitialValue, defaultValue, hasControlLoaded, onOptionChanged]);

    useEffect(() => {
        if(!getValues(fieldName)){
            setValue(fieldName, defaultValue);
        }
    }, [setValue, getValues, fieldName, defaultValue]);

    useEffect(() => {
        return () => {
            if (setValue) { // make sure the parent FormProvider has not already been cleaned up
                setValue(fieldName, '');
                unregister(fieldName);
            }
        }
    }, [fieldName, unregister, setValue]);

    return (
        <div data-testid="boolean-wrapper" className={classnames("validation-boolean", { "validation-boolean--error": validationError })}>
            <div>
                <label data-testid="boolean-title" className="validation-boolean__label">{title}</label>
                {showTooltip === true && <TooltipDescription tooltipName={`${fieldName}-tooltip`} description={tooltipDescription} validationError={validationError} />}
                <ValidationError fieldName={fieldName} className="validation-boolean__icon" inline="true" />
            </div>
            {options?.map((option, i) => {
                if (i < 2) return <div key={i}>
                    <input type="radio" id={`${fieldName}-${option}`} data-testid="radio-option" value={option} name={`boolean-${fieldName}`}
                        className="validation-boolean__option" {...register(fieldName, validationRules)} onChange={e => onOptionChanged(e.currentTarget.value)}/>
                    <label htmlFor={`${fieldName}-${option}`} className="validation-boolean__option-label">{option}</label>
                </div>
            })}
            {showChildFields && field?.childTemplateFields?.map((childField, i) => (
                <div key={i} data-testid={`child-${i}`} className="ringi-editor-field">
                    <RingiInput field={childField} />
                </div>
            ))}
        </div>
    );
}