import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocale } from 'services/localization/localizationContextProvider';
import { useAuthorizationClaims } from 'providers/authorizationClaimsProvider';
import { Button } from 'react-bootstrap';
import { put } from 'services/api';
import ValidationTypeahead from 'controls/ValidationTypeahead/ValidationTypeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { requiredRule } from 'services/validationRules';
import { existsRule, inactiveRule } from 'services/serverValidationRules';
import { useSubmittedRingi } from 'pages/Ringis/providers/submittedRingiContextProvider';
import { APPROVER_RESPONSE } from 'constants/enums';
import { RINGI_STATUS } from 'constants/enums';
import 'pages/Ringis/components/Approvers/ApproverMenu.scss';

export default function ApproverMenu() {
    const methods = useForm({ reValidateMode: 'onSubmit' });
    const [canAddApprover, setCanAddApprover] = useState(false);
    const { loginName, isAdmin } = useAuthorizationClaims();
    const watchApproverSelection = methods.watch("additionalApprovers");
    const {
        pages: {
            view_ringi: {
                approval_chain: approvalChainLocalization
            }
        },
        typeahead_messages: typeaheadMessagesLocalization
    } = useLocale();
    const { isLockedDown, approvers, ringi, refreshApprovers, refreshRingi, approverAndReaderCandidates } = useSubmittedRingi();

    useEffect(() => {
        const approver = approvers.find(ra => ra.user?.loginName?.trim().toLocaleLowerCase() === loginName);
        const isApproverWhoHasNotApproved = approver?.approverResponseId === APPROVER_RESPONSE.Pending;
        const isPendingJapanApproval = ringi.statusId === RINGI_STATUS.PendingJapan;
        isPendingJapanApproval ? setCanAddApprover(false) : setCanAddApprover(!isLockedDown && (isAdmin || isApproverWhoHasNotApproved));
    }, [isLockedDown, isAdmin, loginName, approvers, ringi]);

    const addAdditionalApprover = async (formData) => {
        const response = await put(`ringis/${ringi.id}/approvers/${formData.additionalApprovers}`);
        if (response != null) {
            await refreshApprovers();
            await refreshRingi();
        }
        methods.reset();
    }

    return (
        <>
            {canAddApprover &&
                <div className="approver-menu__button-bar">
                    <FormProvider {...methods}>
                        <form data-testid="add-additional-approver-form" className="add-additional-approver" onSubmit={methods.handleSubmit(addAdditionalApprover)}>
                            <div className="add-approver-bar__approver-container">
                                <ValidationTypeahead
                                    fieldName="additionalApprovers"
                                    validationRules={{
                                        required: requiredRule,
                                        validate: {
                                            approverExists: async (additionalApprovers) =>
                                                await existsRule(`ringis/${ringi.id}/approvers/${additionalApprovers}`, approvalChainLocalization.add_additional_approver_exists_message),
                                            approverIsNotOwner: (additionalApprovers) => { if (additionalApprovers === ringi.createdByUserId) return approvalChainLocalization.add_additional_approver_is_initiator_message },
                                            inactiveUser: async (approverUserId) => await inactiveRule(`users/${approverUserId}/active`)
                                        }
                                    }}
                                    options={approverAndReaderCandidates}
                                    dropup={true}
                                    labelKey={option => `${option.firstName} ${option.lastName}, ${option.emailAddress}`}
                                    filterBy={["firstName", "lastName", "emailAddress"]}
                                    placeholder={approvalChainLocalization.add_additional_approver_placeholder}
                                    emptyLabel={typeaheadMessagesLocalization.no_approvers_available} />
                                <div className="add-approver-bar__add-approver">
                                    <Button type="submit" data-testid="add-approver-button" className="add-approver-button" disabled={!watchApproverSelection}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            }
        </>
    );
}
