import React, { useState, useEffect } from 'react';
import ExistingCustomFieldPanel from 'pages/Configuration/Panels/ExistingCustomFieldPanel';
import ReadOnlyFieldPanel from 'pages/Configuration/Panels/ReadOnlyFieldPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContextMenu from 'controls/ContextMenu/ContextMenu';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { put } from 'services/api';
import { useModal, ModalBody, ModalButtons, ModalOkButton, ModalCancelButton } from 'controls/ActionModal/ActionModal';
import { useLocale } from 'services/localization/localizationContextProvider';
import classNames from 'classnames';
import { useBaseRingiTemplate, useCategoryTemplate, useTypeTemplate } from 'pages/Configuration/providers/templateContextProvider';
import 'pages/Configuration/Panels/FieldPanel.scss';

export default function FieldPanel({ templateField, locked = false, fieldReload, showDisabledFields, onInEditMode, onNotInEditMode }) {
    const [inEditMode, setInEditMode] = useState(false);
    const [menuItems, setMenuItems] = useState();
    const [editEnabled, setEditEnabled] = useState();
    const [optionsEnabled, setOptionsEnabled] = useState();
    const [DisableTemplateFieldModal, showDisableTemplateFieldModal] = useModal();
    const [EnableTemplateFieldModal, showEnableTemplateFieldModal] = useModal();
    const localizedDisableFieldModal = useLocale().modals.confirmation.field.disabling;
    const localizedEnableFieldModal = useLocale().modals.confirmation.field.enabling;

    const baseRingiTemplate = useBaseRingiTemplate();
    const categoryTemplate = useCategoryTemplate();
    const typeTemplate = useTypeTemplate();

    useEffect(function computePanelIconsEnabledState() {
        if (templateField.isCustom && !locked) {
            setEditEnabled(templateField.isEnabled);
            setOptionsEnabled(true);
        }
        else {
            setEditEnabled(false);
            setOptionsEnabled(false);
        }
    }, [templateField.isCustom, templateField.isEnabled, locked]);

    const handleEdit = () => {
        if (editEnabled) {
            setInEditMode(true);
            onInEditMode();
        }
    };

    const handleCancel = () => {
        setInEditMode(false);
        onNotInEditMode();
    }

    const reloadTemplateFields = () => {
        baseRingiTemplate.reload();
        if (categoryTemplate) {
            categoryTemplate.reload();
        }
        if (typeTemplate) {
            typeTemplate.reload();
        }
    }

    const handleEdited = async () => {
        reloadTemplateFields();
        setInEditMode(false);
        onNotInEditMode();
    }

    const disableTemplateField = async () => {
        await put(`templateFields/${templateField.id}/disable`);
        reloadTemplateFields();
    };

    const enableTemplateField = async () => {
        await put(`templateFields/${templateField.id}/enable`);
        reloadTemplateFields();
    };

    useEffect(function computeMenuItems() {
        if (optionsEnabled) {
            setMenuItems([
                {
                    text: localizedDisableFieldModal.menu_option_title,
                    condition: () => templateField.isEnabled,
                    action: showDisableTemplateFieldModal
                },
                {
                    text: localizedEnableFieldModal.menu_option_title,
                    condition: () => !templateField.isEnabled,
                    action: showEnableTemplateFieldModal
                }
            ]);
        }
    }, [
        optionsEnabled,
        templateField.isEnabled,
        localizedDisableFieldModal,
        localizedEnableFieldModal,
        showDisableTemplateFieldModal,
        showEnableTemplateFieldModal
    ]);
    return (
        <>
            <div data-testid={`template-field-container-${templateField.id}`} className={classNames('template-field-container', { 'template-field-container--read-only': !inEditMode }, { 'template-field-container--disabled': !templateField.isEnabled })}>
                {
                    inEditMode
                        ? <ExistingCustomFieldPanel templateField={templateField} onCancel={handleCancel} onEdited={handleEdited} />
                        : (
                            <>
                                <ReadOnlyFieldPanel templateField={templateField} fieldReload={fieldReload} showDisabledFields={showDisabledFields} />
                                <div className="panel-icons">
                                    <FontAwesomeIcon
                                        className={classNames('panel-icon', { 'panel-icon--disabled': !editEnabled })}
                                        icon={faEdit}
                                        onClick={handleEdit}
                                    />
                                    <ContextMenu menuItems={menuItems} />
                                </div>
                            </>
                        )
                }
            </div>

            <DisableTemplateFieldModal variant='confirmation' title={localizedDisableFieldModal.title}>
                <ModalBody>{localizedDisableFieldModal.message}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton />
                    <ModalOkButton text={localizedDisableFieldModal.button_text} onClick={disableTemplateField} />
                </ModalButtons>
            </DisableTemplateFieldModal>

            <EnableTemplateFieldModal variant='confirmation' title={localizedEnableFieldModal.title}>
                <ModalBody>{localizedEnableFieldModal.message}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton />
                    <ModalOkButton text={localizedEnableFieldModal.button_text} onClick={enableTemplateField} />
                </ModalButtons>
            </EnableTemplateFieldModal>
        </>
    );
}
