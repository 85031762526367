import React, { useState } from 'react';
import ReportSummaryRow from "./ReportSummaryRow";
import './ReportSummaryDropdown.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

const ReportSummaryDropdown = ({ data, i }) => {
    const [isOpen, setOpen] = useState(true);

    return (
        <>
            <tr className="report-summary__header">
                <td colSpan="5" onClick={() => { setOpen((prev) => !prev); }}>
                    <div>
                        <div className={`report-summary__header-icon ${!isOpen ? "closed" : ""}`} ><i>
                            <FontAwesomeIcon icon={faAngleUp} />
                        </i></div>{data.category}</div>
                </td>
            </tr>
            {data.summaryReports.map((report) => (
                <ReportSummaryRow data={report} className={`report-summary__dropdown ${!isOpen ? "closed" : ""}`} />
            ))}
        </>
    );
}

export default ReportSummaryDropdown;