import React, { useCallback, useEffect, useState } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useLocale } from 'services/localization/localizationContextProvider';
import { fetch, post } from 'services/api';
import classNames from 'classnames';
import { useModal, ModalBody, ModalButtons, ModalOkButton, ModalCancelButton } from 'controls/ActionModal/ActionModal';
import ContextMenu from 'controls/ContextMenu/ContextMenu';
import 'pages/Approval/components/ApprovalTemplateTab.scss';

const ApprovalTemplateTab = ({ approvalTemplate, refreshApprovalTemplates, showDisabledTemplates }) => {
    const [menuItems, setMenuItems] = useState([]);
    const localized = useLocale();
    const localizedConfirmationModal = useLocale().modals.confirmation.approvalTemplate;
    const localizedWarningMessage = useLocale().pages.approval;
    const [ DisableApprovalTemplateConfirmationModal, showDisableApprovalTemplateConfirmationModal ] = useModal();
    const [ EnableApprovalTemplateConfirmationModal, showEnableApprovalTemplateConfirmationModal ] = useModal();
    const [ ApprovalTemplateInUseModal, showApprovalTemplateInUseModal ] = useModal();
    const history = useHistory();

    const handleDisableApprovalTemplateClick = useCallback(async () => {
        const isApprovalTemplateInUse = await fetch(`approvalTemplates/${approvalTemplate.id}/isInUse`);
        if (isApprovalTemplateInUse) {
            showApprovalTemplateInUseModal();
        }
        else {
            showDisableApprovalTemplateConfirmationModal();
        }
    });

    const handleEnableApprovalTemplateClick = useCallback(async () => {
        showEnableApprovalTemplateConfirmationModal();
    });

    useEffect(() => {
        setMenuItems([
            {
                text: localized.modals.confirmation.approvalTemplate.menu.disable,
                condition: () => approvalTemplate.isEnabled,
                action: async () => await handleDisableApprovalTemplateClick()
            },
            {
                text: localized.modals.confirmation.approvalTemplate.menu.enable,
                condition: () => !approvalTemplate.isEnabled,
                action: () => handleEnableApprovalTemplateClick()
            }
        ]);
    }, [approvalTemplate.isEnabled]);

    const disableApprovalTemplate = async () => {
        var response = await post(`approvalTemplates/${approvalTemplate.id}/disable`);
        if (response != null) {
            if (history.location.pathname === `/admin/approval/${approvalTemplate.id}`) {
                history.push("/admin/approval");
            }
            await refreshApprovalTemplates();
        }
    };

    const enableApprovalTemplate = async () => {
        var response = await post(`approvalTemplates/${approvalTemplate.id}/enable`);
        if (response != null) {
            history.push(`/admin/approval`);
            await refreshApprovalTemplates();
        }
    };

    return (
        <>
        {((showDisabledTemplates && !approvalTemplate.isEnabled) || (!showDisabledTemplates && approvalTemplate.isEnabled)) &&
        <>
            <div className={classNames("approval-template-tab-container", { "approval-template-tab--disabled": !approvalTemplate.isEnabled})} data-testid={`approval-template-tab-${approvalTemplate.id}`} >
                <Nav.Link as={NavLink} to={`/admin/approval/${approvalTemplate.id}`} className="approval-template-tab" disabled={!approvalTemplate.isEnabled}>
                    <span data-testid="approval-template-name">{approvalTemplate.name}</span>

                </Nav.Link>
                {approvalTemplate.hasInactiveApprover &&
                    <OverlayTrigger placement="bottom" flip={true} overlay={<Tooltip className="approval-template-tab-warning_tooltip">{localizedWarningMessage.approval_contains_inactive_user}</Tooltip>}>
                        <span className="approval-template-tab-warning__container">
                            <FontAwesomeIcon data-testid="error-icon" icon={faExclamationTriangle} size="1x" className="approval-template-tab-warning" />
                        </span>
                    </OverlayTrigger>
                }
                <ContextMenu menuItems={menuItems} className="approval-template-tab-menu" dropDirection="left" />
            </div>

            <ApprovalTemplateInUseModal variant='warning' title={localized.modals.warning.approvalTemplate.title}>
                <ModalBody>{localized.modals.warning.approvalTemplate.message}</ModalBody>
                <ModalButtons>
                        <ModalOkButton text={localized.modals.warning.approvalTemplate.success} />
                </ModalButtons>
            </ApprovalTemplateInUseModal>

            <DisableApprovalTemplateConfirmationModal variant='confirmation' title={localizedConfirmationModal.disabling.title}>
                <ModalBody>{localized.formatString(localizedConfirmationModal.disabling.message, { approvalTemplatePlaceholder: approvalTemplate.name })}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton />
                    <ModalOkButton text={localizedConfirmationModal.disabling.success}
                        onClick={disableApprovalTemplate} />
                </ModalButtons>
            </DisableApprovalTemplateConfirmationModal>

            <EnableApprovalTemplateConfirmationModal variant='confirmation' title={localizedConfirmationModal.enabling.title}>
                <ModalBody>{localized.formatString(localizedConfirmationModal.enabling.message, { approvalTemplatePlaceholder: approvalTemplate.name })}</ModalBody>
                <ModalButtons>
                    <ModalCancelButton />
                    <ModalOkButton text={localizedConfirmationModal.enabling.success}
                        onClick={enableApprovalTemplate} />
                </ModalButtons>
            </EnableApprovalTemplateConfirmationModal>
        </>
        }
        </>
    );
};

export default ApprovalTemplateTab;