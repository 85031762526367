const en = {
    base_template_editor: {
        autogenerated: " (Autogenerated)",
        currency: "Currency",
        currency_placeholder: "Currency",
        date: "Date",
        date_placeholder: "MM/DD/YYYY",
        date_range: "Date Range",
        date_range_placeholder: "MM/DD/YYYY - MM/DD/YYYY",
        dropdown: "Drop Down",
        dropdown_default_option_text: "Custom Drop Down",
        free_text: "Free Text",
        free_text_placeholder: "Free Text",
        long_free_text_placeholder: "Long Free Text",
        number: "Number",
        number_placeholder: "Number",
        percentage_placeholder: "Percentage",
        required: " (Required)"
    },
    buttons: {
        add: "Add",
        cancel: "Cancel",
        save: "Save",
        ok: "OK",
        attach: "Attach",
        edit: "Edit",
        reply: "Reply",
        remove: "Remove",
        back: "Back",
        cancel_ringi: "Cancel Ringi",
        copy_ringi: "Copy Ringi"
    },
    category_list: {
        category_card: {
            edit_title: "Edit Category Title",
            edit_permissions: "Edit Permissions",
            disable: "Disable Category",
            enable: "Enable Category"
        },
        disable_category_toggle_text: "View Disabled Categories",
        set_permissions_for_users: "Set permissions for specific users",
        select_user_to_view_category: "Select User to View Category",
        no_users_available: "No Users Available",
        one_or_more_users_required: "One or more users must be added",
        category_contains_inactive_user: "Update inactive user"
    },
    configuration_page: {
        disable_field_toggle_text: "View Disabled Fields",
        base_editor: {
            title: "Base Fields"
        },
        base_layer: {
            title: "Base Ringi Configuration",
            type_search_results: "Search Type Results"
        },
        category_editor: {
            title: "Category Fields"
        },
        menu_base: "Base",
        toggle: {
            collapse: "collapse",
            expand: "expand"
        },
        type_editor: {
            approval_section_title: "Approval",
            approval_template_default_option: "Select an Approval Template...",
            approval_template_title: "Approval Template*",
            additional_approver_section_title: "Additional Approvers",
            additional_approver_editor_title: "Additional Approver",
            additional_approver_add_button: "Add Additional Approver",
            additional_reader_section_title: "Additional Readers",
            additional_reader_editor_title: "Additional Reader",
            additional_reader_add_button: "Add Additional Reader",
            classification_default_option: "Select a Classification...",
            classification_title: "Classification*",
            division_default_option: "Select a Division...",
            division_title: "Division*",
            requires_approval_default_option: "Select an Approval Type...",
            requires_approval_title: "SMC Corporation (Japan) Approval*",
            title: "Type Fields"
        },
        type_list: {
            add_type_text: "Add Type",
            disabled_name_tooltip: "The title cannot be changed as the Type is in use",
            send_email_to_zendesk: " Send email to Zendesk",
            send_email_subtext: "Send email to Zendesk for Ringis approved with this type",
            disable_type_toggle_text: "View Disabled Types",
            type_contains_inactive_user: "Update inactive user"
        },
        type_template_field_editor: {
            default_option_label: "Set as Default Selection",
            enable_type: "Enable Type"
        },
        type_panel_menu: {
            edit_type: "Edit Type"
        },
        search_types: {
            placeholder_text: "Search Types"
        }
    },
    copyright_smc: "© Copyright SMC {year}",
    database_localization_keys: {
        ApprovalRequirement_AdminReview: "Ringi Admin Review",
        ApprovalRequirement_NoApproval: "No Approval",
        ApprovalRequirement_Required: "Approval Required",
        HQClassification_ADM: "ADM",
        HQClassification_ENG: "ENG",
        HQClassification_SAL: "SAL",
        NotificationType_RingiApproved: "Ringi approved by SMC Corporation of America",
        NotificationType_RingiDenied: "Ringi denied by {userSubject}",
        NotificationType_RingiApprovedByProxy: "Ringi approved by Proxy",
        NotificationType_RingiDeniedByProxy: "Ringi denied by Proxy",
        NotificationType_RingiApprovedByHQ: "Ringi approved by SMC Corporation (Japan)",
        NotificationType_RingiDeniedByHQ: "Ringi denied by SMC Corporation (Japan)",
        NotificationType_RequestHQForRingiApproval: "Ringi approved by subsidiary and sent to SMC Corporation (Japan) for approval",
        NotificationType_ApproversAddedNeedApproval: "A Ringi requires your approval",
        NotificationType_ApproverAddedNeedsApproval: "A Ringi requires your approval",
        NotificationType_ReadersAddedToRingi: "You have been added as a reader of a Ringi",
        NotificationType_ReaderAddedToRingi: "You have been added as a reader of a Ringi",
        NotificationType_CommentAddedToRingi: "A comment was added to your Ringi",
        NotificationType_ReplyAddedToRingi: "A reply was added to your Ringi",
        NotificationType_ReplyMadeToComment: "A reply was made to your comment",
        NotificationType_RingiApprovedByHQNotifyApprovers: "Ringi approved by SMC Corporation (Japan)",
        NotificationType_RingiDeniedByHQNotifyApprovers: "Ringi denied by SMC Corporation (Japan)",
        NotificationType_RingiApprovedFinalNotifyReaders: "Ringi approved by SMC Corporation of America",
        NotificationType_RingiDeniedFinalNotifyReaders: "Ringi denied by SMC Corporation of America",
        NotificationType_RingiApprovedFinal: "Ringi approved by SMC Corporation of America",
        NotificationType_RingiDeniedFinal: "Ringi denied by {userSubject}",
        NotificationType_UserImportFileMissing: "A failure occurred during the FTP retrieval process for the User Import file",
        NotificationType_CostCenterImportFileMissing: "A failure occurred during the FTP retrieval process for the Cost Center Manager Import file",
        NotificationType_RingiApprovedByProxyNotifyReader: "Ringi approved by Proxy",
        NotificationType_RingiDeniedByProxyNotifyReader: "Ringi denied by Proxy",
        NotificationType_TopUserRoleNotDeterminedInRollUp: "Approval Chain Issue - VP Not Determined",
        NotificationType_ApprovalChainRollUpGroupHasLoop: "Approval Chain Issue - Rollup Group is Causing a Loop",
        NotificationType_RingiApprovedByHQNotifyReaders: "Ringi approved by SMC Corporation (Japan)",
        NotificationType_RingiDeniedByHQNotifyReaders: "Ringi denied by SMC Corporation (Japan)",
        NotificationType_RingiApprovalOverdue: "Ringi awaiting your review",
        NotificationType_RingiApproverNoLongerActive: "Ringi approver {userSubject} is no longer active",
        NotificationType_RingiReaderNoLongerActive: "Ringi reader {userSubject} is no longer active",
        NotificationType_RingiSubmitterNoLongerActive: "Ringi submitter {userSubject} is no longer active",
        NotificationType_RingiApprovalOverdueNotifyAdmin: "Ringi awaiting approval by {userSubject} for {overdueDays} days",
        NotificationType_CostCentersNotImportedNotifyAdmin: "Cost Centers from the import process have an incorrect format or do not exist",
        NotificationType_InvalidCostCenterMgrsNotifyAdmin: "Cost Center Managers from the import process are inactive or do not exist",
        NotificationType_RingiCancelled: "Ringi has been cancelled by {userSubject}",
        NotificationType_RingiApprovedConditionally: "Ringi has been approved with conditions by {userSubject}",
        NotificationType_RingiPaused: "Ringi has been paused by {userSubject}",
        NotificationType_RingiResumed: "Ringi has been resumed by {userSubject}",
        NotificationType_LegacyRingiMigrationFail: "Legacy Ringi migration is overdue; see email notification for details",
        NotificationType_RingiHasInvalidApprovalChain: "Ringi has one or more inactive approvers."
    },
    dropdown_options_tooltip: "Separate each individual drop down option with a semicolon",
    errors_on_form: "Please review errors below",
    fields_must_not_match: "Field values must not match",
    login_button: "Secure Login via OKTA",
    user_is_inactive: "Your user account has not been configured in the Ringi Approval Application.",
    contact_support: "Please contact IT support at support@1smc.com to request access.",
    modals: {
        confirmation: {
            approvalLevel: {
                deleting: {
                    message: "This approval level contains approvers.  Are you sure you want to remove this approval level and all its approvers from the approval process?",
                    success: "Delete",
                    title: "Delete Approvers on Approval Level"
                }
            },
            approvalTemplate: {
                disabling: {
                    message: "Are you sure you want to disable {approvalTemplatePlaceholder}?",
                    success: "Disable",
                    title: "Disable Approval Template"
                },
                enabling: {
                    message: "Are you sure you want to enable {approvalTemplatePlaceholder}?",
                    success: "Enable",
                    title: "Enable Approval Template"
                },
                menu: {
                    enable: "Enable Template",
                    disable: "Disable Template"
                }
            },
            approver: {
                approve: {
                    message: "Are you sure you want to approve this Ringi?",
                    condition: "Add a Condition For Approval",
                    conditionTitle: "Condition",
                    commentMessage: "This will display in the comments tab.",
                    success: "Approve",
                    title: "Approve Ringi"
                },
                deleting: {
                    message: "Are you sure you want to delete the approver {approverCardTitle}?",
                    success: "Delete",
                    title: "Delete Approver"
                },
                deny: {
                    message: "Are you sure you want to deny this Ringi? If so, please enter a reason for denial.",
                    placeholder: "Enter reason for denial",
                    success: "Deny",
                    title: "Deny Ringi"
                },
                removingFromChain: {
                    default_message: "Are you sure you want to remove approver {approverName} from the approval chain?",
                    default_title: "Remove Approver From Approval Chain",
                    ringi_send_to_japan_message: "Removing the approver will cause the Ringi to be sent to Japan for approval.",
                    ringi_send_to_japan_title: "Remove Approver and Send to Japan",
                    ringi_change_status_to_pending_admin_review_message: "Removing the approver will change the Ringi status to Pending Admin Review.",
                    ringi_change_status_to_pending_admin_review_title: "Remove Approver and Change Ringi Status",
                    ringi_will_complete_message: "Removing the approver will cause the Ringi to be completed.",
                    ringi_will_complete_title: "Remove Approver and Complete Ringi",
                    shift_approval_level_message: "Removing the approver will cause the remaining approval levels to be shifted up.",
                    success: "Remove"
                }
            },
            commenter: {
                removing: {
                    message: "Do you want to remove this comment?",
                    success: "Remove",
                    title: "Remove Comment"
                }
            },
            category: {
                disabling: {
                    body: "Are you sure you want to disable {categoryNamePlaceholder}?",
                    button_text: "Disable",
                    title: "Disable Category"
                },
                enable: {
                    body: "Are you sure you want to enable {categoryNamePlaceholder}?",
                    button_text: "Enable",
                    title: "Enable Category"
                }
            },
            field: {
                disabling: {
                    button_text: "Disable",
                    menu_option_title: "Disable Field",
                    type_menu_option_title: "Disable Type",
                    message: "Are you sure you want to disable this Field?  Any disabled Field will not be present on all Ringis created after it has been disabled.",
                    type_message: "Are you sure you want to disable this Type?  Any disabled Type will not be present on all Ringis created after it has been disabled.",
                    title: "Disable Field",
                    type_title: "Disable Type"
                },
                enabling: {
                    button_text: "Enable",
                    menu_option_title: "Enable Field",
                    type_menu_option_title: "Enable Type",
                    message: "Are you sure you want to enable this Field?  All enabled Fields will be present on all Ringis created after it is enabled.",
                    type_message: "Are you sure you want to enable this Type?  All enabled Types will be present on all Ringis created after it is enabled.",
                    title: "Enable Field",
                    type_title: "Enable Type"
                },
                copying: {
                    button_text: "Copy",
                    textbox_title: "Type Title*",
                    placeholder: " (Copy)",
                    dropdown_title: "Category*",
                    type_menu_option_title: "Copy Type",
                    title: "Copy Type",
                    duplicate_field_modal_title: "Duplicate Field Names Warning",
                    duplicate_field_modal_message: `You are copying over duplicate named fields. They will be copied with "(copy)" appended. You can edit and rename them after copying.`
                }
            },
            navigateAway: {
                cancel: "Stay",
                success: "Leave",
                title: "Are you sure you want to leave?"
            },
            removeFileAttachment: {
                cancel: "Cancel",
                message: "Are you sure you want to remove this attachment?",
                success: "Remove",
                title: "Remove Attachment"
            },
            headquarterApproval: {
                title: "SMC Corporation (Japan) Approval",
                message: "Enter the Consent Number to complete the Ringi",
                placeholder: "e.g. AA-BB-12-3456",
                success: "Complete",
                cancel: "Cancel",
                condition: "Add a Condition For Completion",
            },
            disableCostCenter: {
                title: "Disable Cost Center",
                message: "Are you sure you want to disable this Cost Center? Any disabled Cost Center will not be present on all Ringis created after it has been disabled.",
                success: "Disable",
                cancel: "Cancel"
            },
            adminReview: {
                options: {
                    complete: "Complete Ringi",
                    sendToJapan: "Send To Japan"
                },
                complete: {
                    message: "Are you sure you want to complete this Ringi?",
                    condition: "Add a Condition For completion",
                    success: "Complete",
                    title: "Complete Ringi"
                },
                sendToJapan: {
                    message: "Are you sure you want to send this Ringi to Japan for approval?",
                    subMessage: "If so, confirm the email address(es) this Ringi will be sent to.",
                    success: "Send",
                    title: "Send to Japan",
                    dropdownTitle: "Division",
                    dropdownDefaultText: "No Division",
                    addEmailAddressLabel: "Add Email Address",
                    addEmailAddressButton: "Add",
                    invalidEmailAddressFormatMessage: "You have entered an invalid email address format",
                    invalidEmailAddressDomainMessage: "You have entered an invalid SMC Domain"
                }
            },
            ringi: {
                cancel: {
                    message: "Are you sure you want to cancel this Ringi? If so, please enter a reason for cancellation.",
                    placeholder: "Enter reason for cancelling",
                    success: "Cancel Ringi",
                    title: "Cancel Ringi"
                }
            }
        },
        warning: {
            approvalTemplate: {
                message: "This approval template has been used in the creation of a Ringi and cannot be disabled. " +
                    "If this approval template should no longer be used, please disable it after all active Ringis for " +
                    "this approval template have been completed.",
                success: "OK",
                title: "Approval Template In Use"
            },
            categoryInUseEdit: {
                body: "This Category has been used in the creation of a Ringi and cannot be edited. If this Category should no longer be used, please disable it after all active Ringis for this Category have been completed.",
                title: "Category Not Editable"
            },
            categoryInUseDisable: {
                body: "This Category has been used in the creation of a Ringi and cannot be disabled. If this Category should no longer be used, please disable it after all active Ringis for this Category have been completed.",
                title: "Category In Use"
            },
            categoryHasActiveTypes: {
                body: "{categoryNamePlaceholder} has enabled Types. All Types must be disabled before disabling a Category.",
                title: "Disable Category"
            },
            typeNameInUseDisable: {
                body: "This Type has been used in the creation of a Ringi and cannot be disabled. If this Type should no longer be used, please disable it after all active Ringis for this Type have been completed.",
                title: "Disable Type"
            },
            divisionIsDisabled: {
                body: "The Division, {divisionName}, for this type has been disabled. Please enable {divisionName} before enabling this type.",
                title: "Division Is Disabled"
            }
        },
        error: {
            default: {
                messageSorry: "We are sorry, something went wrong!",
                messageContactSupport: "If this error persists, please contact support at",
                messageErrorId: "Ringi Error Id:",
                ok: "Ok",
                title: "Error"
            }
        }
    },
    nav_menu_links: {
        approval: "Approval",
        configuration: "Configuration",
        category: "Categories",
        cost_center: "Cost Center",
        division_management: "Division Management",
        application_settings: "Application Settings",
        logout: "Log Out",
        notification_preferences: "Notification Preferences",
        report_app_issue: "Report an App Issue",
        reports: "Reports",
        ringis: "Ringis"
    },
    pages: {
        approval: {
            approval_list_toggle_label: "View Disabled Approval Templates",
            approval_type_additional_approvers: "Additional Approvers",
            approval_type_additional_approvers_description: "Approvers listed on Ringi Type configuration",
            approval_type_additional_approvers_short_description: "Approvers listed on Ringi",
            approval_type_cost_center: "Cost Center Mgmt Chain",
            approval_type_cost_center_description: "Management chain from Cost Center Manager to Director",
            approval_type_cost_center_short_description: "From Manager to Director",
            approval_type_default_value: "Select an approval type...",
            approval_type_label_text: "Approval Type",
            approval_type_smc_employee: "SMC Employee",
            new_approval_template_button_text: "New Approval Template Name*",
            new_approver_title: "Add Approver",
            no_approval_templates_message: "Create Approval Template to Begin",
            select_approval_template_message: "Select an Approval Template to Begin",
            smc_employee_approver_label_text: "Approver",
            approval_contains_inactive_user: "Update inactive user"
        },
        division: {
            division_management_title: "Division Management",
            select_division_message: "Select a Division to Begin",
            no_divisions_message: "Create Division to Begin",
            no_division_recipients_message: "At least one individual needs to be associated with the division.",
            add_email_address_label: "Email Address*",
            add_full_name_label: "Full Name*",
            add_email_address_button: "Add",
            missing_email_address_format_message: "Email Address is required",
            invalid_email_address_format_message: "You have entered an invalid Email Address format",
            invalid_email_address_domain_message: "You have entered an invalid SMC Domain",
            division_email_select_header: "Email addresses associated with the division",
            set_as_primary: "Set As Primary",
            full_name_required_message: "Full Name is required",
            division_dirty_message: "You have unsaved changes to the division.",
            set_primary_tooltip: "This email address will appear in the 'To' field in the email and non-primary email addresses will appear in the 'CC'",
            toggle_view_disabled_divisions: "View Disabled Divisions",
            division_list: {
                add_division_input_title: "New Division Name*",
                edit_division_input_title: "Division Name*",
                add_division_select_title: "Classification*",
                add_division_unique_error: "Must be unique",
                division_select_placeholder: "Select a Classification",
                cancel: "Cancel",
                save: "Save",
                context_menu: {
                    edit: "Edit Division",
                    disable: "Disable Division",
                    enable: "Enable Division"
                },
                modals: {
                    in_use_title: "Division In Use",
                    in_use_message: "This Division is currently being used in the following Ringi Type configuration(s) and cannot be disabled. If the Division should no longer be used, disable it after it has been removed from the configuration(s).",
                    disable_title: "Disable Division",
                    disable_message: "Are you sure you want to disable this  Division?",
                    disable_confirm: "Disable",
                    enable_title: "Enable Division",
                    enable_message: "Are you sure you want to enable this Division?",
                    enable_confirm: "Enable",
                    cancel: "Cancel"
                }
            }
        },
        cost_center: {
            context_menu: {
                disable: "Disable Cost Center",
                edit: "Edit Cost Center",
                enable: "Enable Cost Center"
            },
            description_label: "Description*",
            edit_cost_center_title: "Edit Cost Center",
            new_button: "Add Cost Center",
            new_cost_center_title: "New Cost Center",
            number_label: "Number*",
            page_title: "Cost Center",
            display_disabled_cost_centers_toggle: "View Disabled Cost Centers"
        },
        draft_ringi: {
            cancel_button: "Cancel",
            page_title_when_editing: "Edit Ringi",
            page_title_when_new: "Create New Ringi",
            save_button: "Save",
            save_as_draft_notification_message: "Your changes have been saved as a draft.",
            save_as_draft_notification_title: "Changes Saved",
            submit_button: "Submit",
            submitted_notification_message: "Your changes have been submitted.",
            submitted_notification_title: "Changes Submitted",
            save_as_draft_error_notification_message: "There is an issue with this Ringi's Approval chain. One or more approvers are currently inactive. It has been saved with a status of Draft - Error and the Ringi administrators have been notified for further action.",
            save_as_draft_error_notification_title: "Changes Saved With Draft - Error Status",
            ringi_admin_notified_for_corrections_message: "The Ringi Admin has been notified that corrections are needed for your Ringi and will contact you."
        },
        my_ringis: {
            select_or_create_ringi_message: "Select a Ringi or Create a New Ringi to Begin"
        },
        reports: {
            heading: "Summary Reports",
            description: "These reports are summarized by specific fields that exist on each Ringi and are displayed for a specific year. You can switch the year you are viewing by using the drop down in the upper right corner.",
            primary_column_headers: {
                title: "Category",
                ringi_number: "# of Ringis",
                approved: "Approved",
                denied: "Denied",
                total: "Total"
            },
            cost_center_column_headers: {
                title: "Cost Center",
                ringi_number: "# of Ringis",
                approved: "Approved",
                denied: "Denied",
                total: "Total"
            },
            category_title: "Category Summary",
            type_title: "Type Summary",
            cost_center_title: "Cost Center Summary",
            report_year: "Report Year:",
        },
        view_ringi: {
            approval_chain: {
                add_additional_approver_exists_message: "Employee Already on Approval Chain",
                add_additional_approver_is_initiator_message: "Ringi submitter/initiator cannot be added to the approval chain.",
                add_additional_approver_placeholder: "Add Approver",
                add_additional_approver_title: "Select Approver",
                add_approver_text: "Add Approver",
                approved_status: "Approved",
                denied_status: "Denied",
                headquarter_approval_card_name: "SMC Corporation (Japan) Approval",
                pending_approval_status: "Notified",
                proxy_mode: "Proxy Mode",
                pending_notification_status: "Pending Notification",
                proxy_approved_status: "Approved by Proxy",
                proxy_denied_status: "Denied by Proxy",
                approve_by_proxy_label: "Approve by proxy"
            },
            comments: {
                no_comments_message: "There are no comments to display",
                pause_approval_require_response: "Pause Ringi approval and require response",
                add_attachment_text: "Add Attachment",
                feedback_placeholder_text: "Enter Feedback",
                edited_text: " (edited)",
                reply_placeholder_text: "Enter your reply here",
                ringi_denied: "Ringi Denied",
                ringi_canceled: "Ringi Cancelled",
                conditional_approval_comment_text: "CONDITIONAL APPROVAL - REVIEW NEEDED",
                pause_ringi_for_reply: "Pause Ringi for reply",
                pause_ringi_for_reply_long: "Temporarily pause Ringi to wait for a reply",
                pause_reply_needed_text: "REPLY NEEDED"
            },
            readers: {
                add_reader_exists_as_reader_message: "Employee Already a Reader",
                add_reader_exists_as_approver_message: "Employee Already an Approver",
                add_reader_placeholder: "Add Reader",
                add_reader_text: "Add Reader",
                add_reader_title: "Select Reader",
                add_reader_is_creator_message: "Ringi submitter/initiator cannot be added as an additional Reader",
                read_text: "read",
                unread_text: "unread",
                action_text: "Mark as",
                message: "Would you like to remove this reader?",
                success: "Remove",
                title: "Remove Reader"
            },
            history_logs: {
                no_history_logs_message: "There are no history logs to display",
                event_1_message: "Submitted by param1",
                event_2_message: "Approved by param1",
                event_3_message: "Denied by param1",
                event_4_message: "Ringi proxy approved by param1",
                event_5_message: "Ringi proxy denied by param1",
                event_6_message: "Ringi approved by SMC Corporation (Japan)",
                event_7_message: "Ringi denied By SMC Corporation (Japan)",
                event_8_message: "Ringi approved by subsidiary and sent to SMC Corporation (Japan) for approval",
                event_9_message: "Ringi approved by subsidiary and sent to SMC Corporation (Japan) for approval by param1",
                event_10_message: "Additional approver param2 added to Ringi by param1",
                event_11_message: "Reader param1 added at submit",
                event_12_message: "Additional reader param1 added to Ringi",
                event_13_message: "Ringi Completed by param1",
                event_14_message: "Ringi Cancelled by param1",
                event_15_message: "param1 changed from param2 to param3",
                event_16_message: "Ringi Paused by param1",
                event_17_message: "Ringi Resumed by param1",
                event_18_message: "Approved by param1 with conditions",
                event_19_message: "Ringi proxy approved by param1 with conditions",
                event_20_message: "Ringi approved by SMC Corporation (Japan) with conditions",
                event_21_message: "Ringi Completed by param1 with conditions",
                event_22_message: "Reader param1 removed from Ringi",
                field_update_name_AFCNumber: "AFC Number"
            }
        },
        view_ringis: {
            create_new_ringi_button_text: "Create New Ringi",
            current_ringi_view_option: "Current Ringis",
            submitted_ringi_view_option: "My Submitted Ringis",
            ringis_to_approve_option: "Ringis to Approve",
            ringis_to_read_option: "Ringis to Read",
            draft_ringi_view_option: "My Drafts",
            archived_ringis_option: "Archived Ringis",
            empty_message_current_ringis: "There are no Current Ringis",
            empty_message_review_ringis: "There are no Ringis to approve",
            empty_message_filtered_ringis: "There are no Ringis that match your criteria",
            empty_message_read_ringis: "There are no Ringis to read",
            empty_message_draft_ringis: "There are no Draft Ringis",
            empty_message_archived_ringis: "There are no Archived Ringis",
            set_default_view: "Set as Default View",
            empty_message_submitted_ringis: "No Submitted Ringis to display",
            days_to_approve_text: "Days To Approve",
            search_ringis: {
                ringi_search_placeholder: "Search by Ringi Number, Subject, Purpose, AFC #, or Consent #",
                no_selection_error: "Select a field to search on"
            },
            filter_flyout: {
                filter_button: "   Filters",
                title: "Filter",
                status_field: "Status",
                initiator_field: "Initiator",
                category_field: "Category",
                type_field: "Type",
                costCenter_field: "Cost Center",
                start_date_field: "Submission From",
                end_date_field: "Submission To",
                completed_start_date_field: "Completed From",
                completed_end_date_field: "Completed To",
                apply_button: "Apply",
                apply_and_save_button: "Apply & Set as Default",
                clear_all_filters: "Clear All Filters",
                search_placeholder: "Search",
                status: {
                    approved: "Approved",
                    denied: "Denied",
                    completed: "Completed - approved and rejected",
                    pendingAll: "Pending - All",
                    pendingJapan: "Pending Japan",
                    cancelled: "Cancelled",
                    paused: "Paused",
                    draft: "Draft",
                    draftError: "Draft Error",
                    approvedWithConditions: "Approved With Conditions"
                }
            },
            columns_flyout: {
                columns_button: "   Columns",
                title: "Columns",
                apply_button: "Apply",
                apply_and_save_button: "Apply & Save as Default",
                add_button: "Add Column",
                reset_columns_button: "Revert to original columns"
            }
        },
        notifications: {
            list: {
                empty_message: "There Are No Notifications To Display",
                notifications_tab: "Notifications",
                admin_notifications_tab: "Admin Alerts",
                mark_one_read: "Mark as read",
                mark_one_unread: "Mark as unread",
                mark_all_as_read: "Mark all as read",
                mark_all_read: "Mark all as read",
                hyperlink_message: "Display the Ringi details page and mark as read"
            },
            preferences: {
                page_title: "Notification Preferences",
                instructions: "Adjust your notification preferences below so that you are getting the notifications that are most useful to you. Any of the notifications below that you opt into will be sent to {emailPlaceholder}.",
                approvals: {
                    title: "Approvals",
                    description: "By unchecking this checkbox you will no longer receive notifications via email regarding approvals for any Ringis. You will still be able to see all of your recent notifications within the Ringi application by clicking on the bell icon in the upper right hand corner."
                },
                denials: {
                    title: "Denials",
                    description: "By unchecking this checkbox you will no longer receive notifications via email regarding denials for any Ringis. You will still be able to see all of your recent notifications within the Ringi application by clicking on the bell icon in the upper right hand corner."
                },
                pending: {
                    title: "RINGI's Pending 14+ days",
                    description: "By unchecking this checkbox you will no longer receive notifications via email reminding you there are Ringis pending your approval. You will still be able to see all of your recent notifications within the Ringi application by clicking on the bell icon in the upper right hand corner."
                },
                readers_added: {
                    title: "Readers Added",
                    description: "By unchecking this checkbox you will no longer receive notifications via email when you are added as a reader to any Ringi. You will still be able to see all of your recent notifications within the Ringi application by clicking on the bell icon in the upper right hand corner."
                },
                approvers_added: {
                    title: "Approvers Added",
                    description: "By unchecking this checkbox you will no longer receive notifications via email when you are added as an approver to any Ringis. You will still be able to see all of your recent notifications within the Ringi application by clicking on the bell icon in the upper right hand corner."
                },
                comments_replies: {
                    title: "Comments and Replies",
                    description: "By unchecking this checkbox you will no longer receive notifications via email for any comments or replies added to your Ringis. You will still be able to see all of your recent notifications within the Ringi application by clicking on the bell icon in the upper right hand corner."
                },
                confirmation_saved: "Your notification preferences have been saved.",
                confirmation_opted_out: "You have opted out of all email notifications.",
                buttons: {
                    opt_out_all: "Opt out from all email notifications",
                    save: "Save Notification Preferences"
                }
            }
        },
        application_settings: {
            page_title: "Application Settings",
            edit_button: "Edit",
            cancel: "Cancel",
            save: "Save",
            app_issue_email_addresses: "Enter a semicolon separated list of email addresses",
            japan_email_domains: "Currently, only a single domain should be entered",
            single_email_address: "Enter a single email address",
            sendgrid_email_address: "This email address must match what's configured in the SendGrid account for email messages to function"
        }
    },
    ringi_dirty_message: "You have unsaved changes for your Ringi.",
    legacy_ringi_message: "Imported from Lotus Notes",
    ringi_editor: {
        category_default_value: "Select a category...",
        cost_center_default_value: "Select a cost center",
        required_indicator: "*",
        type_default_value: "Select a type...",
    },
    ringi_field: {
        required_documents_label: "Required Document(s)",
        additional_documents_label: "Additional Document(s)",
        multiple_attachments_label: "Attachment(s)",
        multiple_comment_attachments_label: "Comment Attachment(s)",
        category_label: "Category",
        completed_date_label: "Completed Date",
        cost_center_label: "Cost Center",
        initiator_label: "Initiator",
        purpose_label: "Purpose",
        opportunity_costs: "Opportunity Costs",
        alternatives: "Alternatives",
        ringi_number_label: "Ringi Number",
        afc_number_label: "AFC Number",
        consent_number_label: "Consent Number",
        status_label: "Status",
        subject_label: "Subject",
        submitted_date_label: "Date Submitted",
        type_label: "Type",
        ringi_submitted_by_label: "Submitted By",
        dollar_amount_requested: "Dollar Amount Requested",
        approval_by_label: "Approval By Date",
        days_to_approve_label: "Days to Approve",
        ringi_details_button: "Details",
        ringi_approvers_button: "Approvers",
        ringi_comments_button: "Comments",
        ringi_readers_button: "Readers",
        ringi_history_button: "History",
        ringi_more_button: "More"
    },
    ringi_input: {
        currency_unit_icon: "dollar-sign",
        date_range_end_label: "To",
        date_range_start_label: "From",
        type_default_value: "Select a type..."
    },
    typeahead_messages: {
        no_approvers_available: "No Approvers Available",
        no_readers_available: "No Readers Available"
    },
    validation_messages: {
        only_one_additional_approver_approval_type_message: "Only one additional approver can be assigned to the template",
        only_one_cost_center_approval_type_message: "Only one rollup group can be assigned to the template",
        required: "Required",
        attachment_count_less_than_required: "The number of attachments is less than the number required",
        duplicate_additional_user: "User is already an approver or reader"
    },
    report_app_issue: {
        subject: "Reporting a Ringi Application Issue",
        body: "We're sorry you're experiencing this issue. Please provide the following information and include as much detail as possible. We'll look into this and work on a resolution as soon as possible.%0A%0A- Device Type(Mobile Device or PC)%0A%0A- What browser you are using%0A%0A- Which page in the application you experienced the issue (screen shot)%0A%0A- Description of the issue and any information that may help to reproduce it%0A%0A"
    }
};

export default en;