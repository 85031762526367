import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import TimeAgo from "javascript-time-ago";
import en from 'javascript-time-ago/locale/en.json'
import ja from 'javascript-time-ago/locale/ja.json'
import App from 'App/App';
import { loadEnv } from 'services/environment';

const setTimeAgoLocales = () => {
    TimeAgo.addDefaultLocale(en);
    TimeAgo.addLocale(ja);
}

setTimeAgoLocales();

function setCSSViewportHeightCustomProperty() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

setCSSViewportHeightCustomProperty();

window.addEventListener('resize', () => {
    setCSSViewportHeightCustomProperty();
});

loadEnv().then(() => {
    ReactDOM.render(<App />, document.getElementById('root'));
});