import React, { useEffect, useState } from 'react';
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ValidationError, { GetValidationError } from 'components/ValidationError';
import { useValidationService } from 'providers/validationServiceContextProvider';
import classnames from 'classnames';
import TooltipDescription from 'components/TooltipDescription/TooltipDescription';
import 'controls/ValidationInput/ValidationInput.scss';

export default function ValidationInput({
    fieldName,
    validationRules,
    title,
    className,
    prefixIconName,
    suffixIconName,
    validationLevel,
    defaultValue,
    boldTitle,
    onValidationError,
    onClear,
    tooltipDescription = null,
    showTooltip = false,
    ...rest
}) {
    const { register, unregister, setValue } = useFormContext();
    const inputId = `${fieldName}-input`;
    const { registerField, unRegisterField } = useValidationService();

    const [currentValue, setCurrentValue] = useState("");

    let errorIconOffset = 0;
    const inputType = { ...rest }?.type;
    const isFirefox = navigator.userAgent.includes('Firefox');

    // No offset in FF because input control does not include the calendar icon
    if (!isFirefox) {
        if (inputType === "number" || inputType === "date") {
            errorIconOffset++;
        }
    }
    if (suffixIconName) {
        errorIconOffset++;
    }

    const validationError = GetValidationError(fieldName);

    useEffect(() => {
        if (onValidationError) {
            onValidationError(validationError);
        }
    }, [validationError]);

    useEffect(() => {
        if (validationLevel) {
            registerField(fieldName, validationLevel);
        }

        return () => {
            unRegisterField(fieldName, validationLevel);
        }
    }, [registerField, unRegisterField, validationLevel, fieldName]);

    useEffect(() => {
        return () => {
            if (setValue) { // make sure the parent FormProvider has not already been cleaned up
                setValue(fieldName, '');
                unregister(fieldName);
            }
        }
    }, [fieldName, setValue]);

    const onClearButtonClicked = () => {
        setValue(fieldName, '');
        setCurrentValue("");
        onClear();
    }

    const onCurrentValueChange = (value) => {
        setCurrentValue(value);
        setValue(fieldName, value);
    }

    return (
        <div data-testid="input-wrapper" className={classnames("validation-input", { "validation-input--error": validationError })}>
            {title && <label htmlFor={inputId} data-testid={`${inputId}-label`} className={classnames("validation-input__label", boldTitle ? "text-bold" : '')}>{title}</label>}
            {showTooltip === true && <TooltipDescription tooltipName={`${inputId}-tooltip`} description={tooltipDescription} validationError={validationError} />}
            <div className={classnames("validation-input__wrapper", {
                "validation-input__wrapper--with-prefix": prefixIconName,
                "validation-input__wrapper--with-suffix": suffixIconName
            })}>
                {prefixIconName && <FontAwesomeIcon data-testid='prefix-icon' className="validation-input-wrapper__prefix-icon" icon={prefixIconName} />}
                <input id={inputId}
                    data-testid={inputId}
                    {...register(fieldName, validationRules)}
                    onChange={(e) => onCurrentValueChange(e.target.value)}
                    defaultValue={defaultValue}
                    required={isFirefox && inputType === "date"} // required attribute added for FF to suppress the (x) icon from displaying, does not make required
                    className={classnames("validation-input-wrapper__input", className)} {...rest} />
                {suffixIconName && <FontAwesomeIcon data-testid='suffix-icon' className="validation-input-wrapper__suffix-icon" icon={suffixIconName} />}
                {(onClear && currentValue) &&
                    <span onClick={onClearButtonClicked} data-testid="input-wrapper-clear-button" className="clear-icon-container">
                        <FontAwesomeIcon className="validation-input-wrapper__clear-icon" icon={faTimes} />
                    </span>
                }
                <ValidationError fieldName={fieldName}
                    className={classnames("validation-input-wrapper__icon")} />
            </div>
        </div>
    );
}