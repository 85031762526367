import React, { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner'
import classNames from 'classnames';
import { useLocale } from 'services/localization/localizationContextProvider';
import { useApiState } from 'providers/apiStateProvider';
import { useModal, ModalBody, ModalSection, ModalButtons, ModalOkButton } from 'controls/ActionModal/ActionModal';
import './Loading.scss'

const Loading = ({ children }) => {
    const { isLoading, errorMessage, errorDetails, clearErrorMessage } = useApiState();
    const [ErrorModal, showErrorModal, hideErrorModal] = useModal();

    const localized = useLocale();
    const localizedErrorModal = localized.modals.error.default;

    useEffect(() => {
        if (!isLoading && errorMessage != null) {
            showErrorModal();
        }
        else {
            hideErrorModal();
        }
    }, [isLoading, errorMessage, errorDetails, showErrorModal, hideErrorModal]);

    return (
        <>
            {
                isLoading &&
                <Spinner animation="border" role="status" className="fetch-loader" data-testid="data-loading-spinner">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            }
            <div className={classNames('', { "page__overlay--loading": isLoading })} data-testid="overlay-layer" />
            <div className="page__container" data-testid="main-content">
                {children}
            </div>

            <ErrorModal variant="warning" title={localizedErrorModal.title}>
                {!errorDetails?.isDevEnv ?
                    <>
                        <ModalSection id="1">{localizedErrorModal.messageSorry}</ModalSection>
                        <ModalSection id="2">{localizedErrorModal.messageContactSupport} <a href={"mailto:" + errorDetails?.supportEmail + "?subject=Ringi Error Id: " + errorDetails?.errorId}>{errorDetails?.supportEmail}</a></ModalSection>
                        <ModalSection id="3">{localizedErrorModal.messageErrorId} {errorDetails?.errorId}</ModalSection>
                    </>
                    :
                    <ModalBody>{errorMessage}</ModalBody>
                }
                <ModalButtons>
                    <ModalOkButton text={localizedErrorModal.Ok} onClick={() => clearErrorMessage()} />
                </ModalButtons>
            </ErrorModal>
        </>
    )
}

export default Loading;