import React from 'react';
import { useLocale, formatLocalized } from 'services/localization/localizationContextProvider';

const Copyright = () => {

    const localized = useLocale();

    const copyrightText = formatLocalized(
        localized.copyright_smc, { year: new Date(Date.now()).getFullYear() }
    );

    return (
        <div className="copyright">
            {copyrightText}
        </div>
    );
};

export default Copyright;
