import React, { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBookReader, faHistory, faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { faComment, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import RingiPage, { PageContent, PageSidebar } from 'controls/RingiPage/RingiPage';
import { useScreenDetect } from 'hooks/useScreenDetect';
import ReadOnlyRingiPanel from 'pages/Ringis/components/ReadOnlyRingiPanel';
import ApproverList from 'pages/Ringis/components/Approvers/ApproverList';
import ReaderList from 'pages/Ringis/components/Readers/ReaderList';
import CommentList from 'pages/Ringis/components/CommentList';
import HistoryLogList from 'pages/Ringis/components/HistoryLogList';
import { SubmittedRingiContextProvider } from 'pages/Ringis/providers/submittedRingiContextProvider';
import { useLocale } from 'services/localization/localizationContextProvider';
import 'pages/Ringis/ViewRingiPage.scss';

export default function ViewRingiPage() {
    const { isMobile, isDesktop } = useScreenDetect();
    const [activeTab, setActiveTab] = useState("");
    const [showMoreTabs, setShowMoreTabs] = useState(false);

    const { ringiId } = useParams();
    const localizedRingiField = useLocale().ringi_field;
    const history = useHistory();

    useEffect(() => {
        setActiveTab(isMobile ? "ringiDetails" : "approvers");
    }, [isMobile]);

    const handleTabSelect = (e) => {
        setActiveTab(e);

        if (e === 'more') {
            setShowMoreTabs(true);
        } else {
            setShowMoreTabs(false);
        }
    }

    return (
        <RingiPage>
            <SubmittedRingiContextProvider ringiId={ringiId}>
                <PageContent>
                    {isDesktop &&
                        <>
                            <ReadOnlyRingiPanel />
                        </>
                    }
                </PageContent>
                <PageSidebar className={classNames("view-ringi__sidebar", { "view-ringi__sidebar--delimiter": isDesktop })} columnWidthPixels={390} primaryMobileContent>
                    <Tab.Container activeKey={activeTab} onSelect={handleTabSelect} >
                        <div className="view-ringi-sidebar__tab_header">
                            {isMobile &&
                                <div className="view-ringi_sidebar__back-button-container">
                                    <div data-testid="view-ringi-back-arrow" className="view-ringi__back-button" onClick={() => {history.goBack()}}>
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </div>
                                </div>
                            }
                            <Nav className="view-ringi-sidebar__tabs">
                                {isMobile &&
                                    <Nav.Item data-testid="ringi-details-tab" className="view-ringi-sidebar__tab view-ringi-sidebar__ringi-details-tab">
                                        <Nav.Link eventKey="ringiDetails">
                                            <div>
                                                <div>
                                                    <FontAwesomeIcon icon={faFileSignature} size="lg" />
                                                </div>
                                                <div>
                                                    {localizedRingiField.ringi_details_button}
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                }
                                <Nav.Item className="view-ringi-sidebar__tab">
                                    <Nav.Link eventKey="comments">
                                        {isMobile ?
                                            <div>
                                                <div>
                                                    <FontAwesomeIcon icon={faComment} size="lg" />
                                                </div>
                                                <div>
                                                    {localizedRingiField.ringi_comments_button}
                                                </div>
                                            </div>
                                            :
                                            localizedRingiField.ringi_comments_button
                                        }
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="view-ringi-sidebar__tab">
                                    <Nav.Link eventKey="approvers">
                                        {isMobile ?
                                            <div>
                                                <div>
                                                    <FontAwesomeIcon icon={faThumbsUp} size="lg" />
                                                </div>
                                                <div>
                                                    {localizedRingiField.ringi_approvers_button}
                                                </div>
                                            </div>
                                            :
                                            localizedRingiField.ringi_approvers_button
                                        }
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="view-ringi-sidebar__tab">
                                    <Nav.Link eventKey="readers">
                                        {isMobile ?
                                            <div>
                                                <div>
                                                    <FontAwesomeIcon icon={faBookReader} size="lg" />
                                                </div>
                                                <div>
                                                    {localizedRingiField.ringi_readers_button}
                                                </div>
                                            </div>
                                            :
                                            localizedRingiField.ringi_readers_button
                                        }
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="view-ringi-sidebar__tab">
                                    <Nav.Link eventKey="history">
                                        {isMobile ?
                                            <div>
                                                <div>
                                                    <FontAwesomeIcon icon={faHistory} size="lg" />
                                                </div>
                                                <div>
                                                    {localizedRingiField.ringi_history_button}
                                                </div>
                                            </div>
                                            :
                                            localizedRingiField.ringi_history_button
                                        }
                                    </Nav.Link>
                                </Nav.Item>
                                {isMobile ?
                                    <></>
                                    // If additional tabs are needed in the future, this can be uncommented and used as a go-by to
                                    // add a "More..." button with child menu items for additional menu tabs:
                                    //<Nav.Item className="view-ringi-sidebar__tab view-ringi-sidebar__ringi-details-wide-tab">
                                    //    <Nav.Link eventKey="more">
                                    //        <div>
                                    //            <div>
                                    //                <FontAwesomeIcon icon={faEllipsisH} size="lg" />
                                    //            </div>
                                    //            <div>
                                    //                {localizedRingiField.ringi_more_button}
                                    //            </div>
                                    //        </div>
                                    //    </Nav.Link>
                                    //    <div className={classNames("view-ringi-sidebar__more-tab", { "view-ringi-sidebar__more-tabs-visible": showMoreTabs, "view-ringi-sidebar__more-tabs-invisible": !showMoreTabs })}>
                                    //        <Nav.Link eventKey="foo">
                                    //            <div>
                                    //                <div>
                                    //                    <FontAwesomeIcon icon={faFoo} size="lg" />
                                    //                </div>
                                    //                <div>
                                    //                    {localizedRingiField.ringi_foo_button}
                                    //                </div>
                                    //            </div>
                                    //        </Nav.Link>
                                    //        <Nav.Link eventKey="bar">
                                    //            <div>
                                    //                <div>
                                    //                    <FontAwesomeIcon icon={faBar} size="lg" />
                                    //                </div>
                                    //                <div>
                                    //                    {localizedRingiField.ringi_bar_button}
                                    //                </div>
                                    //            </div>
                                    //        </Nav.Link>
                                    //    </div>
                                    //</Nav.Item>
                                    :
                                    <></>
                                    //<>
                                    //    <Nav.Item className="view-ringi-sidebar__tab">
                                    //        <Nav.Link eventKey="foo">
                                    //            {localizedRingiField.ringi_foo_button}
                                    //        </Nav.Link>
                                    //    </Nav.Item>
                                    //    <Nav.Item className="view-ringi-sidebar__tab">
                                    //        <Nav.Link eventKey="bar">
                                    //            {localizedRingiField.ringi_bar_button}
                                    //        </Nav.Link>
                                    //    </Nav.Item>
                                    //</>
                                }
                            </Nav>
                        </div>
                        <Tab.Content>
                            {isMobile &&
                                <Tab.Pane eventKey="ringiDetails" className="view-ringi-sidebar__tab-content view-ringi-sidebar__ringi-details">
                                    <ReadOnlyRingiPanel />
                                </Tab.Pane>
                            }
                            <Tab.Pane data-testid="comments-tab" eventKey="comments" className="view-ringi-sidebar__tab-content">
                                <CommentList ringiId={ringiId} />
                            </Tab.Pane>
                            <Tab.Pane data-testid="approver-tab" eventKey="approvers" className="view-ringi-sidebar__tab-content">
                                <ApproverList />
                            </Tab.Pane>
                            <Tab.Pane data-testid="reader-tab" eventKey="readers" className="view-ringi-sidebar__tab-content">
                                <ReaderList />
                            </Tab.Pane>
                            <Tab.Pane data-testid="history-tab" eventKey="history" className="view-ringi-sidebar__tab-content">
                                <HistoryLogList ringiId={ringiId} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </PageSidebar>
            </SubmittedRingiContextProvider>
        </RingiPage>
    );
}
