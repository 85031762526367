import React, { useState } from 'react';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SearchBox.scss';

export default function SearchBox({ fieldName, placeholderText, onSearch, onClear }) {
    const [searchString, setSearchString] = useState("");

    const handleSearch = (event) => {
        event.preventDefault();
        onSearch(searchString);
    };

    const onSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
        event.preventDefault();
        onSearch();
        }
    };

    const handleClear = () => {
        setSearchString("");
        onClear();
    }

    return (
        <>
            <form>
                <div className="search-box-container" data-testid={`search-box-${fieldName}`}>
                    <input
                        type="text"
                        data-testid={`search-box-${fieldName}-input`}
                        placeholder={placeholderText}
                        onChange={e => setSearchString(e.currentTarget.value)}
                        value={searchString}
                    />
                    <span className="search-box-button-container">
                        {searchString &&
                        <span className="search-box-button" data-testid={`search-box-${fieldName}-clear-button`} onClick={handleClear}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>}
                        <button className="search-box-button" data-testid={`search-box-${fieldName}-search-button`} onClick={handleSearch} onKeyDown={onSearchKeyDown} disabled={searchString.length <= 2}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </span>
                </div>
            </form>
        </>
    );
};