import React, { useContext, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useUserContextProvider } from 'providers/userContextProvider';

const AuthorizationClaimsContext = React.createContext();
const useAuthorizationClaims = () => useContext(AuthorizationClaimsContext);

const AuthorizationClaimsProvider = ({ children }) => {
    const { oktaAuth, authState } = useOktaAuth();
    const [isAdmin, setIsAdmin] = useState(false);
    const [loginName, setLoginName] = useState();
    const [userName, setUserName] = useState();
    const [userTitle, setUserTitle] = useState();

    const { isUserActiveCalled, isUserActive } = useUserContextProvider();

    useEffect(() => {
        if (authState && authState.accessToken) {
            setIsAdmin(authState.accessToken.claims.ringi_administrator);
        }
    }, [authState]);

    useEffect(() => {
        if (authState && authState.idToken) {
            if (isUserActiveCalled === true && isUserActive === false) {
                window.localStorage.setItem('isUserActive', false);
                oktaAuth.signOut("/");
            }
            else {
                setLoginName(authState.idToken.claims.preferred_username.trim().toLocaleLowerCase());
                setUserName(authState.idToken.claims.name);
                setUserTitle(authState.idToken.claims.title);
            }
        }
    }, [authState]);

    return (
        <AuthorizationClaimsContext.Provider value={{
            isAdmin: isAdmin,
            loginName: loginName,
            userName: userName,
            userTitle: userTitle
        }}>
            {children}
        </AuthorizationClaimsContext.Provider>
    );
}


export {
    AuthorizationClaimsProvider,
    useAuthorizationClaims
};