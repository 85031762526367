import React, { useState, useCallback, useEffect } from 'react';
import { fetch, post, httpDelete } from 'services/api';
import { useLocale } from 'services/localization/localizationContextProvider';
import TypeAdditionalUsersEditor from './TypeAdditionalUsersEditor';

export default function TypeAdditionalApproversEditor({ typeLayerId }) {
    const {
        configuration_page: localizedConfigurationPage,
        typeahead_messages: localizedTypeaheadMessages,
        buttons: localizedButtons,
        validation_messages: localizedValidationMessages
    } = useLocale();

    const localization = {
        section_title: localizedConfigurationPage.type_editor.additional_approver_section_title,
        editor_title: localizedConfigurationPage.type_editor.additional_approver_editor_title,
        editor_open_button: localizedConfigurationPage.type_editor.additional_approver_add_button,
        no_users_available: localizedTypeaheadMessages.no_approvers_available,
        add_button: localizedButtons.add,
        duplicate_additional_user: localizedValidationMessages.duplicate_additional_user
    };

    const [selectedAdditionalApprovers, setSelectedAdditionalUsers] = useState([]);

    const fetchSelectedAdditionalApprovers = useCallback(async () => {
        const selectedAdditionalApprovers = await fetch(`layers/${typeLayerId}/approvers`);
        setSelectedAdditionalUsers(selectedAdditionalApprovers);
    }, [typeLayerId]);

    const onAdditionalApproverSubmit = useCallback(async (approverId) => {
        var response = await post(`layers/${typeLayerId}/approvers/${approverId}`);
        if(response != null){
            fetchSelectedAdditionalApprovers();
        }
        return response;
    }, [fetchSelectedAdditionalApprovers, typeLayerId]);

    const onAdditionalApproverRemove = useCallback(async (approverId) => {
        var response = await httpDelete(`layers/${typeLayerId}/approvers/${approverId}`);
        if(response != null){
            fetchSelectedAdditionalApprovers();
        }
        return response;
    }, [fetchSelectedAdditionalApprovers, typeLayerId]);

    useEffect(() => {
        fetchSelectedAdditionalApprovers();
    }, [fetchSelectedAdditionalApprovers]);

    return (
        <TypeAdditionalUsersEditor
            selectedAdditionalUsers={selectedAdditionalApprovers}
            postUser={onAdditionalApproverSubmit}
            deleteUser={onAdditionalApproverRemove}
            layerId={typeLayerId}
            localization={localization}
        />
    );
}                                                                                                                                                                                                                                                                                                                                                               